import ClearIcon from '@mui/icons-material/ClearOutlined';
import { Box, IconButton, InputBase } from '@mui/material';

interface SOptions {
  searchTerm: string | undefined;
  setSearchTerm: (value: string) => void;
  onSearchTermChange: () => void;
}
export const GridSearch = ({ searchTerm, setSearchTerm, onSearchTermChange }: SOptions) => {
  return (
    <Box border={1} borderRadius={1} borderColor="ActiveBorder">
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search"
        inputProps={{ 'aria-label': 'search google maps' }}
        value={searchTerm}
        name="searchTerm"
        onChange={(event) => {
          setSearchTerm(event.target.value);
          if (event.target.value.length < 3) return;
          onSearchTermChange();
        }}
      />
      <IconButton
        type="button"
        sx={{ p: '10px' }}
        aria-label="search"
        onClick={() => {
          setSearchTerm('');
          onSearchTermChange();
        }}
      >
        <ClearIcon fontSize="small" />
      </IconButton>
    </Box>
  );
};
