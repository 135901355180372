import { cleanColumnName } from '../../../helpers/cleanColumnName.js';

interface NodesOptions {
  graph: string;
  parent: string;
  nodes: Record<string, unknown>;
  scopePath: string[];
  level?: number;
  rootNumber?: number;
}

const rootStyle = `style root fill:none,color:#69D2E7,stroke:#999`;
const highlightStyle = `fill:none,color:#69D2E7,stroke:#999`;
const defaultStyle = `fill:none,color:#999,stroke:#999`;

export const getScopeGraphNodes = ({
  graph,
  parent,
  nodes,
  scopePath,
  level = 0,
  rootNumber = 0,
}: NodesOptions) => {
  let _rootNumber = rootNumber;
  const _level = level;
  let _graph = graph + `${rootStyle}\n`;
  Object.entries(nodes).forEach(([item, _nodes]) => {
    let parentIdentifier = `${_rootNumber}`;
    if (parent === 'root') parentIdentifier = ``;
    _graph =
      _graph +
      `${parent}${parentIdentifier}(${cleanColumnName(
        parent,
      )})-->${item}${_rootNumber}(${cleanColumnName(item)})\n`;

    // console.log(_level, item, scopePath[_level]);

    if (item === scopePath[_level]) {
      _graph = _graph + `style ${item}${_rootNumber} ${highlightStyle}\n`;
    } else {
      _graph = _graph + `style ${item}${_rootNumber} ${defaultStyle}\n`;
    }
    if (_nodes) {
      _graph = getScopeGraphNodes({
        graph: _graph,
        parent: item,
        nodes: _nodes as Record<string, unknown>,
        scopePath,
        level: _level + 1,
        rootNumber: _rootNumber,
      });
      if (parent === 'root') _rootNumber++;
    }
  });
  return _graph;
};
