import { KaeplaSimulationParameterNumeric } from '../../../../../services/kaeplaTypes/Application/KaeplaSimulationParameterNumeric';
import { KaeplaSimulationParameterText } from '../../../../../services/kaeplaTypes/Application/KaeplaSimulationParameterText';
import { KaeplaSimulationPercentage } from '../../../../../services/kaeplaTypes/Application/KaeplaSimulationPercentage';
import { KaeplaSimulationRulesetWithParameters } from '../../../../../services/kaeplaTypes/Application/KaeplaSimulationRulesetWithParameters.js';
import { MatrixTimeSlice } from '../../../../../services/kaeplaTypes/MatrixTimeSlice';

interface Options {
  ruleset: KaeplaSimulationRulesetWithParameters;
  simulationRulesets: KaeplaSimulationRulesetWithParameters[];
  parameter: string;
  parameterYears: number[];
}

export const addNumericParameterToSimulationRulesets = ({
  ruleset,
  parameter,
  simulationRulesets,
  parameterYears,
}: Options) => {
  const newRuleset = { ...ruleset };

  let rulesetParameters: (KaeplaSimulationParameterText | KaeplaSimulationParameterNumeric)[] = [];
  if (newRuleset.parameters) {
    rulesetParameters = [...newRuleset.parameters];
  }

  const percentages: KaeplaSimulationPercentage[] = [];
  parameterYears.forEach((year) => {
    percentages.push({
      key: `${year}`,
      timeSlice: MatrixTimeSlice.year,
      dateFrom: `${year}-01-01`,
      dateUntil: `${year}-12-31`,
      percentage: 0,
    });
  });

  const newParameter: KaeplaSimulationParameterNumeric = {
    dimension: parameter,
    parameterType: 'numeric',
    rulesetId: ruleset.id,
    aggregation: 'SUM',
    percentages,
  };

  rulesetParameters.push(newParameter);

  newRuleset.parameters = rulesetParameters;

  const newRulesets = simulationRulesets.map((r) => {
    if (r.id === ruleset.id) {
      return newRuleset;
    }
    return r;
  });

  if (!newRulesets?.find((r) => r.id === ruleset.id)) {
    newRulesets?.push(newRuleset);
  }

  return newRulesets;
};
