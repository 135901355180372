import { WhereClause } from '@atrigam/atrigam-service-firebase-watcher';
import { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { AIConversation } from './AIConversationalUI/AIConversation';
import { AIConversationalUI } from './AIConversationalUI/AIConversationalUI';
import { AIScrollWrapper } from './AIConversationalUI/AIScrollWrapper';
import { AskQuestion } from './AskQuestion';
import { useAuth } from '../../../AuthReactProvider';
import { promptScopeOnCall } from '../../../services/api/askAiQuestion';
import { addFirestoreCollectionListener } from '../../../services/firestore/addFirestoreCollectionListener';
import { createEvent } from '../../../services/firestore/createEvent';
import { getRandomAiQuestion } from '../../../services/firestore/getRandomAiQuestion';
import { KaeplaDataOperation } from '../../../services/kaeplaTypes/Application/KaeplaDataOperation';
import { KaeplaEventType } from '../../../services/kaeplaTypes/Application/KaeplaEventType';
import { KaeplaFunctionGroup } from '../../../services/kaeplaTypes/Application/KaeplaFunctionGroup';
import { AiQuestionLog } from '../../../services/kaeplaTypes/importer/AiQuestionLog';
import { projectState } from '../../../services/recoil/nonpersistent/projectState';
import { currentScopePathState } from '../../../services/recoil/persistent/currentScopePathState';

export const Conversation = () => {
  const { kaeplaUser } = useAuth();
  const project = useRecoilValue(projectState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const [currentQuestion, setCurrentQuestion] = useState<string>('');
  const [submittedQuestion, setSubmittedQuestion] = useState<string>('');
  const [processing, setProcessing] = useState(false);
  const [aiQuestionLogs, setAiQuestionLogs] = useState<AiQuestionLog[]>([]);
  const [lastLog, setLastLog] = useState<AiQuestionLog>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const logAskQuestion = useMemo(() => {
    if (!kaeplaUser?.uid) return {};
    return {
      uid: kaeplaUser.uid,
      eventType: KaeplaEventType.AI_ASK_QUESTION,
      functionGroup: KaeplaFunctionGroup.AI,
      operation: KaeplaDataOperation.READ,
      project,
    };
  }, [kaeplaUser?.uid, project]);

  const getRandomQuestion = async () => {
    if (!project?.id) return;
    const randomAiQuestion = await getRandomAiQuestion({
      project,
      scopePathStringified: JSON.stringify(currentScopePath),
    });
    if (!randomAiQuestion) {
      return;
    }
    setSubmittedQuestion(randomAiQuestion.question);
    setCurrentQuestion('');
  };

  // AI Questions Log Listener
  useEffect(() => {
    if (!project?.id) return;
    if (!kaeplaUser?.uid) return;

    const fireStorePath = `aiQuestionLogs`;

    const queryWhere: WhereClause[] = [
      {
        fieldPath: 'projectId',
        opStr: '==',
        value: project.id,
      },
      {
        fieldPath: 'uid',
        opStr: '==',
        value: kaeplaUser.uid,
      },
    ];

    const unsubscribe = addFirestoreCollectionListener({
      fireStorePath,
      queryWhere,
      orderBy: {
        fieldPath: 'askedAt',
        direction: 'desc',
      },
      limit: 10,
      callback: (data) => {
        setProcessing(true);
        const _aiQuestionLogs = data as AiQuestionLog[];
        const _aiQuestionLogsSorted = [..._aiQuestionLogs].sort(
          (a, b) => a.askedAt.toMillis() - b.askedAt.toMillis(),
        );
        setLastLog(_aiQuestionLogsSorted.at(-1));
        setAiQuestionLogs(_aiQuestionLogsSorted);
        setTimeout(() => {
          setProcessing(false);
        }, 200);
      },
    });
    return () => {
      unsubscribe();
    };
  }, [kaeplaUser?.uid, project.id]);

  // Prompt
  useEffect(() => {
    if (!kaeplaUser?.uid) return;
    if (!project?.id) return;
    if (submittedQuestion === '') return;

    setProcessing(true);

    promptScopeOnCall({
      callBack: () => {
        void createEvent(logAskQuestion);
        setSubmittedQuestion('');
        setProcessing(false);
      },
      params: {
        scopePathStringified: JSON.stringify(currentScopePath),
        projectId: project.id,
        question: submittedQuestion,
        uid: kaeplaUser.uid,
      },
    });
  }, [project.id, currentScopePath, submittedQuestion, kaeplaUser?.uid, logAskQuestion]);

  return (
    <AIConversationalUI
      scrollWrapper={
        <AIScrollWrapper
          aiQuestionLogs={aiQuestionLogs}
          processing={processing}
          conversation={
            <AIConversation
              setProcessing={setProcessing}
              setCurrentQuestion={setCurrentQuestion}
              aiQuestionLogs={aiQuestionLogs}
              lastLog={lastLog}
              getRandomQuestion={getRandomQuestion}
              submittedQuestion={submittedQuestion}
            />
          }
        />
      }
      askQuestion={
        <AskQuestion
          processing={processing}
          currentQuestion={currentQuestion}
          setCurrentQuestion={setCurrentQuestion}
          setSubmittedQuestion={setSubmittedQuestion}
          getRandomQuestion={getRandomQuestion}
        />
      }
    />
  );
};
