import AddIcon from '@mui/icons-material/AddOutlined';
import { Box, Button, Grid, Toolbar, Typography } from '@mui/material';
import { useState } from 'react';
import { SetterOrUpdater, useRecoilValue } from 'recoil';

import { AggregationSelect } from './features/PerspectiveBlocks/features/AggregationSelect.js';
import { DimensionSelect } from './features/PerspectiveBlocks/features/DimensionSelect.js';
import { isEligibleForTimeDimension } from './features/PerspectiveBlocks/helpers/isEligibleForTimeDimension.js';
import { isEligibleForValueDimension } from './features/PerspectiveBlocks/helpers/isEligibleForValueDimension.js';
import { useAuth } from '../../../AuthReactProvider.js';
import { updatePerspective } from '../../../services/firestore/updatePerspective.js';
import { KaeplaPerspective } from '../../../services/kaeplaTypes/Application/KaeplaPerspective';
import { projectState } from '../../../services/recoil/nonpersistent/projectState';
import { InlineEdit } from '../../features/InlineEdit';

interface Options {
  perspective: Partial<KaeplaPerspective>;
  setPerspective: SetterOrUpdater<Partial<KaeplaPerspective>>;
  addBlock: () => Promise<void>;
}

export const PerspectiveMainParameters = ({ perspective, setPerspective, addBlock }: Options) => {
  const { kaeplaUser } = useAuth();
  const project = useRecoilValue(projectState);
  const [valueDimension, setValueDimension] = useState(
    perspective.valueDimension ?? project.defaultPerspective?.valueDimension,
  );
  const [aggregation, setAggregation] = useState(
    perspective.aggregation ?? project.defaultPerspective?.aggregation ?? 'SUM',
  );
  const [aggregationOverTimeName, setAggregationOverTImeName] = useState(
    perspective.aggregationOverTimeName ??
      project.defaultPerspective?.aggregationOverTimeName ??
      `${valueDimension} ${aggregation}`,
  );
  const [timeDimension, setTimeDimension] = useState(
    perspective.timeDimension ?? project.defaultPerspective?.timeDimension,
  );
  const [isUnsaved, setIsUnsaved] = useState(false);

  if (perspective.locked) return null;

  return (
    <Grid item xs={12}>
      <Toolbar sx={{ minHeight: 16 }} disableGutters variant="dense">
        <DimensionSelect
          setDimension={(value) => {
            setValueDimension(value);
            setIsUnsaved(true);
          }}
          dimension={valueDimension}
          isEligibleForDimension={isEligibleForValueDimension}
        />
        <Box sx={{ width: '3px' }} />
        <AggregationSelect
          setAggregation={(value) => {
            setAggregation(value);
            setIsUnsaved(true);
          }}
          aggregation={aggregation}
        />
        <Box height="auto" p={1}>
          Over
        </Box>
        <DimensionSelect
          setDimension={(value) => {
            setTimeDimension(value);
            setIsUnsaved(true);
          }}
          dimension={timeDimension}
          isEligibleForDimension={isEligibleForTimeDimension}
        />
        <Box sx={{ width: '3px' }} />
        <Box height="auto" p={1}>
          AS
        </Box>
        <Box minWidth={200}>
          <InlineEdit
            value={aggregationOverTimeName}
            callback={(value) => {
              setAggregationOverTImeName(value);
            }}
            changeCallback={(_value) => {
              setIsUnsaved(true);
            }}
          />
        </Box>
        <Box sx={{ width: '3px' }} />
        <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
        <Button
          size="small"
          variant="contained"
          disabled={!isUnsaved}
          onClick={() => {
            const newPerspective = { ...perspective };
            newPerspective.valueDimension = valueDimension;
            newPerspective.aggregation = aggregation;
            newPerspective.timeDimension = timeDimension;
            newPerspective.aggregationOverTimeName = aggregationOverTimeName;
            void updatePerspective({ project, perspective: newPerspective });
            setPerspective(newPerspective);
            setIsUnsaved(false);
          }}
        >
          save
        </Button>
        <Box sx={{ ml: 2 }}>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              void addBlock();
            }}
            disabled={perspective?.createdBy !== kaeplaUser?.uid}
            startIcon={<AddIcon />}
          >
            Add Chart
          </Button>
        </Box>
      </Toolbar>
    </Grid>
  );
};
