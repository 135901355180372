import { Box } from '@mui/material';

import { PivotView } from '../features/PivotView/PivotView';

export const DataViewGrid = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        sx={{
          flexGrow: 1,
          height: '100vh',
        }}
      >
        <PivotView />
      </Box>
    </Box>
  );
};
