import CameraIcon from '@mui/icons-material/CameraAltOutlined';
import { ListItem, ListItemAvatar, Avatar, ListItemText, Box } from '@mui/material';
import { useState } from 'react';

import { useAuth } from '../../../../AuthReactProvider.js';
import { createOrUpdateUser } from '../../../../services/firestore/createOrUpdateUser.js';
import { updateProjectAssignmentsUpdatedAtForUser } from '../../../../services/firestore/updateProjectAssignmentsUpdatedAtForUser.js';
import { KaeplaUser } from '../../../../services/kaeplaTypes/Application/KaeplaUser.js';
import { ImageUpload } from '../../../Layout/features/ImageUpload.js';
import { InlineEdit } from '../../../features/InlineEdit.js';

export const UserAvatarEditor = () => {
  const { kaeplaUser, setKaeplaUser } = useAuth();
  const [uploadAvatar, setUploadAvatar] = useState(false);
  const [newAvatar, setNewAvatar] = useState<string>();

  return (
    <>
      <ListItem dense>
        <ListItemAvatar>
          <Avatar
            src={newAvatar ?? kaeplaUser?.base64Avatar ?? kaeplaUser?.avatar}
            sx={{ width: 80, height: 80, cursor: 'pointer', mr: 3 }}
            onClick={(event) => {
              event.stopPropagation();
              setUploadAvatar(!uploadAvatar);
            }}
          >
            {/* {!kaeplaUser?.avatar && kaeplaUser?.acronym} */}
            <CameraIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          onKeyDown={(event) => event.stopPropagation()}
          sx={{ mr: 3 }}
          primary={
            <InlineEdit
              fullWidth
              dataTestId="user-avatar-editor-display-name"
              value={kaeplaUser?.displayName ?? ''}
              callback={(newValue) => {
                const update = { uid: kaeplaUser?.uid, displayName: newValue };
                setKaeplaUser({ ...kaeplaUser, displayName: newValue } as KaeplaUser);
                void createOrUpdateUser({ user: update });
              }}
            />
          }
          secondary={
            <Box>
              <Box data-testid="user-avatar-editor-email" sx={{ fontSize: '100%' }}>
                {kaeplaUser?.email}
              </Box>
              <Box data-testid="user-avatar-editor-uid" sx={{ fontSize: '80%' }}>
                {kaeplaUser?.uid}
              </Box>
            </Box>
          }
          primaryTypographyProps={{ sx: { fontSize: '110%' } }}
          secondaryTypographyProps={{ component: 'div' }}
        />
      </ListItem>
      <ListItem dense sx={{ display: uploadAvatar ? 'block' : 'none' }}>
        <ImageUpload
          show={uploadAvatar}
          callback={(base64Avatar) => {
            if (!kaeplaUser) return;
            const update = async () => {
              setNewAvatar(base64Avatar);
              await createOrUpdateUser({
                user: { uid: kaeplaUser.uid, base64Avatar },
              });
              setKaeplaUser({ ...kaeplaUser, base64Avatar } as KaeplaUser);
              void updateProjectAssignmentsUpdatedAtForUser({
                uid: kaeplaUser.uid,
              });
            };
            void update();
          }}
        />
      </ListItem>
    </>
  );
};
