import { getApp } from 'firebase/app';
import {
  Timestamp,
  collection,
  getDocs,
  getFirestore,
  limit,
  query,
  where,
} from 'firebase/firestore';

import { KaeplaFilter } from '../kaeplaTypes/Application/KaeplaFilter.js';
import { KaeplaProject } from '../kaeplaTypes/Application/KaeplaProject.js';

interface Options {
  project: KaeplaProject;
  scopePathStringified: string;
}

export const getFiltersForProject = async ({ project, scopePathStringified }: Options) => {
  const data: KaeplaFilter<Timestamp>[] = [];
  if (!project) return data;
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `projects/${project.id}/filters`;
  const firestoreCollection = collection(firestoreDatabase, fireStorePath);
  const firestoreQuery = query(
    firestoreCollection,
    where('scopePathStringified', '==', scopePathStringified),
    limit(20),
  );
  const snapshot = await getDocs(firestoreQuery);
  snapshot.forEach((document_) => {
    const filter = document_.data();
    filter.id = document_.id;
    data.push(filter as KaeplaFilter<Timestamp>);
  });
  return data;
};
