export interface KaeplaImportProgress {
  job: 'none' | 'setup' | 'ingestion' | 'transformation' | 'finalization';
  status: KaeplaImportProgressStatus;
}

export enum KaeplaImportProgressStatus {
  started = 'started',
  finished = 'finished',
  error = 'error',
  none = 'none',
  canceled = 'canceled',
}
