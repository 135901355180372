import { Alert, Divider, Grid, Paper, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';

import { Conversation } from './Conversation.js';
import { projectState } from '../../../services/recoil/nonpersistent/projectState';
import { Layout } from '../../Layout/Layout';
import { ProjectInfoSmall } from '../Perspectives/features/ProjectSummary/features/ProjectInfoSmall.js';

export const ExperimentalAi = () => {
  const project = useRecoilValue(projectState);

  if (!project?.id) return null; // wait for project listener

  return (
    <Layout hasScopeNavigation showCustomerSelector>
      <Grid container spacing={2}>
        <Grid item xs={12} xl={10}>
          <Paper sx={{ p: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={3}>
                <ProjectInfoSmall title={project.name} />
              </Grid>
              <Grid item sm={2}>
                <Divider orientation="vertical" />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={7}
                sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
              >
                <Typography variant="h6">Data Insights Q&A</Typography>
                {project.currentImportId && (
                  <Alert severity="info">An update is currently in progress.</Alert>
                )}
                <Typography variant="body1">
                  We transform your questions into queries for your data. Ask your questions in any
                  language. Results will improve over time.
                </Typography>
                <Typography variant="body1">
                  Up- or down-voting the results helps to improve results even faster!
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} xl={10}>
          <Conversation />
        </Grid>
      </Grid>
    </Layout>
  );
};
