import { Box, Grid, LinearProgress } from '@mui/material';

import { AiQuestionLog } from '../../../../services/kaeplaTypes/importer/AiQuestionLog';
import { Answer } from '../Conversation/Answer';
import { AskARandomQuestion } from '../Conversation/AskARandomQuestion';
import { ContinueConversation } from '../Conversation/ContinueConversation';
import { Question } from '../Conversation/Question';
import { QuestionAndAnswer } from '../Conversation/QuestionAndAnswer';
import { UnsureHowToStart } from '../Conversation/UnsureHowToStart';
import { isOld } from '../helpers/isOld';

interface Options {
  lastLog?: AiQuestionLog;
  aiQuestionLogs: AiQuestionLog[];
  setProcessing: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentQuestion: React.Dispatch<React.SetStateAction<string>>;
  getRandomQuestion: () => Promise<void>;
  submittedQuestion: string;
}

export const AIConversation = ({
  lastLog,
  aiQuestionLogs,
  submittedQuestion,
  setCurrentQuestion,
  getRandomQuestion,
  setProcessing,
}: Options) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={10}>
        <Box height={400} />
      </Grid>
      {aiQuestionLogs.length === 0 && (
        <QuestionAndAnswer setProcessing={setProcessing} a={<UnsureHowToStart />} delay={1000} />
      )}
      {aiQuestionLogs.length === 0 && (
        <QuestionAndAnswer
          setProcessing={setProcessing}
          a={<AskARandomQuestion getRandomQuestion={getRandomQuestion} />}
          delay={3000}
        />
      )}
      <Grid item xs={12}>
        {aiQuestionLogs.map((aiQuestionLog) => (
          <Grid key={aiQuestionLog.id} container spacing={2}>
            <Grid item xs={10}>
              <Question aiQuestionLog={aiQuestionLog} setCurrentQuestion={setCurrentQuestion} />
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={2} />
            <Grid item xs={10}>
              {!aiQuestionLog.answer && submittedQuestion === aiQuestionLog.question && (
                <LinearProgress />
              )}
              {aiQuestionLog.answer && <Answer answer={aiQuestionLog.answer} log={aiQuestionLog} />}
            </Grid>
            <Grid item xs={12} />
          </Grid>
        ))}
        {aiQuestionLogs.length > 0 && lastLog && isOld(lastLog.askedAt) && (
          <QuestionAndAnswer
            setProcessing={setProcessing}
            a={<ContinueConversation />}
            delay={5000}
          />
        )}
      </Grid>
    </Grid>
  );
};
