import { getApp } from 'firebase/app';
import { Timestamp, doc, getFirestore, updateDoc } from 'firebase/firestore';

interface Options {
  projectId: string;
  name: string;
}

export const renameProject = async ({ projectId, name }: Options) => {
  if (!projectId || !name) return;
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `projects/${projectId}`;
  const firestoreDocumentReference = doc(firestoreDatabase, fireStorePath);
  await updateDoc(firestoreDocumentReference, { name, updatedAt: Timestamp.now() });
};
