/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-unassigned-import */
import ClearIcon from '@mui/icons-material/ClearOutlined';
import FullScreenExitIcon from '@mui/icons-material/FullscreenExitOutlined';
import FullScreenIcon from '@mui/icons-material/FullscreenOutlined';
import GapOnlyIcon from '@mui/icons-material/HeightOutlined';
import GapOnlyOffIcon from '@mui/icons-material/HorizontalRuleOutlined';
import TableIcon from '@mui/icons-material/TableViewOutlined';
import { Box, IconButton, InputBase, Paper, Stack, Typography } from '@mui/material';
import {
  GridReadyEvent,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  SideBarDef,
} from 'ag-grid-community';
import { LicenseManager } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useDebouncedCallback } from 'use-debounce';

import 'ag-grid-enterprise/styles/ag-grid.css';
import 'ag-grid-enterprise/styles/ag-theme-quartz.css';
import './style.css';
import { useAuth } from '../../../../AuthReactProvider';
import { getDataForValidation } from '../../../../services/api/getDataForValidation';
import { KaeplaValidationParameters } from '../../../../services/kaeplaTypes/Application/KaeplaValidationParameters';
import { dataViewState } from '../../../../services/recoil/nonpersistent/dataViewState';
import { gridColumnState } from '../../../../services/recoil/nonpersistent/gridColumnState';
import { matrixFilteredState } from '../../../../services/recoil/nonpersistent/matrixFilteredState';
import { perspectiveState } from '../../../../services/recoil/nonpersistent/perspectiveState';
import { projectState } from '../../../../services/recoil/nonpersistent/projectState';
import { simulationState } from '../../../../services/recoil/nonpersistent/simulationState';
import { currentScopePathState } from '../../../../services/recoil/persistent/currentScopePathState';
import { filterSettingsState } from '../../../../services/recoil/persistent/filterSettingState';
import { filterSqlState } from '../../../../services/recoil/persistent/filterSqlState';
import { KaeplaDataView } from '../../../../typings/KaeplaDataView';
import { validationColor } from '../../../Theme/colors';
import { Filters } from '../../../features/Filters/Filters';
import { getColumnDefinitions } from '../../Perspectives/features/PivotView/helpers/getColumnDefinitions';

LicenseManager.setLicenseKey(
  `Using_this_{AG_Grid}_Enterprise_key_{AG-064886}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Atrigam_GmbH}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Kaepla}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Kaepla}_need_to_be_licensed___{Kaepla}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{12_August_2025}____[v3]_[01]_MTc1NDk1MzIwMDAwMA==4fb0cd1dbd971b5836c40b4bead31a90`,
);

export interface IOlympicData {
  athlete: string;
  age: number;
  country: string;
  year: number;
  date: string;
  sport: string;
  gold: number;
  silver: number;
  bronze: number;
  total: number;
}

interface Options {
  gapOnly: boolean;
  setGapOnly: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ValidationGrid = ({ gapOnly, setGapOnly }: Options) => {
  const { kaeplaUser } = useAuth();
  const [columnState, setColumnState] = useRecoilState(gridColumnState);
  const filterSettings = useRecoilValue(filterSettingsState);
  const matrixFiltered = useRecoilValue(matrixFilteredState);
  const simulation = useRecoilValue(simulationState);
  const project = useRecoilValue(projectState);
  const perspective = useRecoilValue(perspectiveState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const filterSql = useRecoilValue(filterSqlState);
  const [dataView, setDataView] = useRecoilState(dataViewState);
  const containerStyle = useMemo(() => ({ width: '100%', height: '90%' }), []);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const gridReference = useRef<AgGridReact>(null);
  const [searchTerm, setSearchTerm] = useState<string>();
  const compareColor = validationColor;

  const defaultColDefinition = useMemo(() => {
    return {
      flex: 1,
      minWidth: 150,
    };
  }, []);

  const autoGroupColumnDefinition = useMemo(() => {
    return {
      minWidth: 200,
      resizable: true,
      pinned: true,
    };
  }, []);

  const sideBar = useMemo<SideBarDef | string | string[] | boolean | null>(() => {
    return {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: false,
            suppressValues: false,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressColumnFilter: true,
            suppressColumnSelectAll: true,
            suppressColumnExpandAll: true,
          },
        },
        // 'filters',
      ],
      defaultToolPanel: 'columns',
    };
  }, []);

  const getRows = useCallback(
    (pivotParameters: IServerSideGetRowsParams) => {
      if (!kaeplaUser?.uid) return;
      if (!currentScopePath) return;
      if (!matrixFiltered?.dimensions?.dimensions) return;
      // console.log('column defs ->', gridReference.current!.api.getColumnDefs());
      const pivotRequestParameters = pivotParameters.request;

      const parameters: KaeplaValidationParameters = {
        uid: kaeplaUser.uid,
        scopePath: currentScopePath,
        projectId: project.id,
        pivotRequestParameters,
        searchTerm,
        gapOnly,
        info: 'ValidationView',
      };

      if (filterSettings.isActive) {
        parameters.filterSql = filterSql;
      }

      const getData = async () => {
        const validationData = await getDataForValidation({
          params: parameters,
          uid: kaeplaUser?.uid,
        });

        const rowData = validationData.rows as unknown[];
        if (rowData) {
          pivotParameters.success({ rowData });
        } else {
          pivotParameters.fail();
        }
      };
      void getData();
    },
    [
      kaeplaUser?.uid,
      currentScopePath,
      matrixFiltered?.dimensions?.dimensions,
      project.id,
      searchTerm,
      gapOnly,
      filterSettings.isActive,
      filterSql,
    ],
  );

  const onSearchTermChange = useDebouncedCallback(() => {
    const dataSource: IServerSideDatasource = {
      getRows,
    };

    // register the datasource with the grid
    gridReference.current!.api.setGridOption('serverSideDatasource', dataSource);
  }, 1000);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onGridReady = useCallback(
    (_parameters_: GridReadyEvent<Record<string, unknown>>) => {
      if (!matrixFiltered?.dimensions?.dimensions) {
        return;
      }
      const dimensions = matrixFiltered?.dimensions?.dimensions;
      const columnDefs = getColumnDefinitions(dimensions, perspective, compareColor);
      gridReference.current!.api.setGridOption('columnDefs', columnDefs);
      // other settings
      gridReference.current!.api.autoSizeAllColumns();

      // register the datasource with the grid
      const dataSource: IServerSideDatasource = {
        getRows,
      };
      gridReference.current!.api.setGridOption('serverSideDatasource', dataSource);
    },
    [getRows, matrixFiltered?.dimensions?.dimensions, perspective, compareColor],
  );

  const onStoreRefreshed = useCallback(() => {
    // is this ever fired?
    // console.log('store refreshed');
    gridReference.current!.api.autoSizeAllColumns();
  }, []);

  const persistColumnState = useCallback(() => {
    if (dataView === KaeplaDataView.Table) return;
    setColumnState(gridReference.current!.api.getColumnState());
  }, [dataView, setColumnState]);

  const loadColumnState = useCallback(() => {
    if (columnState && dataView === KaeplaDataView.Grid) {
      gridReference.current!.api.applyColumnState({
        state: columnState,
        applyOrder: true,
      });
    }
  }, [columnState, dataView]);

  // this re-renders the grid when the simulation, filter or scope changes
  useEffect(() => {
    if (!gridReference.current!.api) {
      return;
    }
    if (!matrixFiltered?.dimensions?.dimensions) {
      return;
    }

    const dataSource: IServerSideDatasource = {
      getRows,
    };

    // register the datasource with the grid
    gridReference.current!.api.setGridOption('serverSideDatasource', dataSource);
  }, [getRows, matrixFiltered?.dimensions?.dimensions, simulation, filterSql]);

  // this does the setup of the grid column definitions
  useEffect(() => {
    if (!gridReference.current!.api) {
      return;
    }
    if (!matrixFiltered?.dimensions?.dimensions) {
      return;
    }

    const dimensions = matrixFiltered?.dimensions?.dimensions;
    const columnDefs = getColumnDefinitions(dimensions, perspective, compareColor);
    gridReference.current!.api.setGridOption('columnDefs', columnDefs);
    // other settings
    gridReference.current!.api.autoSizeAllColumns();
  }, [matrixFiltered?.dimensions?.dimensions, perspective, compareColor]);

  return (
    <Box
      component={Paper}
      variant="outlined"
      borderRadius="3px 3px 0 0"
      sx={{ height: dataView === KaeplaDataView.Table ? 500 : '100%' }}
    >
      <Box padding={1}>
        <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2}>
          <Box border={1} borderRadius={1} borderColor="ActiveBorder">
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search"
              inputProps={{ 'aria-label': 'search google maps' }}
              value={searchTerm}
              name="searchTerm"
              onChange={(event) => {
                setSearchTerm(event.target.value);
                if (event.target.value.length < 3) return;
                onSearchTermChange();
              }}
            />
            <IconButton
              type="button"
              sx={{ p: '10px' }}
              aria-label="search"
              onClick={() => {
                setSearchTerm('');
                onSearchTermChange();
              }}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          </Box>
          <IconButton
            size="small"
            color="primary"
            sx={{ p: '10px' }}
            onClick={() => {
              setGapOnly(!gapOnly);
            }}
          >
            {gapOnly ? (
              <GapOnlyIcon
                sx={{
                  transform: 'rotate(90deg)',
                }}
              />
            ) : (
              <GapOnlyOffIcon />
            )}
          </IconButton>
          <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
          {dataView === KaeplaDataView.Table && (
            <IconButton
              size="small"
              data-testid="user-dialog-close"
              onClick={() => {
                setDataView(KaeplaDataView.Grid);
              }}
            >
              {dataView === KaeplaDataView.Table ? <FullScreenIcon /> : <FullScreenExitIcon />}
            </IconButton>
          )}
          {dataView === KaeplaDataView.Grid && (
            <IconButton
              size="small"
              data-testid="user-dialog-table-view"
              onClick={() => {
                setDataView(KaeplaDataView.Table);
              }}
            >
              <TableIcon />
            </IconButton>
          )}
        </Stack>
      </Box>
      <Box sx={{ p: '10px' }}>
        <Filters />
      </Box>
      <div style={containerStyle}>
        <div style={gridStyle} className="ag-theme-quartz">
          <AgGridReact
            ref={gridReference}
            rowModelType="serverSide"
            onStoreRefreshed={onStoreRefreshed}
            // enableAdvancedFilter={dataView !== DataView.Table && true} // this will take a while to implement on the server side
            // rowGroupPanelShow="always"
            // pivotPanelShow="always"
            // rowData={rowData}
            // columnDefs={columnDefs}
            defaultColDef={defaultColDefinition}
            autoGroupColumnDef={autoGroupColumnDefinition}
            pivotMode={false}
            alwaysMultiSort={true}
            sideBar={dataView === KaeplaDataView.Table ? [] : sideBar}
            // sideBar={dataView !== DataView.Table ? ['columns', 'filters'] : []}
            // serverSidePivotResultFieldSeparator={'_'}
            // removePivotHeaderRowWhenSingleValueColumn={true}
            suppressExpandablePivotGroups={true}
            maxConcurrentDatasourceRequests={1}
            maxBlocksInCache={2}
            purgeClosedRowNodes={true}
            onGridReady={onGridReady}
            // load column state
            onFirstDataRendered={loadColumnState}
            // persist column state
            onColumnVisible={persistColumnState}
            onColumnPinned={persistColumnState}
            onColumnResized={persistColumnState}
            onColumnMoved={persistColumnState}
            onColumnRowGroupChanged={persistColumnState}
            onColumnValueChanged={persistColumnState}
          />
        </div>
      </div>
    </Box>
  );
};
