import { Box, Typography } from '@mui/material';

import { MatrixValidation } from '../../../../services/kaeplaTypes';
import { Validation } from '../components/Validation';

interface Options {
  matrixValidation?: MatrixValidation;
}

export const Validate = ({ matrixValidation }: Options) => {
  const validationResults = matrixValidation?.validations.flatMap(
    (validation) => validation?.rows ?? [],
  );
  if (matrixValidation?.validations.length === 0) {
    return <Typography variant="h3">No validations found</Typography>;
  }
  if (validationResults?.length === 0) {
    return (
      <Typography variant="h3">
        Your data calculates correctly according to the configured validations!
      </Typography>
    );
  }

  return (
    <Box>
      {matrixValidation?.validations.map((validation) => (
        <Validation key={validation.validation} validation={validation} />
      ))}
    </Box>
  );
};
