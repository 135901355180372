import AccountCircle from '@mui/icons-material/AccountCircle';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
} from '@mui/material';
import { useState } from 'react';

export interface SimpleDialogProperties {
  open: boolean;
  onClose: (value: string) => void;
}

export const AddTeamMember = ({ onClose, open }: SimpleDialogProperties) => {
  const [email, setEmail] = useState('');

  const handleClose = () => {
    onClose(email);
    setEmail('');
  };

  const handleCancel = () => {
    setEmail('');
    onClose('');
  };

  return (
    <Dialog
      data-testid="team-add-team-member-dialog"
      onClose={handleCancel}
      onKeyUp={(event) => {
        if (open && event.key === 'Enter') {
          handleClose();
          event.stopPropagation();
        }
      }}
      open={open}
    >
      <DialogTitle data-testid="dialog-title">Invite Team Member</DialogTitle>

      <DialogContent>
        <TextField
          inputProps={{ 'data-testid': 'add-team-member-input' }}
          InputProps={{
            sx: { minWidth: 300 },
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          autoFocus
          margin="dense"
          id="name"
          label="Invite Team Member"
          type="email"
          fullWidth
          variant="standard"
          onChange={(event) => {
            setEmail(event.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button data-testid="dialog-cancel" variant="text" onClick={handleCancel} autoFocus>
          Cancel
        </Button>
        <Button data-testid="dialog-invite" onClick={handleClose} autoFocus>
          Invite
        </Button>
      </DialogActions>
    </Dialog>
  );
};
