import { Box, Grid, lighten } from '@mui/material';
import { Bar } from 'react-chartjs-2';

import { stackedBarChartConfig } from '../../Perspectives/features/PerspectiveBlocks/features/StackedBarChart/stackedBarChart.config';

const actual = [
  {
    label: '_China',
    data: [2_276_315, 5_950_000, 6_699_805],
    backgroundColor: '#fff187',
    borderWidth: 2,
    borderColor: '#128056',
    stack: 'Actual',
  },
  {
    label: '_Spain',
    data: [2_870_896, 3_168_551, 2_165_217],
    backgroundColor: '#c96654',
    borderWidth: 2,
    borderColor: '#128056',
    stack: 'Actual',
  },
  {
    label: '_USA',
    data: [10_355_510, 21_846_209, 24_174_515],
    backgroundColor: '#71e269',
    borderWidth: 2,
    borderColor: '#128056',
    stack: 'Actual',
  },
];

const contract = [
  {
    label: '_China',
    data: [2_176_315, 5_550_000, 6_499_805],
    backgroundColor: lighten('#fff187', 0.5),
    borderWidth: 5,
    borderColor: '#724aaa',
    stack: 'Contract',
  },
  {
    label: '_Spain',
    data: [2_670_896, 3_068_551, 2_265_217],
    backgroundColor: lighten('#c96654', 0.5),
    borderWidth: 5,
    borderColor: '#724aaa',
    stack: 'Contract',
  },
  {
    label: '_USA',
    data: [10_155_510, 21_546_209, 24_074_515],
    backgroundColor: lighten('#71e269', 0.5),
    borderWidth: 5,
    borderColor: '#724aaa',
    stack: 'Contract',
  },
];

const dummyData = {
  labels: ['2024-01-01', '2025-01-01', '2026-01-01'],
  datasets: [...actual, ...contract],
};
export const GroupStack = () => {
  return (
    <Box>
      GroupStack
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box height={240}>
            <Bar options={stackedBarChartConfig as Record<string, unknown>} data={dummyData} />
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ whiteSpace: 'pre-wrap' }}>
          {JSON.stringify(dummyData, null, 2)}
        </Grid>
      </Grid>
    </Box>
  );
};
