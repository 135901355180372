export const getCleanPath = (scopePath: string[]) => {
  const scopePathClone = [...scopePath];
  const cleanScopePath = scopePathClone.map((_level) => {
    let level = _level;
    if (level.includes(':')) {
      level = level.split(':')[0];
    }
    return level;
  });
  return cleanScopePath;
};
