import { Button, Grid } from '@mui/material';
import { useState } from 'react';

import { dummyOnCall } from '../../../../services/api/admin/dummy';

export const Dummy = () => {
  const [response, setResponse] = useState<{ success: boolean; rows: Record<string, unknown>[] }>();
  const [error, setError] = useState<string>();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            dummyOnCall({
              callBack: (_response) => {
                // eslint-disable-next-line no-console
                console.log(_response);
                setResponse(_response);
              },
              errorCallBack: (_error) => {
                setError(_error.message);
              },
              params: {
                projectId: 'test',
              },
              // params: {
              //   metaLogId: metaLog.id,
              //   needsResolution: !metaLog.needsResolution,
              // },
              // uid: kaeplaUser?.uid,
            });
          }}
        >
          test
        </Button>
      </Grid>
      {error && <Grid item>ERROR: {error}</Grid>}
      <Grid item xs={12} sx={{ whiteSpace: 'pre-wrap', fontFamily: 'monospace' }}>
        {JSON.stringify(response, null, 2)}
      </Grid>
    </Grid>
  );
};
