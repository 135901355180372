import { Box, Link, useTheme } from '@mui/material';
import { useRecoilValue } from 'recoil';

import { useAuth } from '../../../../AuthReactProvider';
import { clearAiLogForProjectAndUser } from '../../../../services/firestore/clearAiLogForProjectAndUser';
import { projectState } from '../../../../services/recoil/nonpersistent/projectState';
import { kaeplaAssignmentState } from '../../../../services/recoil/persistent/kaeplaAssignmentState';

export const AIFooter = () => {
  const { kaeplaUser } = useAuth();
  const theme = useTheme();
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  const project = useRecoilValue(projectState);
  return (
    <Box
      bgcolor={theme.palette.primary.light}
      mx={-1}
      mb={-1}
      mt={2}
      px={1}
      sx={{ fontSize: 12, color: theme.palette.primary.main }}
      textAlign="end"
    >
      {kaeplaAssignment && (
        <Link
          href="#"
          sx={{ mr: 8 }}
          onClick={() => {
            if (!project?.id || !kaeplaUser?.uid) return;
            void clearAiLogForProjectAndUser({ projectId: project.id, uid: kaeplaUser?.uid });
          }}
        >
          clear
        </Link>
      )}{' '}
      powered by Atrigam
    </Box>
  );
};
