import { getApp } from 'firebase/app';
import { collection, getDocs, getFirestore, limit, query, where } from 'firebase/firestore';

import { KaeplaProject } from '../kaeplaTypes/Application/KaeplaProject.js';
import { AiQuestionMetaLog } from '../kaeplaTypes/importer/AiQuestionMetaLog.js';

interface Options {
  project: KaeplaProject;
  scopePathStringified?: string;
}

export const getRandomAiQuestion = async ({ project, scopePathStringified }: Options) => {
  const data: AiQuestionMetaLog[] = [];
  if (!project) return data[0];
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `aiQuestionMetaLogs`;
  const firestoreCollection = collection(firestoreDatabase, fireStorePath);
  const whereClauses = [where('projectId', '==', project.id), where('sqlExample', '!=', null)];
  if (scopePathStringified) {
    whereClauses.push(where('scopePathStringified', '==', scopePathStringified));
  }
  const firestoreQuery = query(firestoreCollection, ...whereClauses, limit(50));
  const snapshot = await getDocs(firestoreQuery);
  snapshot.forEach((document_) => {
    const aiQuestionsLogs = document_.data() as AiQuestionMetaLog;
    aiQuestionsLogs.id = document_.id;
    data.push(aiQuestionsLogs);
  });

  return data[Math.floor(Math.random() * data.length)];
};
