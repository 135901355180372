import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';

import { requestLog } from '../../Frontend/helpers/logger.js';
import { KaeplaValidationParameters } from '../kaeplaTypes/Application/KaeplaValidationParameters.js';
import { MatrixApiPivotResponse } from '../kaeplaTypes/MatrixApiPivotResponse.js';

interface KOptions {
  params: KaeplaValidationParameters;
  uid?: string;
}

export const getDataForValidation = async ({ params, uid }: KOptions) => {
  const functions = getFunctions(getApp('kaepla'), 'europe-west3');
  const getValidationData = httpsCallable(functions, 'getDataForValidation');

  requestLog.log(`getDataForValidation({data:${JSON.stringify({ ...params, uid })}})`);
  const result = await getValidationData(params).catch((error) => {
    requestLog.warn('Error calling pivotData', error);
  });

  if (!result?.data) {
    return {} as MatrixApiPivotResponse;
  }

  return result.data as MatrixApiPivotResponse;
};
