import { getFromKaepla } from '../../../../../services/api/getFromKaepla';
import { KaeplaApiParameters } from '../../../../../services/kaeplaTypes/Application/KaeplaApiParameters';
import { KaeplaProject } from '../../../../../services/kaeplaTypes/Application/KaeplaProject';
import { KaeplaQueryType } from '../../../../../services/kaeplaTypes/Application/KaeplaQueryType';
import { KaeplaSimulation } from '../../../../../services/kaeplaTypes/Application/KaeplaSimulation';
import { KaeplaSimulationRulesetWithParameters } from '../../../../../services/kaeplaTypes/Application/KaeplaSimulationRulesetWithParameters.js';

interface Options {
  uid: string;
  project: KaeplaProject;
  simulation: KaeplaSimulation;
  simulationRulesets: KaeplaSimulationRulesetWithParameters[];
  scopePath: string[];
  callBack: (success: boolean) => void;
}

export const saveRulesets = ({
  uid,
  project,
  simulation,
  scopePath,
  simulationRulesets,
  callBack,
}: Options) => {
  if (!simulationRulesets) return;
  const _parameters: KaeplaApiParameters = {
    q: 'setSimulationParameters' as KaeplaQueryType,
    projectId: project.id,
    simulationId: simulation.id,
    p: scopePath,
    rulesets: simulationRulesets,
    s: 'SimulationEditor',
  };

  getFromKaepla({
    params: _parameters,
    uid,
    callBack: (apiResponse) => {
      if (!apiResponse) return;
      const response = apiResponse.response as Record<string, unknown>;
      callBack(!!response.success);
    },
  });
};
