import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { deleteGridSet } from '../../../../../../services/firestore/deleteGridSet';
import { KaeplaGridSetStored } from '../../../../../../services/kaeplaTypes/Application/KaeplaGridSetStored';
import { dataGridSettings } from '../../../../../../services/recoil/nonpersistent/dataGridSets';
import { dataGridState } from '../../../../../../services/recoil/nonpersistent/dataGridState';
import { projectState } from '../../../../../../services/recoil/nonpersistent/projectState';

interface Options {
  openDeleteDialog: boolean;
  setOpenDeleteDialog: React.Dispatch<React.SetStateAction<boolean>>;
  selectedGridSet: KaeplaGridSetStored | null;
}

export const DeleteGridSet = ({
  openDeleteDialog,
  setOpenDeleteDialog,
  selectedGridSet,
}: Options) => {
  const project = useRecoilValue(projectState);
  const resetGridState = useResetRecoilState(dataGridState);
  const resetGridSetting = useResetRecoilState(dataGridSettings);
  const handleClose = () => {
    setOpenDeleteDialog(false);
  };

  return (
    <Dialog open={openDeleteDialog} onClose={handleClose}>
      <DialogTitle>Delete Grid Set</DialogTitle>
      <DialogContent>
        {selectedGridSet && (
          <DialogContentText>
            Are you sure you want to delete Grid Set <b>{selectedGridSet.name}</b>?
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        {selectedGridSet && (
          <Button
            type="submit"
            variant="contained"
            onClick={() => {
              void deleteGridSet({
                project,
                gridSet: selectedGridSet,
              });
              resetGridState();
              resetGridSetting();
              setOpenDeleteDialog(false);
            }}
          >
            Delete
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
