import { Box, Typography } from '@mui/material';

import { ValidationRows } from './ValidationRows';
import { MatrixValidationResult } from '../../../../services/kaeplaTypes';

interface VOptions {
  validation?: MatrixValidationResult;
}
export const Validation = ({ validation }: VOptions) => {
  if (!validation?.rows || validation.rows.length === 0) return null;
  return (
    <Box>
      <Typography sx={{ mb: 1 }} variant="h2">
        {validation.validation}
      </Typography>
      <ValidationRows rows={validation.rows} />
    </Box>
  );
};
