import { Box, Chip, Typography } from '@mui/material';
import numbro from 'numbro';
import { Dispatch, SetStateAction } from 'react';

import { KaeplaDimension } from '../../../../../services/kaeplaTypes/Application/KaeplaDimension.js';

interface ROptions {
  valueDimension?: KaeplaDimension;
  value?: number;
  setAbsoluteSimulationValue: Dispatch<SetStateAction<number>>;
}

export const RoundingSuggestions = ({
  valueDimension,
  value,
  setAbsoluteSimulationValue,
}: ROptions) => {
  if (!valueDimension || !value) return null;
  const numberOfSuggestions = Math.round(value).toString().length;
  const valueRounded = Math.round(value);
  const suggestions: number[] = [];
  for (let index = 2; index <= numberOfSuggestions - 2; index++) {
    const formatted = numbro(valueRounded).format({
      average: true,
      totalLength: index,
    });
    const rounding = numbro.unformat(formatted);
    if (suggestions.includes(rounding)) continue;
    suggestions.push(rounding);
  }

  return (
    <Box>
      <Typography component="span" variant="body2">
        Rounding Shortcuts:
      </Typography>{' '}
      {suggestions.map((suggestion) => (
        <Chip
          sx={{ mt: 1, mb: 1, mr: 1 }}
          size="small"
          key={suggestion}
          label={suggestion}
          onClick={() => {
            setAbsoluteSimulationValue(suggestion);
          }}
        />
      ))}
    </Box>
  );
};
