import { getApp } from 'firebase/app';
import { deleteDoc, doc, getFirestore } from 'firebase/firestore';

interface Options {
  customerId: string;
}

export const deleteCustomer = async ({ customerId }: Options) => {
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `customers/${customerId}`;
  const firestoreDocumentReference = doc(firestoreDatabase, fireStorePath);
  await deleteDoc(firestoreDocumentReference);
};
