import { FirebaseError } from 'firebase/app';
import {
  multiFactor,
  MultiFactorInfo,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  User,
} from 'firebase/auth';
import { Dispatch, SetStateAction } from 'react';

import { KaeplaUser } from '../../../../../services/kaeplaTypes/Application/KaeplaUser.js';
import { mfaLog } from '../../../../helpers/logger.js';
import { reloadUser } from '../reloadUser.js';

interface Options {
  kaeplaUser: KaeplaUser;
  setAddFactor: Dispatch<SetStateAction<boolean>>;
  setVerificationCodeRequired: Dispatch<SetStateAction<boolean>>;
  setVerificationError: Dispatch<SetStateAction<string>>;
  user: User | null;
  verificationCode: string;
  verificationId: string;
  setUser: Dispatch<SetStateAction<User | null>>;
  setEnrolledFactors: Dispatch<SetStateAction<MultiFactorInfo[]>>;
  setEditable: Dispatch<SetStateAction<boolean>>;
}

export const completeNewFactorEnrollment = ({
  kaeplaUser,
  setAddFactor,
  setVerificationCodeRequired,
  setVerificationError,
  user,
  verificationCode,
  verificationId,
  setUser,
  setEnrolledFactors,
  setEditable,
}: Options) => {
  if (verificationId === '' || verificationCode === '' || !kaeplaUser || !user) return;
  const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
  const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

  // Complete enrollment.
  mfaLog.log('enroll new mfa factor');
  void multiFactor(user)
    .enroll(multiFactorAssertion, kaeplaUser.displayName)
    .then(() => {
      setVerificationCodeRequired(false);
      setAddFactor(false);
      setEditable(false);
      mfaLog.log('reload user');
      void reloadUser({ user, setUser, setEnrolledFactors });
    })
    .catch((error: FirebaseError) => {
      setVerificationError(error.code);
    });
};
