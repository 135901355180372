import { MatrixSummary } from '../../../services/kaeplaTypes/MatrixSummary.js';

export interface DimensionPathOptions {
  matrix: MatrixSummary;
  index: number;
  newDimension: string;
  scopePath: string[];
}

export const addDimensionToPath = ({
  matrix,
  index,
  newDimension,
  scopePath,
}: DimensionPathOptions) => {
  if (!matrix?.details) return scopePath;

  const details = matrix.details
    .find((item) => item.columnName === newDimension)
    ?.dimensionDetails.filter((d) => d.value !== null);
  const dimensionDefaultValue = details?.[0]?.value;

  const newScopePath = [...scopePath];
  if (newDimension === '') {
    newScopePath.splice(index, newScopePath.length - index);
  } else {
    if (newScopePath[index]?.includes(':')) {
      const dimension = newScopePath[index].split(':')[0];
      newScopePath[index] = `${dimension}:${newDimension}`;
    } else if (dimensionDefaultValue) {
      newScopePath.splice(index, 1);
      newScopePath[index] = `${newDimension}:${dimensionDefaultValue}`;
    }
  }
  return newScopePath;
};
