import { WhereClause } from '@atrigam/atrigam-service-firebase-watcher';

import { addFirestoreCollectionListener } from '../../../../../services/firestore/addFirestoreCollectionListener';
import { ToolCall } from '../../../../../services/kaeplaTypes';

const BATCH_SIZE = 50;
interface WatcherOptions {
  threadId: string;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setToolCalls: React.Dispatch<React.SetStateAction<ToolCall[]>>;
}
export const watchToolCalls = ({ threadId, setLoading, setToolCalls }: WatcherOptions) => {
  setLoading(true);

  const fireStorePath = `aiToolCalls`;
  const queryWhere: WhereClause[] = [];
  queryWhere.push({
    fieldPath: 'threadId',
    opStr: '==',
    value: threadId,
  });

  const limit = BATCH_SIZE;

  return addFirestoreCollectionListener({
    fireStorePath,
    queryWhere,
    orderBy: {
      fieldPath: 'createdAt',
      direction: 'asc',
    },
    limit,
    callback: (data) => {
      const _toolCalls = data as ToolCall[];
      setToolCalls(_toolCalls);
      setLoading(false);
    },
  });
};
