import TargetsIcon from '@mui/icons-material/FilterCenterFocusOutlined';
import {
  Grid,
  Paper,
  Stack,
  Divider,
  Typography,
  Button,
  Box,
  MenuItem,
  List,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../../../AuthReactProvider';
import { cleanColumnName } from '../../../../Frontend/helpers/cleanColumnName';
import { createEvent } from '../../../../services/firestore/createEvent';
import { KaeplaDataOperation } from '../../../../services/kaeplaTypes/Application/KaeplaDataOperation';
import { KaeplaEventType } from '../../../../services/kaeplaTypes/Application/KaeplaEventType';
import { KaeplaFunctionGroup } from '../../../../services/kaeplaTypes/Application/KaeplaFunctionGroup';
import { KaeplaPerspective } from '../../../../services/kaeplaTypes/Application/KaeplaPerspective';
import { KaeplaProject } from '../../../../services/kaeplaTypes/Application/KaeplaProject';
import { KaeplaTargets } from '../../../../services/kaeplaTypes/Application/KaeplaTargets';
import { targetsColor } from '../../defaults.js';

interface Options {
  project: KaeplaProject;
  perspective: Partial<KaeplaPerspective>;
  targetsForSimulation: KaeplaTargets[];
  createTargetsFromSimulation: () => Promise<void>;
  setCreateTargets: (value: React.SetStateAction<boolean>) => void;
}

export const SimulationTargets = ({
  project,
  perspective,
  targetsForSimulation,
  createTargetsFromSimulation,
  setCreateTargets,
}: Options) => {
  const { kaeplaUser } = useAuth();
  const navigate = useNavigate();
  return (
    <Grid item xs={12}>
      <Paper sx={{ p: 2 }}>
        <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
          <Box sx={{ maxWidth: 600 }}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <TargetsIcon sx={{ color: targetsColor }} />
              <Typography variant="h6">Create Targets form this simulation</Typography>
            </Stack>
            <Typography variant="body2" sx={{ mb: 2 }}>
              You'll be creating{' '}
              <u>{perspective.valueDimension ?? project.defaultPerspective?.valueDimension}</u>{' '}
              target figures for the current scope according to this simulation. For targets in
              other dimensions, change the perspective. Currently only yearly targets are supported.
            </Typography>
            <Button
              variant="contained"
              size="small"
              sx={{ mr: 1 }}
              onClick={() => {
                void createTargetsFromSimulation();
              }}
              disableElevation
            >
              create Targets
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                setCreateTargets(false);
              }}
            >
              cancel
            </Button>
          </Box>
          {targetsForSimulation.length > 0 && (
            <Box sx={{ minWidth: 400 }}>
              <Typography variant="h6">Targets created form this simulation</Typography>
              <List>
                {targetsForSimulation.map((targets) => (
                  <Box
                    key={targets.id}
                    component={MenuItem}
                    disableGutters
                    divider
                    onClick={() => {
                      void createEvent({
                        uid: kaeplaUser?.uid,
                        eventType: KaeplaEventType.TARGETS_SELECT_TARGETS,
                        functionGroup: KaeplaFunctionGroup.TARGETS,
                        operation: KaeplaDataOperation.READ,
                        project,
                        scopePath: targets.scopePath,
                      });
                      navigate(`/Targets/${project.id}/${targets.id}`);
                    }}
                  >
                    {targets.dimension && cleanColumnName(targets.dimension)}
                  </Box>
                ))}
              </List>
            </Box>
          )}
        </Stack>
      </Paper>
    </Grid>
  );
};
