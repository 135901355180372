import { ICellRendererParams } from 'ag-grid-community';
export const FallbackRenderer = (properties: ICellRendererParams) => {
  // console.log(properties.data);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  //   const cellValue = properties.valueFormatted ?? properties.value;

  return (
    <span>
      <span>{JSON.stringify(properties.value)}</span>
    </span>
  );
};
