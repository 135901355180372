import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';

import { AiThreads } from './AiThreads/AiThreads.js';
import { Imports } from './Imports/Imports.js';
import { kaeplaAssignmentState } from '../../../services/recoil/persistent/kaeplaAssignmentState.js';
import { Layout } from '../../Layout/Layout.js';

export const Admin = () => {
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  const [tab, setTab] = useState('1');
  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  if (!kaeplaAssignment) return null;

  return (
    <Layout>
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleTabChange} aria-label="admin overview tabs">
            <Tab value="1" label="Imports" />
            <Tab value="2" label="AI Threads" />
          </TabList>
        </Box>
        <TabPanel sx={{ p: 0 }} value="1">
          <Imports />
        </TabPanel>
        <TabPanel sx={{ p: 0 }} value="2">
          <AiThreads />
        </TabPanel>
      </TabContext>
    </Layout>
  );
};
