import { DelayedLinearProgress } from './DelayedLinearProgress';
import { Layout } from '../Layout/Layout';

export const ProjectLoading = () => {
  return (
    <Layout hasScopeNavigation showCustomerSelector>
      <DelayedLinearProgress loading={true} delay={1000} />
    </Layout>
  );
};
