import { KaeplaAuthenticationProvider } from '../../../../../services/kaeplaTypes/Application/KaeplaAuthenticationProvider';

export const defaultAuthProviders: KaeplaAuthenticationProvider[] = [
  {
    label: 'Email-Link',
    providerId: 'emailLink',
    active: true,
    type: 'emailLink',
    alwaysAvailable: true,
  },
  {
    label: 'Password',
    providerId: 'password',
    type: 'password',
    active: true,
  },
];
