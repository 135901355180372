import { WhereClause } from '@atrigam/atrigam-service-firebase-watcher';
import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useAuth } from '../../AuthReactProvider';
import { addFirestoreCollectionListener } from '../../services/firestore/addFirestoreCollectionListener';
import { KaeplaSimulation } from '../../services/kaeplaTypes/Application/KaeplaSimulation';
import { applicationState } from '../../services/recoil/nonpersistent/applicationState';
import { projectState } from '../../services/recoil/nonpersistent/projectState';
import { simulationsState } from '../../services/recoil/nonpersistent/simulationsState';
import { currentScopePathState } from '../../services/recoil/persistent/currentScopePathState';

interface Options {
  showAllSimulationsForProject?: boolean;
}

export const SimulationsListener = ({ showAllSimulationsForProject }: Options) => {
  const { kaeplaUser } = useAuth();
  const project = useRecoilValue(projectState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const setSimulations = useSetRecoilState(simulationsState);
  const setApp = useSetRecoilState(applicationState);

  // this is the simulationsListener
  useEffect(() => {
    if (!kaeplaUser?.uid) return;
    if (!project?.id) return;
    const fireStorePath = `projects/${project.id}/simulations`;
    const queryWhere: WhereClause[] = [];
    if (!showAllSimulationsForProject && currentScopePath) {
      queryWhere.push({
        fieldPath: 'scopePathStringified',
        opStr: '==',
        value: JSON.stringify(currentScopePath),
      });
    }

    const unsubscribe = addFirestoreCollectionListener({
      fireStorePath,
      queryWhere,
      callback: (data) => {
        const _simulations = data as KaeplaSimulation[];
        setSimulations(_simulations);
        setApp((app) => ({ ...app, simulationListenerActive: true }));
      },
    });
    return () => {
      setApp((app) => ({ ...app, simulationListenerActive: false }));
      unsubscribe();
    };
  }, [
    currentScopePath,
    kaeplaUser?.uid,
    project?.id,
    setApp,
    setSimulations,
    showAllSimulationsForProject,
  ]);
  return null;
};
