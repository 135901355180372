import { Grid, LinearProgress } from '@mui/material';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { CreateProjectFab } from './features/CreateProjectFab.js';
import { NoCustomers } from './features/NoCustomers.js';
import { NoProjects } from './features/NoProjects.js';
import { ProjectCard } from './features/ProjectCard.js';
import { ProjectInCreation } from './features/ProjectInCreation.js';
import { useAuth } from '../../../AuthReactProvider.js';
import { createEvent } from '../../../services/firestore/createEvent.js';
import { KaeplaDataOperation } from '../../../services/kaeplaTypes/Application/KaeplaDataOperation.js';
import { KaeplaEventType } from '../../../services/kaeplaTypes/Application/KaeplaEventType.js';
import { KaeplaFunctionGroup } from '../../../services/kaeplaTypes/Application/KaeplaFunctionGroup.js';
import { applicationState } from '../../../services/recoil/nonpersistent/applicationState.js';
import { projectInCreationState } from '../../../services/recoil/nonpersistent/projectInCreationState.js';
import { projectsOverviewState } from '../../../services/recoil/nonpersistent/projectsOverviewState.js';
import { lastProjectIdState } from '../../../services/recoil/persistent/lastProjectState.js';
import { Layout } from '../../Layout/Layout.js';

export const Projects = () => {
  const { kaeplaUser } = useAuth();
  const app = useRecoilValue(applicationState);
  const lastProject = useRecoilValue(lastProjectIdState);
  const projectInCreation = useRecoilValue(projectInCreationState);
  const projectsOverview = useRecoilValue(projectsOverviewState);

  useEffect(() => {
    if (!kaeplaUser?.uid) return;
    if (!app.initialized) return;
    void createEvent({
      uid: kaeplaUser?.uid,
      eventType: KaeplaEventType.PROJECT_ENTER_PROJECT_OVERVIEW,
      functionGroup: KaeplaFunctionGroup.PROJECTS,
      operation: KaeplaDataOperation.READ,
    });
  }, [app.initialized, kaeplaUser?.uid]);

  if (!app.initialized) {
    return (
      <Layout>
        <LinearProgress data-testid="projects-loading" />
      </Layout>
    );
  }

  return (
    <Layout showCustomerSelector>
      <Grid container spacing={2}>
        <CreateProjectFab />
        <NoCustomers />
        <NoProjects creating={!!projectInCreation} />
        <ProjectInCreation creating={!!projectInCreation} />
        {projectsOverview.map((_project) => (
          <ProjectCard
            key={_project.id}
            project={_project}
            selected={_project.id === lastProject}
          />
        ))}
      </Grid>
    </Layout>
  );
};
