import { ListItem, ListItemText } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-enterprise';

import {
  HumanReadableTimestampType,
  convertTimestamp,
} from '../../../../Frontend/helpers/convertTimestamp';
import { KaeplaUser } from '../../../../services/kaeplaTypes/Application/KaeplaUser';

export const UserUpdatedAtCellRenderer = (properties: ICellRendererParams<KaeplaUser>) => {
  if (!properties.data) return null;
  const user = properties.data;
  const { updatedAt } = user;
  return (
    <ListItem sx={{ p: 0 }} dense disableGutters>
      <ListItemText
        primary={updatedAt ? convertTimestamp(updatedAt, HumanReadableTimestampType.date) : '??'}
        secondary={updatedAt && convertTimestamp(updatedAt, HumanReadableTimestampType.timeago)}
      />
    </ListItem>
  );
};
