import ThumbDownVoteIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbUpVoteIcon from '@mui/icons-material/ThumbUpAlt';
import { Stack, Chip, Box, useTheme } from '@mui/material';
import { blue, green, grey, red } from '@mui/material/colors';
import copy from 'copy-to-clipboard';
import pluralize from 'pluralize';
import TimeAgo from 'react-timeago';
import { useRecoilValue } from 'recoil';

import { AiMetaLogFilters } from '../../../../../services/kaeplaTypes/importer/AiMetaLogFilters';
import { AiQuestionMetaLog } from '../../../../../services/kaeplaTypes/importer/AiQuestionMetaLog';
import { knownCustomersState } from '../../../../../services/recoil/nonpersistent/knownCustomersState';
import { projectAssignmentsState } from '../../../../../services/recoil/nonpersistent/userAssignmentState';

interface Options {
  metaLog: AiQuestionMetaLog;
  dense: boolean;
  setMetaLogFilters: React.Dispatch<React.SetStateAction<AiMetaLogFilters>>;
}

export const AiMetaQuestionInfo = ({ metaLog, dense, setMetaLogFilters }: Options) => {
  const theme = useTheme();
  const projectAssignments = useRecoilValue(projectAssignmentsState);
  const projectAssignment = projectAssignments.find((k) => k.projectId === metaLog.projectId);
  const knownCustomers = useRecoilValue(knownCustomersState);
  const customer = knownCustomers.find((k) => k.id === projectAssignment?.customerId);

  return (
    <Stack direction="row" spacing={1}>
      {dense && metaLog?.lastLoggedAt && (
        <TimeAgo
          style={dense ? { fontSize: 12 } : {}}
          date={metaLog.lastLoggedAt.toDate()}
          max={Number.MAX_SAFE_INTEGER}
        />
      )}
      <Chip
        label={`${pluralize('time', metaLog.askedCount, true)} asked`}
        variant="filled"
        sx={{
          fontSize: dense ? 12 : 14,
          height: dense ? 16 : 'inherit',
          bgcolor: blue[100],
          mr: 1,
        }}
        size="small"
        onClick={() => {
          if (!metaLog.askedCount) return;
          setMetaLogFilters((filters) => ({
            ...filters,
            askedCount: metaLog.askedCount,
          }));
        }}
      />
      <Chip
        icon={dense ? <Box>+</Box> : <ThumbUpVoteIcon />}
        label={`${pluralize('time', metaLog.upvoteCount || 0, true)}`}
        variant="filled"
        sx={{
          fontSize: dense ? 12 : 14,
          height: dense ? 16 : 'inherit',
          bgcolor: metaLog.upvoteCount > 0 ? green[100] : grey[100],
          mr: 1,
        }}
        size="small"
        onClick={() => {
          if (!metaLog.upvoteCount) return;
          setMetaLogFilters((filters) => ({
            ...filters,
            upvoteCount: metaLog.upvoteCount,
          }));
        }}
      />
      <Chip
        icon={dense ? <Box>-</Box> : <ThumbDownVoteIcon />}
        label={`${pluralize('time', metaLog.downvoteCount || 0, true)}${
          metaLog.needsResolution ? '' : ', resolved'
        }`}
        variant={metaLog.needsResolution ? 'filled' : 'outlined'}
        sx={{
          fontSize: dense ? 12 : 14,
          height: dense ? 16 : 'inherit',
          bgcolor: metaLog.downvoteCount > 0 && metaLog.needsResolution ? red[100] : grey[100],
          mr: 1,
        }}
        size="small"
        onClick={() => {
          if (metaLog.needsResolution) {
            setMetaLogFilters((filters) => ({
              ...filters,
              needsResolution: false,
            }));
            return;
          }
          if (!metaLog.downvoteCount) return;
          setMetaLogFilters((filters) => ({
            ...filters,
            downvoteCount: 1,
          }));
        }}
      />
      <Box
        sx={{ cursor: 'pointer', fontSize: dense ? 10 : 12, p: 0.2 }}
        onClick={() => {
          copy(metaLog.id);
        }}
      >
        {metaLog.id}
      </Box>
      {projectAssignment && customer && (
        <Box
          sx={{ cursor: 'pointer', fontSize: dense ? 10 : 12, p: 0.2 }}
          onClick={() => {
            if (!projectAssignment.projectId) return;
            setMetaLogFilters((filters) => ({
              ...filters,
              projectId: projectAssignment.projectId,
            }));
          }}
        >
          {customer.name}:{projectAssignment.projectId}
        </Box>
      )}
      {metaLog.sqlExample && metaLog?.lastAnswer?.checkQuery !== metaLog.sqlExample && (
        <Box
          sx={{
            cursor: 'pointer',
            fontSize: dense ? 10 : 12,
            p: 0.2,
            color: theme.palette.error.main,
          }}
        >
          Prompt SQL
        </Box>
      )}
      {metaLog.sqlExample && (
        <Box
          sx={{
            cursor: 'pointer',
            fontSize: dense ? 10 : 12,
            p: 0.2,
            color: theme.palette.success.main,
          }}
          onClick={() => {
            if (!metaLog.sqlExample) return;
            copy(metaLog.sqlExample);
          }}
        >
          Example SQL
        </Box>
      )}
    </Stack>
  );
};
