import { WhereClause } from '@atrigam/atrigam-service-firebase-watcher';
import UnlockedIcon from '@mui/icons-material/LockOpenOutlined';
import LockedIcon from '@mui/icons-material/LockOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  AvatarGroup,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import copy from 'copy-to-clipboard';
import pluralize from 'pluralize';
import { useEffect, useState, MouseEvent } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { CardMenu } from './CardMenu.js';
import { ResellerDeleteDialog } from './ResellerDeleteDialog.js';
import { ResellerDomains } from './ResellerDomains.js';
import { ResellerRenameDialog } from './ResellerRenameDialog.js';
import { useAuth } from '../../../../AuthReactProvider.js';
import { addFirestoreCollectionListener } from '../../../../services/firestore/addFirestoreCollectionListener.js';
import { createEvent } from '../../../../services/firestore/createEvent.js';
import { createResellerAssignmentForEmail } from '../../../../services/firestore/createResellerAssignmentForEmail.js';
import { getUsers } from '../../../../services/firestore/getUsers.js';
import { removeResellerAssignment } from '../../../../services/firestore/removeResellerAssignment.js';
import { KaeplaDataOperation } from '../../../../services/kaeplaTypes/Application/KaeplaDataOperation.js';
import { KaeplaEventType } from '../../../../services/kaeplaTypes/Application/KaeplaEventType.js';
import { KaeplaFunctionGroup } from '../../../../services/kaeplaTypes/Application/KaeplaFunctionGroup.js';
import { KaeplaReseller } from '../../../../services/kaeplaTypes/Application/KaeplaReseller.js';
import { KaeplaResellerAssignment } from '../../../../services/kaeplaTypes/Application/KaeplaResellerAssignment.js';
import { KaeplaUser } from '../../../../services/kaeplaTypes/Application/KaeplaUser.js';
import { knownCustomersState } from '../../../../services/recoil/nonpersistent/knownCustomersState.js';
import { resellerAssignmentsState } from '../../../../services/recoil/nonpersistent/resellerAssignmentState.js';
import { watcherKeysState } from '../../../../services/recoil/nonpersistent/watcherKeysState';
import { AddTeamMember } from '../../../features/AddTeamMember.js';
import { AddTeamMemberToggle } from '../../../features/AddTeamMemberToggle.js';
import { CustomerAvatar } from '../../../features/CustomerAvatar.js';
import { ResellerAvatar } from '../../../features/ResellerAvatar.js';
import { UserAvatar } from '../../../features/UserAvatar.js';
import { avatarGroupSlot } from '../../defaults.js';

interface Options {
  reseller: KaeplaReseller;
}

export const ResellerCard = ({ reseller }: Options) => {
  const { kaeplaUser } = useAuth();
  const knownCustomers = useRecoilValue(knownCustomersState);
  const setWatcherKeys = useSetRecoilState(watcherKeysState);
  const [resellerAssignments, setResellerAssignments] = useRecoilState(resellerAssignmentsState);
  const resellerCustomers = knownCustomers.filter((c) => c.resellerId === reseller.id);
  const [resellerTeam, setResellerTeam] = useState<KaeplaUser[]>([]);
  const [openAddTeamMember, setOpenAddTeamMember] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openRename, setOpenRename] = useState(false);
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  useEffect(() => {
    if (!reseller.id) return;
    const fireStorePath = `resellerAssignments`;
    const queryWhere: WhereClause[] = [
      {
        fieldPath: 'resellerId',
        opStr: '==',
        value: reseller.id,
      },
    ];
    const unsubscribe = addFirestoreCollectionListener({
      fireStorePath,
      queryWhere,
      callback: (_resellerAssignments) => {
        const assignments = _resellerAssignments as KaeplaResellerAssignment[];
        const load = async () => {
          const userIds = assignments.map((c) => c.uid);
          const uniqueUserIds = userIds.filter((v, index, a) => a.indexOf(v) === index);
          const assignedUsers = await getUsers({ userIds: uniqueUserIds });
          setResellerTeam(assignedUsers);
          setResellerAssignments(assignments);
        };

        void load();
      },
      watcherKeyCallback: (key) => {
        setWatcherKeys((_watcherKeys) => {
          const keyId = `resellerTeam-${reseller.id}`;
          const newWatcherKeys = { ..._watcherKeys };
          newWatcherKeys[keyId] = key;
          return newWatcherKeys;
        });
      },
    });
    return () => {
      unsubscribe();
    };
  }, [reseller.id, setResellerAssignments, setWatcherKeys]);

  const handleMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorElement(null);
  };

  const openAddTeamMemberDialog = () => {
    setOpenAddTeamMember(true);
  };

  const handleClickOpenDelete = () => {
    setAnchorElement(null);
    setOpenDelete(true);
  };

  const handleClickOpenRename = () => {
    setAnchorElement(null);
    setOpenRename(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleCloseRename = () => {
    setOpenRename(false);
  };

  const closeAddTeamMemberDialog = (email: string) => {
    setOpenAddTeamMember(false);
    if (!kaeplaUser?.uid || email.length === 0) return;
    const sendInvite = async () => {
      let emailToInvite = email;
      if (!emailToInvite.includes('@')) {
        emailToInvite = `${email}@kaepla.atrigam.com`;
      }

      await createResellerAssignmentForEmail({
        resellerId: reseller.id,
        email: emailToInvite,
        assignedBy: kaeplaUser.uid,
      });

      void createEvent({
        uid: kaeplaUser?.uid,
        eventType: KaeplaEventType.RESELLER_TEAM_INVITE_USER_TO_TEAM,
        functionGroup: KaeplaFunctionGroup.RESELLER,
        operation: KaeplaDataOperation.CREATE,
        reseller,
        eventInfo: {
          affectedUserEmail: email,
        },
      });
    };
    void sendInvite();
  };

  const wasAssignedByMe = (user: KaeplaUser) => {
    const resellerAssignment = resellerAssignments.find(
      (r) => r.resellerId === reseller.id && r.uid === user.uid,
    );
    if (!resellerAssignment) return false;
    return resellerAssignment.assignedBy === kaeplaUser?.uid;
  };

  const removeTeamMemberFromReseller = (user: KaeplaUser) => {
    const resellerAssignment = resellerAssignments.find(
      (r) => r.resellerId === reseller.id && r.uid === user.uid,
    );
    if (!resellerAssignment) return;
    void removeResellerAssignment({ assignmentId: resellerAssignment.id });
  };

  return (
    <Grid xs={12} sm={6} md={4} lg={3} key={reseller.id} item>
      <Card data-testid="reseller-card">
        <CardHeader
          avatar={
            <ResellerAvatar title={reseller.name} icon={reseller.iconBase64} id={reseller.id} />
          }
          title={
            <Tooltip title={reseller.id}>
              <Box data-testid="reseller-name">{reseller.name}</Box>
            </Tooltip>
          }
          subheader={<Box data-testid="reseller-appname">{reseller.appName}</Box>}
          onClick={() => {
            copy(reseller.id);
          }}
          action={
            <IconButton
              edge="end"
              size="small"
              data-testid="reseller-menu-toggle"
              onClick={(event) => {
                event.stopPropagation();
                handleMenuClick(event);
              }}
              disabled={reseller?.isReadOnly === true}
            >
              <MoreVertIcon data-testid="reseller-menu-icon" />
            </IconButton>
          }
        />
        <CardContent>
          <Grid container direction="row" justifyContent="" alignItems="center" spacing={1}>
            <Grid item xs={12}>
              <Chip
                sx={{ mr: 1 }}
                icon={
                  reseller.isReadOnly ? (
                    <LockedIcon data-testid="reseller-locked" color="error" />
                  ) : (
                    <UnlockedIcon data-testid="reseller-unlocked" color="success" />
                  )
                }
                label={reseller.isReadOnly ? 'locked' : 'unlocked'}
                size="small"
                variant="outlined"
              />
              <Chip
                sx={{ mr: 1 }}
                data-testid="reseller-projectcount"
                label={`${pluralize('project', reseller.projectCount, true)}`}
                size="small"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption">Team</Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction="row"
                justifyContent="space-between"
                // divider={<Divider orientation="vertical" flexItem />}
                spacing={2}
                sx={{ pl: 1.5 }}
              >
                <AvatarGroup max={4} spacing="small" slotProps={avatarGroupSlot}>
                  {resellerTeam.map((resellerUser) => (
                    <UserAvatar
                      key={resellerUser.uid}
                      self={reseller.resellerHandleUid === resellerUser.uid}
                      user={resellerUser}
                      removeCallback={
                        wasAssignedByMe(resellerUser) ? removeTeamMemberFromReseller : undefined
                      }
                    />
                  ))}
                </AvatarGroup>
                <AddTeamMemberToggle callback={openAddTeamMemberDialog} />
                <AddTeamMember open={openAddTeamMember} onClose={closeAddTeamMemberDialog} />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption">Customers</Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                {resellerCustomers.map((resellerCustomer) => (
                  <Grid key={resellerCustomer.id} item>
                    <CustomerAvatar customer={resellerCustomer} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption">Access Domains</Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <ResellerDomains reseller={reseller} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <ResellerDeleteDialog
        openDelete={openDelete}
        handleCloseDelete={handleCloseDelete}
        reseller={reseller}
      />

      <ResellerRenameDialog
        openRename={openRename}
        handleCloseRename={handleCloseRename}
        reseller={reseller}
      />

      <CardMenu
        anchorEl={anchorElement}
        open={!!anchorElement}
        onClose={handleMenuClose}
        handleClickOpenDelete={handleClickOpenDelete}
        handleClickOpenRename={handleClickOpenRename}
      />
    </Grid>
  );
};
