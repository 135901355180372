import DeleteIcon from '@mui/icons-material/Delete';
import FavoriteIcon from '@mui/icons-material/StarRounded';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@mui/material';
import { grey, yellow } from '@mui/material/colors';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { perspectiveName } from './helpers/perspectiveName.js';
import { updatePerspectiveFavorites } from './helpers/updatePerspectiveFavorites.js';
import { useAuth } from '../../../AuthReactProvider.js';
import { useUserPreferences } from '../../../UserPreferencesProvider';
import { createEvent } from '../../../services/firestore/createEvent';
import { deletePerspective } from '../../../services/firestore/deletePerspective';
import { KaeplaDataOperation } from '../../../services/kaeplaTypes/Application/KaeplaDataOperation';
import { KaeplaEventType } from '../../../services/kaeplaTypes/Application/KaeplaEventType';
import { KaeplaFunctionGroup } from '../../../services/kaeplaTypes/Application/KaeplaFunctionGroup';
import { KaeplaPerspective } from '../../../services/kaeplaTypes/Application/KaeplaPerspective';
import { perspectiveFavoritesState } from '../../../services/recoil/nonpersistent/perspectiveFavoritesState';
import { perspectiveState } from '../../../services/recoil/nonpersistent/perspectiveState';
import { perspectivesState } from '../../../services/recoil/nonpersistent/perspectivesState';
import { projectState } from '../../../services/recoil/nonpersistent/projectState';
import { scopePathsState } from '../../../services/recoil/nonpersistent/scopePathsState';
import { projectAssignmentsState } from '../../../services/recoil/nonpersistent/userAssignmentState';
import { currentScopePathState } from '../../../services/recoil/persistent/currentScopePathState';
import { kaeplaAssignmentState } from '../../../services/recoil/persistent/kaeplaAssignmentState';
import { knownUsersState } from '../../../services/recoil/persistent/knownUsersState';
import { GenericConfirmDialog } from '../../Layout/features/GenericDeleteConfirmDialog';
import { UserAvatar } from '../../features/UserAvatar';
import { convertTimestamp, HumanReadableTimestampType } from '../../helpers/convertTimestamp';
import { scopePathSimplified } from '../../helpers/scopePathSimplified';
import { checkPathIsInScope } from '../Scopes/helpers/checkPathIsInScope.js';

interface Options {
  perspective: KaeplaPerspective;
  own?: boolean;
}

export const PerspectiveCard = ({ perspective, own }: Options) => {
  const { kaeplaUser } = useAuth();
  const navigate = useNavigate();
  const { setPreferences } = useUserPreferences();
  // get
  const project = useRecoilValue(projectState);
  const knownUsers = useRecoilValue(knownUsersState);
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  const projectAssignments = useRecoilValue(projectAssignmentsState);
  // get & set
  const [currentScopePath, setCurrentScopePath] = useRecoilState(currentScopePathState);
  const [scopePaths, setScopePaths] = useRecoilState(scopePathsState);
  const [perspectiveFavorites, setPerspectiveFavorites] = useRecoilState(perspectiveFavoritesState);
  // set
  const setPerspective = useSetRecoilState(perspectiveState);
  const setPerspectives = useSetRecoilState(perspectivesState);

  const owner = knownUsers.find((user) => user.uid === perspective.createdBy);
  const [hover, setHover] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const isFavorite = () => {
    if (perspective.id === 'default') return true;
    return perspectiveFavorites
      .filter((s) => s.isFavorite)
      .some((s) => s.perspectiveId === perspective.id);
  };

  const isInScope = () => {
    if (project.id === 'default') return true;
    return checkPathIsInScope({
      project,
      projectAssignments,
      scopePath: perspective.scopePath,
    });
  };

  const goToPerspective = () => {
    if (!kaeplaUser?.uid || !currentScopePath) return;

    // TODO: ask user if it is okay to switch the scope if we're not already here
    if (perspective.scopePath) {
      if (!isInScope()) {
        return;
      }
      setCurrentScopePath(perspective.scopePath);
      const newScopePaths = { ...scopePaths };
      newScopePaths[project.id] = perspective.scopePath;
      setScopePaths(newScopePaths);
    }

    setPerspective(perspective);
    setPreferences({ lastPerspectiveId: perspective.id });
    updatePerspectiveFavorites({
      uid: kaeplaUser.uid,
      project,
      perspective,
      currentScopePath,
      perspectiveFavorites,
      setPerspectiveFavorites,
      isFav: true,
    });

    void createEvent({
      uid: kaeplaUser?.uid,
      eventType: KaeplaEventType.PERSPECTIVES_SELECT_PERSPECTIVE,
      functionGroup: KaeplaFunctionGroup.PERSPECTIVES,
      operation: KaeplaDataOperation.READ,
      project,
      scopePath: perspective.scopePath,
    });
    navigate(`/Perspective/${project.id}`);
  };

  const removePerspective = async () => {
    if (!kaeplaUser?.uid || !currentScopePath) return;
    if (!perspective.scopePath) return;
    if (!isInScope()) {
      return;
    }

    const key = `${kaeplaUser.uid}-${perspective.id}`;
    setPerspectiveFavorites((favorites) => favorites.filter((f) => f.id !== key));

    await deletePerspective({ project, perspective });

    setPerspectives((perspectives) => perspectives.filter((p) => p.id !== perspective.id));

    void createEvent({
      uid: kaeplaUser?.uid,
      eventType: KaeplaEventType.PERSPECTIVES_DELETE_PERSPECTIVE,
      functionGroup: KaeplaFunctionGroup.PERSPECTIVES,
      operation: KaeplaDataOperation.DELETE,
      project,
      scopePath: perspective.scopePath,
    });

    // set default perspective as new perspective
    if (project.defaultPerspective) {
      const defaultPerspective = project.defaultPerspective;
      setPerspective(defaultPerspective);
      setPreferences({ lastPerspectiveId: defaultPerspective.id });
      updatePerspectiveFavorites({
        uid: kaeplaUser.uid,
        project,
        perspective: defaultPerspective,
        currentScopePath,
        perspectiveFavorites,
        setPerspectiveFavorites,
        isFav: true,
      });

      void createEvent({
        uid: kaeplaUser?.uid,
        eventType: KaeplaEventType.PERSPECTIVES_SELECT_PERSPECTIVE,
        functionGroup: KaeplaFunctionGroup.PERSPECTIVES,
        operation: KaeplaDataOperation.READ,
        project,
        scopePath: currentScopePath,
      });
    }

    navigate(`/Perspectives/${project.id}`);
  };

  const elevate = () => {
    if (hover) return 8;
    return 2;
  };

  return (
    <Card
      elevation={elevate()}
      sx={{
        cursor: 'pointer',
      }}
      onClick={() => {
        goToPerspective();
      }}
      onFocus={() => {
        setHover(true);
      }}
      onBlur={() => {
        setHover(false);
      }}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      <CardHeader
        title={perspectiveName(perspective)}
        avatar={owner && <UserAvatar user={owner} />}
        subheader={
          perspective.createdAt
            ? `updated ${convertTimestamp(
                perspective.createdAt,
                HumanReadableTimestampType.timeago,
              )}`
            : 'did not run yet'
        }
      />
      <CardContent sx={{ minHeight: 150 }}>
        <Tooltip title={perspective.id}>
          <Link
            variant="caption"
            underline="hover"
            color="inherit"
            sx={{
              opacity: isInScope() ? 1 : 0.6,
            }}
            onClick={(event) => {
              event.stopPropagation();
              goToPerspective();
            }}
          >
            {[project.name, ...scopePathSimplified(perspective.scopePath)].join(' ➔ ')}
          </Link>
        </Tooltip>
      </CardContent>
      <CardActions sx={{ m: 0, pt: 0 }}>
        <IconButton
          aria-label="add to favorites"
          onClick={(event) => {
            if (!kaeplaUser?.uid || !currentScopePath) return;
            event.stopPropagation();

            updatePerspectiveFavorites({
              uid: kaeplaUser.uid,
              project,
              perspective,
              currentScopePath,
              perspectiveFavorites,
              setPerspectiveFavorites,
              isFav: !isFavorite(),
            });
          }}
          disabled={perspective.id === 'default'}
        >
          <FavoriteIcon sx={{ color: isFavorite() ? yellow[800] : grey[400] }} />
        </IconButton>
        <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
        {(own ?? kaeplaAssignment) && perspective.id !== 'default' && (
          <Tooltip title={perspective.id}>
            <IconButton
              sx={{ alignSelf: 'flex-end' }}
              color="primary"
              onClick={(event) => {
                event.stopPropagation();
                setOpenDelete(true);
              }}
              disabled={perspective.id === 'default'}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
        <GenericConfirmDialog
          openDelete={openDelete}
          title="Delete Dashboard?"
          description="This action can not be reverted."
          confirm="delete"
          handleCloseDelete={() => {
            setOpenDelete(false);
          }}
          processDelete={() => {
            void removePerspective();
          }}
        />
      </CardActions>
    </Card>
  );
  // TODO: confirm delete dialog
};
