import { Paper, Typography, Divider, Grid } from '@mui/material';
import { useRecoilValue } from 'recoil';

import { projectState } from '../../../services/recoil/nonpersistent/projectState';
import { ProjectInfoSmall } from '../Perspectives/features/ProjectSummary/features/ProjectInfoSmall';

export const DataSnapshotsHeader = () => {
  const project = useRecoilValue(projectState);
  return (
    <Grid item xs={12}>
      <Paper sx={{ p: 2 }} data-testid="targets-OverviewHeader">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={3}>
            <ProjectInfoSmall title={project.name} />
          </Grid>
          <Grid item sm={2}>
            <Divider orientation="vertical" />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={7}
            sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
          >
            <Typography variant="h6">Data Snapshots</Typography>
            <Typography variant="body1">
              Data snapshots are historic copies of your data. They can be used to "travel back in
              time".
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
