/* eslint-disable @typescript-eslint/naming-convention */
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import numbro from 'numbro';

import { AiAnswerRecords } from '../../../../services/kaeplaTypes/prompt/AIAnswerRecords';
import { PromptAnswer } from '../../../../services/kaeplaTypes/prompt/PromptAnswer';
import { isItNumber } from '../../../AI/lib/isItNumber';
import { cleanColumnName } from '../../../helpers/cleanColumnName';

interface Options {
  answer: PromptAnswer;
}
export const AnswerTable = ({ answer }: Options) => {
  const records = answer.data as AiAnswerRecords;

  if (!records?.headers) return null;

  const colsMap = records.headers?.map((d) => ({
    accessorKey: d,
    id: d,
    header: cleanColumnName(d),
  }));

  const data: Record<string, unknown>[] = [];
  [...records.records].forEach((value) => {
    [...records.headers].forEach((header) => {
      if (!value[header]) return 'n/a';
      if (typeof value[header] === 'object') {
        value[header] = JSON.stringify(value[header]);
      }
      if (header === 'Year') return value[header];
      if (!isItNumber(value[header] as string)) {
        return value[header];
      }
      const floatified = (value[header] as number) * 1;
      const numberFormatted = numbro(floatified).format({
        thousandSeparated: true,
        mantissa: 2,
      });
      const averaged = numbro(floatified).format({
        average: true,
        mantissa: 2,
      });

      if (header.toLowerCase().includes('volume')) {
        value[header] = `${numberFormatted} (${averaged})`;
      } else {
        value[header] = ` € ${numberFormatted} (${averaged})`;
      }
    });
    data.push(value);
  });

  return (
    <Box component="div">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 80 }} size="small">
          <TableHead>
            <TableRow>
              {colsMap.map((col) => (
                <TableCell sx={{ fontWeight: 'bold' }} key={col.id}>
                  {col.header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                {colsMap.map((col) => (
                  <TableCell key={`${col.id}${index}`}>{row[col.id] as string}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
