import { Button, Paper, Typography } from '@mui/material';

import { requestPasswordResetLink } from '../../../../services/api/sendPasswordResetLink.js';

interface Options {
  setAuthMessage: (message: string | null) => void;
  authMessage: string | null;
}

export const ManagePassword = ({ authMessage, setAuthMessage }: Options) => {
  return (
    <Paper
      data-testid="pw-reset-card"
      sx={{ p: 2, maxWidth: 500, position: 'relative' }}
      elevation={0}
    >
      <Typography variant="body1" sx={{ mb: 2 }}>
        Want to change your password?
        <br />
        We can send you a password-reset link via email.
      </Typography>
      <Button
        variant="contained"
        data-testid="pw-reset-button"
        disabled={authMessage === 'Link is on the way!'}
        onClick={() => {
          setAuthMessage('Link is on the way!');
          void requestPasswordResetLink();
        }}
      >
        request link
      </Button>
    </Paper>
  );
};
