import { Box } from '@mui/material';
import { useMemo, useRef } from 'react';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import { useRecoilValue } from 'recoil';

import { ErpOrderView } from './ErpOrderView';
import {
  deletedOrders,
  manualReviewOrders,
  newOrders,
  unchangedOrders,
  updatedOrders,
} from './ErpOrdersMatchBuckets';
import { dataSourceOrdersState } from '../../../../services/recoil/nonpersistent/dataSourceOrdersState';
import { bucketState } from '../../../../services/recoil/nonpersistent/ihsBucketState';
import { MatchBucketPanel } from '../switcher/MatchBucketPanel';
import { MatchBucketType } from '../types/MatchBucketType';

interface Options {
  fullScreen: boolean;
}

export const ErpDataSourceImportView = ({ fullScreen }: Options) => {
  const ordersFromState = useRecoilValue(dataSourceOrdersState);
  const selectedBucket = useRecoilValue(bucketState);

  const reference = useRef<VirtuosoHandle>(null);
  const orders = useMemo(() => {
    if (!selectedBucket) {
      return manualReviewOrders(ordersFromState);
    }
    switch (selectedBucket.type) {
      case MatchBucketType.NEW: {
        return newOrders(ordersFromState);
      }
      case MatchBucketType.UNCHANGED: {
        return unchangedOrders(ordersFromState);
      }
      case MatchBucketType.UPDATED: {
        return updatedOrders(ordersFromState);
      }
      case MatchBucketType.DELETED: {
        return deletedOrders(ordersFromState);
      }
      case MatchBucketType.MANUAL_REVIEW: {
        return manualReviewOrders(ordersFromState);
      }
      // No default
    }
  }, [selectedBucket, ordersFromState]);

  if (!selectedBucket || !orders) {
    return (
      <Box sx={{ minHeight: fullScreen ? 1500 : 400 }}>
        <MatchBucketPanel explain />
      </Box>
    );
  }

  return (
    <Virtuoso
      ref={reference}
      style={{ height: '400px' }}
      useWindowScroll={fullScreen}
      totalCount={orders.length}
      data={orders}
      itemContent={(_, ihsVehicle) => <ErpOrderView erpOrder={ihsVehicle} />}
      // can't be sticky in a virtuoso list
      // eslint-disable-next-line @typescript-eslint/naming-convention
      // components={{ Footer: () => <div>footer</div> }}
    />
  );
};
