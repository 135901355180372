import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useState } from 'react';

import { renameDataSnapshot } from '../../../services/firestore/renameDataSnapshot.js';
import { KaeplaDataSnapshot } from '../../../services/kaeplaTypes/index.js';

interface Options {
  dataSnapShot: KaeplaDataSnapshot;
  open: boolean;
  handleClose: () => void;
}

export const DataSnapshotRenameDialog = ({ dataSnapShot, open, handleClose }: Options) => {
  const [newName, setNewName] = useState(dataSnapShot.name);
  const [newDescription, setNewDescription] = useState(dataSnapShot.description);

  const renamingOnServer = () => {
    if (!newName) return;

    const data: {
      projectId: string;
      dataSnapShotId: string;
      name: string;
      description?: string;
    } = {
      projectId: dataSnapShot.projectId,
      dataSnapShotId: dataSnapShot.id,
      name: newName,
    };
    if (newDescription && newDescription !== '') {
      data.description = newDescription;
    }
    void renameDataSnapshot(data);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle data-testid="dialog-title">Rename the snapshot</DialogTitle>
      <DialogContent>
        <DialogContentText data-testid="rename-info">
          Renaming might take a few seconds. When the project is being worked on, renaming will be
          disabled.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          fullWidth
          variant="standard"
          value={newName}
          placeholder="Snapshot Name"
          inputProps={{ 'data-testid': 'project-name-input' }}
          onChange={(event) => {
            setNewName(event.target.value);
            event.preventDefault();
          }}
          onKeyUp={(event) => {
            if (!newName) return;
            if (event.key === 'Enter') {
              void renamingOnServer();
            }
          }}
        />
        <TextField
          autoFocus
          margin="dense"
          fullWidth
          variant="outlined"
          placeholder="Snapshot Description"
          value={newDescription}
          inputProps={{ 'data-testid': 'project-description-input' }}
          multiline
          minRows={2}
          maxRows={4}
          onChange={(event) => {
            setNewDescription(event.target.value);
            event.preventDefault();
          }}
          onKeyUp={(event) => {
            if (!newDescription) return;
            if (event.key === 'Enter') {
              void renamingOnServer();
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button data-testid="button-cancel-rename" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          data-testid="button-rename"
          variant="contained"
          color="error"
          onClick={() => {
            if (!newName) return;
            void renamingOnServer();
          }}
        >
          Rename
        </Button>
      </DialogActions>
    </Dialog>
  );
};
