import { DataSource } from '../types/DataSource';

export const dataSources: DataSource[] = [
  {
    id: '1',
    key: 'ihsVehicles',
    label: 'IHS - Vehicles',
    itemLabel: 'Vehicle',
    config: {
      importType: 'manual',
      hasCollapsibleFields: true,
      description:
        'The IHS vehicles file should be in CSV format. It can be downloaded from the IHS website. Please make sure the format of the data is according to the specification.',
    },
  },
  {
    id: '2',
    key: 'erpOrders',
    label: 'ERP - Orders',
    itemLabel: 'Order',
    config: {
      hasCollapsibleFields: false,
      importType: 'automatic',
      description: 'The ERP orders are automatically loaded.',
    },
  },
  {
    id: '3',
    key: 'ihsEngines',
    label: 'IHS - Engines',
    itemLabel: 'Engine',
    config: {
      importType: 'manual',
    },
  },
  {
    id: '4',
    key: 'lmsVehicles',
    label: 'LMS - Vehicles',
    itemLabel: 'Vehicle',
    config: {
      importType: 'manual',
    },
  },
];
