import { getApp } from 'firebase/app';
import {
  collection,
  getDocs,
  getFirestore,
  limit,
  query,
  where,
  orderBy,
} from 'firebase/firestore';

import { logger } from '../../Frontend/helpers/logger';

interface Options {
  callId: string;
}

export const getThreadIdForToolCall = async ({ callId }: Options) => {
  const fireStorePath = `aiThreads`;
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const firestoreCollection = collection(firestoreDatabase, fireStorePath);

  const firestoreQuery = query(
    firestoreCollection,
    where('callId', '==', callId),
    limit(1),
    orderBy('createdAt', 'desc'),
  );
  const snapshot = await getDocs(firestoreQuery).catch((error) => {
    logger.error(`Error getting thread ID: ${error}`);
  });
  if (!snapshot || snapshot.docs.length === 0) return;
  return snapshot.docs[0].data().id as unknown as string;
};
