import { DateTime } from 'luxon';
import { atom } from 'recoil';

import { localForageEffect } from './localForageEffect.js';
import { KaeplaFilterSettings } from '../../kaeplaTypes/Application/KaeplaFilterSetting.js';

const defaultFilterSettingsState: KaeplaFilterSettings<DateTime> = {
  isInitialized: false,
  isActive: false,
  isExpanded: true,
  isApplied: false,
  showSql: false,
  ruleCount: 0,
  updatedAt: DateTime.now(),
};

export const filterSettingsState = atom<KaeplaFilterSettings<DateTime>>({
  key: 'filterSettings',
  default: defaultFilterSettingsState,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  effects_UNSTABLE: [localForageEffect],
});
