import { atom } from 'recoil';

import { MatrixSummary } from '../../kaeplaTypes/MatrixSummary.js';

const defaultMatrixFilteredState: MatrixSummary | undefined = {};

export const matrixFilteredState = atom<MatrixSummary | undefined>({
  key: 'matrixFiltered',
  default: defaultMatrixFilteredState,
});
