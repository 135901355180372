export const simplifyScopePath = (path: string[], _level?: number) => {
  let level = 0;
  if (_level) {
    level = _level;
  }
  const newPath: string[] = [];
  path.forEach((p) => {
    let newP = p;
    if (level <= path.length) {
      newP = p.split(':')[1];
    }
    newPath.push(newP);
  });
  return newPath;
};
