import { Alert, AlertColor, Box, Button } from '@mui/material';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { dataSourceImportState } from '../../../../services/recoil/nonpersistent/dataSourceImportState';
import { bucketState } from '../../../../services/recoil/nonpersistent/ihsBucketState';

interface Options {
  setViewDetails: React.Dispatch<React.SetStateAction<boolean>>;
}

export const BucketActions = ({ setViewDetails }: Options) => {
  const resetDataSourceImport = useResetRecoilState(dataSourceImportState);
  const selectedBucket = useRecoilValue(bucketState);

  const color = selectedBucket?.color as AlertColor;
  if (selectedBucket?.description) {
    return (
      <Box sx={{ p: 1 }}>
        <Alert severity={color}>{selectedBucket.description}</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 1 }}>
      <Button
        variant="text"
        onClick={() => {
          resetDataSourceImport();
          setViewDetails(false);
        }}
      >
        Back
      </Button>
    </Box>
  );
};
