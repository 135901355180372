import { getApp } from 'firebase/app';
import { getFirestore, collection, query, where, getDocs, deleteDoc } from 'firebase/firestore';

interface Options {
  projectId: string;
  uid: string;
}

export const clearAiLogForProjectAndUser = async ({ projectId, uid }: Options) => {
  //   console.log('clearing');

  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `aiQuestionLogs`;
  const firestoreCollection = collection(firestoreDatabase, fireStorePath);
  const firestoreQuery = query(
    firestoreCollection,
    where('projectId', '==', projectId),
    where('uid', '==', uid),
  );
  const snapshot = await getDocs(firestoreQuery);
  snapshot.forEach((document_) => {
    // console.log('->', document_.ref.path);
    void deleteDoc(document_.ref);
  });
};
