import { path, clone } from 'rambda';

import { getCleanPath } from './getCleanPath.js';
import { KaeplaModel } from '../../../services/kaeplaTypes/Application/KaeplaModel.js';

export const removeDimensionAtPath = (
  model: KaeplaModel,
  scopePath: string[],
  dimension: string,
) => {
  const newModel = clone(model);
  const newModelScope = newModel.delegation;
  const cleanPath = getCleanPath(scopePath);
  cleanPath.pop();

  const dotNotation = cleanPath.join('.');
  const parentNode = path<Record<string, unknown>>(dotNotation, newModelScope);
  if (parentNode) {
    delete parentNode[dimension];
  } else {
    delete newModelScope[dimension];
  }

  return newModel;
};
