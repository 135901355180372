import SuggestIcon from '@mui/icons-material/AssistantOutlined';
import SearchIcon from '@mui/icons-material/SearchOutlined';
import { Divider, Grid, IconButton, InputBase, Paper } from '@mui/material';

interface Options {
  processing: boolean;
  currentQuestion: string;
  setCurrentQuestion: React.Dispatch<React.SetStateAction<string>>;
  setSubmittedQuestion: React.Dispatch<React.SetStateAction<string>>;
  getRandomQuestion: () => Promise<void>;
}

export const AskQuestion = ({
  processing,
  currentQuestion,
  setCurrentQuestion,
  setSubmittedQuestion,
  getRandomQuestion,
}: Options) => {
  return (
    <Grid container>
      <Grid item xs={12} sx={{ display: 'none' }}>
        show me the total Turnover for 2023
        <br />
        show me the total Turnover for 2023 included in business plan
        <br />
        show me the total Turnover for 2023 and 2024 included in business plan
        <br />
        Umsatzumme und Jahr laut Business Plan gruppiert nach Jahr
        <br />
        give me the turnover sum and the monthly volume sum for 2023
        <br />
        the turnover average and the monthly volume average for 2023
        <br />
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            fullWidth
            value={currentQuestion}
            placeholder="Ask a question"
            name="AiMessage"
            onChange={(event) => {
              setCurrentQuestion(event.target.value);
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                setSubmittedQuestion(currentQuestion.trim());
                setCurrentQuestion('');
              }
            }}
            disabled={processing}
          />
          <IconButton
            type="button"
            sx={{ p: '2px' }}
            aria-label="ask"
            disabled={processing || currentQuestion === ''}
            onClick={() => {
              setSubmittedQuestion(currentQuestion.trim());
              setCurrentQuestion('');
            }}
          >
            <SearchIcon />
          </IconButton>
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <IconButton
            color="primary"
            sx={{ p: '10px' }}
            aria-label="suggest"
            onClick={() => {
              void getRandomQuestion();
            }}
          >
            <SuggestIcon />
          </IconButton>
        </Paper>
      </Grid>
    </Grid>
  );
};
