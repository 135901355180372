import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, Button, Paper, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';

import { dataSourceState } from '../../../../services/recoil/nonpersistent/dataSourceState';

interface DOptions {
  handleApiImport: () => void;
}

export const DataSourceApiImport = ({ handleApiImport }: DOptions) => {
  const selectedDataSource = useRecoilValue(dataSourceState);
  return (
    <Paper variant="elevation" sx={{ p: 2 }}>
      <Box sx={{ maxWidth: 400 }}>
        <Typography variant="h5" gutterBottom>
          Start a new import for {selectedDataSource.label}
        </Typography>
        <Typography variant="body1" gutterBottom>
          The data is fetched from the ERP-System via API and imported into the system. Also there
          is a scheduled automatic import in place which will fetch the data every 2 weeks.
        </Typography>
        {selectedDataSource?.config && (
          <Typography variant="body1" gutterBottom>
            {selectedDataSource.config.description}
          </Typography>
        )}
        <Box sx={{ m: 2 }} />
        <Button
          component="label"
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
          onClick={() => {
            handleApiImport();
          }}
        >
          Import now
        </Button>
      </Box>
    </Paper>
  );
};
