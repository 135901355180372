import DeleteIcon from '@mui/icons-material/DeleteOutline';
import RenameIcon from '@mui/icons-material/EditOutlined';
import { Menu } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';

interface Options {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  handleClickOpenDelete: () => void;
  handleClickOpenRename: () => void;
}
export const CardMenu = ({
  anchorEl,
  open,
  onClose,
  handleClickOpenDelete,
  handleClickOpenRename,
}: Options) => {
  if (!open) return null;

  return (
    <>
      <Menu anchorEl={anchorEl} open onClose={onClose}>
        <MenuItem
          onClick={(event) => {
            event.stopPropagation();
            handleClickOpenRename();
          }}
        >
          <ListItemIcon>
            <RenameIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Rename</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={(event) => {
            event.stopPropagation();
            handleClickOpenDelete();
          }}
        >
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};
