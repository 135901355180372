import { getApp } from 'firebase/app';
import { deleteDoc, doc, getFirestore } from 'firebase/firestore';

import { KaeplaPerspective } from '../kaeplaTypes/Application/KaeplaPerspective.js';
import { KaeplaProject } from '../kaeplaTypes/Application/KaeplaProject.js';

interface Options {
  project: KaeplaProject;
  perspective: Partial<KaeplaPerspective>;
}

export const deletePerspective = async ({ project, perspective }: Options) => {
  if (!perspective.id) return;
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `projects/${project.id}/perspectives/${perspective.id}`;
  const firestoreDocumentReference = doc(firestoreDatabase, fireStorePath);
  return deleteDoc(firestoreDocumentReference);
};
