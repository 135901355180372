import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Card, CardActions, CardHeader, Collapse, Typography, useTheme } from '@mui/material';
import { green, grey } from '@mui/material/colors';
import { useEffect, useState } from 'react';
import TimeAgo from 'react-timeago';
import { useRecoilValue } from 'recoil';

import { AiMetaQuestionInfo } from './AiMetaQuestionInfo';
import { AiSqlCodeEditor } from './AiSqlEditor';
import { AiMetaLogFilters } from '../../../../../services/kaeplaTypes/importer/AiMetaLogFilters';
import { AiQuestionMetaLog } from '../../../../../services/kaeplaTypes/importer/AiQuestionMetaLog';
import { knownUsersState } from '../../../../../services/recoil/persistent/knownUsersState';
import { ExpandMore } from '../../../../features/ExpandMore';
import { UserAvatar } from '../../../../features/UserAvatar';
import { AnswerPerAnswerType } from '../../../ExperimentalAi/AnswerPerAnswerType';

interface Options {
  metaLog: AiQuestionMetaLog;
  expandAll: boolean;
  dense: boolean;
  setMetaLogFilters: React.Dispatch<React.SetStateAction<AiMetaLogFilters>>;
  setLastResolvedLog: React.Dispatch<React.SetStateAction<AiQuestionMetaLog | undefined>>;
}

export const AiMetaQuestion = ({
  metaLog,
  dense,
  expandAll,
  setMetaLogFilters,
  setLastResolvedLog,
}: Options) => {
  const theme = useTheme();
  const knownUsers = useRecoilValue(knownUsersState);
  const [showSql, setShowSql] = useState(false);
  const user = knownUsers.find((k) => k.uid === metaLog.lastUid);

  useEffect(() => {
    if (expandAll === false) {
      setShowSql(false);
    }
  }, [expandAll]);

  return (
    <Card
      sx={{
        position: 'relative',
        border: metaLog.sqlExample ? 1 : 0,
        borderLeft: metaLog.sqlExample ? 5 : 0,
        borderColor: metaLog.sqlExample ? green[200] : grey[500],
      }}
    >
      <CardHeader
        sx={dense ? { p: 0.7 } : {}}
        title={<Typography sx={{ fontSize: dense ? 14 : 16 }}>{metaLog.question}</Typography>}
        avatar={
          user && (
            <UserAvatar
              user={user}
              size={dense ? 35 : 45}
              onClickCallback={() => {
                setMetaLogFilters((filters) => ({ ...filters, lastUid: user.uid }));
              }}
            />
          )
        }
        subheader={
          <Box>
            {!dense && metaLog?.lastLoggedAt && (
              <TimeAgo date={metaLog.lastLoggedAt.toDate()} max={Number.MAX_SAFE_INTEGER} />
            )}
            {dense && (
              <AiMetaQuestionInfo
                metaLog={metaLog}
                dense={dense}
                setMetaLogFilters={setMetaLogFilters}
              />
            )}
          </Box>
        }
      />
      <CardActions
        disableSpacing
        sx={dense ? { position: 'absolute', top: 0, right: 0 } : { px: 2 }}
      >
        {!dense && (
          <AiMetaQuestionInfo
            metaLog={metaLog}
            dense={dense}
            setMetaLogFilters={setMetaLogFilters}
          />
        )}
        <ExpandMore
          size="small"
          expand={showSql || expandAll}
          onClick={() => {
            setShowSql(!showSql);
          }}
          aria-expanded={showSql}
          aria-label="show more"
        >
          <ExpandMoreIcon fontSize="small" />
        </ExpandMore>
      </CardActions>
      <Collapse in={showSql || expandAll} timeout="auto" unmountOnExit>
        <Box>
          <Box
            border={1}
            borderLeft={metaLog.lastAcceptedAnswer ? 3 : 1}
            borderColor={metaLog.lastAcceptedAnswer ? green[200] : grey[500]}
            borderRadius={1}
            p={1}
            m={3}
          >
            {metaLog.lastAnswer && <AnswerPerAnswerType answer={metaLog.lastAnswer} />}
            {!metaLog.lastAnswer && <Box>Can not answer this question.</Box>}
          </Box>
          {metaLog.lastFeedback && (
            <Box border={1} borderColor={theme.palette.info.main} borderRadius={1} p={1} m={3}>
              <Box color={theme.palette.info.light} mb={1}>
                Last user feedback
              </Box>
              {metaLog.lastFeedback}
            </Box>
          )}
          {metaLog.lastAnswer?.checkQuery && (
            <Box p={1} sx={{ fontFamily: 'monospace', fontSize: 12 }}>
              <AiSqlCodeEditor
                metaLog={metaLog}
                query={metaLog.lastAnswer.checkQuery}
                setLastResolvedLog={setLastResolvedLog}
              />
            </Box>
          )}
        </Box>
      </Collapse>
    </Card>
  );
};
