import { Alert, Card, Grid } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Md5 } from 'ts-md5';

import { DonutChart } from './DonutChart.js';
import { DonutChartHeader } from './DonutChartHeader.js';
import {
  getChartDataForPieChart,
  KaeplaPieChartDataset,
  KaeplaPieChartDatasets,
} from './getChartDataForPieChart';
import { useAuth } from '../../../../../../../AuthReactProvider.js';
import { getFromKaepla } from '../../../../../../../services/api/getFromKaepla.js';
import { KaeplaPerspectiveBlock } from '../../../../../../../services/kaeplaTypes/Application/KaeplaPerspectiveBlock.js';
import { KaeplaQueryType } from '../../../../../../../services/kaeplaTypes/Application/KaeplaQueryType';
import { MatrixAggregation } from '../../../../../../../services/kaeplaTypes/MatrixAggregation.js';
import { chartDataState } from '../../../../../../../services/recoil/nonpersistent/chartDataState.js';
import { perspectiveState } from '../../../../../../../services/recoil/nonpersistent/perspectiveState.js';
import { projectState } from '../../../../../../../services/recoil/nonpersistent/projectState.js';
import { simulationState } from '../../../../../../../services/recoil/nonpersistent/simulationState.js';
import { currentScopePathState } from '../../../../../../../services/recoil/persistent/currentScopePathState.js';
import { filterSettingsState } from '../../../../../../../services/recoil/persistent/filterSettingState.js';
import { filterSqlState } from '../../../../../../../services/recoil/persistent/filterSqlState.js';
import { CardContentMinPadding } from '../../../../../../features/CardContentMinPadding.js';
import { useIsVisible } from '../../../../../../helpers/useIsVisible.js';
import { chartParameters } from '../../helpers/chartParameters.js';
import { KaeplaChartLabel } from '../../helpers/createChartLabels.js';
import { createPieChartLabels } from '../../helpers/createPieChartLabels.js';
import { ChartEditor } from '../ChartEditor.js';
import { ChartLabels } from '../ChartLabels.js';

interface AOptions {
  block: KaeplaPerspectiveBlock;
  blockNumber: number;
  locked?: boolean;
}

export const DonutChartBlock = ({ block, blockNumber, locked }: AOptions) => {
  const { kaeplaUser } = useAuth();
  const project = useRecoilValue(projectState);
  const perspective = useRecoilValue(perspectiveState);
  const simulation = useRecoilValue(simulationState);
  const filterSql = useRecoilValue(filterSqlState);
  const filterSettings = useRecoilValue(filterSettingsState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const scopePathStringified = JSON.stringify(currentScopePath);
  const chartKey = Md5.hashStr(
    `dnchart-${perspective.id ?? 'p'}${simulation?.id ?? 's'}${blockNumber}${scopePathStringified}${filterSql ?? 'f'}${filterSettings.isActive ? 't' : 'f'}`,
  );
  const [allChartData, setAllChartData] = useRecoilState(chartDataState);
  const [wasVisible, setWasVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const [error, setError] = useState<string>();
  const [chartLabels, setChartLabels] = useState<KaeplaChartLabel[]>([]);
  const [chartData, setChartData] = useState<KaeplaPieChartDatasets>();
  const [data, setData] = useState<MatrixAggregation>();
  const chartReference = useRef();
  const reference = useRef(null);
  const isVisible = useIsVisible(reference);

  const toggleLabel = (label: KaeplaChartLabel) => {
    const newChartLabels = [...chartLabels];
    const labelReference = newChartLabels.find((l) => l.datasetName === label.datasetName);
    if (labelReference && chartData?.datasets && data) {
      labelReference.isToggled = !labelReference.isToggled;
      setChartLabels(newChartLabels);
      let _chartData: {
        labels: string[];
        datasets: KaeplaPieChartDataset[];
      } = {
        labels: [],
        datasets: [],
      };
      _chartData = getChartDataForPieChart({
        datasetLabel: `${block.valueDimension} ${block.aggregation}`,
        aggregationData: data.aggregationData,
        datasets: data.labels,
        chartLabels: newChartLabels,
      });
      setChartData(_chartData);
    }
  };

  // pre-use the locally stored chart data
  useEffect(() => {
    if (perspective.id && allChartData?.[chartKey]) {
      const _chartData = allChartData[chartKey] as KaeplaPieChartDatasets;
      setLoading(false);
      setChartData(_chartData);
    }
  }, [setChartData, allChartData, perspective.id, blockNumber, chartKey]);

  // only load visible charts
  useEffect(() => {
    if (!isVisible) return;
    setWasVisible(true);
  }, [isVisible]);

  useEffect(() => {
    if (!wasVisible || !currentScopePath) return;
    if (!filterSettings.isInitialized) return;

    if (!block.aggregationDimension) {
      setEditing(true);
      setLoading(false);
      return;
    }

    getFromKaepla({
      params: chartParameters({
        project,
        queryType: 'aggregation' as KaeplaQueryType,
        currentScopePath,
        filterSql,
        filterActive: filterSettings.isActive,
        block,
      }),
      uid: kaeplaUser?.uid,
      errorCallBack: (_error) => {
        setChartLabels([]);
        setChartData({
          labels: [],
          datasets: [],
        });
        setError(`Chart data backend error: ${_error.message}`);
        setLoading(false);
      },
      callBack: (apiResponse) => {
        if (!apiResponse || !block.aggregationDimension) {
          setLoading(false);
          return;
        }
        const result = apiResponse.response as MatrixAggregation;

        const _chartLabels = createPieChartLabels(result.labels, block.aggregationDimension);
        const _chartData = getChartDataForPieChart({
          datasetLabel: `${block.valueDimension} ${block.aggregation}`,
          aggregationData: result.aggregationData,
          datasets: result.labels,
          chartLabels: _chartLabels,
          simulation,
          aggregationDataSimulated: result.aggregationData,
        });
        setAllChartData((previous) => {
          return {
            ...previous,
            [chartKey]: _chartData,
          };
        });
        setData(result);
        setChartLabels(_chartLabels);
      },
    });
  }, [
    block,
    currentScopePath,
    filterSettings.isActive,
    filterSettings.isInitialized,
    wasVisible,
    filterSql,
    kaeplaUser?.uid,
    project,
    simulation,
    setAllChartData,
    perspective.id,
    blockNumber,
    chartKey,
  ]);

  return (
    <Card sx={{ minHeight: 320, height: '100%' }} ref={reference}>
      <Grid container>
        <Grid item xs={editing ? 8 : 12}>
          <CardContentMinPadding sx={{ pt: 1.1 }}>
            <DonutChartHeader
              chartReference={chartReference}
              block={block}
              setEditing={setEditing}
              editing={editing}
              blockNumber={blockNumber}
              locked={locked}
              highlightFilterOverride
            />
            {error && <Alert severity="error">{error}</Alert>}
            {!error && (
              <DonutChart chartReference={chartReference} chartData={chartData} loading={loading} />
            )}
            <ChartLabels
              block={block}
              chartLabels={chartLabels}
              simulation={simulation}
              toggleLabel={toggleLabel}
            />
          </CardContentMinPadding>
        </Grid>
        {editing && (
          <Grid item xs={4}>
            <ChartEditor
              chartReference={chartReference}
              block={block}
              blockNumber={blockNumber}
              setEditing={setEditing}
            />
          </Grid>
        )}
      </Grid>
    </Card>
  );
};
