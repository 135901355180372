import { One2OneMatchDetailRenderer } from './MatchRenderers/One2OneMatchDetailRenderer';
import { OneAndN2ZeroMatchDetailRenderer } from './MatchRenderers/OneAndN2ZeroMatchDetailRenderer';
import { IhsVehicleFull } from '../types/IhsVehicleFull';
import { MatchType } from '../types/MatchType';

interface Options {
  ihsVehicle: IhsVehicleFull;
  setHighlight: React.Dispatch<React.SetStateAction<boolean>>;
}

export const IhsMatchDetail = ({ ihsVehicle, setHighlight }: Options) => {
  if (!ihsVehicle.matchType) return null;

  return renderMatchType(ihsVehicle.matchType);

  function renderMatchType(matchType: MatchType) {
    switch (matchType) {
      case MatchType.ONE2ZERO: {
        return <OneAndN2ZeroMatchDetailRenderer ihsVehicle={ihsVehicle} />;
      }
      case MatchType.N2ZERO: {
        return <OneAndN2ZeroMatchDetailRenderer ihsVehicle={ihsVehicle} />;
      }
      case MatchType.ZERO2N: {
        return <OneAndN2ZeroMatchDetailRenderer ihsVehicle={ihsVehicle} />;
      }
      case MatchType.ONE2ONE: {
        return <One2OneMatchDetailRenderer ihsVehicle={ihsVehicle} setHighlight={setHighlight} />;
      }
      case MatchType.N2ONE: {
        return <One2OneMatchDetailRenderer ihsVehicle={ihsVehicle} setHighlight={setHighlight} />;
      }
      default: {
        return null;
      }
    }
  }
};
