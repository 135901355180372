import styled from '@emotion/styled';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import Image from 'mui-image';
import numbro from 'numbro';
import pluralize from 'pluralize';
import { useEffect, useState, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { CardMenu } from './CardMenu.js';
import { useAuth } from '../../../../AuthReactProvider.js';
import { watcherServiceKaepla } from '../../../../firebaseInit.js';
import { getFromKaepla } from '../../../../services/api/getFromKaepla.js';
import { updateProject } from '../../../../services/firestore/updateProject.js';
import { KaeplaCustomer } from '../../../../services/kaeplaTypes/Application/KaeplaCustomer.js';
import { KaeplaProject } from '../../../../services/kaeplaTypes/Application/KaeplaProject.js';
import { MatrixTimeSlice } from '../../../../services/kaeplaTypes/MatrixTimeSlice.js';
import { KaeplaApiParameters, KaeplaQueryType } from '../../../../services/kaeplaTypes/index.js';
import { applicationState } from '../../../../services/recoil/nonpersistent/applicationState.js';
import { dataGridSettings } from '../../../../services/recoil/nonpersistent/dataGridSets.js';
import { knownCustomersState } from '../../../../services/recoil/nonpersistent/knownCustomersState.js';
import { knownResellersState } from '../../../../services/recoil/nonpersistent/knownResellersState.js';
import { matrixFilteredState } from '../../../../services/recoil/nonpersistent/matrixFilteredState.js';
import { modelState } from '../../../../services/recoil/nonpersistent/modelState.js';
import { perspectiveState } from '../../../../services/recoil/nonpersistent/perspectiveState.js';
import { projectState } from '../../../../services/recoil/nonpersistent/projectState.js';
import { projectTargetsState } from '../../../../services/recoil/nonpersistent/projectTargetsState.js';
import { scopePathsState } from '../../../../services/recoil/nonpersistent/scopePathsState.js';
import { selectedCustomerState } from '../../../../services/recoil/nonpersistent/selectedCustomer.js';
import { simulationState } from '../../../../services/recoil/nonpersistent/simulationState.js';
import { snapShotState } from '../../../../services/recoil/nonpersistent/snapshotState.js';
import { projectAssignmentsState } from '../../../../services/recoil/nonpersistent/userAssignmentState.js';
import { userRootPathsState } from '../../../../services/recoil/nonpersistent/userRootPathsState.js';
import { currentScopePathState } from '../../../../services/recoil/persistent/currentScopePathState.js';
import { filterSettingsState } from '../../../../services/recoil/persistent/filterSettingState.js';
import { filterSqlState } from '../../../../services/recoil/persistent/filterSqlState.js';
import { filterTreeState } from '../../../../services/recoil/persistent/filterTreeState.js';
import { kaeplaAssignmentState } from '../../../../services/recoil/persistent/kaeplaAssignmentState.js';
import { lastCustomerIDState } from '../../../../services/recoil/persistent/lastCustomerState';
import { lastProjectIdState } from '../../../../services/recoil/persistent/lastProjectState.js';
import { timeSliceState } from '../../../../services/recoil/persistent/timeSliceState.js';
import { ResellerAvatar } from '../../../features/ResellerAvatar.js';
import { parseErrorLog } from '../../../helpers/logger.js';
import { simplifyScopePath } from '../../../helpers/simplifyScopePath.js';
import { timeAgo } from '../../../helpers/timeAgo.js';
import { ProjectTeam } from '../../Perspectives/features/ProjectSummary/features/ProjectTeam.js';
import { avatarAppearDuration } from '../../defaults.js';

interface Options {
  project: KaeplaProject;
  selected: boolean;
}

const StyledCardHeader = styled(({ ...other }) => <CardHeader {...other} />)`
  & .MuiCardHeader-content {
    flex: 1 1 auto;
    width: 60%;
  }
`;

export const ProjectCard = ({ project, selected }: Options) => {
  const theme = useTheme();
  const { kaeplaUser } = useAuth();
  const navigate = useNavigate();
  // get
  const app = useRecoilValue(applicationState);
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  const knownResellers = useRecoilValue(knownResellersState);
  const knownCustomers = useRecoilValue(knownCustomersState);
  const userRootPaths = useRecoilValue(userRootPathsState);
  const projectAssignments = useRecoilValue(projectAssignmentsState);
  // set
  const setProject = useSetRecoilState(projectState);
  const setLastProject = useSetRecoilState(lastProjectIdState);
  const setTimeSlice = useSetRecoilState(timeSliceState);
  const setScopePaths = useSetRecoilState(scopePathsState);
  const setLastCustomer = useSetRecoilState(lastCustomerIDState);
  const setCurrentScopePath = useSetRecoilState(currentScopePathState);
  // get & set
  const [selectedCustomer, setSelectedCustomer] = useRecoilState(selectedCustomerState);
  // reset
  const resetSimulation = useResetRecoilState(simulationState);
  const resetSnapshot = useResetRecoilState(snapShotState);
  const resetFilterTree = useResetRecoilState(filterTreeState);
  const resetFilterSql = useResetRecoilState(filterSqlState);
  const resetFilterSettings = useResetRecoilState(filterSettingsState);
  const resetPerspective = useResetRecoilState(perspectiveState);
  const resetProjectTargets = useResetRecoilState(projectTargetsState);
  const resetCurrentScopePath = useResetRecoilState(currentScopePathState);
  const resetModel = useResetRecoilState(modelState);
  const resetMatrixFiltered = useResetRecoilState(matrixFilteredState);
  const resetGridSets = useResetRecoilState(dataGridSettings);
  // local
  const [hover, setHover] = useState(false);
  const [defaultPath, setDefaultPath] = useState<string[]>();
  const [assignmentsCount, setAssignmentsCount] = useState(0);
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const [projectCustomer, setProjectCustomer] = useState<KaeplaCustomer>();

  const handleMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorElement(null);
  };

  const getSubHeader = () => {
    if (project.matrixInPreparation) {
      return 'data is being prepared now';
    }
    if (project.matrixUnavailable) {
      return 'no data available';
    }
    if (project.lastUpdatedAt) {
      return 'updated ' + timeAgo(project.lastUpdatedAt) || 'never';
    }
    return 'not set up yet';
  };

  const subHeaderText = getSubHeader();

  const resellerFromCustomer = (c: KaeplaCustomer | undefined) => {
    return knownResellers.filter((k) => !!k).find((r) => r.id === c?.resellerId);
  };

  const reseller = resellerFromCustomer(selectedCustomer);

  const goToAssignments = () => {
    setProject(project);

    const rootPathStringified = userRootPaths[project.id];
    if (rootPathStringified) {
      const path = JSON.parse(rootPathStringified) as string[];
      setCurrentScopePath(path);
    } else {
      resetCurrentScopePath();
    }

    // reset dependencies from previously selected project
    resetSimulation();
    resetSnapshot();
    resetFilterTree();
    resetFilterSql();
    resetFilterSettings();
    resetPerspective();
    resetProjectTargets();
    resetGridSets();
    resetModel();
    resetMatrixFiltered();

    const _customer = knownCustomers.find((c) => c.id === project.customerId);
    setSelectedCustomer(_customer);
    if (_customer) {
      setLastCustomer(_customer.id);
    }
    setLastProject(project.id);
    setTimeSlice(project.defaultPerspective?.timeSlice ?? MatrixTimeSlice.year);
    if (project.matrixUnavailable === true || project.matrixInPreparation) {
      navigate(`/Perspective/${project.id}`);
      return;
    }
    navigate(`/Assignments/${project.id}`);
  };

  const generateDummyData = async () => {
    if (!defaultPath) return;

    // unmount the project listener
    const watcherKeys = watcherServiceKaepla.get().getSubscriptionKeys();
    let projectWatcherKey;
    watcherKeys.forEach((watcher) => {
      const [watcherType] = watcher.split(':');
      if (watcherType === `projects/${project.id}`) {
        projectWatcherKey = watcher;
      }
    });
    if (projectWatcherKey) {
      watcherServiceKaepla.get().unsubscribe(projectWatcherKey);
    }

    const newProject = {
      ...project,
      matrixUnavailable: true,
      matrixInPreparation: false,
    } as KaeplaProject;
    await updateProject({ project: newProject });

    const newScopePaths: Record<string, string[] | undefined> = {};
    newScopePaths[newProject.id] = defaultPath;
    setScopePaths(newScopePaths);
    setProject(newProject);
    const _customer = knownCustomers.find((c) => c.id === project.customerId);
    setSelectedCustomer(_customer);
    if (_customer) {
      setLastCustomer(_customer.id);
    }
    setLastProject(newProject.id);
    setTimeSlice(newProject.defaultPerspective?.timeSlice ?? MatrixTimeSlice.quarter);
    navigate(`/Perspective/${project.id}`);
  };

  const handleGenerateDummyData = () => {
    void generateDummyData();
  };

  const showRecordsText = () => {
    // project.totalRecordsCount could be both 0 or undefined
    if (!project.totalRecordsCount) return 'No records';

    const records = numbro(project.totalRecordsCount).format({
      average: true,
      mantissa: 1,
    });
    return `${records} records`;
  };

  const showDimensionsText = () => {
    // project.totalDimensionsCount could be both 0 or undefined
    if (!project.totalDimensionsCount) return 'No dimensions';

    return `${project.totalDimensionsCount} dimensions`;
  };

  const elevate = () => {
    if (hover) return 8;
    if (selected) return 6;
    return 2;
  };

  useEffect(() => {
    if (!app.projectsLoaded) return;
    if (userRootPaths[project.id]) {
      let parsed: string[] = [];
      try {
        parsed = JSON.parse(userRootPaths[project.id]) as string[];
      } catch (error) {
        parseErrorLog.log('parse error', error);
      }
      setDefaultPath(parsed);
    }
    // kaeplaAdmins
    if (kaeplaAssignment && !userRootPaths[project.id]) {
      setDefaultPath([]);
    }
    setAssignmentsCount(projectAssignments.filter((p) => p.projectId === project.id).length);
  }, [app.projectsLoaded, project.id, userRootPaths, projectAssignments, kaeplaAssignment]);

  useEffect(() => {
    const parameters: KaeplaApiParameters = {
      q: 'meta' as KaeplaQueryType,
      projectId: project.id,
      s: 'ProjectCard',
    };
    getFromKaepla({
      callBack: (apiResponse) => {
        if (!apiResponse?.customer) return;
        const _projectCustomer = apiResponse.customer;
        if (_projectCustomer) {
          setProjectCustomer(_projectCustomer);
        }
      },
      params: parameters,
      uid: kaeplaUser?.uid,
    });
  }, [kaeplaUser?.uid, project.id]);

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Card
        className="project-card"
        data-testid="project-card"
        elevation={elevate()}
        onFocus={() => {
          setHover(true);
        }}
        onBlur={() => {
          setHover(false);
        }}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
        sx={{
          cursor: 'pointer',
          // opacity: selected || hover ? 1 : 0.8,
          position: 'relative',
          height: '100%',
        }}
        onClick={() => {
          goToAssignments();
        }}
      >
        <StyledCardHeader
          avatar={
            <Avatar
              data-testid="project-avatar"
              sx={{
                bgcolor: 'transparent',
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor:
                  theme.palette.mode === 'light'
                    ? theme.palette.grey[300]
                    : theme.palette.grey[800],
              }}
              variant="square"
            >
              {projectCustomer?.iconBase64 && (
                <Image
                  width="70%"
                  height="70%"
                  alt={projectCustomer?.name}
                  src={projectCustomer?.iconBase64}
                  duration={avatarAppearDuration}
                />
              )}
            </Avatar>
          }
          title={<Box data-testid="project-name">{project.name}</Box>}
          subheader={
            <Tooltip
              slotProps={{
                popper: {
                  sx: { opacity: kaeplaAssignment?.devTeamMember ? 1 : 3 },
                },
              }}
              title={project.id}
            >
              <Box data-testid="project-subheader">{subHeaderText}</Box>
            </Tooltip>
          }
          titleTypographyProps={{
            noWrap: true,
          }}
          action={
            (kaeplaAssignment !== undefined ||
              (project.ownedBy ?? project.createdBy) === kaeplaUser?.uid) && (
              <IconButton
                edge="end"
                size="small"
                data-testid="project-menu-toggle"
                onClick={(event) => {
                  event.stopPropagation();
                  handleMenuClick(event);
                }}
              >
                <MoreVertIcon data-testid="project-menu-icon" />
              </IconButton>
            )
          }
        />
        <CardContent
          sx={{
            minHeight: 100,
            py: 0,
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {kaeplaAssignment && reseller && (
                <IconButton
                  data-testid="project-reseller-button"
                  size="small"
                  aria-label="settings"
                  edge="start"
                >
                  <ResellerAvatar
                    title={reseller.name}
                    icon={reseller.iconBase64}
                    small
                    id={reseller.id}
                  />
                </IconButton>
              )}
              <Chip
                data-testid="project-records"
                sx={{ mr: 1, mt: 1 }}
                label={showRecordsText()}
                size="small"
                variant="outlined"
              />
              {kaeplaAssignment && (
                <Chip
                  data-testid="project-dimensions"
                  sx={{ mr: 1, mt: 1 }}
                  label={showDimensionsText()}
                  size="small"
                  variant="outlined"
                />
              )}
              <Chip
                data-testid="project-assignmentsCount"
                sx={{ mr: 1, mt: 1 }}
                label={`${pluralize('assignment', assignmentsCount, true)}`}
                size="small"
                variant="outlined"
              />
            </Grid>
            {defaultPath && (
              <Grid item xs={12}>
                <Typography variant="caption">
                  {defaultPath.length > 0
                    ? simplifyScopePath(defaultPath).join(' ➔ ')
                    : 'All Scopes'}
                </Typography>
                <Divider />
              </Grid>
            )}
            {defaultPath && (
              <Grid item sm={12}>
                <ProjectTeam project={project} disableInvites defaultPath={defaultPath} />
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
      {(kaeplaAssignment !== undefined ||
        (project.ownedBy ?? project.createdBy) === kaeplaUser?.uid) && (
        <CardMenu
          anchorEl={anchorElement}
          open={!!anchorElement}
          onClose={handleMenuClose}
          handleGenerateDummyData={handleGenerateDummyData}
          project={project}
        />
      )}
    </Grid>
  );
};
