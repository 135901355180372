import { MatchType } from './types/MatchType';

export const INSIGNIFICANT_OPACITY = 0.4;
export const DELETE_SPEED = 500; // 4000

// IHS-Vehicles
export const IHS_VEHICLES_UPLOAD_SPEED = 7000; // 7000
export const IHS_VEHICLE_MATCH_TYPE_COUNTS: {
  count: number;
  matchType: MatchType;
}[] = [
  { count: 3468, matchType: MatchType.ONE2ONE },
  { count: 23, matchType: MatchType.ONE2ZERO },
  { count: 5, matchType: MatchType.N2ZERO },
  { count: 28, matchType: MatchType.N2ONE },
  { count: 8, matchType: MatchType.ZERO2N },
];

// ERP-Orders
export const ERP_ORDERS_IMPORT_SPEED = 1000; // 7000
export const ERP_ORDERS_MATCH_TYPE_COUNTS: {
  count: number;
  matchType: MatchType;
}[] = [
  { count: 3468, matchType: MatchType.ONE2ONE },
  { count: 10, matchType: MatchType.ONE2ZERO },
  { count: 10, matchType: MatchType.N2ZERO },
  { count: 28, matchType: MatchType.N2ONE },
  { count: 14, matchType: MatchType.ZERO2N },
];
