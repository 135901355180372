import { getApp } from 'firebase/app';
import { collection, doc, DocumentData, getFirestore, setDoc, Timestamp } from 'firebase/firestore';

import { createOrUpdateUser } from './createOrUpdateUser.js';
import { getUID } from './getUID.js';
import { getUserByEmail } from './getUserByEmail.js';
import { getNamesFromEmail } from '../../Frontend/helpers/getNamesFromEmail.js';
import { KaeplaResellerAssignment } from '../kaeplaTypes/Application/KaeplaResellerAssignment.js';
import { KaeplaUser } from '../kaeplaTypes/Application/KaeplaUser.js';
import { FirestoreTimestamp } from '../kaeplaTypes/FirestoreTimeStamp.js';

interface Options {
  resellerId: string;
  email: string;
  assignedBy: string;
}

export const createResellerAssignmentForEmail = async ({
  resellerId,
  email,
  assignedBy,
}: Options) => {
  let user = await getUserByEmail({ email });
  if (!user) {
    const namesFromEmail = getNamesFromEmail(email);
    const userToCreate: Partial<KaeplaUser> = {
      uid: getUID(),
      email,
      displayName: namesFromEmail.displayName,
      acronym: namesFromEmail.acronym,
    };
    user = await createOrUpdateUser({ user: userToCreate });
  }
  if (!user || !resellerId) return;
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `resellerAssignments`;
  const firestoreCollectionReference = collection(firestoreDatabase, fireStorePath);
  const firestoreDocumentReference = doc(firestoreCollectionReference);
  const assignment: KaeplaResellerAssignment = {
    id: firestoreDocumentReference.id,
    uid: user.uid,
    resellerId,
    inviteEmail: email,
    inviteAccessDomain: window.location.hostname,
    assignedAt: Timestamp.now() as FirestoreTimestamp,
    assignedBy,
    updatedAt: Timestamp.now() as FirestoreTimestamp,
    canCreateCustomer: true,
  };
  await setDoc(firestoreDocumentReference, assignment as DocumentData);
};
