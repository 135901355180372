import { KaeplaImport } from '../../../../services/kaeplaTypes/Application/KaeplaImport.js';
import { KaeplaOpsUpdateStatus } from '../../../../services/kaeplaTypes/importer/KaeplaImportUpdateStatus.js';

export const failedOrCancelledOrFinished = (dataImportUpdate: KaeplaImport) => {
  return (
    dataImportUpdate.updateStatus === KaeplaOpsUpdateStatus.failed ||
    dataImportUpdate.updateStatus === KaeplaOpsUpdateStatus.canceled ||
    dataImportUpdate.updateStatus === KaeplaOpsUpdateStatus.cancelRequested ||
    dataImportUpdate.updateStatus === KaeplaOpsUpdateStatus.finished
  );
};
