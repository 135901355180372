import { fuelSystems } from '../IHS-Vehicles/data/fuelSystems';
import { fuelTypes } from '../IHS-Vehicles/data/fuelTypes';
import { regions } from '../IHS-Vehicles/data/regions';
import { IhsVehicleFull } from '../types/IhsVehicleFull';

export const generateIhsMatch = (
  index: number,
  vehicle: IhsVehicleFull,
  insignificantOnly: boolean,
): IhsVehicleFull => {
  const randomFuelTypeIndex = Math.floor(Math.random() * fuelTypes.length);
  const randomFuelSystemIndex = Math.floor(Math.random() * fuelSystems.length);
  const randomRegionIndex = Math.floor(Math.random() * regions.length);

  let fuelType = fuelTypes[randomFuelTypeIndex];
  let fuelSystem = fuelSystems[randomFuelSystemIndex];
  let region = regions[randomRegionIndex];
  if (insignificantOnly) {
    fuelType = vehicle.fuelType!;
    fuelSystem = vehicle.fuelSystem!;
    region = vehicle.region!;
  }

  return {
    ...vehicle,
    id: `match-${vehicle.id}-${index}`,
    fuelType,
    fuelSystem,
    region,
  };
};
