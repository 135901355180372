import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';

interface KOptions {
  params: {
    projectAssignmentId?: string;
    customerAssignmentId?: string;
  };
}

export const resendInvite = ({ params }: KOptions) => {
  const functions = getFunctions(getApp('kaepla'), 'europe-west3');
  const resendInviteEmail = httpsCallable(functions, 'resendInviteEmail');
  void resendInviteEmail(params);
};
