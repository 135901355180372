import { TableBody, TableCell, TableRow, lighten, useTheme } from '@mui/material';

import { INSIGNIFICANT_OPACITY } from '../../config';
import { ErpOrder } from '../../types/ErpOrder';
import { ordersSchema } from '../data/ordersSchema';

interface Options {
  erpOrder: ErpOrder;
}

export const OneAndN2ZeroMatchDetailRenderer = ({ erpOrder }: Options) => {
  const theme = useTheme();
  const resultRowBgColor = lighten(theme.palette.success.light, 0.85);

  return (
    <TableBody>
      {erpOrder.matches.length > 1 &&
        erpOrder.matches.map((vehicle) => (
          <TableRow key={vehicle.id}>
            {ordersSchema
              .filter((field) => !field.isCollapsible || erpOrder.isFieldsExpanded)
              .map((field) => (
                <TableCell
                  key={field.fieldName}
                  sx={{
                    whiteSpace: 'nowrap',
                    opacity: field.isInsignificantField ? INSIGNIFICANT_OPACITY : 1,
                    width: field.width ?? 'auto',
                  }}
                >
                  {vehicle[field.fieldName] as string}
                </TableCell>
              ))}
          </TableRow>
        ))}
      <TableRow key={erpOrder.id} sx={{ backgroundColor: resultRowBgColor }}>
        {ordersSchema
          .filter((field) => {
            if (!erpOrder.isFieldsExpanded && field.isCollapsible) return false;
            return true;
          })
          .map((field) => (
            <TableCell
              key={field.fieldName}
              sx={{
                fontWeight: field.isValueField ?? field.isKeyField ? 'bold' : 100,
                whiteSpace: 'nowrap',
                opacity: field.isInsignificantField ? INSIGNIFICANT_OPACITY : 1,
              }}
            >
              {erpOrder[field.fieldName] as string}
            </TableCell>
          ))}
      </TableRow>
    </TableBody>
  );
};
