import AddTeamMemberIcon from '@mui/icons-material/PersonAddOutlined';
import { Box, IconButton } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';

interface Options {
  callback: () => void;
}

export const AddTeamMemberToggle = ({ callback }: Options) => {
  const theme = useTheme();
  return (
    <Box>
      <IconButton
        data-testid="team-add-team-member"
        sx={{
          bgcolor: grey[300],
          borderColor:
            theme.palette.mode === 'light'
              ? theme.palette.common.white
              : theme.palette.common.black,
          borderWidth: 2,
          borderStyle: 'solid',
          color:
            theme.palette.mode === 'light'
              ? theme.palette.common.white
              : theme.palette.common.black,
        }}
        color="primary"
        onClick={(event) => {
          event.stopPropagation();
          callback();
        }}
      >
        <AddTeamMemberIcon
          sx={{
            width: 30,
            height: 30,
          }}
        />
      </IconButton>
    </Box>
  );
};
