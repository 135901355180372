import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';

import { requestLog } from '../../../Frontend/helpers/logger';

type DataReturn = (data: { success: boolean; rows: Record<string, unknown>[] }) => void;

interface DummyParameters {
  projectId?: string;
  sqlExample?: string;
  inquiryExample?: string;
  tryRun?: boolean;
  needsResolution?: boolean;
}

interface KOptions {
  callBack?: DataReturn;
  errorCallBack?: (error: Error) => void;
  setLoading?: (loading: boolean) => void;
  params?: DummyParameters;
  uid?: string;
}

export const dummyOnCall = ({ params, uid, setLoading, callBack, errorCallBack }: KOptions) => {
  const functions = getFunctions(getApp('kaepla'), 'europe-west3');
  const dummy = httpsCallable(functions, 'dummy', { timeout: 700_000 });

  requestLog.log(`dummy(${JSON.stringify({ ...params, uid })})`);

  void dummy(params)
    .then((result) => {
      // console.log("loading", params.q, loading);
      if (typeof setLoading === 'function') {
        setLoading(false);
      }
      if (callBack) {
        callBack(result.data as { success: boolean; rows: Record<string, unknown>[] });
      }
    })
    .catch((error: Error) => {
      if (errorCallBack) {
        errorCallBack(error);
      }
    });
};
