import { generateFromString } from 'generate-avatar';
import colors from 'nice-color-palettes';

export const getRandomAvatar = (name: string) => {
  const chartColors1 = [...colors[0], ...colors[1]];
  const chartColors2 = [...colors[10], ...colors[11]];
  const chartColors3 = [...colors[2], ...colors[3]];
  const chartColors4 = [...colors[4], ...colors[5]];
  const chartColors5 = [...colors[6], ...colors[7]];
  const chartColors6 = [...colors[8], ...colors[9]];
  const variants: string[] = [
    `data:image/svg+xml;utf8,${generateFromString(name)}`,
    `https://api.dicebear.com/9.x/glass/svg?seed=${name}&backgroundColor=${chartColors1.join(',')}`,
    `https://api.dicebear.com/9.x/glass/svg?seed=${name}&backgroundColor=${chartColors2.join(',')}`,
    `https://api.dicebear.com/9.x/glass/svg?seed=${name}&backgroundColor=${chartColors3.join(',')}`,
    `https://api.dicebear.com/9.x/glass/svg?seed=${name}&backgroundColor=${chartColors4.join(',')}`,
    `https://api.dicebear.com/9.x/glass/svg?seed=${name}&backgroundColor=${chartColors5.join(',')}`,
    `https://api.dicebear.com/9.x/glass/svg?seed=${name}&backgroundColor=${chartColors6.join(',')}`,
  ];
  const random = Math.floor(Math.random() * variants.length);
  return variants[random];
};
