import { Dispatch, SetStateAction } from 'react';

import { DonutChartEditor } from './DonutChart/DonutChartEditor.js';
import { StackedBarChartChartEditor } from './StackedBarChart/StackedBarChartChartEditor.js';
import { KaeplaPerspectiveBlock } from '../../../../../../services/kaeplaTypes/Application/KaeplaPerspectiveBlock.js';
import { KaeplaPerspectiveBlockType } from '../../../../../../services/kaeplaTypes/Application/KaeplaPerspectiveBlockType.js';

interface EOptions {
  block: KaeplaPerspectiveBlock;
  setAggregation: Dispatch<SetStateAction<string>>;
  setValueDimension: Dispatch<SetStateAction<string | undefined>>;
  setAggregationDimension: Dispatch<SetStateAction<string | undefined>>;
  setTimeDimension: Dispatch<SetStateAction<string | undefined>>;
  setSplitDimension: Dispatch<SetStateAction<string | undefined>>;
}

export const ChartEditorFromType = ({
  block,
  setAggregation,
  setValueDimension,
  setAggregationDimension,
  setTimeDimension,
  setSplitDimension,
}: EOptions) => {
  switch (block.type) {
    case KaeplaPerspectiveBlockType.aggregationOverTime: {
      return (
        <StackedBarChartChartEditor
          block={block}
          setAggregation={setAggregation}
          setValueDimension={setValueDimension}
          setAggregationDimension={setAggregationDimension}
          setTimeDimension={setTimeDimension}
          setSplitDimension={setSplitDimension}
        />
      );
    }
    case KaeplaPerspectiveBlockType.aggregationPercentages: {
      return (
        <DonutChartEditor
          block={block}
          setAggregation={setAggregation}
          setValueDimension={setValueDimension}
          setAggregationDimension={setAggregationDimension}
        />
      );
    }
    case KaeplaPerspectiveBlockType.treemap: {
      return null;
    }
    // No default
  }
  return null;
};
