import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { dataSourceOrdersState } from '../../../../services/recoil/nonpersistent/dataSourceOrdersState';
import { MatchBuckets } from '../components/MatchBuckets';
import { ErpOrder } from '../types/ErpOrder';
import { MatchBucket } from '../types/MatchBucket';
import { MatchBucketType } from '../types/MatchBucketType';
import { MatchEffect } from '../types/MatchEffect';
import { MatchType } from '../types/MatchType';

export const newOrders = (vehicles: ErpOrder[]) => {
  return vehicles.filter((vehicle) => {
    if (vehicle.matchType === MatchType.ONE2ZERO || vehicle.matchType === MatchType.N2ZERO) {
      return true;
    }
    return false;
  });
};

export const unchangedOrders = (vehicles: ErpOrder[]) => {
  return vehicles.filter((vehicle) => {
    if (vehicle.matchType === MatchType.ONE2ONE && vehicle.updates === false) {
      return true;
    }
    return false;
  });
};

export const updatedOrders = (vehicles: ErpOrder[]) => {
  return vehicles.filter((vehicle) => {
    if (vehicle.matchType === MatchType.ONE2ONE && vehicle.updates === true) {
      return true;
    }
    return false;
  });
};

export const deletedOrders = (vehicles: ErpOrder[]) => {
  return vehicles.filter((vehicle) => {
    if (vehicle.matchType === MatchType.ZERO2N) {
      return true;
    }
    return false;
  });
};

export const manualReviewOrders = (vehicles: ErpOrder[]) => {
  return vehicles.filter((vehicle) => {
    if (vehicle.matchType === MatchType.N2ONE) {
      return true;
    }
    return false;
  });
};
interface Options {
  explain?: boolean;
}

export const ErpOrdersMatchBuckets = ({ explain }: Options) => {
  const ordersFromState = useRecoilValue(dataSourceOrdersState);
  const buckets: MatchBucket[] = useMemo(
    () => [
      {
        type: MatchBucketType.NEW,
        color: 'error' as MatchEffect['color'],
        label: 'unmatched',
        description: 'These deliveries can not be matched to any existing order items.',
        count: newOrders(ordersFromState).length,
      },
      {
        type: MatchBucketType.UNCHANGED,
        label: 'unchanged',
        description: 'These deliveries can be matched to existing order items.',
        color: 'success' as MatchEffect['color'],
        count: unchangedOrders(ordersFromState).length,
      },
      {
        type: MatchBucketType.UPDATED,
        label: 'updated',
        description:
          'These deliveries can be matched to existing orders and contain some information which will update the order item.',
        color: 'info' as MatchEffect['color'],
        count: updatedOrders(ordersFromState).length,
      },
      {
        type: MatchBucketType.DELETED,
        label: 'empty',
        description: 'These order items have no matching deliveries this time.',
        color: 'info' as MatchEffect['color'],
        count: deletedOrders(ordersFromState).length,
      },
      {
        type: MatchBucketType.MANUAL_REVIEW,
        label: 'manual matching',
        description: 'These deliveries need manual review to be matched to existing order items.',
        color: 'warning' as MatchEffect['color'],
        count: manualReviewOrders(ordersFromState).length,
      },
    ],
    [ordersFromState],
  );

  return <MatchBuckets buckets={buckets} explain={explain} />;
};
