import SuggestIcon from '@mui/icons-material/AssistantOutlined';
import { Box, IconButton } from '@mui/material';
import { green } from '@mui/material/colors';

interface Options {
  getRandomQuestion: () => Promise<void>;
}

export const AskARandomQuestion = ({ getRandomQuestion }: Options) => {
  return (
    <Box border={1} borderLeft={3} borderColor={green[200]} borderRadius={1} p={1}>
      Ask a random question by pressing
      <IconButton
        color="primary"
        sx={{ p: '10px' }}
        aria-label="suggest"
        onClick={() => {
          void getRandomQuestion();
        }}
      >
        <SuggestIcon />
      </IconButton>
    </Box>
  );
};
