import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { getApp } from 'firebase/app';
import { getDownloadURL, getStorage, listAll, ref } from 'firebase/storage';
import { useRecoilValue } from 'recoil';

import { useAuth } from '../../../../../AuthReactProvider';
import { getDataForGrid } from '../../../../../services/api/getDataForGrid';
import { createEvent } from '../../../../../services/firestore/createEvent';
import {
  FirebaseProjectId,
  KaeplaDataOperation,
  KaeplaEventEffect,
  KaeplaEventType,
  KaeplaFunctionGroup,
} from '../../../../../services/kaeplaTypes';
import { KaeplaPivotParameters } from '../../../../../services/kaeplaTypes/Application/KaeplaPivotParameters';
import { projectState } from '../../../../../services/recoil/nonpersistent/projectState';
import { currentScopePathState } from '../../../../../services/recoil/persistent/currentScopePathState';
import { Excel } from '../../../../icons/Excel';

interface EOptions {
  open: boolean;
  setOpen: (value: boolean) => void;
  gridReference: React.RefObject<AgGridReact>;
  setDownloading: (value: boolean) => void;
  setDownloadSuccess: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setDownloadError: React.Dispatch<React.SetStateAction<string | undefined>>;
  gridParameters: KaeplaPivotParameters | undefined;
}

export const ExportDialog = ({
  open,
  setOpen,
  gridReference,
  setDownloading,
  setDownloadSuccess,
  setDownloadError,
  gridParameters,
}: EOptions) => {
  const { kaeplaUser } = useAuth();
  const project = useRecoilValue(projectState);
  const currentScopePath = useRecoilValue(currentScopePathState);

  const handleClose = () => {
    setOpen(false);
  };

  const downloadGridData = async () => {
    if (!gridParameters) return;
    const exportColumns = gridReference.current!.api.getColumnState();
    const selectedExportColumns = exportColumns
      .filter((c) => !c.hide)
      .map((column) => column.colId);
    // if (kaeplaUser?.uid !== 'test') return;

    void createEvent({
      uid: kaeplaUser?.uid,
      eventType: KaeplaEventType.EXPORT_START_EXPORT,
      functionGroup: KaeplaFunctionGroup.DATA_EXPORT,
      operation: KaeplaDataOperation.READ,
      scopePath: currentScopePath,
      project,
    });

    setDownloading(true);
    gridParameters.downloadAsFile = true;
    setDownloadError(undefined);
    const response = await getDataForGrid({
      params: { ...gridParameters, selectedExportColumns },
      uid: kaeplaUser?.uid,
      setError: setDownloadError,
    });
    setDownloading(false);
    const { downloadId } = response as Record<string, string>;
    if (!downloadId) {
      setDownloadSuccess(false);
      return;
    }
    const folder = `${project.id}/${downloadId}`;
    const app = getApp('kaepla');
    const appId = app.options.projectId as FirebaseProjectId;
    // const environment = app.options.projectId;
    let bucket;
    if (appId === FirebaseProjectId.production) {
      bucket = 'gs://kaepla-exports-production';
    } else if (appId === FirebaseProjectId.staging) {
      bucket = 'gs://kaepla-exports-staging';
    }
    if (!bucket) {
      return;
    }
    const storage = getStorage(app, bucket);

    // Create a reference under which you want to list
    const listReference = ref(storage, folder);
    listAll(listReference)
      .then((listResult) => {
        listResult.items.forEach((itemReference) => {
          const fileName = itemReference.name;
          if (!fileName.endsWith('.xlsx')) return;
          getDownloadURL(itemReference)
            .then((url) => {
              setDownloadSuccess(true);
              const link = document.createElement('a');
              link.href = url;
              document.body.append(link);
              link.click();
              link.remove();
              setDownloading(false);
              setDownloadSuccess(undefined);
              void createEvent({
                uid: kaeplaUser?.uid,
                eventType: KaeplaEventType.EXPORT_FINISH_EXPORT,
                functionGroup: KaeplaFunctionGroup.DATA_EXPORT,
                operation: KaeplaDataOperation.READ,
                effect: KaeplaEventEffect.EXPORT_DOWNLOAD_SUCCESS,
                scopePath: currentScopePath,
                project,
              });
            })
            .catch((_error) => {
              setDownloadSuccess(false);
            });
        });
      })
      .catch((_error) => {
        setDownloadSuccess(false);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle variant="h3">Download Data</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <DialogContentText>
          {/* When you download the data currently loaded in this view as spreadsheet, be aware that you
          will only get the data currently visible. There might be more records available.
          <br />
          <br />
          <Button
            variant="contained"
            startIcon={<Excel />}
            onClick={() => {
              const excelParameters = {
                fileName: `${project.name}-export-${DateTime.now().toFormat('yyyy-MM-dd-HH-mm-ss')}.xlsx`,
                skipHeader: false,
              };
              gridReference.current!.api.exportDataAsExcel(excelParameters);
              void createEvent({
                uid: kaeplaUser?.uid,
                eventType: KaeplaEventType.EXPORT_GET_DATAGRID_SNAPSHOT,
                functionGroup: KaeplaFunctionGroup.DATA_EXPORT,
                operation: KaeplaDataOperation.READ,
                effect: KaeplaEventEffect.EXPORT_DOWNLOAD_SUCCESS,
                scopePath: currentScopePath,
                project,
              });
            }}
          >
            Download View
          </Button>
          <br />
          <br /> If you want <b>all data</b> as a Spreadsheet, you can request an export (may take a
          while).
          <br />
          <br /> */}
          For your data export, the <u>currently active filter</u> is applied.
          <br />
          The data is prepared on the server and might take a bit. Also the resulting file might be
          quite large. If it is too large to be processed, please narrow your filters or select
          fewer columns and/or define other filter criteria and try again.
          <br />
          <br />
          <Button
            variant="contained"
            startIcon={<Excel />}
            onClick={(event) => {
              event.preventDefault();
              void downloadGridData();
              handleClose();
            }}
          >
            Request Export
          </Button>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
