import { TableBody, TableCell, TableRow, lighten, useTheme } from '@mui/material';

import { ValueUpdate } from './ValueUpdate';
import { INSIGNIFICANT_OPACITY } from '../../config';
import { ErpOrder } from '../../types/ErpOrder';
import { ordersSchema } from '../data/ordersSchema';

interface Options {
  erpOrder: ErpOrder;
  setHighlight: React.Dispatch<React.SetStateAction<boolean>>;
}

export const One2OneMatchDetailRenderer = ({ erpOrder, setHighlight }: Options) => {
  const theme = useTheme();
  const resultRowBgColor = lighten(theme.palette.success.light, 0.85);

  return (
    <TableBody>
      {erpOrder.matches.length > 0 &&
        erpOrder.matches.map((order) => (
          <TableRow key={order.id}>
            {ordersSchema
              .filter((field) => {
                if (!erpOrder.isFieldsExpanded && field.isCollapsible) return false;
                return true;
              })
              .map((field) => (
                <TableCell
                  key={field.fieldName}
                  sx={{
                    fontWeight: field.isKeyField ? 'bold' : 100,
                    whiteSpace: 'nowrap',
                    opacity:
                      field.isInsignificantField &&
                      order[field.fieldName] === erpOrder[field.fieldName]
                        ? INSIGNIFICANT_OPACITY
                        : 1,
                    width: field.width ?? 'auto',
                  }}
                >
                  <ValueUpdate
                    field={field}
                    isUpdated={order[field.fieldName] !== erpOrder[field.fieldName]}
                    value={order[field.fieldName]}
                    originalValue={erpOrder[field.fieldName]}
                    fixedQuantity={Math.round(
                      (order[field.fieldName] as number) / order.quantityPercentChange / 100,
                    )}
                    erpOrder={erpOrder}
                    setHighlight={setHighlight}
                  />
                </TableCell>
              ))}
          </TableRow>
        ))}
      <TableRow key={erpOrder.id} sx={{ backgroundColor: resultRowBgColor }}>
        {ordersSchema
          .filter((field) => {
            if (!erpOrder.isFieldsExpanded && field.isCollapsible) return false;
            return true;
          })
          .map((field) => (
            <TableCell
              key={field.fieldName}
              sx={{
                fontWeight: field.isValueField ?? field.isKeyField ? 'bold' : 100,
                whiteSpace: 'nowrap',
                opacity: field.isInsignificantField ? INSIGNIFICANT_OPACITY : 1,
              }}
            >
              {erpOrder[field.fieldName] as string}
            </TableCell>
          ))}
      </TableRow>
    </TableBody>
  );
};
