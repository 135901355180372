import { Box } from '@mui/material';

interface Options {
  label: string;
  color: string;
  borderColor: string;
  width?: number;
  type?: 'square' | 'circle' | 'rounded';
  onClick?: () => void;
  active?: boolean;
}
export const ChartLabel = ({
  label,
  color,
  borderColor,
  width,
  type,
  onClick,
  active,
}: Options) => {
  const _width = width ?? 12;
  let radius = _width / 2;
  switch (type) {
    case 'square': {
      radius = 0;

      break;
    }
    case 'circle': {
      radius = _width / 2;

      break;
    }
    case 'rounded': {
      radius = _width / 4;

      break;
    }
    // No default
  }
  return (
    <Box
      sx={{
        cursor: onClick ? 'pointer' : 'default',
        textDecoration: active ? 'none' : 'line-through',
        whiteSpace: 'nowrap',
      }}
      mr={2}
      component="span"
      onClick={onClick}
    >
      <Box
        component="span"
        display="inline-block"
        border={1}
        borderRadius={radius}
        width={_width}
        height={_width}
        bgcolor={active ? color : '#eee'}
        borderColor={active ? borderColor : '#a1a1a1'}
        mr={0.5}
      />
      {label}
    </Box>
  );
};
