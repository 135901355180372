import { Grid, Paper } from '@mui/material';
import { useRecoilValue } from 'recoil';

import { customerAssignmentState } from '../../../../services/recoil/nonpersistent/customerAssignmentState.js';
import { projectsOverviewState } from '../../../../services/recoil/nonpersistent/projectsOverviewState.js';
import { selectedCustomerState } from '../../../../services/recoil/nonpersistent/selectedCustomer.js';

interface Options {
  creating: boolean;
}

export const NoProjects = ({ creating }: Options) => {
  const projectsOverview = useRecoilValue(projectsOverviewState);
  const customerAssignments = useRecoilValue(customerAssignmentState);
  const selectedCustomer = useRecoilValue(selectedCustomerState);

  if (creating) return null;

  // customer users who can create a (first) project
  if (
    customerAssignments.length > 0 &&
    projectsOverview.length === 0 &&
    selectedCustomer &&
    customerAssignments.find((c) => c.customerId === selectedCustomer.id)?.canCreateProject
  ) {
    return (
      <Grid item xs={12}>
        <Paper data-testid="no-project-create-one" sx={{ p: 6 }}>
          No projects yet. Create one!
        </Paper>
      </Grid>
    );
  }

  // non-customer users who can create a (first) project
  if (projectsOverview.length > 0) return null;

  return (
    <Grid item xs={12}>
      <Paper
        data-testid="no-project-need-invite"
        sx={{ p: 6 }}
      >{`No projects yet. You need to be invited to one! ;-)`}</Paper>
    </Grid>
  );
};
