import SimulationIcon from '@mui/icons-material/AutoGraphOutlined';
import AssignmentsIcon from '@mui/icons-material/ChecklistOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ExportIcon from '@mui/icons-material/DownloadOutlined.js';
import TargetsIcon from '@mui/icons-material/FilterCenterFocusOutlined';
import DataSnapshotsIcon from '@mui/icons-material/History.js';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import ResellerIcon from '@mui/icons-material/KeyOutlined';
import ScopesIcon from '@mui/icons-material/LayersOutlined';
import AiIcon from '@mui/icons-material/ModelTrainingOutlined.js';
import PerspectivesIcon from '@mui/icons-material/QueryStatsOutlined';
import CustomerIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import ImportIcon from '@mui/icons-material/SyncOutlined';
import WarnIcon from '@mui/icons-material/WarningAmberOutlined';
import { Grid, Toolbar, Tooltip, Typography } from '@mui/material';
import { grey, red, yellow } from '@mui/material/colors';

import { KaeplaEvent } from '../../../services/kaeplaTypes/Application/KaeplaEvent.js';
import { KaeplaEventEffect } from '../../../services/kaeplaTypes/Application/KaeplaEventEffect';
import { KaeplaFunctionGroup } from '../../../services/kaeplaTypes/Application/KaeplaFunctionGroup.js';
import { HumanReadableTimestampType, convertTimestamp } from '../../helpers/convertTimestamp.js';
import {
  alignmentsColor,
  assignmentsColor,
  customersColor,
  dataSnapshotColor,
  experimentalAiColor,
  exportColor,
  importColor,
  perspectivesColor,
  projectsColor,
  resellersColor,
  scopesColor,
  simulationsColor,
  targetsColor,
} from '../defaults';

interface FOptions {
  functionGroup?: KaeplaFunctionGroup;
}

const FunctionGroupIcon = ({ functionGroup }: FOptions) => {
  switch (functionGroup) {
    case KaeplaFunctionGroup.ADMIN: {
      return <InfoIcon fontSize="small" />;
    }
    case KaeplaFunctionGroup.IMPORT: {
      return <ImportIcon sx={{ color: importColor }} fontSize="small" />;
    }
    case KaeplaFunctionGroup.DATA_EXPORT: {
      return <ExportIcon sx={{ color: exportColor }} fontSize="small" />;
    }
    case KaeplaFunctionGroup.DATA_SNAPSHOT: {
      return <DataSnapshotsIcon sx={{ color: dataSnapshotColor }} fontSize="small" />;
    }
    case KaeplaFunctionGroup.ALIGNMENTS: {
      return <InfoIcon sx={{ color: alignmentsColor }} fontSize="small" />;
    }
    case KaeplaFunctionGroup.AI: {
      return <AiIcon sx={{ color: experimentalAiColor }} fontSize="small" />;
    }
    case KaeplaFunctionGroup.ASSIGNMENTS: {
      return <AssignmentsIcon sx={{ color: assignmentsColor }} fontSize="small" />;
    }
    case KaeplaFunctionGroup.CUSTOMER: {
      return <CustomerIcon sx={{ color: customersColor }} fontSize="small" />;
    }
    case KaeplaFunctionGroup.PERSPECTIVES: {
      return <PerspectivesIcon sx={{ color: perspectivesColor }} fontSize="small" />;
    }
    case KaeplaFunctionGroup.PROJECTS: {
      return <DashboardIcon sx={{ color: projectsColor }} fontSize="small" />;
    }
    case KaeplaFunctionGroup.RESELLER: {
      return <ResellerIcon sx={{ color: resellersColor }} fontSize="small" />;
    }
    case KaeplaFunctionGroup.SCOPES: {
      return <ScopesIcon sx={{ color: scopesColor }} fontSize="small" />;
    }
    case KaeplaFunctionGroup.SIMULATIONS: {
      return <SimulationIcon sx={{ color: simulationsColor }} fontSize="small" />;
    }
    case KaeplaFunctionGroup.TARGETS: {
      return <TargetsIcon sx={{ color: targetsColor }} fontSize="small" />;
    }
    default: {
      return <InfoIcon fontSize="small" />;
    }
  }
};

interface Options {
  event: KaeplaEvent;
}

export const EventEntry = ({ event }: Options) => {
  const warn = () => {
    if (event.effect === KaeplaEventEffect.SCOPE_ACCESS_DENIED) {
      return true;
    }
    return false;
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Tooltip title={event.id}>
          <Typography variant="caption">
            {convertTimestamp(event.dateTime, HumanReadableTimestampType.date)}
          </Typography>
        </Tooltip>
      </Grid>
      <Grid item xs={12}>
        <Toolbar sx={{ minHeight: 32 }} disableGutters variant="dense">
          <FunctionGroupIcon functionGroup={event?.functionGroup} />
          <Typography sx={{ ml: 1 }} variant="body2">
            {event.eventType}
          </Typography>
        </Toolbar>
      </Grid>
      {event.effect && (
        <Grid item xs={12}>
          <Toolbar sx={{ minHeight: 32 }} disableGutters variant="dense">
            <InfoIcon sx={{ color: warn() ? red[600] : yellow[700] }} fontSize="small" />
            <Typography
              component="span"
              variant="body2"
              sx={{ ml: 1, color: warn() ? red[600] : grey[500] }}
            >
              {event.effect}
            </Typography>
          </Toolbar>
        </Grid>
      )}
      {event.errorMessage && (
        <Grid item xs={12}>
          <Toolbar sx={{ minHeight: 32 }} disableGutters variant="dense">
            <WarnIcon sx={{ color: red[600] }} fontSize="small" />
            <Typography component="span" variant="body2" sx={{ ml: 1, color: red[600] }}>
              {event.errorMessage}
            </Typography>
          </Toolbar>
        </Grid>
      )}
    </Grid>
  );
};
