import { TableBody, TableCell, TableRow, lighten, useTheme } from '@mui/material';

import { ValueUpdate } from './ValueUpdate';
import { INSIGNIFICANT_OPACITY } from '../../config';
import { IhsVehicleFull } from '../../types/IhsVehicleFull';
import { vehicleSchema } from '../data/vehicleSchema';

interface Options {
  ihsVehicle: IhsVehicleFull;
  setHighlight: React.Dispatch<React.SetStateAction<boolean>>;
}

export const One2OneMatchDetailRenderer = ({ ihsVehicle, setHighlight }: Options) => {
  const theme = useTheme();
  const resultRowBgColor = lighten(theme.palette.success.light, 0.85);

  return (
    <TableBody>
      {ihsVehicle.matches.length > 0 &&
        ihsVehicle.matches.map((vehicle) => (
          <TableRow key={vehicle.id}>
            {vehicleSchema
              .filter((field) => {
                if (!ihsVehicle.isFieldsExpanded && field.isCollapsible) return false;
                return true;
              })
              .map((field) => (
                <TableCell
                  key={field.fieldName}
                  sx={{
                    fontWeight: field.isKeyField ? 'bold' : 100,
                    whiteSpace: 'nowrap',
                    opacity:
                      field.isInsignificantField &&
                      vehicle[field.fieldName] === ihsVehicle[field.fieldName]
                        ? INSIGNIFICANT_OPACITY
                        : 1,
                    width: field.width ?? 'auto',
                  }}
                >
                  <ValueUpdate
                    field={field}
                    isUpdated={vehicle[field.fieldName] !== ihsVehicle[field.fieldName]}
                    value={vehicle[field.fieldName]}
                    originalValue={ihsVehicle[field.fieldName]}
                    fixedPercent={Math.round(
                      (vehicle[field.fieldName] as number) / vehicle.volumePercentChange / 100,
                    )}
                    ihsVehicle={ihsVehicle}
                    setHighlight={setHighlight}
                  />
                </TableCell>
              ))}
          </TableRow>
        ))}
      <TableRow key={ihsVehicle.id} sx={{ backgroundColor: resultRowBgColor }}>
        {vehicleSchema
          .filter((field) => {
            if (!ihsVehicle.isFieldsExpanded && field.isCollapsible) return false;
            return true;
          })
          .map((field) => (
            <TableCell
              key={field.fieldName}
              sx={{
                fontWeight: field.isValueField ?? field.isKeyField ? 'bold' : 100,
                whiteSpace: 'nowrap',
                opacity: field.isInsignificantField ? INSIGNIFICANT_OPACITY : 1,
              }}
            >
              {ihsVehicle[field.fieldName] as string}
            </TableCell>
          ))}
      </TableRow>
    </TableBody>
  );
};
