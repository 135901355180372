import { ChartOptions, TooltipItem } from 'chart.js';
import numbro from 'numbro';

import { cleanColumnName } from '../../../../../../helpers/cleanColumnName.js';
import { matrixDataColorLight } from '../../../../../defaults.js';

const formatLabel = (context: TooltipItem<'bar'>) => {
  const label = context.dataset.label ?? 'n/a';
  const value = context.parsed.y;
  const valueHumanReadable = numbro(value).format({
    average: true,
    mantissa: 1,
  });
  return `${cleanColumnName(label)}: ${valueHumanReadable}`;
};

const formatTicks = (value: string | number) => {
  return numbro(value).format({
    average: true,
    mantissa: 1,
  });
};

export const stackedBarChartConfigWoCurrency: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'index',
  },
  animation: {
    duration: 0,
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      enabled: true,
      callbacks: {
        label: formatLabel,
      },
    },
    datalabels: {
      display: false,
    },
    zoom: {
      zoom: {
        // wheel: {
        //   enabled: true,
        // },
        drag: {
          enabled: true,
          backgroundColor: matrixDataColorLight,
        },
        mode: 'x',
      },
      // pan: {
      //   enabled: true,
      //   modifierKey: 'shift',
      // },
    },
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
      ticks: {
        callback: formatTicks,
      },
    },
  },
};
