import { getApp } from 'firebase/app';
import { collection, DocumentData, getFirestore, doc, setDoc } from 'firebase/firestore';
import { DateTime } from 'luxon';

import { KaeplaProject } from '../kaeplaTypes/Application/KaeplaProject.js';
import { KaeplaSimulation } from '../kaeplaTypes/Application/KaeplaSimulation.js';

interface Options {
  project: KaeplaProject;
  createdBy: string;
  scopePath: string[];
}

export const createNewSimulation = async ({ project, scopePath, createdBy }: Options) => {
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `projects/${project.id}/simulations`;
  const firestoreCollectionReference = collection(firestoreDatabase, fireStorePath);
  const documentReference = doc(firestoreCollectionReference);
  const date = DateTime.now();
  const humanReadable = date.toLocaleString(DateTime.DATETIME_MED);
  const simulation: KaeplaSimulation = {
    id: documentReference.id,
    projectId: project.id,
    simulationMatrix: `${project.dataMatrix}S${documentReference.id}Simu`,
    name: `New simulation`,
    createdBy,
    scopePath,
    scopePathStringified: JSON.stringify(scopePath),
    description: `New simulation ${humanReadable}`,
    isBeingSimulated: false,
    rulesets: [],
  };
  await setDoc(documentReference, simulation as DocumentData);
  return simulation;
};
