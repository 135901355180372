import { DateTime } from 'luxon';

import { MatrixTimeSlice } from '../../services/kaeplaTypes/MatrixTimeSlice';

export const formatDateWithTimeSlice = (date: string, timeSlice?: MatrixTimeSlice) => {
  let thisValue = date;
  const dateTime = DateTime.fromSQL(date);
  if (timeSlice === MatrixTimeSlice.year) {
    thisValue = `${dateTime.year}`;
  }

  return `${thisValue}`;
};
