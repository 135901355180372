import { KaeplaPerspective } from '../../../../services/kaeplaTypes/Application/KaeplaPerspective.js';
import { cleanAggregation } from '../../../helpers/cleanAggregation.js';
import { cleanColumnName } from '../../../helpers/cleanColumnName.js';

export const chartTitleFromPerspective = (perspective: Partial<KaeplaPerspective>) => {
  let aggregationOverTimeDimension = '';
  if (perspective?.valueDimension && perspective?.timeDimension) {
    const valueDimension = cleanColumnName(perspective.valueDimension);
    const aggregation = cleanAggregation(perspective.aggregation ?? 'SUM');
    const time = cleanColumnName(perspective.timeDimension);
    aggregationOverTimeDimension = `${valueDimension} ${aggregation} over ${time}`;
  }
  return aggregationOverTimeDimension;
};
