import { Resetter, SetterOrUpdater } from 'recoil';

import { DimensionPathOptions } from './addDimensionToPath.js';
import { MatrixSummary } from '../../../services/kaeplaTypes/MatrixSummary.js';

interface HandlePathChangeOptions {
  matrix: MatrixSummary;
  scopePath: string[] | undefined;
  projectId: string;
  scopePaths: Record<string, string[] | undefined>;
  index: number;
  newValue: string;
  addDimensionToPath: (data: DimensionPathOptions) => string[];
  setScopePaths: SetterOrUpdater<Record<string, string[] | undefined>>;
  setCurrentScopePath: SetterOrUpdater<string[] | undefined>;
  resetMatrixFiltered: Resetter;
}

export const handlePathChange = ({
  matrix,
  scopePath,
  projectId,
  scopePaths,
  index,
  newValue,
  addDimensionToPath,
  setScopePaths,
  setCurrentScopePath,
  resetMatrixFiltered,
}: HandlePathChangeOptions) => {
  let newDimension = newValue;
  let newScopePath: string[] = [];
  if (scopePath) {
    newScopePath = [...scopePath];
  }
  if (newValue && `${newValue}`.includes('changeDimension!!')) {
    newDimension = newValue.replace('changeDimension!!', '');
    newScopePath.splice(index, newScopePath.length - index);
  }
  newScopePath = addDimensionToPath({
    matrix,
    index,
    newDimension,
    scopePath: newScopePath,
  });
  const newScopePaths = { ...scopePaths };
  newScopePaths[projectId] = newScopePath;
  setScopePaths(newScopePaths);
  setCurrentScopePath(newScopePaths[projectId] ?? []);
  resetMatrixFiltered();
};
