import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

import { KaeplaPerspectiveBlockType } from '../../../../../../services/kaeplaTypes/Application/KaeplaPerspectiveBlockType.js';
import { availableBlockTypes } from '../helpers/availableBlockTypes.js';

interface Options {
  setBlockType: Dispatch<SetStateAction<KaeplaPerspectiveBlockType>>;
  blockType: string;
}

export const BlockTypeSelect = ({ setBlockType, blockType }: Options) => {
  const handleChange = (event: SelectChangeEvent) => {
    setBlockType(event.target.value as KaeplaPerspectiveBlockType);
  };

  return (
    <FormControl sx={{ m: 0, mb: 2, minWidth: 220 }} size="small" margin="dense">
      <Select
        id="blockTypeSelect"
        value={blockType || ''}
        onChange={handleChange}
        size="small"
        inputProps={{ name: 'blockTypeSelectInput' }}
      >
        <MenuItem>
          <em>None</em>
        </MenuItem>
        {availableBlockTypes.map((a) => (
          <MenuItem key={a.name} value={a.name}>
            {a.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
