const barChartBorderWidth = 1;
export const getStackedBarChartBorders = (
  datasets: string[],
  index: number,
  hasSimulation: boolean,
) => {
  if (!hasSimulation) {
    return 0;
  }
  return {
    top: index === datasets.length - barChartBorderWidth ? barChartBorderWidth : 0,
    bottom: 0,
    left: barChartBorderWidth,
    right: barChartBorderWidth,
  };
};
