import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';

export const drawerWidth = 240;
export const StyledDrawer = styled(MuiDrawer, {
  shouldForwardProp: (property) => property !== 'open',
})(({ theme, open }) => ({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(0),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));
