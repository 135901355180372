import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';

import { requestLog } from '../../Frontend/helpers/logger.js';

interface KOptions {
  params: Record<string, unknown>;
  callBack?: () => void;
}

export const updateSimulationParametersOnCall = ({ params, callBack }: KOptions) => {
  const functions = getFunctions(getApp('kaepla'), 'europe-west3');
  const updateSimulationParameters = httpsCallable(functions, 'updateSimulationParameters');

  requestLog.log(`updateSimulationParameters({data:${JSON.stringify(params)}})`);
  void updateSimulationParameters(params).then(() => {
    if (callBack) {
      callBack();
    }
  });
};
