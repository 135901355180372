import TimelineContent from '@mui/lab/TimelineContent';
import { Typography } from '@mui/material';
import { useState } from 'react';

import { EventEntry } from './EventEntry.js';
import { KaeplaEventGroup } from './EventGroups.js';

interface EOptions {
  group: KaeplaEventGroup;
}
export const EventGroup = ({ group }: EOptions) => {
  const [showMore, setShowMore] = useState(false);
  return (
    <TimelineContent>
      {group.lastEvent && !showMore && (
        <EventEntry key={group.lastEvent.id} event={group.lastEvent} />
      )}
      <Typography
        sx={{
          textDecoration: 'underline',
          cursor: 'pointer',
          display: showMore || group.events.length - 1 === 0 ? 'none' : 'block',
        }}
        variant="caption"
        onClick={() => {
          setShowMore(true);
        }}
      >
        {group.events.length - 1} more...
      </Typography>
      {showMore && group.events.map((event) => <EventEntry key={event.id} event={event} />)}
    </TimelineContent>
  );
};
