import { Box, useTheme } from '@mui/material';
import numbro from 'numbro';
import { useSetRecoilState } from 'recoil';

import { dataSourceVehiclesState } from '../../../../../services/recoil/nonpersistent/dataSourceVehiclesState';
import { IhsVehicleFull } from '../../types/IhsVehicleFull';
import { IhsVehiclesConfigurationSchemaField } from '../../types/IhsVehiclesConfigurationSchemaField';

interface ValueUpdateProperties {
  field: IhsVehiclesConfigurationSchemaField;
  isUpdated: boolean;
  value: unknown;
  originalValue: unknown;
  fixedPercent: number;
  ihsVehicle: IhsVehicleFull;
  setHighlight: React.Dispatch<React.SetStateAction<boolean>>;
}
export const ValueUpdate = ({
  field,
  value,
  originalValue,
  fixedPercent,
  isUpdated,
  ihsVehicle,
  setHighlight,
}: ValueUpdateProperties) => {
  const theme = useTheme();
  const setVehicles = useSetRecoilState(dataSourceVehiclesState);

  const handleUpdateClick = (updateValue: number | string) => {
    setHighlight(true);
    setVehicles((previous) =>
      previous.map((vehicle) => {
        if (vehicle.id !== ihsVehicle.id) return vehicle;
        const item = { ...vehicle, [field.fieldName]: updateValue, isReviewed: true };
        return item;
      }),
    );
    setTimeout(() => {
      setHighlight(false);
    }, 1000);
  };

  if (!field.isValueField && isUpdated) {
    return (
      <Box>
        <Box
          component="span"
          sx={{ color: theme.palette.info.light, cursor: 'pointer' }}
          onClick={() => {
            handleUpdateClick(value as string);
          }}
        >
          {value as string}
        </Box>
        <Box component="span" sx={{ mx: 1, textDecoration: 'line-through' }}>
          {(originalValue as string) ?? 'no previous value'}
        </Box>
      </Box>
    );
  }
  if (!field.isValueField) {
    return (
      <Box
        sx={{ cursor: 'pointer' }}
        onClick={() => {
          handleUpdateClick(value as string);
        }}
      >
        {value as string}
      </Box>
    );
  }

  return (
    <Box sx={{ whiteSpace: 'nowrap' }}>
      {numbro(value).format({
        thousandSeparated: true,
        mantissa: 2,
      })}
      <Box component="span" sx={{ mx: 1 }}>
        {' → '}
      </Box>
      <Box component="span" sx={{ color: fixedPercent < 0 ? 'red' : 'blue' }}>
        {numbro(fixedPercent).format({ forceSign: true })}%
      </Box>
    </Box>
  );
};
