import { SideBarDef } from 'ag-grid-community';

export const initialColumnDefinition = {
  flex: 1,
  minWidth: 150,
};

export const initialAutoGroupColumnDefinition = {
  minWidth: 200,
  resizable: true,
  pinned: true,
};

export const initialSideBar: SideBarDef = {
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        suppressRowGroups: false,
        suppressValues: false,
        suppressPivots: true,
        suppressPivotMode: true,
        suppressColumnFilter: false,
        suppressColumnSelectAll: false,
        suppressColumnExpandAll: true,
      },
    },
    // 'filters',
  ],
  defaultToolPanel: 'columns',
};
