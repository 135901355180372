export const mapCurrency = (isoCode: string) => {
  if (currencyMap[isoCode]) {
    return currencyMap[isoCode];
  }
  return isoCode;
};

const currencyMap: Record<string, string> = {
  EUR: '€',
  USD: '$',
};
