/* eslint-disable @typescript-eslint/naming-convention */
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

export const ValidationRows = ({ rows }: { rows: Record<string, unknown>[] }) => {
  if (rows.length === 0) return null;
  const headers = Object.keys(rows[0]);
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 80 }} size="small">
        <TableHead>
          <TableRow>
            {headers.map((col) => (
              <TableCell sx={{ fontWeight: 'bold' }} key={col}>
                {col}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              {headers.map((col) => (
                <TableCell
                  key={`${row[col] as string}${index}`}
                >{`${row[col] as string}`}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
