import { createPaletteForLabelAndDataset, defaultChartColor } from './chartColors.js';
import { cleanColumnName } from '../../../../../helpers/cleanColumnName.js';

export interface KaeplaPieChartLabel {
  label: string;
  datasetName: string;
  color: string;
  isToggled: boolean;
}

export const createPieChartLabels = (datasets: string[], label: string) => {
  const palette = createPaletteForLabelAndDataset(label, datasets);

  const chartLabels: KaeplaPieChartLabel[] = datasets.map((dataset) => ({
    datasetName: dataset,
    label: cleanColumnName(dataset).trim(),
    color: palette.map.get(dataset) ?? defaultChartColor,
    isToggled: false,
  }));

  return chartLabels;
};
