import { Alert } from '@mui/material';
import { useRecoilValue } from 'recoil';

import { dataSourceState } from '../../../../services/recoil/nonpersistent/dataSourceState';
import { ErpDataSourceImportView } from '../ERP-Orders/ErpDataSourceImportView';
import { IhsDataSourceImportView } from '../IHS-Vehicles/IhsDataSourceImportView';

interface Options {
  fullScreen: boolean;
}

export const DataSourceImportView = ({ fullScreen }: Options) => {
  const selectedDataSource = useRecoilValue(dataSourceState);

  if (selectedDataSource.key === 'ihsVehicles') {
    return <IhsDataSourceImportView fullScreen={fullScreen} />;
  } else if (selectedDataSource.key === 'erpOrders') {
    return <ErpDataSourceImportView fullScreen={fullScreen} />;
  }

  return <Alert severity="info">No data yet.</Alert>;
};
