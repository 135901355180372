import { ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-enterprise';

import { KaeplaUser } from '../../../../services/kaeplaTypes/Application/KaeplaUser.js';
import { UserAvatar } from '../../../features/UserAvatar.js';

export const UserMainCellRenderer = (properties: ICellRendererParams<KaeplaUser>) => {
  if (!properties.data) return null;
  const user = properties.data;
  const { displayName, email, acronym } = user;
  return (
    <ListItem sx={{ p: 0 }} dense disableGutters>
      <ListItemAvatar>
        <UserAvatar user={user} size={50} />
      </ListItemAvatar>
      <ListItemText
        primary={`${displayName} (${acronym})`}
        secondary={email}
        primaryTypographyProps={{ sx: { fontSize: '110%' } }}
        secondaryTypographyProps={{ sx: { fontSize: '110%' } }}
      />
    </ListItem>
  );
};
