import { DndContext } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { Dispatch, SetStateAction } from 'react';
import { useRecoilValue } from 'recoil';

import { SimulationRuleset } from './SimulationRuleset.js';
import { handleRulesetDragEnd } from './_helpers/handleRulesetDragEnd.js';
import { KaeplaSimulation } from '../../../../services/kaeplaTypes/Application/KaeplaSimulation.js';
import { KaeplaSimulationRulesetWithParameters } from '../../../../services/kaeplaTypes/Application/KaeplaSimulationRulesetWithParameters.js';
import { projectState } from '../../../../services/recoil/nonpersistent/projectState.js';
import { currentScopePathState } from '../../../../services/recoil/persistent/currentScopePathState.js';

interface Options {
  simulation?: KaeplaSimulation;
  setSimulation: Dispatch<SetStateAction<KaeplaSimulation | undefined>>;
  simulationRulesets?: KaeplaSimulationRulesetWithParameters[];
  setSimulationRulesets: Dispatch<
    SetStateAction<KaeplaSimulationRulesetWithParameters[] | undefined>
  >;
  setPreview: Dispatch<SetStateAction<boolean>>;
  setRuleset: Dispatch<SetStateAction<KaeplaSimulationRulesetWithParameters | undefined>>;
  own: boolean;
}

export const SimulationRulesets = ({
  simulation,
  simulationRulesets,
  setSimulationRulesets,
  setSimulation,
  setPreview,
  setRuleset,
  own,
}: Options) => {
  const currentScopePath = useRecoilValue(currentScopePathState);
  const project = useRecoilValue(projectState);

  if (!simulation || !simulationRulesets || !currentScopePath || !project) {
    return null;
  }

  return (
    <DndContext
      onDragEnd={(event) =>
        handleRulesetDragEnd({
          event,
          simulation,
          project,
          scopePath: currentScopePath,
          setSimulation,
        })
      }
    >
      <SortableContext items={simulation?.rulesets} disabled={!own}>
        {simulationRulesets
          ?.filter((r) => !!r?.id)
          .map((_ruleset) => (
            <SimulationRuleset
              key={_ruleset.id}
              ruleset={_ruleset}
              simulation={simulation}
              setSimulation={setSimulation}
              own={own}
              scopePath={currentScopePath}
              simulationRulesets={simulationRulesets}
              setSimulationRulesets={setSimulationRulesets}
              setPreview={setPreview}
              setRuleset={setRuleset}
              sortableDisabled={simulationRulesets.length <= 1}
            />
          ))}
      </SortableContext>
    </DndContext>
  );
};
