import { atom } from 'recoil';

import { MatrixTimeseries } from '../../kaeplaTypes/MatrixTimeseries.js';
import { MatrixTimeseriesEntry } from '../../kaeplaTypes/MatrixTimeseriesEntry.js';

// TODO: have a default time series so the animation is smoother initially?
const defaultEntries: MatrixTimeseriesEntry[] = [];

const defaultTimeSeriesState: MatrixTimeseries = {
  timeseries: defaultEntries,
  job: { ms: 0, bytes: 0, cached: true },
};

export const timeSeriesState = atom<MatrixTimeseries>({
  key: 'timeSeries',
  default: defaultTimeSeriesState,
});
