import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

interface Options {
  setAggregation: Dispatch<SetStateAction<string>>;
  aggregation: string;
}

export const availableAggregations: Record<string, string>[] = [
  { name: 'Sum', function: 'SUM' },
  { name: 'Average', function: 'AVG' },
  { name: 'Minimum', function: 'MIN' },
  { name: 'Maximum', function: 'MAX' },
];

export const AggregationSelect = ({ setAggregation, aggregation }: Options) => {
  const handleChange = (event: SelectChangeEvent) => {
    setAggregation(event.target.value);
  };

  return (
    <FormControl sx={{ m: 0, minWidth: 100 }} size="small" margin="dense">
      <Select
        id="aggregationSelect"
        value={aggregation || ''}
        onChange={handleChange}
        size="small"
        inputProps={{ name: 'aggregationSelectInput' }}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {availableAggregations.map((a) => (
          <MenuItem key={a.function} value={a.function}>
            {a.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
