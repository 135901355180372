import { getApp } from 'firebase/app';
import { collection, doc, DocumentData, getFirestore, setDoc, Timestamp } from 'firebase/firestore';

import { createOrUpdateUser } from './createOrUpdateUser.js';
import { getCustomer } from './getCustomer.js';
import { getCustomerAssignmentsForUser } from './getCustomerAssignmentsForUser.js';
import { getUID } from './getUID.js';
import { getUserByEmail } from './getUserByEmail.js';
import { getNamesFromEmail } from '../../Frontend/helpers/getNamesFromEmail.js';
import { KaeplaCustomerAssignment } from '../kaeplaTypes/Application/KaeplaCustomerAssignment.js';
import { KaeplaUser } from '../kaeplaTypes/Application/KaeplaUser.js';
import { FirestoreTimestamp } from '../kaeplaTypes/FirestoreTimeStamp.js';

interface Options {
  customerId: string;
  resellerId: string;
  email: string;
  assignedBy: string;
}

export const createCustomerAssignmentForEmail = async ({
  customerId,
  resellerId,
  email,
  assignedBy,
}: Options) => {
  let user = await getUserByEmail({ email });
  if (!user) {
    const namesFromEmail = getNamesFromEmail(email);
    const userToCreate: Partial<KaeplaUser> = {
      uid: getUID(),
      email,
      displayName: namesFromEmail.displayName,
      acronym: namesFromEmail.acronym,
    };
    user = await createOrUpdateUser({ user: userToCreate });
  }
  if (!user || !customerId) return;
  const existingAssignments = await getCustomerAssignmentsForUser({ uid: user.uid, customerId });
  if (existingAssignments.length > 0) {
    return;
  }
  const customer = await getCustomer({ id: customerId });
  const inviteAccessDomain = customer?.customerDomains?.[0] ?? window.location.hostname;
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `customerAssignments`;
  const firestoreCollectionReference = collection(firestoreDatabase, fireStorePath);
  const firestoreDocumentReference = doc(firestoreCollectionReference);
  const assignment: KaeplaCustomerAssignment = {
    id: firestoreDocumentReference.id,
    inviteEmail: email,
    inviteAccessDomain,
    uid: user.uid,
    customerId,
    resellerId,
    assignedAt: Timestamp.now() as FirestoreTimestamp,
    assignedBy,
    canCreateProject: true,
    complete: false,
  };
  await setDoc(firestoreDocumentReference, assignment as DocumentData);
};
