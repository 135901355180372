import { Box, Typography, Tooltip } from '@mui/material';
import { DateTime } from 'luxon';
import pluralize from 'pluralize';
import TimeAgo from 'react-timeago';
import { useRecoilValue } from 'recoil';

import { dataSourceState } from '../../../../services/recoil/nonpersistent/dataSourceState';
import { DataSourceImport } from '../types/DataSourceImport';

interface DOptions {
  dataSourceImport: DataSourceImport;
}

export const DataSourceImportHeader = ({ dataSourceImport }: DOptions) => {
  const selectedDataSource = useRecoilValue(dataSourceState);
  const count = (dataSourceImport.matched ?? 0) + (dataSourceImport.unmatched ?? 0);
  return (
    <Tooltip title={dataSourceImport.id}>
      <Box>
        <Typography variant="h5" color="textPrimary">
          {dataSourceImport.createdAt.toDate().toDateString()}
          {' @ '}
          {DateTime.fromJSDate(dataSourceImport.createdAt.toDate()).toFormat('hh:mm')}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="body2" color="textSecondary">
            {count} {pluralize(selectedDataSource.itemLabel, count)}, created:{' '}
            <TimeAgo date={dataSourceImport.createdAt.toDate()} max={Number.MAX_SAFE_INTEGER} />
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {dataSourceImport.archived ? 'Archived' : ''}
          </Typography>
        </Box>
      </Box>
    </Tooltip>
  );
};
