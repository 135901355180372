import AddIcon from '@mui/icons-material/Add';
import { Fab } from '@mui/material';
import { randCompanyName, randProduct, randImg, randUrl } from '@ngneat/falso';
import { Timestamp } from 'firebase/firestore';

import { useAuth } from '../../../../AuthReactProvider.js';
import { createCustomer } from '../../../../services/firestore/createCustomer.js';
import { KaeplaCustomer } from '../../../../services/kaeplaTypes/Application/KaeplaCustomer.js';
import { FirestoreTimestamp } from '../../../../services/kaeplaTypes/FirestoreTimeStamp.js';
import { getRandomAvatar } from '../../../helpers/getRandomAvatar.js';

interface Options {
  resellerId: string;
}

export const CreateCustomerFab = ({ resellerId }: Options) => {
  const { kaeplaUser } = useAuth();
  if (!kaeplaUser) return null;
  const save = () => {
    const companyName = randCompanyName();
    const customer: Partial<KaeplaCustomer> = {
      name: companyName,
      appName: randProduct().title,
      logoUrl: randImg(),
      linkUrl: randUrl(),
      resellerId,
      createdBy: kaeplaUser.uid,
      createdAt: Timestamp.now() as FirestoreTimestamp,
      updatedAt: Timestamp.now() as FirestoreTimestamp,
      iconBase64: getRandomAvatar(companyName),
      projectCount: 0,
    };
    void createCustomer({ customer });
  };
  return (
    <Fab
      color="primary"
      aria-label="add customer"
      data-testid="customer-add"
      sx={{ position: 'fixed', bottom: 20, right: 20 }}
      onClick={() => {
        void save();
      }}
    >
      <AddIcon />
    </Fab>
  );
};
