import { Box, Skeleton } from '@mui/material';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
// eslint-disable-next-line import/no-extraneous-dependencies, import/no-unassigned-import
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Zoom from 'chartjs-plugin-zoom';
import { MutableRefObject, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

import { KaeplaChartDatasets } from './getChartDataForStackedBarChart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Zoom,
  annotationPlugin,
  ChartDataLabels,
);

interface SBOptions {
  chartReference: MutableRefObject<undefined>;
  chartData?: KaeplaChartDatasets;
  loading: boolean;
  chartOptions: ChartOptions;
}

export const StackedBarChart = ({
  chartReference,
  chartData,
  loading,
  chartOptions,
}: SBOptions) => {
  useEffect(() => {
    if (!loading && chartReference?.current) {
      const chart = chartReference.current as ChartJS;
      chart.resetZoom();
    }
  }, [chartReference, loading]);

  return (
    <Box height={240}>
      {loading && (
        <Skeleton
          sx={{ margin: 'auto', opacity: 0.5 }}
          variant="rectangular"
          animation="wave"
          width="100%"
          height={240}
        />
      )}
      {chartData && !loading && (
        <Bar
          ref={chartReference}
          options={chartOptions as Record<string, unknown>}
          data={{ ...chartData, datasets: chartData.datasets.filter((d) => !d.hidden) }}
        />
      )}
    </Box>
  );
};
