import { updateProject } from './updateProject.js';
import { KaeplaProject } from '../kaeplaTypes/Application/KaeplaProject.js';

interface Options {
  project: KaeplaProject;
  uid: string;
}

export const takeOverProject = async ({ project, uid }: Options) => {
  if (!uid) return;
  const update: Partial<KaeplaProject> = { ...project, createdBy: uid, ownedBy: uid };
  await updateProject({ project: update });
};
