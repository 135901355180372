import { Alert } from '@mui/material';

import { AiMetaLogFilters } from '../../../../services/kaeplaTypes/importer/AiMetaLogFilters';
import { AiQuestionMetaLog } from '../../../../services/kaeplaTypes/importer/AiQuestionMetaLog';

interface Options {
  aiMetaLogs: AiQuestionMetaLog[];
  metaLogFilters: AiMetaLogFilters;
}

export const AiMetaLogResultInfo = ({ aiMetaLogs, metaLogFilters }: Options) => {
  if (
    aiMetaLogs.length === 0 &&
    metaLogFilters.needsResolution === true &&
    metaLogFilters.downvoteCount
  ) {
    return <Alert severity="info">no new down-voted answers</Alert>;
  }

  if (aiMetaLogs.length === 0) {
    return <Alert severity="info">no answers for these filters</Alert>;
  }
};
