import ResetIcon from '@mui/icons-material/RestoreOutlined';
import LargeGridIcon from '@mui/icons-material/ViewAgendaRounded';
import MediumGridIcon from '@mui/icons-material/ViewStreamRounded';
import {
  Box,
  ButtonGroup,
  Chip,
  Divider,
  FormControlLabel,
  IconButton,
  Switch,
  Toolbar,
  Typography,
} from '@mui/material';
import { useState } from 'react';

import { AiMetaLogListener } from './AiMetaLogListener';
import { AiMetaLogResultInfo } from './AiMetaLogResultInfo';
import { AiMetaQuestion } from './AiMetaLogs/AiMetaQuestion';
import { deleteFilter } from './helpers/deleteFilter';
import { isDefaultFiltersSet } from './helpers/isDefaultFiltersSet';
import { AiMetaLogFilters } from '../../../../services/kaeplaTypes/importer/AiMetaLogFilters';
import { AiQuestionMetaLog } from '../../../../services/kaeplaTypes/importer/AiQuestionMetaLog';

export const AiMeta = () => {
  const [aiMetaLogs, setAiMetaLogs] = useState<AiQuestionMetaLog[]>([]);
  const [lastResolvedLog, setLastResolvedLog] = useState<AiQuestionMetaLog>();
  const [metaLogFilters, setMetaLogFilters] = useState<AiMetaLogFilters>({
    needsResolution: true,
    downvoteCount: 1,
  });
  const [expandAll, setExpandAll] = useState(false);
  const [dense, setDense] = useState(false);

  return (
    <Box>
      <AiMetaLogListener
        setAiMetaLogs={setAiMetaLogs}
        lastResolvedLog={lastResolvedLog}
        metaLogFilters={metaLogFilters}
      />
      <Box sx={{ m: -3, mb: 3 }}>
        <Toolbar sx={{ minHeight: 35, mx: 4 }} disableGutters variant="dense">
          {!isDefaultFiltersSet(metaLogFilters) && (
            <IconButton
              size="small"
              onClick={() => {
                setMetaLogFilters((filters) => ({
                  ...filters,
                  needsResolution: true,
                  downvoteCount: 1,
                }));
              }}
              color={dense ? 'primary' : 'default'}
            >
              <ResetIcon />
            </IconButton>
          )}
          {Object.entries(metaLogFilters).map(([filter, value]) => (
            <Chip
              sx={{ mr: 1 }}
              key={filter}
              size="small"
              variant="outlined"
              label={`${filter}: ${value}`}
              onDelete={() => {
                setMetaLogFilters(deleteFilter(metaLogFilters, filter));
              }}
            />
          ))}
          <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
          <ButtonGroup variant="text" aria-label="text button group">
            <IconButton
              size="small"
              disabled={aiMetaLogs.length === 0}
              onClick={() => {
                setDense(false);
              }}
              color={dense ? 'default' : 'primary'}
            >
              <LargeGridIcon />
            </IconButton>
            <IconButton
              size="small"
              disabled={aiMetaLogs.length === 0}
              onClick={() => {
                setDense(true);
              }}
              color={dense ? 'primary' : 'default'}
            >
              <MediumGridIcon />
            </IconButton>
          </ButtonGroup>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={expandAll}
                disabled={aiMetaLogs.length === 0}
                onChange={() => {
                  setExpandAll(!expandAll);
                }}
              />
            }
            labelPlacement="start"
            label="expand all"
          />
        </Toolbar>
        <Divider />
      </Box>
      {aiMetaLogs.map((metaLog) => (
        <Box key={metaLog.id} sx={{ mb: 1 }}>
          <AiMetaQuestion
            metaLog={metaLog}
            expandAll={expandAll}
            dense={dense}
            setMetaLogFilters={setMetaLogFilters}
            setLastResolvedLog={setLastResolvedLog}
          />
          {/* {JSON.stringify(metaLog)} */}
        </Box>
      ))}
      <AiMetaLogResultInfo aiMetaLogs={aiMetaLogs} metaLogFilters={metaLogFilters} />
    </Box>
  );
};
