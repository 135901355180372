import { WhereClause } from '@atrigam/atrigam-service-firebase-watcher';
import { OrderByDirection } from 'firebase/firestore';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { useAuth } from '../../../../AuthReactProvider';
import { addFirestoreCollectionListener } from '../../../../services/firestore/addFirestoreCollectionListener';
import { AiMetaLogFilters } from '../../../../services/kaeplaTypes/importer/AiMetaLogFilters';
import { AiQuestionMetaLog } from '../../../../services/kaeplaTypes/importer/AiQuestionMetaLog';
import { kaeplaAssignmentState } from '../../../../services/recoil/persistent/kaeplaAssignmentState';
import { requestLog } from '../../../helpers/logger';

interface Options {
  metaLogFilters: AiMetaLogFilters;
  setAiMetaLogs: React.Dispatch<React.SetStateAction<AiQuestionMetaLog[]>>;
  lastResolvedLog?: AiQuestionMetaLog;
}

export const AiMetaLogListener = ({ metaLogFilters, setAiMetaLogs, lastResolvedLog }: Options) => {
  const { kaeplaUser } = useAuth();
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);

  useEffect(() => {
    if (!kaeplaAssignment) return;
    if (!kaeplaUser?.uid) return;

    const fireStorePath = `aiQuestionMetaLogs`;
    const queryWhere: WhereClause[] = [];

    const direction: OrderByDirection = 'desc';
    let orderBy = { fieldPath: 'lastLoggedAt', direction };

    if (metaLogFilters.projectId) {
      queryWhere.push({
        fieldPath: 'projectId',
        opStr: '==',
        value: metaLogFilters.projectId,
      });
    }

    if (metaLogFilters.lastUid) {
      queryWhere.push({
        fieldPath: 'lastUid',
        opStr: '==',
        value: metaLogFilters.lastUid,
      });
    }

    if (metaLogFilters.downvoteCount) {
      queryWhere.push({
        fieldPath: 'downvoteCount',
        opStr: '>=',
        value: metaLogFilters.downvoteCount,
      });
      orderBy = { fieldPath: 'downvoteCount', direction };
    }

    if (metaLogFilters.upvoteCount) {
      queryWhere.push({
        fieldPath: 'upvoteCount',
        opStr: '>=',
        value: metaLogFilters.upvoteCount,
      });
      orderBy = { fieldPath: 'upvoteCount', direction };
    }

    if (metaLogFilters.askedCount) {
      queryWhere.push({
        fieldPath: 'askedCount',
        opStr: '>=',
        value: metaLogFilters.askedCount,
      });
      orderBy = { fieldPath: 'askedCount', direction };
    }

    if (metaLogFilters.needsResolution) {
      queryWhere.push({
        fieldPath: 'needsResolution',
        opStr: '==',
        value: true,
      });
    }

    // orderBy = { ...orderBy, fieldPath: 'lastLoggedAt' };

    const unsubscribe = addFirestoreCollectionListener({
      fireStorePath,
      queryWhere,
      orderBy,
      limit: 100,
      callback: (dataFromServer) => {
        let aiMetaLogsFromServer = dataFromServer as AiQuestionMetaLog[];
        requestLog.log(`AiMetaLogsListener`, aiMetaLogsFromServer.length);
        if (lastResolvedLog) {
          aiMetaLogsFromServer = [...aiMetaLogsFromServer].filter(
            (log) => log.id !== lastResolvedLog.id,
          );
          aiMetaLogsFromServer.push(lastResolvedLog);
        }
        setAiMetaLogs(aiMetaLogsFromServer);
      },
    });
    return () => {
      unsubscribe();
    };
  }, [kaeplaAssignment, kaeplaUser?.uid, metaLogFilters, setAiMetaLogs, lastResolvedLog]);

  return null;
};
