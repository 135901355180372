import { getApp } from 'firebase/app';
import { collection, DocumentData, getFirestore, doc, setDoc, Timestamp } from 'firebase/firestore';

import { KaeplaProject } from '../kaeplaTypes/Application/KaeplaProject.js';
import { FirestoreTimestamp } from '../kaeplaTypes/FirestoreTimeStamp.js';
import { KaeplaDataSnapshot, KaeplaOpsUpdateStatus } from '../kaeplaTypes/index.js';

interface Options {
  project: KaeplaProject;
  createdBy: string;
}

export const createNewDataSnapshot = async ({ project, createdBy }: Options) => {
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `projects/${project.id}/dataSnapshots`;
  const firestoreCollectionReference = collection(firestoreDatabase, fireStorePath);
  const documentReference = doc(firestoreCollectionReference);
  const snapshot: Partial<KaeplaDataSnapshot> = {
    id: documentReference.id,
    accessDomain: project.accessDomain,
    customerId: project.customerId,
    resellerId: project.resellerId,
    projectId: project.id,
    createdBy,
    createdAt: Timestamp.now() as FirestoreTimestamp,
    updateStatus: KaeplaOpsUpdateStatus.initial,
    archived: false,
  };
  await setDoc(documentReference, snapshot as DocumentData);
  return snapshot;
};
