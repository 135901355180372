import { colord } from 'colord';
import Palette from 'iwanthue/palette';

const darken = 0.15;
const saturate = 0.05;
export const currentDataColorShift = (color: string) => {
  return colord(color).darken(darken).saturate(saturate).toHex();
};

const lighten = 0.15;
const desaturate = 0.1;
const alpha = 0.3;
export const simulationDataColorShift = (color: string) => {
  return colord(color).darken(lighten).desaturate(desaturate).alpha(alpha).toRgbString();
};

export const defaultChartColor = '#CCC';
export const createPaletteForLabelAndDataset = (label: string, datasets: string[]) => {
  return Palette.generateFromValues(label, datasets, {
    defaultColor: defaultChartColor,
    colorSpace: 'intense', // pimp, intense, pastel, sensible, colorblind, all
    distance: 'compromise',
  });
};
