import { ListItem, ListItemAvatar, ListItemText } from '@mui/material';

import { AuthProviderActions } from './AuthProviderActions';
import { AuthProviderIcon } from './AuthProviderIcon';
import { KaeplaAuthenticationProviderProperties } from '../../../../../services/kaeplaTypes/Application/KaeplaAuthenticationProviderProperties';

export const AuthProvider = ({
  provider,
  isActiveForUser,
  setAuthMessage,
}: KaeplaAuthenticationProviderProperties) => {
  return (
    <ListItem
      data-testid={`auth-${provider.providerId}-${isActiveForUser ? 'active' : 'inactive'}`}
    >
      <ListItemAvatar>
        <AuthProviderIcon providerId={provider.providerPlatform ?? provider.providerId} />
      </ListItemAvatar>
      <ListItemText primary={provider.label} />
      <AuthProviderActions
        setAuthMessage={setAuthMessage}
        provider={provider}
        isActiveForUser={isActiveForUser}
      />
    </ListItem>
  );
};
