import { Typography } from '@mui/material';

import { KaeplaDimension } from '../../../../../services/kaeplaTypes/Application/KaeplaDimension.js';
import { formatValueWithDimension } from '../../../../helpers/formatValueWithDimension.js';

interface COptions {
  valueDimension: KaeplaDimension;
  value: number;
  isAbsolute?: boolean;
}
export const TimeSliceValue = ({ valueDimension, value, isAbsolute }: COptions) => {
  return (
    <Typography variant="caption" fontSize={14}>
      {isAbsolute === false ? `∼` : ` `}
      {formatValueWithDimension(valueDimension, value)}
    </Typography>
  );
};
