import {
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl,
  Typography,
  Box,
  InputLabel,
} from '@mui/material';
import { clone } from 'rambda';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import { useUserPreferences } from '../../../../../../UserPreferencesProvider';
import { KaeplaSimulation } from '../../../../../../services/kaeplaTypes/Application/KaeplaSimulation.js';
import { projectState } from '../../../../../../services/recoil/nonpersistent/projectState';
import { simulationFavoritesState } from '../../../../../../services/recoil/nonpersistent/simulationFavoritesState';
import { simulationState } from '../../../../../../services/recoil/nonpersistent/simulationState.js';
import { simulationsState } from '../../../../../../services/recoil/nonpersistent/simulationsState';
import { SelectAvatar } from '../../../../../features/SelectAvatar.js';
import { isSimulationOutdated } from '../../../../Simulations/SimulationEditor/_helpers/isSimulationOutdated';

interface Options {
  disabled?: boolean;
}

export const SelectSimulation = ({ disabled }: Options) => {
  const { setPreferences } = useUserPreferences();
  const simulationFavorites = useRecoilValue(simulationFavoritesState);
  const simulations = useRecoilValue(simulationsState);
  const project = useRecoilValue(projectState);
  const resetSimulation = useResetRecoilState(simulationState);
  const [simulation, setSimulation] = useRecoilState(simulationState);
  const [selectedSimulation, setSelectedSimulation] = useState<string | undefined>();

  const isFavorite = (s: KaeplaSimulation) => {
    return simulationFavorites.filter((sf) => sf.isFavorite).some((sf) => sf.simulationId === s.id);
  };

  // this effect runs if simulation is changed from outside (e.g. the simulation overview)
  useEffect(() => {
    if (simulation) {
      const simulationFromState = simulations?.find((s) => s.id === simulation.id);
      setSelectedSimulation(simulationFromState?.id ?? '');
    }
  }, [simulation, simulations]);

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedSimulation(event.target.value);
    const chosenSimulation = clone(simulations)?.find(
      (_simulation) => _simulation.id === event.target.value,
    );
    if (chosenSimulation) {
      chosenSimulation.id = event.target.value;
      setSimulation(chosenSimulation);
      setPreferences({ lastSimulationId: chosenSimulation.id });
    } else {
      resetSimulation();
      setPreferences({ lastSimulationId: undefined });
    }
  };

  if (!simulations || simulations?.length === 0) {
    return (
      <Typography sx={{ fontSize: '100%' }} color="text.secondary">
        no simulations yet
      </Typography>
    );
  }

  return (
    <FormControl
      fullWidth
      sx={{ mt: 0, p: 0, minWidth: 160 }}
      size="small"
      margin="dense"
      variant="standard"
    >
      <InputLabel size="small" id="simulation-label">
        Simulation
      </InputLabel>
      <Select
        id="simulationSelect"
        value={selectedSimulation ?? ''}
        label="Select a Simulation"
        onChange={handleChange}
        size="small"
        SelectDisplayProps={{ style: { marginTop: 0, padding: 0, paddingRight: 16 } }}
        disableUnderline
        autoFocus={false}
        disabled={disabled}
        inputProps={{ name: 'simulationSelectInput' }}
      >
        <MenuItem dense value="">
          <em>No Simulation</em>
        </MenuItem>
        {simulations
          ?.filter((s) => isFavorite(s))
          .map((s) => (
            <MenuItem
              dense
              key={s.id}
              value={s.id}
              disabled={!s.rulesets || s.rulesets.length === 0}
              sx={{ whiteSpace: 'nowrap' }}
            >
              <Box component="span" mr={1}>
                <SelectAvatar uid={s.createdBy} />
              </Box>
              {s.name}
              {(!s.rulesets || s.rulesets.length === 0) && ' - needs params'}
              {isSimulationOutdated(project, s) && ' - outdated'}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};
