import EditOffIcon from '@mui/icons-material/EditOffOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';
import { Box, Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { PerspectiveMainParameters } from './PerspectiveMainParameters.js';
import { DataTimeline } from './features/DataTimeline/DataTimeline.js';
import { PerspectiveBlocks } from './features/PerspectiveBlocks/PerspectiveBlocks.js';
import { ProjectSummary } from './features/ProjectSummary/ProjectSummary.js';
import { useAuth } from '../../../AuthReactProvider.js';
import { addPerspectiveBlock } from '../../../services/firestore/addPerspectiveBlock.js';
import { updatePerspective } from '../../../services/firestore/updatePerspective.js';
import { KaeplaPerspective } from '../../../services/kaeplaTypes/Application/KaeplaPerspective.js';
import { KaeplaPerspectiveBlock } from '../../../services/kaeplaTypes/Application/KaeplaPerspectiveBlock.js';
import { KaeplaPerspectiveBlockType } from '../../../services/kaeplaTypes/Application/KaeplaPerspectiveBlockType.js';
import { applicationState } from '../../../services/recoil/nonpersistent/applicationState.js';
import { perspectiveState } from '../../../services/recoil/nonpersistent/perspectiveState.js';
import { projectState } from '../../../services/recoil/nonpersistent/projectState.js';
import { AiFab } from '../../AI/AiFab.js';
import { Layout } from '../../Layout/Layout.js';
import { Filters } from '../../features/Filters/Filters.js';
import { InlineEdit } from '../../features/InlineEdit.js';

interface POptions {
  perspective: Partial<KaeplaPerspective>;
}

export const CustomPerspective = ({ perspective }: POptions) => {
  const { kaeplaUser } = useAuth();
  // get
  const application = useRecoilValue(applicationState);
  const project = useRecoilValue(projectState);
  // set
  const setPerspective = useSetRecoilState(perspectiveState);

  const addBlock = async () => {
    if (!project.defaultPerspective) return;
    const block: Partial<KaeplaPerspectiveBlock> = {
      type: 'AggregationOverTime' as KaeplaPerspectiveBlockType,
      aggregation: 'SUM',
      valueDimension: project.defaultPerspective?.valueDimension,
      timeDimension: project.defaultPerspective?.timeDimension,
    };
    const newPerspective = await addPerspectiveBlock({ project, perspective, block });
    setPerspective(newPerspective);
  };

  return (
    <Layout hasScopeNavigation showCustomerSelector>
      <Grid container spacing={3}>
        <Grid item xs={12} md={5} lg={4}>
          <ProjectSummary />
        </Grid>
        <Grid item xs={12} md={7} lg={8}>
          <DataTimeline />
        </Grid>
        <Grid sx={{ display: application.showFilter ? 'block' : 'none' }} item xs={12}>
          <Filters />
        </Grid>
        <Grid item xs={9}>
          <Typography component="div" variant="h5">
            <InlineEdit
              value={perspective.name ?? 'n/a'}
              callback={(newValue) => {
                const newPerspective = { ...perspective };
                newPerspective.name = newValue;
                void updatePerspective({ project, perspective: newPerspective });
                setPerspective(newPerspective);
              }}
              readOnly={perspective?.createdBy !== kaeplaUser?.uid}
            />
          </Typography>
          <Typography component="div" variant="body2">
            <InlineEdit
              value={perspective.description ?? ''}
              callback={(newValue) => {
                const newPerspective = { ...perspective };
                newPerspective.description = newValue;
                void updatePerspective({ project, perspective: newPerspective });
                setPerspective(newPerspective);
              }}
              fullWidth
              readOnly={perspective?.createdBy !== kaeplaUser?.uid}
            />
          </Typography>
        </Grid>
        {perspective?.createdBy === kaeplaUser?.uid && (
          <Grid item container xs={3} justifyContent="flex-end">
            <Box sx={{ ml: 2 }}>
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  const newPerspective = { ...perspective };
                  newPerspective.locked = !perspective.locked;
                  void updatePerspective({ project, perspective: newPerspective });
                  setPerspective(newPerspective);
                }}
                startIcon={perspective.locked ? <EditIcon /> : <EditOffIcon />}
              >
                {perspective.locked ? 'Edit' : 'Done Editing'}
              </Button>
            </Box>
          </Grid>
        )}
        {perspective?.createdBy === kaeplaUser?.uid && (
          <PerspectiveMainParameters
            perspective={perspective}
            setPerspective={setPerspective}
            addBlock={addBlock}
          />
        )}
        {perspective.blocks && (
          <PerspectiveBlocks blocks={perspective.blocks} locked={perspective?.locked ?? false} />
        )}
        <AiFab />
      </Grid>
    </Layout>
  );
};
