import { getApp } from 'firebase/app';
import { Timestamp, doc, getDoc, getFirestore } from 'firebase/firestore';

import { KaeplaFilter } from '../kaeplaTypes/Application/KaeplaFilter.js';

interface Options {
  projectId: string;
  filterId: string;
}
export const getFilter = async ({ projectId, filterId }: Options) => {
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `projects/${projectId}/filters/${filterId}`;
  const firestoreDocumentReference = doc(firestoreDatabase, fireStorePath);
  const snapshot = await getDoc(firestoreDocumentReference);
  return snapshot.data() as KaeplaFilter<Timestamp>;
};
