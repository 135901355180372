import { KeyOutlined } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Paper,
  Typography,
} from '@mui/material';
import { EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth';
import { useState } from 'react';
import PasswordChecklist from 'react-password-checklist';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../../../AuthReactProvider.js';
import { kaeplaAuth } from '../../../../firebaseInit.js';
import { requestPasswordReset } from '../../../../services/api/requestPasswordReset.js';

interface Options {
  token: string;
}

export const ResetPassword = ({ token }: Options) => {
  const { kaeplaUser } = useAuth();
  const navigate = useNavigate();
  const [passwordResetRequested, setPasswordResetRequested] = useState<boolean>(false);
  const [password, setPassword] = useState('');
  const [passwordAgain, setPasswordAgain] = useState('');
  const [passwordValid, setPasswordValid] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const doResetPassword = async () => {
    if (!kaeplaUser || !kaeplaAuth.currentUser) return;

    const { success: apiSuccess, error: apiError } = await requestPasswordReset({
      password,
      token,
    });

    setPasswordResetRequested(false);
    if (apiError) {
      setError(apiError);
      setSuccess(false);
      return;
    }

    if (apiSuccess) {
      const credential = EmailAuthProvider.credential(kaeplaUser?.email, password);
      await reauthenticateWithCredential(kaeplaAuth.currentUser, credential);
      setError('');
      setSuccess(true);
    }
  };

  return (
    <Paper
      data-testid="pw-reset-card"
      sx={{ p: 2, maxWidth: 500, position: 'relative' }}
      elevation={0}
    >
      <Typography variant="body1" sx={{ mb: 2 }} data-testid="pw-reset-message">
        Please choose a password.
      </Typography>
      {passwordResetRequested && (
        <Alert data-testid="pw-reset-info" severity="info" sx={{ mb: 2 }}>
          <CircularProgress size={10} sx={{ mr: 1 }} />
          <Typography variant="body2" component="span">
            Hold on, we are setting your password.
          </Typography>
        </Alert>
      )}
      {error !== '' && (
        <Alert
          data-testid="pw-reset-info"
          severity="error"
          sx={{ mb: 2 }}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              data-testid="pw-reset-finish"
              onClick={() => {
                navigate('/User');
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <Typography variant="body2" component="span">
            {error}
          </Typography>
        </Alert>
      )}
      {success && (
        <Alert
          data-testid="pw-reset-info"
          severity="info"
          sx={{ mb: 2 }}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              data-testid="pw-reset-finish"
              onClick={() => {
                navigate('/User');
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <Typography variant="body2" component="span">
            You can use your new password now.
          </Typography>
        </Alert>
      )}
      {!success && error === '' && (
        <>
          <FormControl variant="standard">
            <InputLabel htmlFor="input-with-icon-adornment">Password</InputLabel>
            <Input
              sx={{ fontSize: '110%' }}
              type="password"
              onChange={(event) => setPassword(event.target.value)}
              disabled={passwordResetRequested}
              inputProps={{ 'data-testid': 'pw-reset-newPassword' }}
              startAdornment={
                <InputAdornment position="start">
                  <KeyOutlined />
                </InputAdornment>
              }
            />
          </FormControl>
          <Box sx={{ mb: 2 }} />
          <FormControl variant="standard">
            <InputLabel htmlFor="input-with-icon-adornment">Repeat Password</InputLabel>
            <Input
              sx={{ fontSize: '110%' }}
              type="password"
              onChange={(event) => setPasswordAgain(event.target.value)}
              disabled={passwordResetRequested}
              inputProps={{ 'data-testid': 'pw-reset-passwordConfirm' }}
              startAdornment={
                <InputAdornment position="start">
                  <KeyOutlined />
                </InputAdornment>
              }
            />
          </FormControl>
          <Box sx={{ mb: 2 }} />
          {password !== '' && !passwordResetRequested && (
            <Box data-testid="pw-reset-checklist">
              <PasswordChecklist
                rules={['minLength', 'specialChar', 'number', 'capital', 'match']}
                minLength={5}
                value={password}
                valueAgain={passwordAgain}
                onChange={(isValid) => {
                  setPasswordValid(isValid);
                }}
              />
            </Box>
          )}
          <Box sx={{ mb: 2 }} />
          <Button
            variant="contained"
            data-testid="pw-reset-reset-button"
            disabled={!passwordValid || passwordResetRequested}
            onClick={() => {
              setPasswordResetRequested(true);
              void doResetPassword();
            }}
          >
            set password
          </Button>
        </>
      )}
    </Paper>
  );
};
