/* eslint-disable @typescript-eslint/no-explicit-any */

import { ColDef, ColGroupDef } from 'ag-grid-community';

import { KaeplaDimension, KaeplaPerspective } from '../../../../../../services/kaeplaTypes';
import { cleanColumnName } from '../../../../../helpers/cleanColumnName';
import { CompareValuesCellRenderer } from '../cellRenderers/CompareValuesCellRenderer';
import { FallbackRenderer } from '../cellRenderers/FallBackRenderer';

const dataTypeMap: Record<string, string> = {
  STRING: 'text',
  BIGNUMERIC: 'number',
  FLOAT64: 'number',
  INT64: 'number',
};

export const getColumnDefinitions = (
  dimensions: KaeplaDimension[],
  perspective: Partial<KaeplaPerspective> | undefined,
  compareColor?: string,
) => {
  const perspectiveDimension = perspective?.valueDimension;

  const sortedDimensions = [...dimensions]
    .filter((d) => d.visible)
    .sort((a, b) => {
      if (a.columnName === perspectiveDimension && b.columnName !== perspectiveDimension) {
        return -1;
      } else if (a.columnName !== perspectiveDimension && b.columnName === perspectiveDimension) {
        return 1;
        // } else if (a.enableSlicing && !b.enableSlicing) {
        //   return -1;
        // } else if (!a.enableSlicing && b.enableSlicing) {
        //   return 1;
        // } else if (a.enableAggregation && !b.enableAggregation) {
        //   return -1;
        // } else if (!a.enableAggregation && b.enableAggregation) {
        //   return 1;
      } else {
        return a.columnName.localeCompare(b.columnName);
      }
    });

  const columnDefs: (ColDef<any, any> | ColGroupDef<any>)[] = [];
  sortedDimensions.forEach((d) => {
    const base: ColDef<any, any> = {
      field: d.columnName,
      headerName: cleanColumnName(d.columnName),
      cellDataType: dataTypeMap[d.dataType],
      enableRowGroup: d.enableSlicing,
      enableValue: d.enableAggregation,
    };
    let definition: ColDef<any, any> = {};
    if (d.dataType === 'STRING') {
      definition = {
        filter: 'agTextColumnFilter',
      } as ColDef<any, any>;
    } else if (d.columnName.includes('__Simulated') || d.columnName.includes('__Precalculated')) {
      definition = {
        hide: true,
        suppressColumnsToolPanel: true,
      } as ColDef<any, any>;
    } else if (d.columnName === 'Effect_Year') {
      definition = {} as ColDef<any, any>;
    } else if (d.dataType === 'BIGNUMERIC' || d.dataType === 'FLOAT64' || d.dataType === 'INT64') {
      definition = {
        aggFunc: d.enableAggregation && 'sum',
        type: 'rightAligned',
        cellRenderer: CompareValuesCellRenderer,
        cellRendererParams: {
          color: compareColor ?? 'black',
          isCurrency: d.isCurrency,
        },
      } as ColDef<any, any>;
    } else {
      definition = {
        filter: 'agTextColumnFilter',
        cellRenderer: FallbackRenderer,
      } as ColDef<any, any>;
    }
    columnDefs.push({ ...base, ...definition });
  });

  if (!columnDefs) return [];
  return columnDefs;
};
