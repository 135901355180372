import {
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl,
  Typography,
  Box,
  InputLabel,
  Stack,
} from '@mui/material';
import { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import { useUserPreferences } from '../../../../../../UserPreferencesProvider';
import { KaeplaPerspective } from '../../../../../../services/kaeplaTypes/Application/KaeplaPerspective';
import { perspectiveFavoritesState } from '../../../../../../services/recoil/nonpersistent/perspectiveFavoritesState';
import { perspectiveState } from '../../../../../../services/recoil/nonpersistent/perspectiveState.js';
import { perspectivesState } from '../../../../../../services/recoil/nonpersistent/perspectivesState';
import { projectState } from '../../../../../../services/recoil/nonpersistent/projectState.js';
import { SelectAvatar } from '../../../../../features/SelectAvatar.js';
import { perspectiveName } from '../../../helpers/perspectiveName';

export const SelectPerspective = () => {
  const { setPreferences } = useUserPreferences();
  const project = useRecoilValue(projectState);
  const perspectiveFavorites = useRecoilValue(perspectiveFavoritesState);
  const resetPerspective = useResetRecoilState(perspectiveState);
  const [perspectives, setPerspectives] = useRecoilState(perspectivesState);
  const [perspective, setPerspective] = useRecoilState(perspectiveState);

  // this effect runs if perspective is changed from outside (e.g. the simulation overview)
  useEffect(() => {
    let _perspective: KaeplaPerspective | undefined;
    if (project.defaultPerspective) {
      _perspective = project.defaultPerspective as unknown as KaeplaPerspective;
    }
    if (perspective) {
      _perspective = perspective as unknown as KaeplaPerspective;
    }
    if (_perspective) {
      const changedPerspective = _perspective;

      setPerspectives((_perspectives) => [
        ..._perspectives.filter((p) => p.id !== _perspective?.id),
        changedPerspective,
      ]);
    }
  }, [perspective, project.defaultPerspective, setPerspectives]);

  const isFavorite = (s: KaeplaPerspective) => {
    return (
      perspectiveFavorites.filter((sf) => sf.isFavorite).some((sf) => sf.perspectiveId === s.id) ||
      s?.id === 'default'
    );
  };

  const handleChange = (event: SelectChangeEvent) => {
    const chosenPerspective = perspectives.find(
      (_perspective) => _perspective.id === event.target.value,
    );
    if (chosenPerspective) {
      setPerspective(chosenPerspective);
      setPreferences({ lastPerspectiveId: chosenPerspective.id });
    } else {
      resetPerspective();
    }
  };

  if (!perspectives || perspectives?.length === 0) {
    return (
      <Typography sx={{ fontSize: '100%' }} color="text.secondary">
        no perspectives yet
      </Typography>
    );
  }

  return (
    <FormControl
      fullWidth
      sx={{ mt: 0, p: 0, minWidth: 160 }}
      size="small"
      margin="dense"
      variant="standard"
    >
      <InputLabel size="small" id="perspective-label">
        Dashboard
      </InputLabel>
      {perspectives && (
        <Select
          id="perspectiveSelect"
          value={perspective?.id ?? 'default'}
          label="Select a Dashboard"
          onChange={handleChange}
          size="small"
          SelectDisplayProps={{ style: { marginTop: 0, padding: 0, paddingRight: 16 } }}
          disableUnderline
          autoFocus={false}
          inputProps={{ name: 'perspectiveSelectInput' }}
        >
          {perspectives
            ?.filter((s) => isFavorite(s))
            .map((_perspective) => (
              <MenuItem
                dense
                key={_perspective.id}
                value={_perspective.id}
                sx={{ whiteSpace: 'nowrap' }}
              >
                <Stack direction="row" spacing={2}>
                  <Box component="span" mr={1} py={0.5}>
                    {_perspective.id !== 'default' && <SelectAvatar uid={_perspective.createdBy} />}
                    {_perspective.id === 'default' && (
                      <Box
                        component="span"
                        sx={{ display: 'inline-block' }}
                        border={1}
                        borderRadius="50%"
                        borderColor="gray"
                        bgcolor="lightgray"
                        width={25}
                        height={25}
                      />
                    )}
                  </Box>
                  <Box component="span" pt={0.8}>
                    {perspectiveName(_perspective)}
                  </Box>
                </Stack>
              </MenuItem>
            ))}
        </Select>
      )}
    </FormControl>
  );
};
