import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  LinearProgress,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import colors from 'nice-color-palettes';
import { clone } from 'rambda';
import { SetterOrUpdater, useRecoilState, useSetRecoilState } from 'recoil';

import { DimensionPie } from './DimensionPie.js';
import { DimensionSummaryTable } from './DimensionSummaryTable.js';
import { ItmCountInfo } from './ItemCountInfo.js';
import { updateProject } from '../../../../services/firestore/updateProject.js';
import { KaeplaDimensionDetails } from '../../../../services/kaeplaTypes/Application/KaeplaDimensionDetails.js';
import { KaeplaModel } from '../../../../services/kaeplaTypes/Application/KaeplaModel.js';
import { KaeplaProject } from '../../../../services/kaeplaTypes/Application/KaeplaProject.js';
import { MatrixSummary } from '../../../../services/kaeplaTypes/MatrixSummary.js';
import { projectState } from '../../../../services/recoil/nonpersistent/projectState.js';
import { currentScopePathState } from '../../../../services/recoil/persistent/currentScopePathState.js';
import { addDimensionToPath } from '../../../ScopeNavigation/helpers/addDimensionToPath.js';
import { addScopeToModel } from '../../../ScopeNavigation/helpers/addScopeToModel.js';
import { getCurrentLevel } from '../../../ScopeNavigation/helpers/getCurrentLevel.js';
import { isDimensionDelegable } from '../../../ScopeNavigation/helpers/isDimensionDelegable.js';
import { removeDimensionAtPath } from '../../../ScopeNavigation/helpers/removeDimensionAtPath.js';
import { cleanColumnName } from '../../../helpers/cleanColumnName.js';

interface DOptions {
  model: KaeplaModel;
  setModel: SetterOrUpdater<KaeplaModel>;
  matrix: MatrixSummary;
  scopePath: string[];
  detail: KaeplaDimensionDetails;
  loading: boolean;
  scopePaths: Record<string, string[] | undefined>;
  setScopePaths: SetterOrUpdater<Record<string, string[] | undefined>>;
}
export const ScopeCard = ({
  model,
  setModel,
  matrix,
  scopePath,
  detail,
  loading,
  scopePaths,
  setScopePaths,
}: DOptions) => {
  const [project, setProject] = useRecoilState(projectState);
  const setCurrentScopePath = useSetRecoilState(currentScopePathState);
  const chartColors = [...colors[0], ...colors[1], ...colors[2], ...colors[3]];

  const nextLevelScopes = Object.keys(model.delegation);

  if (!matrix.dimensions || !project || !scopePath) return null;
  return (
    <Grid item xs={12} md={6} lg={4} key={detail.columnName} sx={{ position: 'relative' }}>
      <Card sx={{ height: '100%' }}>
        {loading ? <LinearProgress /> : <Box sx={{ height: 4 }} />}
        <CardHeader
          title={
            <Typography variant="h6" sx={{ minHeight: '3em' }}>
              {cleanColumnName(detail.columnName)}{' '}
              {detail.dimensionDetails && ItmCountInfo(detail.dimensionDetails.length)}{' '}
            </Typography>
          }
        />
        <CardContent sx={{ py: 0 }}>
          {/* {isDimensionDelegable({
              scopePath,
              detail,
              dimensions: matrix.dimensions,
            }) && "- is delegable"}
            {hasDimensionPie(detail, matrix.totalCount?.totalCount) &&
              "- has pie"} */}
          {isDimensionDelegable(
            {
              scopePath,
              detail,
              dimensions: matrix.dimensions,
            } || getCurrentLevel(scopePath) === detail.columnName,
          ) && (
            <CardActions sx={{ p: 0 }}>
              {getCurrentLevel(scopePath) !== detail.columnName && (
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    const { columnName } = detail;
                    const newModel: KaeplaModel = addScopeToModel(model, scopePath, columnName);
                    setModel(newModel);
                    if (project) {
                      const newProject = { id: project.id } as KaeplaProject;
                      newProject.model = newModel;
                      setProject({ ...project, ...newProject });
                      void updateProject({ project: newProject });

                      const newScopePath = addDimensionToPath({
                        matrix,
                        index: scopePath.length,
                        newDimension: columnName,
                        scopePath,
                      });
                      setCurrentScopePath(newScopePath);

                      const newScopePaths = clone(scopePaths);
                      newScopePaths[project.id] = newScopePath;
                      setScopePaths(newScopePaths);
                    }
                  }}
                  disabled={nextLevelScopes.includes(detail.columnName)}
                >
                  Create Scope
                </Button>
              )}
              {getCurrentLevel(scopePath) === detail.columnName && (
                <Button
                  size="small"
                  color="error"
                  onClick={() => {
                    const newModel: KaeplaModel = removeDimensionAtPath(
                      model,
                      scopePath,
                      detail.columnName,
                    );
                    setModel(newModel);
                    if (project) {
                      const newProject = { id: project.id } as KaeplaProject;
                      newProject.model = newModel;
                      setProject({ ...project, ...newProject });
                      void updateProject({ project: newProject });
                    }

                    const newScopePath = [...scopePath];
                    newScopePath.pop();
                    const newScopePaths = clone(scopePaths);
                    newScopePaths[project.id] = newScopePath;
                    setScopePaths(newScopePaths);
                    setCurrentScopePath(newScopePath);
                  }}
                >
                  Remove Scope
                </Button>
              )}
              <Box sx={{ height: 32, width: 10 }} />
            </CardActions>
          )}
        </CardContent>
        <CardContent>
          <DimensionPie
            detail={detail}
            totalCount={matrix.totalCount?.totalCount}
            chartColors={chartColors}
          />
          <DimensionSummaryTable detail={detail} chartColors={chartColors} />
        </CardContent>
      </Card>
    </Grid>
  );
};
