import { getApp } from 'firebase/app';
import { collection, DocumentData, getFirestore, doc, setDoc, Timestamp } from 'firebase/firestore';
import { DateTime } from 'luxon';

import { KaeplaFilter } from '../kaeplaTypes/Application/KaeplaFilter.js';
import { KaeplaProject } from '../kaeplaTypes/Application/KaeplaProject.js';

interface Options {
  project: KaeplaProject;
  createdBy: string;
  scopePath: string[] | undefined;
  filterTreeStringified: string;
  filterSql?: string;
  isCopy?: boolean;
  filterName?: string;
}

export const saveFilterAsPreset = async ({
  project,
  scopePath,
  createdBy,
  filterTreeStringified,
  filterSql,
  isCopy,
  filterName,
}: Options) => {
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `projects/${project.id}/filters`;
  const firestoreCollectionReference = collection(firestoreDatabase, fireStorePath);
  const documentReference = doc(firestoreCollectionReference);
  const date = DateTime.now();
  const humanReadable = date.toLocaleString(DateTime.DATETIME_MED);
  const filterPreset: Partial<KaeplaFilter<Timestamp>> = {
    id: documentReference.id,
    name: `${filterName ?? 'New filter preset'} ${isCopy ? '' : humanReadable}${
      isCopy ? '(copy)' : ''
    }`,
    createdBy,
    scopePath,
    scopePathStringified: JSON.stringify(scopePath),
    description: `${filterName ?? 'New filter preset'}${
      isCopy ? ' copy' : ''
    } saved on ${humanReadable}`,
    filterTreeStringified,
    filterSql,
  };
  await setDoc(documentReference, filterPreset as DocumentData);
  return filterPreset as KaeplaFilter<Timestamp>;
};
