import { Box, Grid } from '@mui/material';
import { useRecoilValue } from 'recoil';

import { dataViewState } from '../../../../services/recoil/nonpersistent/dataViewState';
import { projectState } from '../../../../services/recoil/nonpersistent/projectState';
import { KaeplaDataView } from '../../../../typings/KaeplaDataView';
import { ValidationGrid } from '../ValidationGrid/ValidationGrid';

interface Options {
  gapOnly: boolean;
  setGapOnly: React.Dispatch<React.SetStateAction<boolean>>;
}
export const Explore = ({ gapOnly, setGapOnly }: Options) => {
  const project = useRecoilValue(projectState);
  const dataView = useRecoilValue(dataViewState);

  if (project.importConfigurationId === undefined) {
    return null;
  }

  if (dataView === KaeplaDataView.Grid) {
    return (
      <Box sx={{ display: 'flex' }}>
        <Box
          sx={{
            flexGrow: 1,
            height: '100vh',
          }}
        >
          <ValidationGrid gapOnly={gapOnly} setGapOnly={setGapOnly} />
        </Box>
      </Box>
    );
  }

  return (
    <Grid item xs={12}>
      <ValidationGrid gapOnly={gapOnly} setGapOnly={setGapOnly} />
    </Grid>
  );
};
