import { Alert } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';

import { renameProject } from '../../../../services/firestore/renameProject.js';
import { KaeplaProject } from '../../../../services/kaeplaTypes/Application/KaeplaProject.js';

interface Options {
  project: KaeplaProject;
  open: boolean;
  handleClose: () => void;
  handleMenuClose: () => void;
}

export const ProjectRenameDialog = ({ project, open, handleClose, handleMenuClose }: Options) => {
  const [newName, setNewName] = useState(project.name);
  const [renaming, setRenaming] = useState(false);
  const [updatedAt] = useState(project.updatedAt);

  const renamingOnServer = () => {
    setRenaming(true);
    const newProject = { ...project };
    newProject.name = newName;
    void renameProject({ projectId: project.id, name: newName });
  };

  useEffect(() => {
    if (updatedAt !== project.updatedAt) {
      setRenaming(false);
      handleClose();
      handleMenuClose();
    }
  }, [handleClose, handleMenuClose, project.updatedAt, updatedAt]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle data-testid="dialog-title">Rename the project</DialogTitle>
      <DialogContent>
        <DialogContentText data-testid="rename-info">
          Renaming might take a few seconds. When the project is being worked on, renaming will be
          disabled.
        </DialogContentText>
        {renaming && (
          <Alert data-testid="rename-progress-message" variant="outlined" severity="info">
            Renaming Project in progress...
          </Alert>
        )}
        <TextField
          autoFocus
          margin="dense"
          fullWidth
          variant="standard"
          value={newName}
          inputProps={{ 'data-testid': 'project-name-input' }}
          onChange={(event) => {
            setNewName(event.target.value);
            event.preventDefault();
          }}
          onKeyUp={(event) => {
            if (!newName) return;
            if (event.key === 'Enter') {
              void renamingOnServer();
            }
          }}
          disabled={renaming}
        />
      </DialogContent>
      <DialogActions>
        <Button data-testid="button-cancel-rename" onClick={handleClose} disabled={renaming}>
          Cancel
        </Button>
        <Button
          disabled={renaming}
          data-testid="button-rename"
          variant="contained"
          color="error"
          onClick={() => {
            if (!newName) return;
            void renamingOnServer();
          }}
        >
          Rename
        </Button>
      </DialogActions>
    </Dialog>
  );
};
