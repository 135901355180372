import { getScopeGraph } from './getScopeGraph.js';
import { KaeplaModel } from '../../../../services/kaeplaTypes/Application/KaeplaModel.js';

interface Options {
  model: KaeplaModel;
  scopePath: string[];
}
export const graphFromScopeModel = ({ model, scopePath }: Options) => {
  if (!model.delegation) return null;

  return getScopeGraph({ delegation: model.delegation, scopePath });
};
