import { getApp } from 'firebase/app';
import { DocumentData, getFirestore, doc, setDoc } from 'firebase/firestore';

import { KaeplaPerspective } from '../kaeplaTypes/Application/KaeplaPerspective.js';
import { KaeplaProject } from '../kaeplaTypes/Application/KaeplaProject.js';

interface Options {
  project: KaeplaProject;
  perspective: Partial<KaeplaPerspective>;
}

export const updatePerspective = async ({ project, perspective }: Options) => {
  if (!project.id || !perspective.id) return;
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `projects/${project.id}/perspectives/${perspective.id}`;
  const documentReference = doc(firestoreDatabase, fireStorePath);
  await setDoc(documentReference, perspective as DocumentData);
  return perspective;
};
