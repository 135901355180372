import OpenIcon from '@mui/icons-material/OpenInNewOutlined.js';
import { Box, IconButton, Stack, Tooltip } from '@mui/material';

import { isImportDead } from './ImportLog/isImportDead.js';
import { KaeplaImport } from '../../../services/kaeplaTypes/Application/KaeplaImport.js';
import { KaeplaOpsUpdateStatus } from '../../../services/kaeplaTypes/importer/KaeplaImportUpdateStatus.js';

interface Options {
  dataImportUpdate: KaeplaImport;
}
export const ImportCardTitle = ({ dataImportUpdate }: Options) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Box>
        {`${dataImportUpdate?.number ? `#${dataImportUpdate?.number}` : '...'}`}
        <Box
          component="span"
          sx={{
            ml: 1,
            mr: 1,
            color: isImportDead(dataImportUpdate) ? 'red' : 'inherit',
          }}
        >
          {dataImportUpdate.updateStatus ===
          (KaeplaOpsUpdateStatus.finished || KaeplaOpsUpdateStatus.failed)
            ? dataImportUpdate.updateStatus
            : dataImportUpdate.phase}
        </Box>
        <Box sx={{ fontStyle: 'italic' }} component="span">
          {dataImportUpdate.updateType}
        </Box>
      </Box>
      {dataImportUpdate.sourceUrl && (
        <Tooltip title={`Importing from: ${dataImportUpdate.sourceUrl}`}>
          <IconButton
            aria-label={dataImportUpdate.sourceUrl}
            color="primary"
            size="small"
            edge="end"
            onClick={() => {
              if (!dataImportUpdate.sourceUrl) return;
              // https://www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/
              const newWnd = window.open(dataImportUpdate.sourceUrl, '_blank');
              if (newWnd?.opener) newWnd.opener = null;
            }}
          >
            <OpenIcon sx={{ fontSize: 16, my: -1 }} fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
};
