import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import numbro from 'numbro';
import { useRecoilValue } from 'recoil';

import { matrixFilteredState } from '../../../../../../services/recoil/nonpersistent/matrixFilteredState.js';
import { projectState } from '../../../../../../services/recoil/nonpersistent/projectState.js';

export const ProjectRecordsCount = () => {
  const project = useRecoilValue(projectState);
  const matrixFiltered = useRecoilValue(matrixFilteredState);
  if (!project.totalRecordsCount || !matrixFiltered?.totalCount?.totalCount) {
    return null;
  }

  if (project.totalRecordsCount === matrixFiltered?.totalCount?.totalCount) {
    return (
      <Box>
        <Typography sx={{ fontSize: 14 }} color="text.secondary">
          {numbro(project.totalRecordsCount).format({
            average: true,
            mantissa: 1,
          })}{' '}
          Records
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Typography sx={{ fontSize: 14 }} color="text.secondary">
        {numbro(matrixFiltered.totalCount.totalCount).format({
          average: true,
          mantissa: 1,
        })}{' '}
        /{' '}
        {numbro(project.totalRecordsCount).format({
          average: true,
          mantissa: 1,
        })}{' '}
        Records
      </Typography>
    </Box>
  );
};
