export const updateIntervalMap = {
  off: { value: 0, label: 'off' },
  one: { value: 1, label: 'every hour' },
  three: { value: 3, label: 'every 3 hours' },
  six: { value: 6, label: 'every 6 hours' },
  twelve: { value: 12, label: 'every 12 hours' },
  twentyFour: { value: 24, label: 'every 24 hours' },
  fortyEight: { value: 48, label: 'every 2 days' },
  oneHundredAndTwenty: { value: 120, label: 'every 5 days' },
  twoHundredAndForty: { value: 240, label: 'every 10 days' },
  threeHundredAndThirtySix: { value: 336, label: 'every 14 days' },
};
