export const truncateMiddle = (fullString: string, stringLength: number) => {
  if (fullString.length <= stringLength) return fullString;
  const separator = '...';
  const separatorLength = separator.length;
  const charsToShow = stringLength - separatorLength;
  const frontChars = Math.ceil(charsToShow / 2);
  const backChars = Math.floor(charsToShow / 2);

  return (
    fullString.slice(0, Math.max(0, frontChars)) +
    separator +
    fullString.slice(fullString.length - backChars)
  );
};
