import DeleteIcon from '@mui/icons-material/Delete';
import DraghandleIcon from '@mui/icons-material/DragHandle';
import {
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Switch,
  Toolbar,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Resizable } from 're-resizable';
import {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useRecoilValue } from 'recoil';

import { KaeplaSlider } from './KaeplaSlider.js';
import { ParameterYear } from './ParameterYear.js';
import { getAliasFor } from './_helpers/getAliasFor.js';
import { granularityLevel } from './_helpers/granularityLevel.js';
import { levelGranularity } from './_helpers/levelGranularity.js';
import { KaeplaSimulation } from '../../../../services/kaeplaTypes/Application/KaeplaSimulation.js';
import { KaeplaSimulationParameterGranularity } from '../../../../services/kaeplaTypes/Application/KaeplaSimulationParameterGranularity';
import { KaeplaSimulationParameterNumeric } from '../../../../services/kaeplaTypes/Application/KaeplaSimulationParameterNumeric';
import { KaeplaSimulationParameterText } from '../../../../services/kaeplaTypes/Application/KaeplaSimulationParameterText';
import { KaeplaSimulationRulesetWithParameters } from '../../../../services/kaeplaTypes/Application/KaeplaSimulationRulesetWithParameters.js';
import { matrixFilteredState } from '../../../../services/recoil/nonpersistent/matrixFilteredState.js';
import { projectState } from '../../../../services/recoil/nonpersistent/projectState.js';
import { cleanColumnName } from '../../../helpers/cleanColumnName.js';
import { mapCurrency } from '../../../helpers/mapCurrency.js';

interface Options {
  parameter: KaeplaSimulationParameterNumeric | KaeplaSimulationParameterText;
  simulation: KaeplaSimulation;
  ruleset: KaeplaSimulationRulesetWithParameters;
  equalizerParentReference: RefObject<HTMLInputElement>;
  simulationRulesets: KaeplaSimulationRulesetWithParameters[];
  setSimulationRulesets: Dispatch<
    SetStateAction<KaeplaSimulationRulesetWithParameters[] | undefined>
  >;
  setPreview: Dispatch<SetStateAction<boolean>>;
  own: boolean;
}

export const SimulationParameterNumeric = ({
  parameter,
  simulation,
  ruleset,
  equalizerParentReference,
  simulationRulesets,
  setSimulationRulesets,
  setPreview,
  own,
}: Options) => {
  const theme = useTheme();
  const project = useRecoilValue(projectState);
  const matrixFiltered = useRecoilValue(matrixFilteredState);
  const [years, setYears] = useState<number[]>([]);

  const dimension = useMemo(
    () => matrixFiltered?.dimensions?.dimensions.find((d) => d.columnName === parameter.dimension),
    [matrixFiltered?.dimensions?.dimensions, parameter.dimension],
  );

  const equalizerReference = useRef<HTMLInputElement>(null);

  const defaultEqualizerWidth = 890;
  const defaultEqualizerHeight = 320;
  const [equalizerWidth, setEqualizerWidth] = useState(defaultEqualizerWidth);
  const [equalizerHeight, setEqualizerHeight] = useState(defaultEqualizerHeight);
  const [allowNegativePercentage, setAllowNegativePercentage] = useState(false);
  const [granularity, setGranularity] = useState(KaeplaSimulationParameterGranularity.max);

  const handleNegativePercentageChange = () => {
    setAllowNegativePercentage(!allowNegativePercentage);
  };

  const autoToggleNegativeToggle = useCallback(() => {
    if (!ruleset?.parameters) return;
    const _parameter = [...ruleset.parameters].find(
      (p) => p.dimension === parameter.dimension,
    ) as KaeplaSimulationParameterNumeric;
    if (!_parameter) return;

    const _years = matrixFiltered?.simulationYears?.years ?? [];
    setYears(_years);
    const lowest = _years
      .map((year) => {
        let _percentage = 0;
        if (parameter?.dimension && _parameter?.percentages) {
          const percentageOfYear = [..._parameter.percentages].find((_p) => _p.key === `${year}`);
          if (percentageOfYear !== undefined) {
            _percentage = percentageOfYear.percentage || 0;
          }
        }
        return _percentage;
      })
      .reduce((a, b) => Math.min(a, b), 0);

    if (lowest < 0) {
      setAllowNegativePercentage(true);
    }
  }, [parameter.dimension, ruleset?.parameters, matrixFiltered]);

  useEffect(() => {
    autoToggleNegativeToggle();
  }, [autoToggleNegativeToggle]);

  useLayoutEffect(() => {
    const updateEqualizer = () => {
      setEqualizerWidth(equalizerParentReference.current?.offsetWidth ?? defaultEqualizerWidth);
      setEqualizerHeight(
        allowNegativePercentage ? defaultEqualizerHeight * 1.2 : defaultEqualizerHeight,
      );
    };
    window.addEventListener('resize', updateEqualizer);
    setTimeout(() => {
      // the timeline needs a moment to adjust
      updateEqualizer();
    }, 100);
    updateEqualizer();
    return () => window.removeEventListener('resize', updateEqualizer);
  }, [allowNegativePercentage, equalizerParentReference]);

  if (!project) return null;

  return (
    <Resizable
      style={{
        marginBottom: 8,
        // border: "1px solid red"
      }}
      size={{ width: equalizerWidth, height: equalizerHeight }}
      minHeight={defaultEqualizerHeight}
      minWidth={defaultEqualizerWidth}
      enable={{
        top: false,
        right: false,
        bottom: true,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      onResize={(_event, _direction, _reference, _d) => {
        setEqualizerHeight(equalizerReference.current?.offsetHeight ?? defaultEqualizerHeight);
      }}
    >
      <Box
        borderBottom={1}
        // borderRadius={1}
        borderColor={theme.palette.grey[300]}
        p={1}
        px={2}
        sx={{
          height: '100%',
          width: '100%',
          opacity: simulation?.isBeingSimulated ? 0.3 : 1,
        }}
        ref={equalizerReference}
      >
        <Grid container spacing={1} sx={{ height: '100%' }}>
          <Grid item xs={12}>
            <Toolbar disableGutters variant="dense">
              {dimension &&
                cleanColumnName(
                  getAliasFor(dimension, matrixFiltered?.dimensions?.dimensions),
                )}{' '}
              {dimension?.currency && mapCurrency(dimension?.currency)}
              <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
              <Box sx={{ mr: 2 }}>{granularity}</Box>
              <KaeplaSlider
                sx={{ width: 200, mr: 5 }}
                color="primary"
                value={granularityLevel(granularity)}
                onChange={(_event, value) => {
                  const level = levelGranularity(value as number);
                  if (!level) return;
                  setGranularity(level);
                }}
                step={50}
                marks
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={allowNegativePercentage}
                    onChange={handleNegativePercentageChange}
                    disabled={!own}
                    size="small"
                  />
                }
                label="-100/100"
              />
              <IconButton
                aria-label="delete"
                color="primary"
                size="small"
                disabled={!own}
                onClick={() => {
                  const newRulesets = [...simulationRulesets].map((r) => {
                    if (r.id === ruleset.id) {
                      const newRuleset = { ...ruleset };
                      newRuleset.parameters = [...newRuleset.parameters].filter(
                        (p) => p.dimension !== parameter.dimension,
                      );
                      return newRuleset;
                    }
                    return r;
                  });
                  setSimulationRulesets(newRulesets);
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Toolbar>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={0} direction="row" justifyContent="space-between">
              {years.map((year) => (
                <ParameterYear
                  key={year}
                  year={year}
                  ruleset={ruleset}
                  parameter={parameter}
                  equalizerHeight={equalizerHeight}
                  granularity={granularity}
                  simulation={simulation}
                  own={own}
                  simulationRulesets={simulationRulesets}
                  setSimulationRulesets={setSimulationRulesets}
                  setPreview={setPreview}
                  allowNegativePercentage={allowNegativePercentage}
                />
              ))}
            </Stack>
          </Grid>
          <Grid item container xs={12} justifyContent="center">
            <Box sx={{ position: 'relative' }}>
              <DraghandleIcon
                sx={{ color: 'lightgray', position: 'absolute', top: -10 }}
                fontSize="small"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Resizable>
  );
};
