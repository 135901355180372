import { atom } from 'recoil';

import { localForageEffect } from './localForageEffect.js';
import { MatrixTimeSlice } from '../../kaeplaTypes/MatrixTimeSlice.js';

const defaultTimeSliceState: MatrixTimeSlice = MatrixTimeSlice.month;

export const timeSliceState = atom<MatrixTimeSlice>({
  key: 'timeSlice',
  default: defaultTimeSliceState,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  effects_UNSTABLE: [localForageEffect],
});
