import ResetIcon from '@mui/icons-material/RestoreOutlined';
import ZoomInIcon from '@mui/icons-material/ZoomInOutlined';
import ZoomOutIcon from '@mui/icons-material/ZoomOutOutlined';
import { Button, ButtonGroup } from '@mui/material';
import { Chart as ChartJS } from 'chart.js';
import { Dispatch, MutableRefObject, SetStateAction, useEffect, useState } from 'react';

interface CTZOptions {
  chartReference: MutableRefObject<undefined>;
}

// TODO: how do we update the zoomed state?
// cause this doesn't work
const zoomCompleteListener = (
  chartReference: MutableRefObject<undefined>,
  setZoomed: Dispatch<SetStateAction<boolean>>,
) => {
  const chart = chartReference?.current as unknown as ChartJS;
  if (chart?.options.plugins?.zoom?.zoom) {
    chart.options.plugins.zoom.zoom.onZoom = (context) => {
      // console.log('onZoom override...', context.chart.isZoomedOrPanned());
      setZoomed(context.chart.isZoomedOrPanned());
    };
    chart.options.plugins.zoom.zoom.onZoomComplete = (context) => {
      setZoomed(context.chart.isZoomedOrPanned());
    };
  }
};

export const ChartToolbarZoom = ({ chartReference }: CTZOptions) => {
  const [zoomed, setZoomed] = useState(false);
  const chart = chartReference?.current as unknown as ChartJS;

  useEffect(() => {
    zoomCompleteListener(chartReference, setZoomed);
  }, [chartReference]);

  // Chart Interactions
  const handleResetZoomIn = () => {
    if (chart) {
      chart.zoom(1.2);
      setZoomed(true);
    }
  };

  const handleResetZoomOut = () => {
    if (chart) {
      chart.zoom(0.8);
      if (!chart?.isZoomedOrPanned()) {
        setZoomed(false);
      }
    }
  };

  const handleResetZoom = () => {
    if (chart) {
      chart.resetZoom();
      setZoomed(false);
    }
  };

  return (
    <ButtonGroup size="small" disableElevation variant="outlined">
      <Button
        onClick={handleResetZoomOut}
        disabled={!zoomed}
        endIcon={<ZoomOutIcon sx={{ m: 0.25, ml: -1 }} />}
      />
      <Button onClick={handleResetZoomIn} endIcon={<ZoomInIcon sx={{ m: 0.25, ml: -1 }} />} />
      <Button
        onClick={handleResetZoom}
        // disabled={!zoomed}
        endIcon={<ResetIcon sx={{ m: 0.25, ml: -1 }} />}
      />
    </ButtonGroup>
  );
};
