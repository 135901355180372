import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';

interface Options {
  password: string;
  token: string;
}

export const requestPasswordReset = async ({ password, token }: Options) => {
  const functions = getFunctions(getApp('kaepla'), 'europe-west3');
  const resetPassword = httpsCallable(functions, 'resetPassword');
  const passwordResetResult = await resetPassword({
    hostname: window.location.hostname,
    password,
    token,
  });
  const { data } = passwordResetResult;
  return data as { success: boolean; error: string };
};
