import GapOnlyIcon from '@mui/icons-material/HeightOutlined';
import GapOnlyOffIcon from '@mui/icons-material/HorizontalRuleOutlined';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Badge, Box, Divider, Grid, Paper, Tab, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { Explore } from './Tabs/Explore.js';
import { Validate } from './Tabs/Validate.js';
import { useAuth } from '../../../AuthReactProvider.js';
import { getFromKaepla } from '../../../services/api/getFromKaepla.js';
import { validateData } from '../../../services/api/validateData.js';
import { MatrixSummary } from '../../../services/kaeplaTypes/MatrixSummary.js';
import {
  KaeplaApiParameters,
  KaeplaQueryType,
  MatrixValidation,
} from '../../../services/kaeplaTypes/index.js';
import { applicationState } from '../../../services/recoil/nonpersistent/applicationState.js';
import { dataViewState } from '../../../services/recoil/nonpersistent/dataViewState.js';
import { matrixFilteredState } from '../../../services/recoil/nonpersistent/matrixFilteredState.js';
import { matrixLoadingState } from '../../../services/recoil/nonpersistent/matrixLoadingState.js';
import { projectState } from '../../../services/recoil/nonpersistent/projectState.js';
import { currentScopePathState } from '../../../services/recoil/persistent/currentScopePathState.js';
import { KaeplaDataView } from '../../../typings/KaeplaDataView.js';
import { Layout } from '../../Layout/Layout.js';
import { ProjectLoading } from '../../features/ProjectLoading.js';
import { ProjectInfoSmall } from '../Perspectives/features/ProjectSummary/features/ProjectInfoSmall.js';
import { simulationDataColor } from '../defaults.js';

export const ValidateData = () => {
  const { kaeplaUser } = useAuth();
  const app = useRecoilValue(applicationState);
  const project = useRecoilValue(projectState);
  const dataView = useRecoilValue(dataViewState);
  const setMatrixFiltered = useSetRecoilState(matrixFilteredState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const setMatrixLoading = useSetRecoilState(matrixLoadingState);
  const [validatorLoading, setValidatorLoading] = useState(true);
  const [gapOnly, setGapOnly] = useState(true);
  const [matrixValidation, setMatrixValidation] = useState<MatrixValidation>();
  const [tabValue, setTabValue] = useState('validate');

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (!app.projectsLoaded) return;
    if (!currentScopePath) return;
    if (!kaeplaUser?.uid || !project?.id) {
      return;
    }
    const parameters: KaeplaApiParameters = {
      q: 'summary' as KaeplaQueryType,
      p: currentScopePath,
      projectId: project?.id,
      s: 'Dashboard',
    };

    getFromKaepla({
      callBack: (apiResponse) => {
        const response = apiResponse.response as MatrixSummary;
        setMatrixFiltered((_matrixFiltered) => ({ ..._matrixFiltered, ...response }));
      },
      setLoading: setMatrixLoading,
      params: parameters,
      uid: kaeplaUser?.uid,
    });

    validateData({
      callBack: (apiResponse) => {
        setMatrixValidation(apiResponse);
        setValidatorLoading(false);
      },
      errorCallBack: () => {
        setValidatorLoading(false);
      },
      setLoading: setMatrixLoading,
      params: {
        projectId: project.id,
      },
      uid: kaeplaUser?.uid,
    });
  }, [
    app.projectsLoaded,
    currentScopePath,
    kaeplaUser?.uid,
    project?.id,
    setMatrixFiltered,
    setMatrixLoading,
  ]);

  if (validatorLoading) {
    return <ProjectLoading />;
  }

  if (dataView === KaeplaDataView.Grid) {
    return <Explore gapOnly={gapOnly} setGapOnly={setGapOnly} />;
  }

  return (
    <Layout hasScopeNavigation showCustomerSelector>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }} data-testid="targets-OverviewHeader">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={3}>
                <ProjectInfoSmall title={project.name} />
              </Grid>
              <Grid item sm={2}>
                <Divider orientation="vertical" />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={7}
                sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
              >
                <Typography variant="h6">Validate Data</Typography>
                <Typography variant="body1">
                  {tabValue === 'validate' && (
                    <Box>
                      Analyze data validation results.
                      <br />
                    </Box>
                  )}
                  {tabValue === 'explore' && (
                    <Box>
                      Explore your data to investigate calculation gaps and data quality issues.
                      <br />
                      If source values differ from calculated values, they are displayed in{' '}
                      <Box component="span" color={simulationDataColor}>
                        color
                      </Box>
                      .
                      <br />
                      Rounding deviations below 0.001 are are highlighted with a
                      <Box component="span" sx={{ color: 'orange', fontSize: 12, ml: 1 }}>
                        ⚠
                      </Box>
                      .
                      <Box display="flex" alignItems="center">
                        <GapOnlyIcon
                          sx={{
                            transform: 'rotate(90deg)',
                          }}
                          color="primary"
                        />
                        /
                        <GapOnlyOffIcon color="primary" />{' '}
                        <Typography variant="body1">show/hide gaps.</Typography>
                      </Box>
                    </Box>
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange}>
                <Tab
                  value="validate"
                  sx={{ fontSize: '1.2rem' }}
                  label={
                    <Badge
                      badgeContent={
                        matrixValidation?.validations.flatMap(
                          (validation) => validation?.rows ?? [],
                        ).length
                      }
                      color="info"
                    >
                      Validate
                      <Box component="span" sx={{ ml: 1 }} />
                    </Badge>
                  }
                />
                <Tab label="Explore" sx={{ fontSize: '1.2rem' }} value="explore" />
              </TabList>
            </Box>
            <TabPanel value="validate">
              <Validate matrixValidation={matrixValidation} />
            </TabPanel>
            <TabPanel value="explore">
              <Explore gapOnly={gapOnly} setGapOnly={setGapOnly} />
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>
    </Layout>
  );
};
