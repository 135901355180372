import { Box, LinearProgress, LinearProgressProps, Typography } from '@mui/material';

export function LinearProgressWithLabel(properties: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ whiteSpace: 'nowrap', fontSize: 12, mr: 1 }}>Manual Review</Box>
      <Box sx={{ width: '100%', mr: 1, minWidth: 80 }}>
        <LinearProgress variant="determinate" color="info" {...properties} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          properties.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
