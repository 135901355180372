import { atom } from 'recoil';

import { KaeplaSimulation } from '../../kaeplaTypes/Application/KaeplaSimulation.js';

const defaultSimulationState: Partial<KaeplaSimulation> | undefined = undefined;

export const simulationState = atom<Partial<KaeplaSimulation>>({
  key: 'simulation',
  default: defaultSimulationState,
});
