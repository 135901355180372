import { getApp } from 'firebase/app';
import { deleteDoc, doc, getFirestore } from 'firebase/firestore';

import { getUserPreferences } from './getUserPreferences.js';
import { logger } from '../../Frontend/helpers/logger.js';

interface Options {
  uid: string;
  projectId: string;
}

export const clearUserPreferencesForUserAndProject = async ({ uid, projectId }: Options) => {
  const userPreferences = await getUserPreferences({ uid });
  if (userPreferences.length === 0) return;
  const projectUserPreferences = userPreferences.find(
    (preferences) => preferences.projectId === projectId,
  );
  if (!projectUserPreferences) return;
  logger.log('clearUserPreferencesForUserAndProject', projectUserPreferences.id);
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStoreDocumentPath = `userPreferences/${projectUserPreferences.id}`;
  const firestoreDocumentReference = doc(firestoreDatabase, fireStoreDocumentPath);
  void deleteDoc(firestoreDocumentReference);
};
