import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';

import { requestLog } from '../../Frontend/helpers/logger.js';
import { MatrixTargetFigures } from '../kaeplaTypes/MatrixTargetFigures.js';

type DataReturn = (data: MatrixTargetFigures) => void;
interface KOptions {
  callBack?: DataReturn;
  errorCallBack?: (error: unknown) => void;
  setLoading?: (loading: boolean) => void;
  params: Record<string, unknown>;
  uid?: string;
}
export const getKaeplaTargets = ({
  callBack,
  errorCallBack,
  setLoading,
  params,
  uid,
}: KOptions) => {
  const functions = getFunctions(getApp('kaepla'), 'europe-west3');
  const getTargets = httpsCallable(functions, 'getKaeplaTargets');

  requestLog.log(`kaeplaTargets.post().json(${JSON.stringify({ ...params, uid })})`);
  void getTargets(params)
    .then((result) => {
      // console.log("loading", params.q, loading);
      if (typeof setLoading === 'function') {
        setLoading(false);
      }
      if (callBack) {
        callBack(result.data as MatrixTargetFigures);
      }
    })
    .catch((error) => {
      if (errorCallBack) {
        errorCallBack(error);
      }
    });
};
