import GenerateIcon from '@mui/icons-material/PlayArrow';
import { Card, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

interface Options {
  factor: number;
  loading: boolean;
  generateData: (factor: number) => void;
  primary: string;
  secondary: string;
  disabled: boolean;
}
export const DummyDataCard = ({
  factor,
  loading,
  generateData,
  primary,
  secondary,
  disabled,
}: Options) => {
  return (
    <List component={Card} data-testid="dummy-data-card">
      <ListItem disablePadding>
        <ListItemButton
          disabled={disabled || loading}
          onClick={() => {
            void generateData(factor);
          }}
        >
          <ListItemIcon>
            <GenerateIcon />
          </ListItemIcon>
          <ListItemText data-testid="dummy-data-size" primary={primary} secondary={secondary} />
        </ListItemButton>
      </ListItem>
    </List>
  );
};
