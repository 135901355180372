import { getApp } from 'firebase/app';
import { collection, doc, DocumentData, getFirestore, setDoc, Timestamp } from 'firebase/firestore';

import { KaeplaCustomer } from '../kaeplaTypes/Application/KaeplaCustomer.js';
import { KaeplaProject } from '../kaeplaTypes/Application/KaeplaProject.js';
import { KaeplaUser } from '../kaeplaTypes/Application/KaeplaUser.js';
import { FirestoreTimestamp } from '../kaeplaTypes/FirestoreTimeStamp.js';

interface Options {
  kaeplaUser: KaeplaUser;
  customer: KaeplaCustomer;
}

export const createProject = async ({ kaeplaUser, customer }: Options) => {
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `projects`;
  const firestoreCollectionReference = collection(firestoreDatabase, fireStorePath);
  const firestoreDocumentReference = doc(firestoreCollectionReference);
  const project: KaeplaProject = {
    accessDomain: window.location.hostname,
    id: firestoreDocumentReference.id,
    dataMatrix: `MatrixM${firestoreDocumentReference.id}`,
    name: `New Project`,
    createdBy: kaeplaUser.uid,
    ownedBy: kaeplaUser.uid,
    customerId: customer.id,
    resellerId: customer.resellerId,
    createdAt: Timestamp.now() as FirestoreTimestamp,
    matrixUnavailable: true,
  };
  await setDoc(firestoreDocumentReference, project as DocumentData);
  return project;
};
