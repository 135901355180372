import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Button } from '@mui/material';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { erpOrdersImportsState } from '../../../../services/recoil/nonpersistent/dataSourceErpOrdersImportState';
import { ihsVehicleImportsState } from '../../../../services/recoil/nonpersistent/dataSourceIhsVehicleImportsState';
import { dataSourceState } from '../../../../services/recoil/nonpersistent/dataSourceState';
import { DELETE_SPEED } from '../config';
import { DataSourceImport } from '../types/DataSourceImport';

interface DOptions {
  dataSourceImport: DataSourceImport;
  setDeletionInProgress: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DeleteDataSourceImport = ({ dataSourceImport, setDeletionInProgress }: DOptions) => {
  const selectedDataSource = useRecoilValue(dataSourceState);
  const setIhsVehicleImports = useSetRecoilState(ihsVehicleImportsState);
  const setErpOrderImports = useSetRecoilState(erpOrdersImportsState);

  const deleteImports = () => {
    if (selectedDataSource.key === 'ihsVehicles') {
      setIhsVehicleImports((oldImports) =>
        oldImports
          .filter((index) => index.id !== dataSourceImport.id)
          .sort((a, b) => a.id.localeCompare(b.id)),
      );
    } else if (selectedDataSource.key === 'erpOrders') {
      setErpOrderImports((oldImports) =>
        oldImports
          .filter((index) => index.id !== dataSourceImport.id)
          .sort((a, b) => a.id.localeCompare(b.id)),
      );
    }
  };

  return (
    <Button
      size="small"
      variant="text"
      onClick={() => {
        setDeletionInProgress(true);
        setTimeout(() => {
          deleteImports();
        }, DELETE_SPEED);
      }}
      endIcon={<DeleteIcon />}
    >
      delete
    </Button>
  );
};
