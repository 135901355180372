import { ErpOrdersConfigurationSchemaField } from '../../types/ConfigurationSchemaField';
import { ErpOrder } from '../../types/ErpOrder';

export const ordersSchema: ErpOrdersConfigurationSchemaField[] = [
  {
    fieldName: 'customerName' as keyof ErpOrder,
    label: 'Customer Name',
    isKeyField: true,
    isVehicleLabelField: true,
    width: 100,
  },
  {
    fieldName: 'customerNumber' as keyof ErpOrder,
    label: 'Customer Number',
    isKeyField: true,
    isVehicleLabelField: true,
    width: 100,
  },
  {
    fieldName: 'materialNumber' as keyof ErpOrder,
    label: 'Material Number',
    isKeyField: true,
    width: 120,
  },
  {
    fieldName: 'customerMaterialNumber' as keyof ErpOrder,
    label: 'Customer Material Number',
    isKeyField: true,
    width: 120,
  },
  {
    fieldName: 'productHierarchy' as keyof ErpOrder,
    label: 'Product Hierarchy',
    isKeyField: true,
    isVehicleLabelField: true,
    width: 200,
  },
  {
    fieldName: 'productionPlan' as keyof ErpOrder,
    label: 'Production Plan',
    width: 80,
  },
  {
    fieldName: 'productVersion' as keyof ErpOrder,
    label: 'Product Version',
    width: 200,
  },
  {
    fieldName: 'productRevision' as keyof ErpOrder,
    label: 'Product Revision',
    isInsignificantField: true,
    width: 200,
  },
  {
    fieldName: 'quantity' as keyof ErpOrder,
    label: 'Quantity',
    isValueField: true,
    width: 200,
  },
];
