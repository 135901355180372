import { Box } from '@mui/material';
import prettyBytes from 'pretty-bytes';
import prettyMilliseconds from 'pretty-ms';
import { useRecoilValue } from 'recoil';

import { MatrixJob } from '../../services/kaeplaTypes/MatrixJob.js';
import { applicationState } from '../../services/recoil/nonpersistent/applicationState.js';

interface Options {
  job: MatrixJob;
  forceShow?: boolean;
}

export const BQJobInfo = ({ job, forceShow }: Options) => {
  const application = useRecoilValue(applicationState);
  if (!application.benchmarks || forceShow) {
    return null;
  }
  return (
    <Box
      component="span"
      sx={{
        fontFamily: 'monospace',
        color: job.cached ? 'green' : 'blue',
        fontSize: 14,
      }}
    >
      {`${prettyMilliseconds(job.ms)}\u00A0${prettyBytes(job.bytes).replace(' ', '')}`}
    </Box>
  );
};
