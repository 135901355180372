import { DocumentData } from 'firebase/firestore';

import { addFirestoreDocumentListener } from './addFirestoreDocumentListener.js';

interface Options {
  projectId: string;
  targetsId: string;
  callback: (document: DocumentData | undefined) => void;
}

export const addTargetsListener = ({ projectId, targetsId, callback }: Options) => {
  const fireStorePath = `projects/${projectId}/targets/${targetsId}`;
  return addFirestoreDocumentListener({ fireStorePath, callback });
};
