import AddIcon from '@mui/icons-material/Add';
import { Fab } from '@mui/material';

interface FOptions {
  setNewDataSourceImport: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AddDataSourceImportFab = ({ setNewDataSourceImport }: FOptions) => {
  return (
    <Fab
      color="primary"
      aria-label="add customer"
      data-testid="add-ihs-import"
      id="data-sources-add-import"
      sx={{ position: 'fixed', bottom: 20, right: 20 }}
      onClick={() => {
        setNewDataSourceImport(true);
      }}
    >
      <AddIcon />
    </Fab>
  );
};
