import { getApp } from 'firebase/app';
import { DocumentData, getFirestore, doc, setDoc } from 'firebase/firestore';

import { KaeplaImport } from '../kaeplaTypes/Application/KaeplaImport.js';
import { KaeplaProject } from '../kaeplaTypes/Application/KaeplaProject.js';

interface Options {
  project: KaeplaProject;
  dataImport: KaeplaImport;
}

export const toggleArchiveImport = async ({ project, dataImport }: Options) => {
  if (!project.id || !dataImport.id) return;
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `imports/${dataImport.id}`;
  const documentReference = doc(firestoreDatabase, fireStorePath);
  dataImport.archived = !dataImport.archived;
  await setDoc(documentReference, dataImport as DocumentData);
  return dataImport;
};
