import { atom } from 'recoil';

import { localForageEffect } from './localForageEffect.js';

export const defaultFilterSqlState = undefined;

export const filterSqlState = atom<string | undefined>({
  key: 'filterSqlState',
  default: defaultFilterSqlState,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  effects_UNSTABLE: [localForageEffect],
});
