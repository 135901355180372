import { SetterOrUpdater } from 'recoil';

import { KaeplaCustomer } from '../../services/kaeplaTypes/Application/KaeplaCustomer.js';

interface Options {
  customer: KaeplaCustomer;
  knownCustomers: KaeplaCustomer[];
  setKnownCustomers: SetterOrUpdater<KaeplaCustomer[]>;
}

export const updateKnownCustomer = ({ customer, knownCustomers, setKnownCustomers }: Options) => {
  let updatedKnownCustomers = [...knownCustomers];
  const knownCustomer = knownCustomers.find((k) => k.id === customer.id);
  if (knownCustomer) {
    updatedKnownCustomers = [...knownCustomers.filter((k) => k.id !== customer.id), customer];
  }

  setKnownCustomers(updatedKnownCustomers);
};
