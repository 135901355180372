import { Box, useTheme } from '@mui/material';
import numbro from 'numbro';
import { useSetRecoilState } from 'recoil';

import { dataSourceOrdersState } from '../../../../../services/recoil/nonpersistent/dataSourceOrdersState';
import { ErpOrdersConfigurationSchemaField } from '../../types/ConfigurationSchemaField';
import { ErpOrder } from '../../types/ErpOrder';

interface ValueUpdateProperties {
  field: ErpOrdersConfigurationSchemaField;
  isUpdated: boolean;
  value: unknown;
  originalValue: unknown;
  fixedQuantity: number;
  erpOrder: ErpOrder;
  setHighlight: React.Dispatch<React.SetStateAction<boolean>>;
}
export const ValueUpdate = ({
  field,
  value,
  originalValue,
  fixedQuantity,
  isUpdated,
  erpOrder,
  setHighlight,
}: ValueUpdateProperties) => {
  const theme = useTheme();
  const setOrders = useSetRecoilState(dataSourceOrdersState);

  const handleUpdateClick = (updateValue: number | string) => {
    setHighlight(true);
    setOrders((previous) =>
      previous.map((order) => {
        if (order.id !== erpOrder.id) return order;
        const item = { ...order, [field.fieldName]: updateValue, isReviewed: true };
        return item;
      }),
    );
    setTimeout(() => {
      setHighlight(false);
    }, 1000);
  };

  if (!field.isValueField && isUpdated) {
    return (
      <Box>
        <Box
          component="span"
          sx={{ color: theme.palette.info.light, cursor: 'pointer' }}
          onClick={() => {
            handleUpdateClick(value as string);
          }}
        >
          {value as string}
        </Box>
        <Box component="span" sx={{ mx: 1, textDecoration: 'line-through' }}>
          {(originalValue as string) ?? 'no previous value'}
        </Box>
      </Box>
    );
  }
  if (!field.isValueField) {
    return (
      <Box
        sx={{ cursor: 'pointer' }}
        onClick={() => {
          handleUpdateClick(value as string);
        }}
      >
        {value as string}
      </Box>
    );
  }

  return (
    <Box sx={{ whiteSpace: 'nowrap' }}>
      {numbro(value).format({
        thousandSeparated: true,
        mantissa: 2,
        optionalMantissa: true,
      })}
      <Box component="span" sx={{ mx: 1 }}>
        {' → '}
      </Box>
      <Box component="span" sx={{ color: fixedQuantity < 0 ? 'red' : 'blue' }}>
        {numbro(fixedQuantity).format({ forceSign: true })}%
      </Box>
    </Box>
  );
};
