export const fuelTypes = [
  'CNG',
  'DIESEL',
  'GAS',
  'GAS-CNG',
  'Electricity',
  'GAS-E85',
  'GAS-E100',
  'GAS-LPG',
  'LPG',
  'Hydrogen',
  'GAS-M100',
];
