import { Alert } from '@mui/material';
import { useRecoilValue } from 'recoil';

import { dataSourceState } from '../../../../services/recoil/nonpersistent/dataSourceState';
import { SelectedDataSource } from '../SelectedDataSource';

interface DOptions {
  viewDetails: boolean;
  setViewDetails: React.Dispatch<React.SetStateAction<boolean>>;
  fullScreen: boolean;
  setFullScreen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DataSourceTabContent = ({
  viewDetails,
  setViewDetails,
  fullScreen,
  setFullScreen,
}: DOptions) => {
  const selectedDataSource = useRecoilValue(dataSourceState);

  if (selectedDataSource.key === 'ihsVehicles' || selectedDataSource.key === 'erpOrders') {
    return (
      <SelectedDataSource
        viewDetails={viewDetails}
        setViewDetails={setViewDetails}
        fullScreen={fullScreen}
        setFullScreen={setFullScreen}
      />
    );
  }
  return <Alert severity="info">No data yet, Please upload your first file.</Alert>;
};
