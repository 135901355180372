import AiRulesIcon from '@mui/icons-material/AutoAwesomeOutlined';
import ConfigurationIcon from '@mui/icons-material/SettingsOutlined';
import { Badge, Button, Paper, Stack, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';

import { dataSourceState } from '../../../../services/recoil/nonpersistent/dataSourceState';
import { dataSourceUseAiRulesState } from '../../../../services/recoil/nonpersistent/dataSourceUseAiRules';

interface Options {
  setManageAiRules: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ConfigPanel = ({ setManageAiRules }: Options) => {
  const selectedDataSource = useRecoilValue(dataSourceState);
  const useAiRules = useRecoilValue(dataSourceUseAiRulesState);
  return (
    <Paper sx={{ p: 2 }}>
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography variant="h5">{selectedDataSource.label}</Typography>
        <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
        <Button startIcon={<ConfigurationIcon />} variant="outlined" size="small">
          Configuration
        </Button>
        {useAiRules && (
          <Badge badgeContent={1} color="info">
            <Button
              startIcon={<AiRulesIcon />}
              variant="outlined"
              size="small"
              onClick={() => {
                setManageAiRules(true);
              }}
            >
              Ai-Rules
            </Button>
          </Badge>
        )}
      </Stack>
    </Paper>
  );
};
