import { Alert, Box, Button, LinearProgress, Stack, useTheme } from '@mui/material';
import CodeEditor from '@uiw/react-textarea-code-editor';
import { useState } from 'react';
import TimeAgo from 'react-timeago';
import { useDebouncedCallback } from 'use-debounce';

import { useAuth } from '../../../../../AuthReactProvider';
import { setSqlExampleOnCall } from '../../../../../services/api/admin/setSqlExample';
import { AiQuestionMetaLog } from '../../../../../services/kaeplaTypes/importer/AiQuestionMetaLog';

interface Options {
  metaLog: AiQuestionMetaLog;
  query: string;
  setLastResolvedLog: React.Dispatch<React.SetStateAction<AiQuestionMetaLog | undefined>>;
}

export const AiSqlCodeEditor = ({ metaLog, query, setLastResolvedLog }: Options) => {
  const { kaeplaUser } = useAuth();
  const theme = useTheme();
  const [sqlExample, setSqlExample] = useState(query);
  const [testRunSuccessful, setTestRunSuccessful] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [rawResult, setRawResult] = useState<Record<string, unknown>[]>();
  const [error, setError] = useState<string | undefined>();

  const saveInquiryExample = useDebouncedCallback((inquiryExample: string) => {
    // eslint-disable-next-line unicorn/no-useless-undefined
    setError(undefined);
    setProcessing(true);
    setSqlExampleOnCall({
      callBack: () => {
        setLastResolvedLog({ ...metaLog, needsResolution: false });
        setProcessing(false);
      },
      errorCallBack: (_error) => {
        setError(_error.message);
        setProcessing(false);
      },
      params: {
        metaLogId: metaLog.id,
        tryRun: true,
        inquiryExample,
      },
      uid: kaeplaUser?.uid,
    });
  }, 3000);

  return (
    <Stack spacing={2}>
      {!metaLog.inquiryExample && (
        <Box border={1} borderColor="ButtonHighlight">
          <CodeEditor
            value={sqlExample}
            data-color-mode={theme.palette.mode}
            language="sql"
            placeholder="Please enter SQL code."
            onChange={(event) => {
              setTestRunSuccessful(false);
              setSqlExample(event.target.value);
            }}
            padding={15}
            style={{
              fontSize: 14,
              fontFamily:
                'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
            }}
          />
          {processing && <LinearProgress />}
          {rawResult && !metaLog.inquiryExample && (
            <CodeEditor
              value={JSON.stringify(rawResult, null, 2)}
              data-color-mode={theme.palette.mode}
              language="json"
              padding={15}
              style={{
                fontSize: 14,
                fontFamily:
                  'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
              }}
            />
          )}
          {metaLog.resolvedAt && metaLog.sqlExample && (
            <Stack sx={{ fontSize: 10 }} direction="row" justifyContent="space-between" spacing={2}>
              {metaLog.sqlExample === query ? (
                <Box sx={{ p: 0.5, color: theme.palette.success.main }}>
                  {` `}AI result matches!
                </Box>
              ) : (
                <Box sx={{ p: 0.5, color: theme.palette.error.main }}>
                  {` `}AI result does not match yet!
                </Box>
              )}
              <Box sx={{ p: 0.5, color: theme.palette.success.main }}>
                SQL Example updated{` `}{' '}
                <TimeAgo date={metaLog.resolvedAt.toDate()} max={Number.MAX_SAFE_INTEGER} />
              </Box>
            </Stack>
          )}
          {metaLog.sqlExample && metaLog.sqlExample?.localeCompare(query) !== 0 && (
            <Box sx={{ pt: 1 }}>
              <Box sx={{ p: 1, color: theme.palette.error.main }}>Current AI result SQL</Box>
              <CodeEditor
                value={query}
                data-color-mode={theme.palette.mode}
                language="sql"
                padding={15}
                style={{
                  fontSize: 14,
                  opacity: 0.6,
                  fontFamily:
                    'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                }}
              />
            </Box>
          )}
        </Box>
      )}
      {error && (
        <Alert
          severity="error"
          onClose={() => {
            // eslint-disable-next-line unicorn/no-useless-undefined
            setError(undefined);
          }}
        >
          {error}
        </Alert>
      )}
      <Box border={1} borderColor="ButtonHighlight">
        <CodeEditor
          value={metaLog.inquiryExample}
          data-color-mode={theme.palette.mode}
          language="plaintext"
          placeholder="add an inquiry as resolution (will remove SQL example if set)"
          padding={15}
          style={{
            fontSize: 14,
            fontFamily:
              'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
          }}
          onChange={(event) => {
            saveInquiryExample(event.target.value);
          }}
        />
      </Box>
      <Stack direction="row-reverse" spacing={2}>
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            // eslint-disable-next-line unicorn/no-useless-undefined
            setError(undefined);
            setProcessing(true);
            setSqlExampleOnCall({
              callBack: (response) => {
                if (response.rows) {
                  setRawResult(response.rows);
                }
                setProcessing(false);
                setTestRunSuccessful(true);
              },
              errorCallBack: (_error) => {
                setError(_error.message);
                setProcessing(false);
              },
              params: {
                metaLogId: metaLog.id,
                tryRun: true,
                sqlExample,
              },
              uid: kaeplaUser?.uid,
            });
          }}
        >
          Run Query
        </Button>
        <Button
          variant="outlined"
          size="small"
          disabled={!testRunSuccessful}
          onClick={() => {
            // eslint-disable-next-line unicorn/no-useless-undefined
            setError(undefined);
            setProcessing(true);
            setSqlExampleOnCall({
              callBack: (response) => {
                setRawResult(response.rows);
                setLastResolvedLog({ ...metaLog, needsResolution: false });
                setProcessing(false);
                setTestRunSuccessful(true);
              },
              errorCallBack: (_error) => {
                setError(_error.message);
                setProcessing(false);
              },
              params: {
                metaLogId: metaLog.id,
                sqlExample,
              },
              uid: kaeplaUser?.uid,
            });
          }}
        >
          Set as example
        </Button>
        <Button
          variant="text"
          size="small"
          onClick={() => {
            // eslint-disable-next-line unicorn/no-useless-undefined
            setError(undefined);
            setProcessing(true);
            setSqlExampleOnCall({
              callBack: (response) => {
                setRawResult(response.rows);
                setLastResolvedLog({ ...metaLog, needsResolution: !metaLog.needsResolution });
                setProcessing(false);
                setTestRunSuccessful(true);
              },
              errorCallBack: (_error) => {
                setError(_error.message);
                setProcessing(false);
              },
              params: {
                metaLogId: metaLog.id,
                needsResolution: !metaLog.needsResolution,
              },
              uid: kaeplaUser?.uid,
            });
          }}
        >
          {metaLog.needsResolution ? 'set resolved' : 'set unresolved'}
        </Button>
      </Stack>
    </Stack>
  );
};
