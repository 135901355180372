import { Box, LinearProgress } from '@mui/material';
import { Timestamp } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { AiThreadItem } from './AiThreadItem';
import { ThreadMessage, ToolCall } from '../../../../../services/kaeplaTypes';
import { kaeplaAssignmentState } from '../../../../../services/recoil/persistent/kaeplaAssignmentState';
import { watchMessages } from '../watchers/watchMessages';
import { watchToolCalls } from '../watchers/watchToolCalls';

interface Options {
  threadId: string;
  showMarkdown: boolean;
}

export interface AiRun {
  id: string;
  threadId: string;
  uid: string;
  projectId: string;
  createdAt: Timestamp;
}

export interface ThreadItem {
  id: string;
  message?: ThreadMessage;
  toolCall?: ToolCall;
  createdAt: Timestamp;
}

export const AiThreadItems = ({ threadId, showMarkdown }: Options) => {
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);

  const [threadItems, setThreadItems] = useState<ThreadItem[]>([]);
  const [messages, setMessages] = useState<ThreadMessage[]>([]);
  const [toolCalls, setToolCalls] = useState<ToolCall[]>([]);
  const [loading, setLoading] = useState(false);

  // get messages, delegated sub-threads, toolCalls, and AI runs
  useEffect(() => {
    if (!kaeplaAssignment) return;
    const unsubscribeMessagesWatcher = watchMessages({ threadId, setLoading, setMessages });
    const unsubscribeToolCallsWatcher = watchToolCalls({ threadId, setLoading, setToolCalls });
    return () => {
      unsubscribeMessagesWatcher();
      unsubscribeToolCallsWatcher();
    };
  }, [kaeplaAssignment, threadId]);

  useEffect(() => {
    if (!kaeplaAssignment) return;
    const _threadItems: ThreadItem[] = [];
    messages.forEach((message) => {
      _threadItems.push({
        message,
        id: message.id,
        createdAt: message.createdAt,
      });
    });
    toolCalls.forEach((toolCall) => {
      _threadItems.push({
        toolCall,
        id: toolCall.id,
        createdAt: toolCall.createdAt,
      });
    });
    _threadItems.sort((a, b) => a.createdAt.seconds - b.createdAt.seconds);
    setThreadItems(_threadItems);
  }, [kaeplaAssignment, messages, toolCalls]);

  return (
    <div>
      {threadItems.map((threadItem) => {
        return (
          <AiThreadItem key={threadItem.id} threadItem={threadItem} showMarkdown={showMarkdown} />
        );
      })}
      {loading && (
        <Box>
          <LinearProgress />
          Loading...
        </Box>
      )}
    </div>
  );
};
