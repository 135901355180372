import { Box, Button } from '@mui/material';
import { useEffect, Dispatch, SetStateAction } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { useAuth } from '../../../../../AuthReactProvider.js';
import { useUserPreferences } from '../../../../../UserPreferencesProvider';
import { getFromKaepla } from '../../../../../services/api/getFromKaepla.js';
import { KaeplaApiParameters } from '../../../../../services/kaeplaTypes/Application/KaeplaApiParameters.js';
import { KaeplaQueryType } from '../../../../../services/kaeplaTypes/Application/KaeplaQueryType.js';
import { MatrixTimeSlice } from '../../../../../services/kaeplaTypes/MatrixTimeSlice.js';
import { MatrixTimeseries } from '../../../../../services/kaeplaTypes/MatrixTimeseries.js';
import { perspectiveState } from '../../../../../services/recoil/nonpersistent/perspectiveState';
import { projectState } from '../../../../../services/recoil/nonpersistent/projectState.js';
import { simulationState } from '../../../../../services/recoil/nonpersistent/simulationState.js';
import { snapShotState } from '../../../../../services/recoil/nonpersistent/snapshotState.js';
import { timeSeriesState } from '../../../../../services/recoil/nonpersistent/timeSeriesState.js';
import { currentScopePathState } from '../../../../../services/recoil/persistent/currentScopePathState.js';
import { filterSettingsState } from '../../../../../services/recoil/persistent/filterSettingState.js';
import { filterSqlState } from '../../../../../services/recoil/persistent/filterSqlState.js';
import { timeSliceState } from '../../../../../services/recoil/persistent/timeSliceState.js';

const slices = [
  { a: 'y', s: MatrixTimeSlice.year },
  { a: 'q', s: MatrixTimeSlice.quarter },
  { a: 'm', s: MatrixTimeSlice.month },
  { a: 'w', s: MatrixTimeSlice.week },
];

interface Options {
  setLoading: Dispatch<SetStateAction<boolean>>;
}

export const TimeSliceSwitcher = ({ setLoading }: Options) => {
  const { kaeplaUser } = useAuth();
  const { setPreferences } = useUserPreferences();
  const project = useRecoilValue(projectState);
  const simulation = useRecoilValue(simulationState);
  const snapshot = useRecoilValue(snapShotState);
  const perspective = useRecoilValue(perspectiveState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const filterSql = useRecoilValue(filterSqlState);
  const filterSettings = useRecoilValue(filterSettingsState);
  const [timeSlice, setTimeSlice] = useRecoilState(timeSliceState);
  const setTimeSeries = useSetRecoilState(timeSeriesState);

  useEffect(() => {
    if (simulation?.isBeingSimulated) return;
    setLoading(true);

    const parameters: KaeplaApiParameters = {
      q: 'timeseries' as KaeplaQueryType,
      p: [] as string[],
      projectId: project.id,
      simulationId: simulation?.id,
      snapshotId: snapshot?.id,
      timeSlice,
      s: 'TimeSliceSwitcher',
    };

    parameters.p = currentScopePath;

    if (perspective?.id) {
      parameters.perspectiveId = perspective?.id;
    }

    if (filterSettings.isActive) {
      parameters.filterSql = filterSql;
    }

    getFromKaepla({
      callBack: (apiResponse) => {
        if (!apiResponse) return;
        const result = apiResponse.response as MatrixTimeseries;
        if (result) {
          setTimeSeries(result);
        }
        setLoading(false);
      },
      errorCallBack: (_error) => {
        // console.log('ERROR', error);
        setLoading(false);
      },
      params: parameters,
      uid: kaeplaUser?.uid,
    });
  }, [
    currentScopePath,
    filterSettings.isActive,
    filterSql,
    kaeplaUser?.uid,
    perspective?.id,
    project.id,
    setLoading,
    setTimeSeries,
    simulation?.id,
    simulation?.isBeingSimulated,
    snapshot?.id,
    timeSlice,
  ]);

  return (
    <>
      {slices.map((slice) => {
        return (
          <Box
            // component="span"
            component={Button}
            variant={slice.s === timeSlice ? 'outlined' : 'text'}
            size="small"
            onClick={() => {
              setTimeSlice(slice.s);
              setPreferences({ lastScopeTimeSlice: slice.s });
              return;
            }}
            sx={{
              p: 0,
              minWidth: '2rem',
              mr: 1,
              cursor: 'pointer',
              // textDecoration: slice.s === timeSlice ? "underline" : "none",
              fontWeight: slice.s === timeSlice ? '400' : '100',
            }}
            key={slice.a}
          >
            {slice.a}
          </Box>
        );
      })}
    </>
  );
};
