import { Input } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

interface Options {
  value: string;
  callback: (value: string) => void;
  changeCallback?: (value: string) => void;
  fullWidth?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  autoFocus?: boolean;
  dataTestId?: string;
  fontSize?: string | number;
  disableUnderline?: boolean;
}

export const InlineEdit = ({
  value,
  callback,
  changeCallback,
  fullWidth,
  disabled,
  readOnly,
  dataTestId,
  fontSize,
  disableUnderline,
  autoFocus,
}: Options) => {
  const [focus, setFocus] = useState(autoFocus);
  const [text, setText] = useState<string>(value);
  const inputField = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setText(value);
    if (autoFocus) {
      inputField.current?.focus();
    }
  }, [value, autoFocus]);

  return (
    <Input
      name="editable-text"
      value={text}
      sx={{ fontSize: fontSize ?? '110%' }}
      onKeyUp={(event) => {
        if (event.key === 'Enter') {
          inputField.current?.blur();
        }
      }}
      onChange={(event) => {
        setText(event.target.value);
        if (changeCallback) {
          changeCallback(event.target.value);
        }
      }}
      onFocus={() => {
        inputField.current?.select();
        setFocus(true);
      }}
      onBlur={() => {
        callback(text);
        setFocus(false);
      }}
      inputRef={inputField}
      disableUnderline={(!focus || readOnly) ?? disableUnderline}
      fullWidth={fullWidth}
      disabled={!!disabled}
      readOnly={!!readOnly}
      inputProps={{ 'data-testid': dataTestId }}
    />
  );
};
