import { Box, useTheme } from '@mui/material';
import { ReactElement } from 'react';

// eslint-disable-next-line import/no-unassigned-import
import './scrollShadow.css';

import { AIFooter } from './AIFooter';
import { CONVERSATIONAL_UI_HEIGHT } from '../../../AI/lib/constants';

interface Options {
  scrollWrapper: ReactElement;
  askQuestion: ReactElement;
}

export const AIConversationalUI = ({ scrollWrapper, askQuestion }: Options) => {
  const theme = useTheme();
  return (
    <Box border={1} borderColor={theme.palette.primary.main} borderRadius={1} p={1} pt={0}>
      {/* <Grid container spacing={2} sx={{ position: 'fixed' }}>
        <Grid item xs={12} xl={10}>
          <Box className="scrollGradient" height={30} width="90%" />
        </Grid>
      </Grid> */}
      <Box height={CONVERSATIONAL_UI_HEIGHT} sx={{ overflow: 'hidden' }}>
        {scrollWrapper}
      </Box>
      {askQuestion}
      <AIFooter />
    </Box>
  );
};
