import { WhereClause } from '@atrigam/atrigam-service-firebase-watcher';
import { Box, LinearProgress, List } from '@mui/material';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useDebouncedCallback } from 'use-debounce';

import { ImportItem } from './ImportItem.js';
import { addFirestoreCollectionListener } from '../../../../services/firestore/addFirestoreCollectionListener.js';
import { KaeplaImport } from '../../../../services/kaeplaTypes/index.js';
import { kaeplaAssignmentState } from '../../../../services/recoil/persistent/kaeplaAssignmentState.js';

const BATCH_SIZE = 50;

export const Imports = () => {
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);

  const [showArchived] = useState(true);
  const [imports, setImports] = useState<KaeplaImport[]>([]);
  const [batches, setBatches] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!kaeplaAssignment) return;
    setLoading(true);

    const fireStorePath = `imports`;
    const queryWhere: WhereClause[] = [];

    if (!showArchived) {
      queryWhere.push({
        fieldPath: 'archived',
        opStr: '==',
        value: false,
      });
    }

    const limit = BATCH_SIZE * batches;

    const unsubscribe = addFirestoreCollectionListener({
      fireStorePath,
      queryWhere,
      orderBy: {
        fieldPath: 'createdAt',
        direction: 'desc',
      },
      limit,
      callback: (data) => {
        const _imports = data as KaeplaImport[];
        setImports(_imports);
        setLoading(false);
      },
    });
    return () => {
      unsubscribe();
    };
  }, [batches, kaeplaAssignment, showArchived]);

  const increaseBatchesDebounced = useDebouncedCallback(() => {
    setLoading(true);
    setBatches((_batches) => _batches + 1);
  }, 300);

  useEffect(() => {
    const main = document.querySelector('main');
    if (!main) return;
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } = main;
      if (scrollTop + clientHeight >= scrollHeight - 20) {
        increaseBatchesDebounced();
      }
    };

    main.addEventListener('scroll', handleScroll);
    return () => {
      main.removeEventListener('scroll', handleScroll);
    };
  }, [increaseBatchesDebounced]);

  return (
    <Box>
      <List sx={{ bgcolor: 'background.paper' }}>
        {imports.map((dataImport) => {
          return <ImportItem key={dataImport.id} dataImport={dataImport} />;
        })}
      </List>
      {loading && (
        <Box>
          <LinearProgress />
          Loading...
        </Box>
      )}
    </Box>
  );
};
