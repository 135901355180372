import { getApp } from 'firebase/app';
import { DocumentData, getFirestore, doc, setDoc } from 'firebase/firestore';

import { KaeplaGridSetStored } from '../kaeplaTypes/Application/KaeplaGridSetStored.js';
import { KaeplaProject } from '../kaeplaTypes/Application/KaeplaProject.js';

interface Options {
  project: KaeplaProject;
  gridSet: KaeplaGridSetStored;
}

export const updateGridSet = async ({ project, gridSet }: Options) => {
  if (!project.id || !gridSet.id) return;
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `projects/${project.id}/gridSets/${gridSet.id}`;
  const documentReference = doc(firestoreDatabase, fireStorePath);
  await setDoc(documentReference, gridSet as DocumentData);
};
