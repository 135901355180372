import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OpenProjectIcon from '@mui/icons-material/OpenInNewOutlined';
import AutomatedIcon from '@mui/icons-material/ScheduleOutlined.js';
import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  Link,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import TimeAgo from 'react-timeago';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { AiThreadItems } from './AiThreadItems';
import { getCustomer } from '../../../../../services/firestore/getCustomer';
import { getProjectsFromProjectIds } from '../../../../../services/firestore/getProjectFromProjectIds';
import { KaeplaCustomer, KaeplaProject, Thread } from '../../../../../services/kaeplaTypes';
import { knownCustomersState } from '../../../../../services/recoil/nonpersistent/knownCustomersState';
import { knownProjectsState } from '../../../../../services/recoil/nonpersistent/knownProjectsState';
import { projectState } from '../../../../../services/recoil/nonpersistent/projectState';
import { knownUsersState } from '../../../../../services/recoil/persistent/knownUsersState';
import { ExpandMore } from '../../../../features/ExpandMore';
import { UserAvatar } from '../../../../features/UserAvatar';
import { getDuration } from '../../../../helpers/getDuration';

interface Options {
  thread: Thread;
  setOnlyThread: React.Dispatch<React.SetStateAction<string | undefined>>;
  showMarkdown: boolean;
}

const ellipsis = {
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};

export const threadRuntime = (thread: Thread) => {
  let duration = '';
  if (thread.createdAt) {
    duration = getDuration(thread.createdAt, thread.updatedAt);
  } else {
    return 'n.a.';
  }
  return `⟷ ${duration}`;
};

export const AiThread = ({ thread, setOnlyThread, showMarkdown }: Options) => {
  const theme = useTheme();
  const knownUsers = useRecoilValue(knownUsersState);
  const knownProjects = useRecoilValue(knownProjectsState);
  const knownCustomers = useRecoilValue(knownCustomersState);
  const setProject = useSetRecoilState(projectState);
  const user = knownUsers.find((k) => k.uid === thread.uid);

  const [toggleExpand, setToggleExpand] = useState(false);
  const [threadProject, setThreadProject] = useState<KaeplaProject>();
  const [threadCustomer, setThreadCustomer] = useState<KaeplaCustomer>();

  const handleExpandClick = () => {
    setToggleExpand(!toggleExpand);
  };

  useEffect(() => {
    if (!thread.projectId) return;
    const knownProject = knownProjects.find((project) => project.id === thread.projectId);
    if (knownProject) {
      setThreadProject(knownProject);

      const knownCustomer = knownCustomers.find(
        (customer) => customer.id === knownProject.customerId,
      );
      if (knownCustomer) {
        setThreadCustomer(knownCustomer);
        return;
      }
      return;
    }

    const getUnknownProject = async () => {
      const unknownProjects = await getProjectsFromProjectIds({
        projectIds: [thread.projectId],
      });
      if (!unknownProjects) return;
      const _project = unknownProjects[0];
      const unknownCustomer = await getCustomer({ id: _project.customerId });
      if (unknownCustomer) {
        setThreadCustomer(unknownCustomer);
      }
      setThreadProject(_project);
    };

    void getUnknownProject();
  }, [thread.projectId, knownCustomers, knownProjects]);

  return (
    <Box
      sx={{
        borderLeftWidth: 4,
        borderLeftStyle: toggleExpand ? 'solid' : 'none',
        borderLeftColor: theme.palette.primary.light,
        my: 1,
      }}
    >
      <Grid container spacing={1}>
        <Grid item md={11} sm={11} xs={11}>
          <Stack direction="row" gap={2} alignItems="center" sx={{ px: 1 }}>
            {user ? (
              <Box>
                <UserAvatar user={user} size={25} />
              </Box>
            ) : (
              <AutomatedIcon fontSize="medium" />
            )}
            <Avatar
              data-testid="project-avatar"
              sx={{
                width: 22,
                height: 22,
                bgcolor: 'transparent',
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor:
                  theme.palette.mode === 'light'
                    ? theme.palette.grey[300]
                    : theme.palette.grey[800],
              }}
              variant="square"
            >
              {threadCustomer && (
                <img width="16" alt={threadCustomer.name} src={threadCustomer.iconBase64} />
              )}
            </Avatar>
            {threadCustomer?.name && (
              <Typography
                sx={{
                  width: 50,
                  ...ellipsis,
                }}
              >
                {threadCustomer.name}
              </Typography>
            )}
            {threadProject?.name && (
              <Typography
                sx={{
                  width: 120,
                  fontSize: '0.8rem',
                  ...ellipsis,
                }}
              >
                {threadProject?.name}
              </Typography>
            )}
            <Link
              sx={{
                width: 220,
                fontSize: '0.8rem',
                ...ellipsis,
              }}
              variant="caption"
              href="#"
              onClick={(event) => {
                setOnlyThread(thread.id);
                event.preventDefault();
              }}
            >
              {thread?.id}
            </Link>
            <Stack direction="row" gap={2} alignItems="flex-start">
              <Box
                component="span"
                sx={{
                  width: 120,
                  ...ellipsis,
                }}
              >
                {'⟼ '}
                <TimeAgo date={thread.createdAt.toDate()} max={Number.MAX_SAFE_INTEGER} />
              </Box>
              <Box
                component="span"
                sx={{
                  width: 120,
                  ...ellipsis,
                }}
              >
                {threadRuntime(thread)}
              </Box>
            </Stack>
            <IconButton
              size="small"
              onClick={(event) => {
                if (!threadProject) return;
                setProject(threadProject);
                const url = window.location.origin + `/SyncData/${thread.projectId}/${thread.id}`;
                window.open(url, '_blank', 'noreferrer');
                event.preventDefault();
              }}
            >
              <OpenProjectIcon sx={{ fontSize: 16 }} />
            </IconButton>
            <Box sx={{ flexGrow: 1 }} />
          </Stack>
        </Grid>
        <Grid item md={1} sm={1} xs={1} container justifyContent="flex-end" alignItems="flex-end">
          <Box sx={{ px: 1 }}>
            <ExpandMore
              expand={toggleExpand}
              aria-expanded={toggleExpand}
              aria-label="toggle expand"
              edge="end"
              sx={{ mr: -0.8 }}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                handleExpandClick();
              }}
            >
              <ExpandMoreIcon fontSize="small" />
            </ExpandMore>
          </Box>
        </Grid>
        {toggleExpand && (
          <>
            <Grid item md={12} sm={12} xs={12}>
              <Divider sx={{ borderStyle: 'dashed' }} />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <AiThreadItems threadId={thread.id} showMarkdown={showMarkdown} />
            </Grid>
          </>
        )}
        <Grid item md={12} sm={12} xs={12}>
          <Divider />
        </Grid>
      </Grid>
    </Box>
  );
};
