import { Avatar, Box, Tooltip } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import Image from 'mui-image';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import { avatarAppearDuration } from '../Screens/defaults.js';

interface Options {
  icon?: string;
  title: string;
  small?: boolean;
  medium?: boolean;
  id: string;
}

export const ResellerAvatar = ({ icon, title, small, medium, id }: Options) => {
  const navigate = useNavigate();
  const theme = useTheme();
  let content: ReactElement = <Box>{title.slice(0, 2)}</Box>;
  if (icon) {
    content = (
      <Image width="70%" height="70%" alt={title} src={icon} duration={avatarAppearDuration} />
    );
  }
  let size = 40;
  if (medium) {
    size = 30;
  }
  if (small) {
    size = 23;
  }

  return (
    <Tooltip title={id}>
      <Avatar
        component="span"
        sx={{
          display: 'inline-flex',
          cursor: 'pointer',
          boxShadow: theme.shadows[1],
          bgcolor: '#fff',
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: grey[400],
          width: size,
          height: size,
        }}
        variant="rounded"
        onClick={() => {
          navigate(`/Customers/${id}`);
        }}
      >
        {content}
      </Avatar>
    </Tooltip>
  );
};
