import { getApp } from 'firebase/app';
import { deleteDoc, doc, getFirestore } from 'firebase/firestore';

interface Options {
  assignmentId: string;
}

export const removeCustomerAssignment = async ({ assignmentId }: Options) => {
  if (!assignmentId) return;
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `customerAssignments/${assignmentId}`;
  const firestoreDocumentReference = doc(firestoreDatabase, fireStorePath);
  await deleteDoc(firestoreDocumentReference);
};
