import { AiMetaLogFilters } from '../../../../../services/kaeplaTypes/importer/AiMetaLogFilters';

export const isDefaultFiltersSet = (metaLogFilters: AiMetaLogFilters) => {
  if (
    metaLogFilters.needsResolution === true &&
    metaLogFilters.downvoteCount === 1 &&
    Object.keys(metaLogFilters).length === 2
  ) {
    return true;
  }
  return false;
};
