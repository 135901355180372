import { getApp } from 'firebase/app';
import { doc, getFirestore, updateDoc } from 'firebase/firestore';

import { AiQuestionLog } from '../kaeplaTypes/importer/AiQuestionLog';

export const updateAiQuestionLog = async (aiQuestionLog: Partial<AiQuestionLog>) => {
  if (!aiQuestionLog.id) return;
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `aiQuestionLogs/${aiQuestionLog.id}`;
  const firestoreDocumentReference = doc(firestoreDatabase, fireStorePath);
  await updateDoc(firestoreDocumentReference, aiQuestionLog);
};
