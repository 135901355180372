import { Grid } from '@mui/material';
import { useRecoilValue } from 'recoil';

import { erpOrdersImportsState } from '../../../../services/recoil/nonpersistent/dataSourceErpOrdersImportState';
import { ihsVehicleImportsState } from '../../../../services/recoil/nonpersistent/dataSourceIhsVehicleImportsState';
import { dataSourceState } from '../../../../services/recoil/nonpersistent/dataSourceState';
import { DataImportSourceCard } from '../components/DataSourceImportCard';

interface IOptions {
  setViewDetails: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ListImportsForDataSource = ({ setViewDetails }: IOptions) => {
  const ihsVehicleImports = useRecoilValue(ihsVehicleImportsState);
  const erpOrderImports = useRecoilValue(erpOrdersImportsState);
  const selectedDataSource = useRecoilValue(dataSourceState);

  if (selectedDataSource.key === 'ihsVehicles') {
    return (
      <Grid container spacing={2}>
        {[...ihsVehicleImports].reverse().map((dataSourceImport) => (
          <DataImportSourceCard
            key={dataSourceImport.id}
            dataSourceImport={dataSourceImport}
            setViewDetails={setViewDetails}
          />
        ))}
      </Grid>
    );
  } else if (selectedDataSource.key === 'erpOrders') {
    return (
      <Grid container spacing={2}>
        {[...erpOrderImports].reverse().map((dataSourceImport) => (
          <DataImportSourceCard
            key={dataSourceImport.id}
            dataSourceImport={dataSourceImport}
            setViewDetails={setViewDetails}
          />
        ))}
      </Grid>
    );
  }
  return null;
};
