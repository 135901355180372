import { Box, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import pluralize from 'pluralize';
import TimeAgo from 'react-timeago';
import { useRecoilValue } from 'recoil';

import { dataSourceImportState } from '../../../../services/recoil/nonpersistent/dataSourceImportState';
import { dataSourceState } from '../../../../services/recoil/nonpersistent/dataSourceState';

export const DataSourceImportHeaderSmall = () => {
  const selectedDataSource = useRecoilValue(dataSourceState);
  const selectedDataSourceImport = useRecoilValue(dataSourceImportState);
  const count = (selectedDataSourceImport.matched ?? 0) + (selectedDataSourceImport.unmatched ?? 0);
  return (
    <Stack alignItems="baseline" direction="row" spacing={1} useFlexGap>
      <Typography variant="h5" color="textPrimary">
        {selectedDataSource.label}
      </Typography>
      <Typography variant="body2">
        {selectedDataSourceImport.createdAt.toDate().toDateString()}
        {' @ '}
        {DateTime.fromJSDate(selectedDataSourceImport.createdAt.toDate()).toFormat('hh:mm')}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="body2" color="textSecondary">
          {count} {pluralize(selectedDataSource.itemLabel, count)}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {selectedDataSourceImport.archived ? 'Archived' : ''}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="body2" color="textSecondary">
          <TimeAgo
            date={selectedDataSourceImport.createdAt.toDate()}
            max={Number.MAX_SAFE_INTEGER}
          />
        </Typography>
      </Box>
    </Stack>
  );
};
