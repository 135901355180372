import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { useAuth } from '../../../../../../AuthReactProvider';
import { createGridSet } from '../../../../../../services/firestore/createGridSet';
import { updateGridSet } from '../../../../../../services/firestore/updateGridSet';
import { KaeplaGridSetStored } from '../../../../../../services/kaeplaTypes/Application/KaeplaGridSetStored';
import { dataGridSettings } from '../../../../../../services/recoil/nonpersistent/dataGridSets';
import { projectState } from '../../../../../../services/recoil/nonpersistent/projectState';

interface Options {
  openSaveDialog: boolean;
  setOpenSaveDialog: React.Dispatch<React.SetStateAction<boolean>>;
  selectedGridSet: KaeplaGridSetStored | null;
  setSelectedGridSet: React.Dispatch<React.SetStateAction<KaeplaGridSetStored | null>>;
}

export const SaveGridSet = ({
  openSaveDialog,
  setOpenSaveDialog,
  selectedGridSet,
  setSelectedGridSet,
}: Options) => {
  const { kaeplaUser } = useAuth();
  const project = useRecoilValue(projectState);
  const gridSettings = useRecoilValue(dataGridSettings);
  const [gridSetName, setGridSetName] = useState<string>(selectedGridSet?.name ?? '');
  const [saveType, setSaveType] = useState<string>('');
  const handleClose = () => {
    setOpenSaveDialog(false);
  };

  useEffect(() => {
    if (selectedGridSet) {
      setGridSetName(selectedGridSet.name);
    }
  }, [selectedGridSet]);

  return (
    <Dialog
      open={openSaveDialog}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          if (!kaeplaUser?.uid) return;
          const setName = event.currentTarget.elements as unknown as Record<
            string,
            Record<string, string>
          >;
          if (saveType === 'update' && selectedGridSet?.id) {
            void updateGridSet({
              project,
              gridSet: {
                ...selectedGridSet,
                name: setName.setName.value,
                gridSettingsStringified: JSON.stringify(gridSettings),
              },
            });
          } else {
            const saveAndSetNewGridSet = async () => {
              const newGridSet = await createGridSet({
                project,
                createdBy: kaeplaUser.uid,
                name: setName.setName.value,
                gridSettingsStringified: JSON.stringify(gridSettings),
              });
              setSelectedGridSet(newGridSet);
            };
            void saveAndSetNewGridSet();
          }
          handleClose();
        },
      }}
    >
      <DialogTitle>Save Grid Set</DialogTitle>
      <DialogContent>
        {!selectedGridSet && (
          <DialogContentText>
            You can save named sets of data grid configurations.
          </DialogContentText>
        )}
        {selectedGridSet && (
          <DialogContentText>
            You can save the current set of data grid configurations under a new name or update the
            existing set (including it's name).
          </DialogContentText>
        )}
        <TextField
          autoFocus
          required
          value={gridSetName}
          margin="dense"
          id="setName"
          name="setName"
          label="Set name"
          type="text"
          fullWidth
          variant="standard"
          onChange={(event) => {
            setGridSetName(event.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        {!selectedGridSet && (
          <Button type="submit" variant="contained">
            Save
          </Button>
        )}
        {selectedGridSet && (
          <Button
            type="submit"
            variant="contained"
            onClick={() => {
              setSaveType('update');
            }}
          >
            Update
          </Button>
        )}
        {selectedGridSet && (
          <Button
            type="submit"
            variant="outlined"
            onClick={() => {
              setSaveType('new');
            }}
          >
            Save new
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
