import { SetterOrUpdater, useRecoilState, useRecoilValue } from 'recoil';

import { dataSourceOrdersState } from '../../../../services/recoil/nonpersistent/dataSourceOrdersState';
import { dataSourceState } from '../../../../services/recoil/nonpersistent/dataSourceState';
import { dataSourceVehiclesState } from '../../../../services/recoil/nonpersistent/dataSourceVehiclesState';
import { SelectedDataSourceImport } from '../SelectedDataSourceImport';
import { ErpOrder } from '../types/ErpOrder';
import { IhsVehicleFull } from '../types/IhsVehicleFull';

interface SOptions {
  fullScreen: boolean;
  setFullScreen: React.Dispatch<React.SetStateAction<boolean>>;
  setViewDetails: React.Dispatch<React.SetStateAction<boolean>>;
  setManageAiRules: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SelectedDataSourceImportForDataSource = ({
  setViewDetails,
  fullScreen,
  setFullScreen,
  setManageAiRules,
}: SOptions) => {
  const selectedDataSource = useRecoilValue(dataSourceState);
  const [vehicles, setVehicles] = useRecoilState(dataSourceVehiclesState);
  const [orders, setOrders] = useRecoilState(dataSourceOrdersState);

  if (selectedDataSource.key === 'ihsVehicles') {
    return (
      <SelectedDataSourceImport
        fullScreen={fullScreen}
        setFullScreen={setFullScreen}
        setViewDetails={setViewDetails}
        setManageAiRules={setManageAiRules}
        items={vehicles}
        setItems={setVehicles as SetterOrUpdater<(IhsVehicleFull | ErpOrder)[]>}
      />
    );
  } else if (selectedDataSource.key === 'erpOrders') {
    return (
      <SelectedDataSourceImport
        fullScreen={fullScreen}
        setFullScreen={setFullScreen}
        setViewDetails={setViewDetails}
        setManageAiRules={setManageAiRules}
        items={orders}
        setItems={setOrders as SetterOrUpdater<(IhsVehicleFull | ErpOrder)[]>}
      />
    );
  }
  return null;
};
