import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import { useRecoilState } from 'recoil';

import { updateCustomer } from '../../../../services/firestore/updateCustomer.js';
import { KaeplaCustomer } from '../../../../services/kaeplaTypes/Application/KaeplaCustomer.js';
import { KaeplaReseller } from '../../../../services/kaeplaTypes/Application/KaeplaReseller.js';
import { knownCustomersState } from '../../../../services/recoil/nonpersistent/knownCustomersState.js';
import { updateKnownCustomer } from '../../../helpers/updateKnownCustomer.js';

interface DDOptions {
  openRename: boolean;
  handleCloseRename: () => void;
  customer: KaeplaCustomer;
  reseller?: KaeplaReseller;
}
export const CustomerRenameDialog = ({
  openRename,
  handleCloseRename,
  customer,
}: // reseller,
DDOptions) => {
  const [knownCustomers, setKnownCustomers] = useRecoilState(knownCustomersState);
  const [name, setName] = useState(customer.name);
  const [appName, setAppName] = useState(customer.appName);

  return (
    <Dialog open={openRename} onClose={handleCloseRename}>
      <DialogTitle data-testid="dialog-title" sx={{ minWidth: 400 }}>
        Rename customer?
        <IconButton
          size="small"
          data-testid="button-cancel-rename"
          sx={{ position: 'absolute', top: 0, right: 0 }}
          onClick={handleCloseRename}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          variant="standard"
          label="customer name"
          inputProps={{ 'data-testid': 'customer-name-input' }}
          value={name}
          onChange={(event) => {
            setName(event.target.value);
          }}
          fullWidth
        />
      </DialogContent>
      <DialogContent>
        <TextField
          variant="standard"
          label="application name"
          inputProps={{ 'data-testid': 'application-name-input' }}
          value={appName}
          onChange={(event) => {
            setAppName(event.target.value);
          }}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          variant="contained"
          color="error"
          data-testid="button-rename"
          onClick={() => {
            handleCloseRename();
            const newCustomer = { ...customer };
            newCustomer.name = name;
            newCustomer.appName = appName;
            updateKnownCustomer({ customer: newCustomer, knownCustomers, setKnownCustomers });
            void updateCustomer({ customer: newCustomer });
          }}
        >
          rename
        </Button>
      </DialogActions>
    </Dialog>
  );
};
