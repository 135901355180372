import { ChartData, Point } from 'chart.js';

import {
  KaeplaPerspective,
  KaeplaTargetsFigure,
  MatrixTimeseries,
} from '../../../../../services/kaeplaTypes';
import { MatrixTimeSlice } from '../../../../../services/kaeplaTypes/MatrixTimeSlice';
import { snapshotColor } from '../../../../Theme/colors';
import {
  matrixDataColor,
  matrixDataColorLight,
  simulationDataColor,
  simulationDataColorLight,
  simulationDataColorShade,
  simulationDataLowerLight,
  simulationDataUpperLight,
  snapshotDataColorLight,
  targetsDataColor,
  targetsDataColorLight,
} from '../../../defaults';

interface ChartDataOptions {
  timeSeries: MatrixTimeseries;
  perspective: Partial<KaeplaPerspective>;
  simulationPreview: boolean;
  previewColor: string;
  previewColorLight: string;
  perspectiveTargetFigures: KaeplaTargetsFigure[];
  timeSlice: MatrixTimeSlice;
}

export const getChartDataForTimeseries = ({
  timeSeries,
  perspective,
  simulationPreview,
  previewColor,
  previewColorLight,
  perspectiveTargetFigures,
  timeSlice,
}: ChartDataOptions) => {
  const dataPoints = timeSeries.timeseries.map((d) => ({
    x: d.date as unknown as number,
    y: d.value,
  }));

  const dataPointsSnapshot = timeSeries?.timeseries
    .filter((d) => !!d.snapshotValue)
    .map((d) => ({
      x: d.date as unknown as number,
      y: d.snapshotValue!,
    }));

  const dataPointsSimulation = timeSeries?.timeseries
    .filter((d) => !!d.simulationValue)
    .map((d) => ({
      x: d.date as unknown as number,
      y: d.simulationValue!,
    }));

  const dataPointsSimulationUpperThresh = [...dataPointsSimulation].map((d) => {
    const dataX = dataPoints.find((dd) => dd.x === d.x);
    const _diff = Math.abs((dataX?.y ?? 0) - d.y);
    const thresh = d.y + _diff * 0.5;
    const point = {
      x: d.x as unknown as number,
      y: thresh,
    };
    return point;
  });

  const dataPointSimulationLowerThresh = [...dataPointsSimulation].map((d) => {
    const dataX = dataPoints.find((dd) => dd.x === d.x);
    const _diff = Math.abs((dataX?.y ?? 0) - d.y);
    const thresh = d.y - _diff * 0.25;
    const point = {
      x: d.x as unknown as number,
      y: thresh,
    };
    return point;
  });
  // console.log("dataPointsSimulation-->", dataPointsSimulation);
  const label = perspective?.aggregationOverTimeName ?? 'n/a';

  const datasets = [
    {
      label,
      data: dataPoints,
      borderColor: matrixDataColor,
      backgroundColor: matrixDataColorLight,
    },
    {
      label: `${label} Simulation Upper Threshold`,
      data: dataPointsSimulationUpperThresh,
      borderColor: simulationPreview ? previewColor : simulationDataUpperLight,
      backgroundColor: simulationPreview ? previewColorLight : simulationDataColorShade,
      pointRadius: 0,
      fill: '+2',
    },
    {
      label: `${label} Simulation`,
      data: dataPointsSimulation,
      borderColor: simulationPreview ? previewColor : simulationDataColor,
      backgroundColor: simulationPreview ? previewColorLight : simulationDataColorLight,
    },
    {
      label: `${label} Simulation Lower Threshold`,
      data: dataPointSimulationLowerThresh,
      borderColor: simulationPreview ? previewColor : simulationDataLowerLight,
      backgroundColor: simulationPreview ? previewColorLight : simulationDataColorShade,
      pointRadius: 0,
    },
    {
      label: `${label} Snapshot`,
      data: dataPointsSnapshot,
      borderColor: snapshotColor,
      backgroundColor: snapshotDataColorLight,
    },
  ];

  const targetDataPoints = perspectiveTargetFigures.map((d) => ({
    x: d.validAsOf as unknown as number,
    y: d.absoluteValue,
  }));

  if (timeSlice === MatrixTimeSlice.year && targetDataPoints.length > 0) {
    datasets.push({
      label: `${label} Targets`,
      data: targetDataPoints,
      borderColor: targetsDataColor,
      backgroundColor: targetsDataColorLight,
    });
  }

  const _data: ChartData<'line', (number | Point | null)[], unknown> = {
    datasets,
  };

  return _data;
};
