import { Box, Grid } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

import { KaeplaSimulation } from '../../../../services/kaeplaTypes/Application/KaeplaSimulation.js';
import { KaeplaSimulationRuleset } from '../../../../services/kaeplaTypes/Application/KaeplaSimulationRuleset.js';
import { SimulationRulesetFilters } from '../../../features/Filters/SimulationRulesetFilters.js';

interface Options {
  simulation: KaeplaSimulation;
  ruleset: KaeplaSimulationRuleset;
  setPreview: Dispatch<SetStateAction<boolean>>;
}

export const SimulationRulesetFilter = ({ ruleset, simulation, setPreview }: Options) => {
  return (
    <Box m={2}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <SimulationRulesetFilters
            ruleset={ruleset}
            simulation={simulation}
            filterHasChangedCallback={(hasChanged) => {
              if (hasChanged) {
                setPreview(true);
              }
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
