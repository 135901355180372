import { useRecoilValue } from 'recoil';

import { UserAvatar } from './UserAvatar.js';
import { knownUsersState } from '../../services/recoil/persistent/knownUsersState.js';

interface Properties {
  uid: string;
}
export const SelectAvatar = ({ uid }: Properties) => {
  const knownUsers = useRecoilValue(knownUsersState);
  const owner = knownUsers.find((user) => user.uid === uid);
  if (owner) {
    return <UserAvatar user={owner} size={25} />;
  }
  return null;
};
