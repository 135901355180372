import SucceededIcon from '@mui/icons-material/CheckCircle.js';
import FailedIcon from '@mui/icons-material/ErrorOutlineRounded.js';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OpenProjectIcon from '@mui/icons-material/OpenInNewOutlined';
import AdminIcon from '@mui/icons-material/Person2Outlined.js';
import AutomatedIcon from '@mui/icons-material/ScheduleOutlined.js';
import SyncIcon from '@mui/icons-material/SyncOutlined';
import {
  Stack,
  Typography,
  Box,
  CircularProgress,
  Button,
  Divider,
  useTheme,
  Link,
  Grid,
  useMediaQuery,
  IconButton,
  Avatar,
} from '@mui/material';
import copy from 'copy-to-clipboard';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import TimeAgo from 'react-timeago';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useAuth } from '../../../../AuthReactProvider';
import { deltaImportOnCall } from '../../../../services/api/deltaImport';
import { fullImportOnCall } from '../../../../services/api/fullImport';
import { createEvent } from '../../../../services/firestore/createEvent';
import { getCustomer } from '../../../../services/firestore/getCustomer';
import { getProjectsFromProjectIds } from '../../../../services/firestore/getProjectFromProjectIds';
import { requestCancelImport } from '../../../../services/firestore/requestCancelImport';
import {
  KaeplaCustomer,
  KaeplaEventType,
  KaeplaFunctionGroup,
  KaeplaImport,
  KaeplaImportLog,
  KaeplaOpsUpdateStatus,
  KaeplaProject,
} from '../../../../services/kaeplaTypes';
import { KaeplaImportProgressStatus } from '../../../../services/kaeplaTypes/importer/KaeplaImportProgress';
import { knownCustomersState } from '../../../../services/recoil/nonpersistent/knownCustomersState';
import { knownProjectsState } from '../../../../services/recoil/nonpersistent/knownProjectsState';
import { projectState } from '../../../../services/recoil/nonpersistent/projectState';
import { knownUsersState } from '../../../../services/recoil/persistent/knownUsersState';
import { ExpandMore } from '../../../features/ExpandMore';
import { UserAvatar } from '../../../features/UserAvatar';
import { LogTerminal } from '../../SyncData/ImportLog/ImportLog';
import { isImportDead } from '../../SyncData/ImportLog/isImportDead';
import { isImportDeadOrFinished } from '../../SyncData/ImportLog/isImportDeadOrFinished';
import { dataImportTimeToGo } from '../../SyncData/helpers/dataIMportTimeToGo';
import { dataImportEndedAt } from '../../SyncData/helpers/dataImportEndedAt';
import { dataImportRuntime } from '../../SyncData/helpers/dataImportRuntime';

interface Options {
  dataImport: KaeplaImport;
}

const typeMap = {
  full: '⬤',
  delta: 'Δ',
};

export const ImportItem = ({ dataImport }: Options) => {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const { kaeplaUser } = useAuth();
  const knownUsers = useRecoilValue(knownUsersState);
  const knownProjects = useRecoilValue(knownProjectsState);
  const knownCustomers = useRecoilValue(knownCustomersState);
  const setProject = useSetRecoilState(projectState);
  const [importProject, setImportProject] = useState<KaeplaProject>();
  const [importCustomer, setImportCustomer] = useState<KaeplaCustomer>();
  const [toggleExpand, setToggleExpand] = useState(false);
  const [updateRequested, setUpdateRequested] = useState<DateTime>();
  const [logExpanded, setLogExpanded] = useState(false);
  const [lastLog, setLastLog] = useState<KaeplaImportLog>();

  const handleExpandClick = () => {
    setToggleExpand(!toggleExpand);
  };

  const handleLogExpandClick = () => {
    setLogExpanded(!logExpanded);
  };

  useEffect(() => {
    if (!dataImport.projectId) return;
    const knownProject = knownProjects.find((project) => project.id === dataImport.projectId);
    if (knownProject) {
      setImportProject(knownProject);

      const knownCustomer = knownCustomers.find(
        (customer) => customer.id === knownProject.customerId,
      );
      if (knownCustomer) {
        setImportCustomer(knownCustomer);
        return;
      }
      return;
    }

    const getUnknownProject = async () => {
      const unknownProjects = await getProjectsFromProjectIds({
        projectIds: [dataImport.projectId],
      });
      if (!unknownProjects) return;
      const _project = unknownProjects[0];
      const unknownCustomer = await getCustomer({ id: _project.customerId });
      if (unknownCustomer) {
        setImportCustomer(unknownCustomer);
        return;
      }
      setImportProject(_project);
    };

    void getUnknownProject();
  }, [dataImport.projectId, knownCustomers, knownProjects]);

  const isUpdateLocked = () => {
    if (!importProject?.importConfigurationId) return true;
    if (importProject?.currentImportId) {
      return true;
    }
    if (updateRequested) {
      return updateRequested > DateTime.now().minus({ minutes: 3 });
    }
  };

  const deltaUpdate = () => {
    if (!kaeplaUser) return;
    if (!importProject) return;
    deltaImportOnCall({
      params: {
        projectId: importProject.id,
      },
    });
    void createEvent({
      uid: kaeplaUser.uid,
      eventType: KaeplaEventType.IMPORT_START_IMPORT,
      functionGroup: KaeplaFunctionGroup.IMPORT,
      project: importProject,
    });
  };

  const fullUpdate = () => {
    if (!kaeplaUser) return;
    if (!importProject) return;
    fullImportOnCall({
      params: {
        projectId: importProject.id,
      },
    });
    void createEvent({
      uid: kaeplaUser.uid,
      eventType: KaeplaEventType.IMPORT_START_FULL_IMPORT,
      functionGroup: KaeplaFunctionGroup.IMPORT,
      project: importProject,
    });
  };

  const user = knownUsers.find((k) => k.uid === dataImport.createdBy);

  if (!dataImport.projectId) return null;

  const cancelUpdate = () => {
    if (!dataImport) return;
    void requestCancelImport({ dataImport });
  };

  return (
    <Box
      sx={{
        borderLeftWidth: 4,
        borderLeftStyle: toggleExpand ? 'solid' : 'none',
        borderLeftColor: theme.palette.primary.light,
        my: 1,
      }}
    >
      <Grid container spacing={1}>
        <Grid item md={12} sm={12} xs={12}>
          <Stack direction="row" gap={2} alignItems="center" sx={{ px: 1 }}>
            {user ? (
              <Box>
                <UserAvatar user={user} size={25} />
              </Box>
            ) : dataImport.createdBy === 'kaepla-admin' ? (
              <AdminIcon fontSize="medium" />
            ) : (
              <AutomatedIcon fontSize="medium" />
            )}
            <Avatar
              data-testid="project-avatar"
              sx={{
                width: 22,
                height: 22,
                bgcolor: 'transparent',
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor:
                  theme.palette.mode === 'light'
                    ? theme.palette.grey[300]
                    : theme.palette.grey[800],
              }}
              variant="square"
            >
              {importCustomer && (
                <img width="16" alt={importCustomer.name} src={importCustomer.iconBase64} />
              )}
            </Avatar>
            {importCustomer?.name && (
              <Typography sx={{ fontSize: '0.8rem' }}>{importCustomer.name}</Typography>
            )}
            <Typography sx={{ fontSize: '1.1rem' }}>{importProject?.name}</Typography>
            <IconButton
              size="small"
              onClick={(event) => {
                if (!importProject) return;
                setProject(importProject);
                const url =
                  window.location.origin + `/SyncData/${dataImport.projectId}/${dataImport.id}`;
                window.open(url, '_blank', 'noreferrer');
                event.preventDefault();
              }}
            >
              <OpenProjectIcon sx={{ fontSize: 16 }} />
            </IconButton>
            <Box sx={{ flexGrow: 1 }} />
            <Box component="span" sx={{ color: theme.palette.info.main, fontWeight: 'bold' }}>
              {dataImport.ingestedRecordsCount}
            </Box>
            <Box
              component="span"
              sx={{ fontSize: 18, p: 1, mr: -0.5, width: 35, textAlign: 'center' }}
            >
              {typeMap[dataImport.updateType]}
            </Box>
          </Stack>
        </Grid>
        <Grid item md={11} sm={11} xs={11}>
          <Stack direction="row" alignItems="center" gap={2} sx={{ px: 1 }}>
            {isImportDead(dataImport) && <FailedIcon color="error" />}
            {dataImport.updateStatus === KaeplaOpsUpdateStatus.finished && (
              <SucceededIcon color="success" />
            )}
            {!isImportDeadOrFinished(dataImport) && (
              <Stack direction="row" alignItems="center" gap={1}>
                <CircularProgress size={16} sx={{ mr: 1 }} />
                <Button
                  size="small"
                  onClick={(event) => {
                    cancelUpdate();
                    event.stopPropagation();
                  }}
                  disabled={!dataImport}
                >
                  cancel
                </Button>
              </Stack>
            )}
            <Stack direction={smUp ? 'row' : 'column'} gap={smUp ? 2 : 1} alignItems="flex-start">
              <Box component="span">
                {'⟼ '}
                <TimeAgo date={dataImport.createdAt.toDate()} max={Number.MAX_SAFE_INTEGER} />
              </Box>
              <Box component="span">{dataImportRuntime(dataImport)}</Box>
              <Box component="span">{dataImportEndedAt(dataImport)}</Box>
              <Box component="span">{dataImportTimeToGo(dataImport, importProject)}</Box>
            </Stack>
          </Stack>
        </Grid>
        <Grid item md={1} sm={1} xs={1} container justifyContent="flex-end" alignItems="flex-end">
          <Box sx={{ px: 1 }}>
            <ExpandMore
              expand={toggleExpand}
              aria-expanded={toggleExpand}
              aria-label="toggle expand"
              edge="end"
              sx={{ mr: -0.8 }}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                handleExpandClick();
              }}
            >
              <ExpandMoreIcon fontSize="small" />
            </ExpandMore>
          </Box>
        </Grid>
        {toggleExpand && (
          <>
            <Grid item md={12} sm={12} xs={12}>
              <Divider sx={{ borderStyle: 'dashed' }} />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <Box sx={{ px: 1 }}>
                created at:{' ⟼ '}
                {dataImport.createdAt &&
                  DateTime.fromMillis(dataImport.createdAt.toMillis())
                    .setLocale('en')
                    .toLocaleString({
                      ...DateTime.DATETIME_MED_WITH_SECONDS,
                    })}
              </Box>
            </Grid>
            <Grid
              item
              md={6}
              sm={6}
              xs={12}
              container
              justifyContent={smUp ? 'flex-end' : 'flex-start'}
            >
              <Box component="span" sx={{ px: 1 }}>
                last updated at:
                {' ⟻ '}
                {dataImport.updatedAt &&
                  DateTime.fromMillis(dataImport.updatedAt.toMillis())
                    .setLocale('en')
                    .toLocaleString({
                      ...DateTime.DATETIME_MED_WITH_SECONDS,
                    })}
              </Box>
            </Grid>
            <Grid item md={6} sm={8} xs={12}>
              <Box sx={{ px: 1 }}>
                projectId:{' '}
                <Link
                  sx={{ opacity: 0.5, cursor: 'pointer' }}
                  variant="caption"
                  onClick={(event) => {
                    copy(dataImport.projectId);
                    event.stopPropagation();
                  }}
                >
                  {dataImport.projectId}
                </Link>
              </Box>
            </Grid>
            <Grid
              item
              md={6}
              sm={4}
              xs={12}
              container
              justifyContent={smUp ? 'flex-end' : 'flex-start'}
            >
              <Box component="span" sx={{ px: 1 }}>
                importId:{' '}
                <Link
                  sx={{ opacity: 0.5, cursor: 'pointer' }}
                  variant="caption"
                  onClick={(event) => {
                    copy(dataImport.id);
                    event.stopPropagation();
                  }}
                >
                  {dataImport.id}
                </Link>
              </Box>
            </Grid>
            <Grid item md={8} sm={8} xs={6}>
              <Stack
                direction={mdUp ? 'row' : 'column'}
                gap={2}
                alignItems="baseline"
                sx={{ px: 1 }}
              >
                <Box component="span" sx={{ whiteSpace: 'nowrap' }}>
                  <Box component="span" sx={{ color: theme.palette.info.main, fontWeight: 'bold' }}>
                    {dataImport.ingestedRecordsCount}
                  </Box>{' '}
                  changes
                </Box>
                <Box component="span" sx={{ whiteSpace: 'nowrap' }}>
                  phase: {dataImport.phase ?? 'n/a'}
                </Box>
                <Box component="span" sx={{ whiteSpace: 'nowrap' }}>
                  updateStatus:{' '}
                  <Box
                    component="span"
                    sx={{
                      color: () => {
                        if (isImportDead(dataImport)) {
                          return 'red';
                        } else if (dataImport.updateStatus === KaeplaOpsUpdateStatus.finished) {
                          return 'green';
                        }
                        return 'inherit';
                      },
                    }}
                  >
                    {dataImport.updateStatus}
                  </Box>
                </Box>
                <Box component="span" sx={{ whiteSpace: 'nowrap' }}>
                  {dataImport.progress.job}:{' '}
                  <Box
                    component="span"
                    sx={{
                      color: () => {
                        if (isImportDead(dataImport)) {
                          return 'red';
                        } else if (
                          dataImport.progress.status === KaeplaImportProgressStatus.finished
                        ) {
                          return 'green';
                        }
                        return 'inherit';
                      },
                    }}
                  >
                    {dataImport.progress.status}
                  </Box>
                </Box>
              </Stack>
            </Grid>
            <Grid item md={4} sm={4} xs={6} container justifyContent="flex-end" sx={{ px: 1 }}>
              <Stack direction={smUp ? 'row' : 'column'} gap={2} alignItems="baseline">
                <Button
                  sx={{ fontSize: '0.7rem' }}
                  variant="outlined"
                  color="secondary"
                  size="small"
                  disabled={isUpdateLocked()}
                  onClick={(event) => {
                    setUpdateRequested(DateTime.now());
                    deltaUpdate();
                    event.stopPropagation();
                  }}
                  startIcon={<SyncIcon fontSize="inherit" sx={{ height: 15 }} />}
                >
                  Δ
                </Button>
                <Button
                  sx={{ ml: 1, fontSize: '0.7rem' }}
                  variant="outlined"
                  color="secondary"
                  size="small"
                  disabled={isUpdateLocked()}
                  onClick={(event) => {
                    setUpdateRequested(DateTime.now());
                    fullUpdate();
                    event.stopPropagation();
                  }}
                  startIcon={<SyncIcon fontSize="inherit" sx={{ height: 15 }} />}
                >
                  full
                </Button>
              </Stack>
            </Grid>
            <Grid
              item
              xs={11}
              sx={{ visibility: lastLog ? 'visible' : 'hidden' }}
              alignItems="baseline"
            >
              {lastLog && (
                <Typography
                  sx={{
                    fontFamily: 'monospace',
                    fontSize: 12,
                    color: theme.palette[lastLog.eventType].light,
                    px: 1,
                  }}
                >
                  {lastLog.event}
                </Typography>
              )}
            </Grid>
            <Grid item xs={1} container justifyContent="flex-end">
              <ExpandMore
                expand={logExpanded}
                onClick={handleLogExpandClick}
                aria-expanded={logExpanded}
                aria-label="show log"
              >
                <ExpandMoreIcon fontSize="small" />
              </ExpandMore>
            </Grid>
            <Grid item xs={12} sx={{ visibility: logExpanded ? 'visible' : 'hidden' }}>
              <LogTerminal
                expanded={logExpanded}
                importId={dataImport.id}
                projectId={dataImport.projectId}
                setLastLog={setLastLog}
              />
            </Grid>
          </>
        )}
        <Grid item md={12} sm={12} xs={12}>
          <Divider />
        </Grid>
      </Grid>
    </Box>
  );
};
