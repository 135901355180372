/* eslint-disable @typescript-eslint/naming-convention */
export const brandModelsMap: Record<string, string[]> = {
  Isuzu: ['D-Max', 'MU-X', 'Trooper', 'Rodeo', 'Ascender'],
  Mazda: ['Mazda3', 'Mazda6', 'CX-5', 'CX-9', 'MX-5 Miata'],
  'Maruti-Suzuki': ['Swift', 'Baleno', 'Dzire', 'Vitara Brezza', 'Ertiga'],
  Hongqi: ['HS5', 'H9', 'E-HS9', 'H5', 'H7'],
  Daihatsu: ['Terios', 'Sirion', 'Gran Max', 'Ayla', 'Sigra'],
  'Shandong Kaima': ['KMC1040', 'KMC1042', 'KMC1046', 'KMC1048', 'KMC1050'],
  Lexus: ['ES', 'RX', 'NX', 'GX', 'LS'],
  Toyota: ['Corolla', 'Camry', 'RAV4', 'Highlander', 'Tacoma'],
  Nissan: ['Altima', 'Maxima', 'Rogue', 'Pathfinder', 'Murano'],
  Chevrolet: ['Silverado', 'Cruze', 'Equinox', 'Malibu', 'Traverse'],
  Geely: ['Coolray', 'Azkarra', 'Okavango', 'Emgrand X7', 'Emgrand 7'],
  Volkswagen: ['Golf', 'Jetta', 'Passat', 'Tiguan', 'Atlas'],
  'Alfa Romeo': ['Giulia', 'Stelvio', '4C', 'Giulietta', 'MiTo'],
  Honda: ['Civic', 'Accord', 'CR-V', 'Pilot', 'Odyssey'],
  Jaguar: ['F-Type', 'XE', 'XF', 'XJ', 'F-PACE'],
  Hyundai: ['Elantra', 'Sonata', 'Tucson', 'Santa Fe', 'Kona'],
  Jeep: ['Wrangler', 'Grand Cherokee', 'Cherokee', 'Compass', 'Renegade'],
  Trumpchi: ['GS4', 'GS5', 'GS8', 'GM8', 'GA4'],
  Audi: ['A4', 'A6', 'Q5', 'Q7', 'TT'],
  'Mercedes-Benz': ['C-Class', 'E-Class', 'GLC', 'GLE', 'S-Class'],
  Roewe: ['RX5', 'RX8', 'i5', 'i6', 'MARVEL X'],
  Changan: ['CS35', 'CS55', 'CS75', 'CS95', 'UNI-K'],
  BMW: ['3 Series', '5 Series', 'X3', 'X5', '7 Series'],
  Ford: ['F-150', 'Mustang', 'Explorer', 'Escape', 'Focus'],
  Mahindra: ['Scorpio', 'XUV500', 'Thar', 'Bolero', 'Marazzo'],
  'Lynk&Co': ['01', '02', '03', '05', '06'],
  Maxus: ['G10', 'D90', 'T60', 'T70', 'EG10'],
  Venucia: ['T90', 'T70', 'T60', 'R30', 'D60'],
  Renault: ['Clio', 'Megane', 'Captur', 'Kadjar', 'Talisman'],
  'Rolls-Royce': ['Phantom', 'Ghost', 'Cullinan', 'Wraith', 'Dawn'],
  MG: ['ZS', 'HS', 'RX5', 'RX8', '5'],
  Baojun: ['510', '530', '560', '610', '730'],
  Skoda: ['Octavia', 'Superb', 'Kodiaq', 'Karoq', 'Rapid'],
  Lincoln: ['Navigator', 'Aviator', 'Nautilus', 'Corsair', 'Continental'],
  Exeed: ['TX', 'LX', 'TXL', 'e-200', 'e-300'],
  Dongfeng: ['AX7', 'AX5', 'AX4', 'AX3', 'AX2'],
  Kia: ['Optima', 'Sorento', 'Sportage', 'Forte', 'Telluride'],
  HAVAL: ['H6', 'H2', 'H9', 'H7', 'F7'],
  'Chengdu Wangpai': ['Wangpai', 'Wangpai EV', 'Wangpai II', 'Wangpai III', 'Wangpai IV'],
  Lamborghini: ['Aventador', 'Huracan', 'Urus', 'Sian', 'Centenario'],
  'Land Rover': ['Range Rover', 'Discovery', 'Defender', 'Evoque', 'Velar'],
  Volvo: ['S60', 'XC60', 'XC90', 'V60', 'V90'],
  Kaicheng: ['K30', 'K50', 'K60', 'K70', 'K80'],
  Polestar: ['2', '1', '3', '4', '5'],
  NIO: ['ES8', 'ES6', 'EC6', 'ET7', 'EP9'],
  Cadillac: ['Escalade', 'XT5', 'CT5', 'CT6', 'XT6'],
  Enovate: ['ME5', 'ME7', 'ME3', 'ME1', 'ME-S'],
  DFSK: ['Glory 580', 'Glory 560', 'Glory 330', 'Glory 360', 'Glory 310'],
  Opel: ['Corsa', 'Astra', 'Insignia', 'Mokka', 'Grandland X'],
  'Beijing Auto': ['Senova D50', 'Senova X55', 'Senova X65', 'Senova X35', 'Senova X25'],
  Aito: ['A7', 'A6', 'A5', 'A3', 'A1'],
  Mitsubishi: ['Outlander', 'Eclipse Cross', 'Mirage', 'Pajero', 'Lancer'],
  Chery: ['Tiggo 8', 'Tiggo 7', 'Tiggo 5X', 'Tiggo 4', 'Tiggo 3X'],
  'Shaanxi Auto': ['Delong', 'F3000', 'F2000', 'F6000', 'F7000'],
  Aiways: ['U5', 'U6', 'U7', 'U8', 'U9'],
  Fiat: ['500', '500X', '500L', '124 Spider', '500e'],
  Chrysler: ['300', 'Pacifica', 'Voyager', 'Aspen', 'Crossfire'],
  Porsche: ['911', 'Cayenne', 'Panamera', 'Macan', 'Taycan'],
  Citroen: ['C3', 'C4', 'C5', 'C1', 'C2'],
  Subaru: ['Impreza', 'Legacy', 'Outback', 'Forester', 'Crosstrek'],
  GMC: ['Sierra', 'Yukon', 'Acadia', 'Terrain', 'Canyon'],
  JAC: ['S2', 'S3', 'S4', 'S5', 'S7'],
  McLaren: ['570S', '720S', '675LT', 'P1', 'GT'],
  'Great Wall': ['Haval H6', 'Haval H2', 'Haval H9', 'Haval H7', 'Haval F7'],
  Haima: ['Haima 7', 'Haima 8', 'Haima S5', 'Haima S7', 'Haima M3'],
  'Aston Martin': ['DB11', 'Vantage', 'DBS Superleggera', 'Rapide', 'Valhalla'],
  Foton: ['Tunland', 'Sauvana', 'Toano', 'Aumark', 'View'],
  Ferrari: ['F8 Tributo', '812 Superfast', 'SF90 Stradale', 'Portofino', 'Roma'],
  Wuling: ['Almaz', 'Cortez', 'Confero', 'Formo', 'Victory'],
  Proton: ['Saga', 'Persona', 'X70', 'Iriz', 'Exora'],
  Beijing: ['BJ20', 'BJ40', 'BJ80', 'BJ90', 'BJ100'],
  Buick: ['Encore', 'Envision', 'Regal', 'LaCrosse', 'Enclave'],
  Peugeot: ['208', '308', '508', '2008', '3008'],
  Ossan: ['Ossan 360', 'Ossan 560', 'Ossan 760', 'Ossan 960', 'Ossan 1160'],
  Qiteng: ['Qiteng EX80', 'Qiteng EX70', 'Qiteng EX60', 'Qiteng EX50', 'Qiteng EX40'],
  Aion: ['LX', 'V', 'S', 'G', 'U'],
  VGV: ['VGV 100', 'VGV 200', 'VGV 300', 'VGV 400', 'VGV 500'],
  'Youngman Lotus': ['Youngman L3', 'Youngman L5', 'Youngman L7', 'Youngman L9', 'Youngman L11'],
  Modern: ['Modern 1', 'Modern 2', 'Modern 3', 'Modern 4', 'Modern 5'],
  BYD: ['Tang', 'Han', 'Qin', 'Song', 'Yuan'],
  Xpeng: ['P7', 'G3', 'P5', 'P3', 'G7'],
  Neta: ['Neta U', 'Neta V', 'Neta S', 'Neta G', 'Neta X'],
  Mini: ['Cooper', 'Countryman', 'Clubman', 'Convertible', 'Paceman'],
  Dacia: ['Sandero', 'Duster', 'Logan', 'Lodgy', 'Dokker'],
  Tata: ['Nexon', 'Harrier', 'Tiago', 'Altroz', 'Safari'],
  Suzuki: ['Swift', 'Vitara', 'Jimny', 'Ciaz', 'Ertiga'],
  Victory: ['Victory S', 'Victory M', 'Victory L', 'Victory XL', 'Victory XXL'],
  DS: ['DS 3', 'DS 4', 'DS 5', 'DS 7', 'DS 9'],
  Bentley: ['Continental GT', 'Flying Spur', 'Bentayga', 'Mulsanne', 'Bacalar'],
  Jianghuai: ['iEV6E', 'iEV7S', 'iEV7S Pro', 'iEV7S Plus', 'iEV7S Pro Plus'],
  Tesla: ['Model 3', 'Model S', 'Model X', 'Model Y', 'Cybertruck'],
  Voyah: ['i-Free', 'i-Lounge', 'i-Lounge+', 'i-Lounge Pro', 'i-Lounge Pro+'],
  Cruise: ['Origin', 'Cruise AV', 'Cruise Origin', 'Cruise AV2', 'Cruise Origin2'],
  Maserati: ['Ghibli', 'Levante', 'Quattroporte', 'GranTurismo', 'MC20'],
  Sollers: ['Sollers S1', 'Sollers S2', 'Sollers S3', 'Sollers S4', 'Sollers S5'],
  UAZ: ['Patriot', 'Hunter', 'Bukhanka', 'Profession', 'Cargo'],
  Ram: ['1500', '2500', '3500', '4500', '5500'],
  Dodge: ['Charger', 'Challenger', 'Durango', 'Journey', 'Grand Caravan'],
  Infiniti: ['Q50', 'Q60', 'QX50', 'QX60', 'QX80'],
  Jinbei: ['Grace', 'Haise', 'Haixing', 'Haixing X30', 'Haixing X5'],
  Jiangling: ['Yusheng', 'Yusheng S330', 'Yusheng S350', 'Yusheng S550', 'Yusheng S560'],
  SEAT: ['Leon', 'Ibiza', 'Ateca', 'Arona', 'Tarraco'],
  Karry: ['Karry K50', 'Karry K60', 'Karry K70', 'Karry K80', 'Karry K90'],
  Ssangyong: ['Tivoli', 'Korando', 'Rexton', 'XLV', 'Musso'],
  Morgan: ['Plus Four', 'Plus Six', '3 Wheeler', 'Aero 8', 'Roadster'],
  Yutong: ['ZK6129H', 'ZK6608DM', 'ZK6129HE', 'ZK6608DH', 'ZK6129HA'],
  Genesis: ['G70', 'G80', 'G90', 'GV80', 'GV70'],
  Fukang: ['Fukang 330', 'Fukang 530', 'Fukang 730', 'Fukang 930', 'Fukang 1130'],
  Chuanlu: ['Chuanlu C3', 'Chuanlu C5', 'Chuanlu C7', 'Chuanlu C9', 'Chuanlu C11'],
  ORA: ['R1', 'R2', 'R3', 'R4', 'R5'],
  Lada: ['Granta', 'Vesta', 'XRAY', 'Largus', 'Niva'],
  Leapmotor: ['T03', 'T03 Pro', 'T03S', 'T03S Pro', 'C11'],
  SAIPA: ['Tiba', 'Pride', 'Saina', 'Quick', 'Khashayar'],
  Tangjun: [
    'Tangjun Oting',
    'Tangjun Xingtu',
    'Tangjun Xingtu 2',
    'Tangjun Xingtu 3',
    'Tangjun Xingtu 4',
  ],
  Smart: ['fortwo', 'forfour', 'eq fortwo', 'eq forfour', 'forjeremy'],
  SWM: ['G01', 'G02', 'G03', 'G04', 'G05'],
  Sehol: ['Sehol S1', 'Sehol S2', 'Sehol S3', 'Sehol S4', 'Sehol S5'],
  Ciimo: [
    'Ciimo Si Ming',
    'Ciimo Si Ming EV',
    'Ciimo Si Ming PHEV',
    'Ciimo Si Ming EV2',
    'Ciimo Si Ming PHEV2',
  ],
  R: ['R1', 'R2', 'R3', 'R4', 'R5'],
  WEY: ['VV5', 'VV6', 'VV7', 'VV7 GT', 'VV9'],
  Lancia: ['Ypsilon', 'Delta', 'Thema', 'Voyager', 'Phedra'],
  'Peugeot (IKCO)': ['207i', '206i', '405i', '207i SD', '206i SD'],
  Bestune: ['T77', 'T99', 'T33', 'T77S', 'T77 Pro'],
  Jetour: ['X70', 'X70S', 'X70 Coupe', 'X90', 'X95'],
  Cupra: ['Formentor', 'Leon', 'Ateca', 'Tavascan', 'Born'],
  Acura: ['TLX', 'MDX', 'RDX', 'ILX', 'NSX'],
  Perodua: ['Myvi', 'Axia', 'Bezza', 'Alza', 'Aruz'],
  Zhongxing: ['Territory', 'Dominator', 'Grand Tiger', 'Little Tiger', 'Urban Ark'],
  Zamyad: ['Z24', 'Z25', 'Z26', 'Z27', 'Z28'],
  Ankai: ['HFF6120K', 'HFF6120K51D', 'HFF6120K51E', 'HFF6120K51F', 'HFF6120K51G'],
  'Iran Khodro': ['Samand', 'Runna', 'Dena', 'Soren', 'Peugeot Pars'],
  Samsung: ['SM3', 'SM5', 'SM6', 'SM7', 'SM8'],
  Changhe: ['Q35', 'Q25', 'Q7', 'Q1', 'Q2'],
  CRRC: ['CRRC 1', 'CRRC 2', 'CRRC 3', 'CRRC 4', 'CRRC 5'],
  'King Long': ['XMQ6127', 'XMQ6128', 'XMQ6129', 'XMQ6130', 'XMQ6131'],
  Lifan: ['X70', 'X60', 'X50', 'X40', 'X30'],
  POER: ['P01', 'P02', 'P03', 'P04', 'P05'],
  Tank: ['300', '500', '700', '900', '1100'],
  Agrale: ['Agrale 8500', 'Agrale 8700', 'Agrale 9200', 'Agrale 10000', 'Agrale 13000'],
  Nanjun: ['NJ1020', 'NJ1026', 'NJ1028', 'NJ1030', 'NJ1032'],
  Deepal: ['Deepal 1', 'Deepal 2', 'Deepal 3', 'Deepal 4', 'Deepal 5'],
  Kaiyi: ['Kaiyi 1', 'Kaiyi 2', 'Kaiyi 3', 'Kaiyi 4', 'Kaiyi 5'],
  Huanghai: ['Huanghai N1', 'Huanghai N2', 'Huanghai N3', 'Huanghai N4', 'Huanghai N5'],
  Senova: ['Senova D20', 'Senova D50', 'Senova D70', 'Senova D80', 'Senova D90'],
  Jetta: ['VA3', 'VA4', 'VS5', 'VS7', 'VS7 GT'],
  Farizon: ['Farizon A9', 'Farizon A8', 'Farizon A7', 'Farizon A6', 'Farizon A5'],
};
