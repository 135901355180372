import { Box, Chip, Grid, Stack } from '@mui/material';
import { useRecoilState } from 'recoil';

import { bucketState } from '../../../../services/recoil/nonpersistent/ihsBucketState';
import { MatchBucket } from '../types/MatchBucket';

interface MOptions {
  buckets: MatchBucket[];
  explain?: boolean;
}

export const MatchBuckets = ({ buckets, explain }: MOptions) => {
  const [selectedBucket, setSelectedBucket] = useRecoilState(bucketState);

  if (explain) {
    return (
      <Stack direction="column" spacing={3} sx={{ p: 2 }}>
        {buckets.map((bucket) => (
          <Grid container key={bucket.type} spacing={2} alignItems="center">
            <Grid item xs={2}>
              <Chip
                key={bucket.type}
                sx={{
                  borderRadius: 1,
                  mr: 1,
                  opacity: selectedBucket?.type === bucket.type ? 1 : 0.8,
                }}
                label={`${bucket.label} ${bucket.count}`}
                color={bucket.color}
                size="medium"
                variant={selectedBucket?.type === bucket.type ? 'filled' : 'outlined'}
                onClick={() => {
                  if (selectedBucket?.type === bucket.type) {
                    setSelectedBucket(undefined);
                    return;
                  }
                  setSelectedBucket(bucket);
                }}
              />
            </Grid>
            <Grid item xs={10}>
              {bucket.description}
            </Grid>
          </Grid>
        ))}
      </Stack>
    );
  }

  return (
    <Box>
      {buckets.map((bucket) => (
        <Chip
          key={bucket.type}
          sx={{ borderRadius: 1, mr: 1, opacity: selectedBucket?.type === bucket.type ? 1 : 0.8 }}
          label={`${bucket.label} ${bucket.count}`}
          color={bucket.color}
          size="medium"
          variant={selectedBucket?.type === bucket.type ? 'filled' : 'outlined'}
          onClick={() => {
            if (selectedBucket?.type === bucket.type) {
              setSelectedBucket(undefined);
              return;
            }
            setSelectedBucket(bucket);
          }}
        />
      ))}
    </Box>
  );
};
