import { WhereClause } from '@atrigam/atrigam-service-firebase-watcher';
import {
  Autocomplete,
  Box,
  LinearProgress,
  Link,
  List,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useDebouncedCallback } from 'use-debounce';

import { AiThread } from './components/AiThread.js';
import { addFirestoreCollectionListener } from '../../../../services/firestore/addFirestoreCollectionListener.js';
import { KaeplaUser, Thread } from '../../../../services/kaeplaTypes/index.js';
import { kaeplaAssignmentState } from '../../../../services/recoil/persistent/kaeplaAssignmentState.js';
import { knownUsersState } from '../../../../services/recoil/persistent/knownUsersState.js';
import { UserAvatar } from '../../../features/UserAvatar.js';

const BATCH_SIZE = 50;

export const AiThreads = () => {
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  const knownUsers = useRecoilValue(knownUsersState);

  const [mainThreads, setMainThreads] = useState<Thread[]>([]);
  const [batches, setBatches] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState<string>();
  const [onlyThread, setOnlyThread] = useState<string>();
  const [showMarkdown, setShowMarkDown] = useState(false);

  useEffect(() => {
    if (!kaeplaAssignment) return;
    setLoading(true);

    const fireStorePath = `aiThreads`;
    const queryWhere: WhereClause[] = [];

    if (onlyThread) {
      queryWhere.push({
        fieldPath: 'id',
        opStr: '==',
        value: onlyThread,
      });
    } else {
      queryWhere.push({
        fieldPath: 'type',
        opStr: '==',
        value: 'main',
      });

      if (selectedUser) {
        queryWhere.push({
          fieldPath: 'uid',
          opStr: '==',
          value: selectedUser,
        });
      }
    }

    const limit = BATCH_SIZE * batches;

    const unsubscribe = addFirestoreCollectionListener({
      fireStorePath,
      queryWhere,
      orderBy: {
        fieldPath: 'createdAt',
        direction: 'desc',
      },
      limit,
      callback: (data) => {
        const _threads = data as Thread[];
        setMainThreads(_threads);
        setLoading(false);
      },
    });
    return () => {
      unsubscribe();
    };
  }, [batches, kaeplaAssignment, onlyThread, selectedUser]);

  const increaseBatchesDebounced = useDebouncedCallback(() => {
    setLoading(true);
    setBatches((_batches) => _batches + 1);
  }, 300);

  useEffect(() => {
    const main = document.querySelector('main');
    if (!main) return;
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } = main;
      if (scrollTop + clientHeight >= scrollHeight - 20) {
        increaseBatchesDebounced();
      }
    };

    main.addEventListener('scroll', handleScroll);
    return () => {
      main.removeEventListener('scroll', handleScroll);
    };
  }, [increaseBatchesDebounced]);

  return (
    <Box>
      <Stack direction="row" spacing={2} sx={{ py: 1 }} alignItems="center">
        <Autocomplete
          disablePortal
          options={knownUsers}
          sx={{ width: 300 }}
          size="small"
          getOptionLabel={(option: KaeplaUser) => option.displayName}
          renderOption={(properties, option: KaeplaUser) => (
            <li {...properties}>
              <Stack spacing={2} direction="row">
                <UserAvatar user={option} size={25} /> <Typography>{option.displayName}</Typography>
              </Stack>
            </li>
          )}
          onChange={(_event, newValue) => {
            if (!newValue) {
              setSelectedUser(undefined);
              return;
            }
            setSelectedUser(newValue.uid);
          }}
          renderInput={(parameters) => <TextField {...parameters} label="User" />}
        />
        {onlyThread && (
          <Link
            href="#"
            onClick={(event) => {
              setOnlyThread(undefined);
              event.preventDefault();
              event.stopPropagation();
            }}
          >
            all threads
          </Link>
        )}
        <Link
          href="#"
          onClick={(event) => {
            setShowMarkDown(!showMarkdown);
            event.preventDefault();
            event.stopPropagation();
          }}
        >
          {showMarkdown ? 'hide' : 'show'} markdown
        </Link>
      </Stack>
      <List sx={{ bgcolor: 'background.paper' }}>
        {mainThreads.map((thread) => {
          return (
            <AiThread
              key={thread.id}
              thread={thread}
              setOnlyThread={setOnlyThread}
              showMarkdown={showMarkdown}
            />
          );
        })}
      </List>
      {loading && (
        <Box>
          <LinearProgress />
          Loading...
        </Box>
      )}
    </Box>
  );
};
