import { Alert, Grid, LinearProgress, Paper, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { knownProjectsState } from '../../../../services/recoil/nonpersistent/knownProjectsState';
import { projectInCreationState } from '../../../../services/recoil/nonpersistent/projectInCreationState';

interface Options {
  creating: boolean;
}

export const ProjectInCreation = ({ creating }: Options) => {
  const projectInCreation = useRecoilValue(projectInCreationState);
  const knownProjects = useRecoilValue(knownProjectsState);
  const resetProjectInCreation = useResetRecoilState(projectInCreationState);

  useEffect(() => {
    if (knownProjects.map((p) => p.id).includes(projectInCreation)) {
      resetProjectInCreation();
    }
  }, [knownProjects, projectInCreation, resetProjectInCreation]);

  if (!creating) return null;
  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Paper sx={{ height: '100%', p: 2, textAlign: 'center' }} data-testid="project-in-creation">
        <LinearProgress />
        <Typography sx={{ mt: 2, fontSize: 12 }}>{projectInCreation}</Typography>
        <Alert sx={{ m: 2 }} variant="outlined" severity="info">
          Your new project might take a minute to show up here.
        </Alert>
      </Paper>
    </Grid>
  );
};
