import { KaeplaImport, KaeplaOpsUpdateStatus } from '../../../../services/kaeplaTypes';

export const isImportDeadOrFinished = (_dataImport: KaeplaImport) => {
  if (!_dataImport) return false;
  return [
    KaeplaOpsUpdateStatus.failed,
    KaeplaOpsUpdateStatus.finished,
    KaeplaOpsUpdateStatus.canceled,
    KaeplaOpsUpdateStatus.cancelRequested,
  ].includes(_dataImport.updateStatus);
};
