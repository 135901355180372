import { Grid, Link, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { KaeplaDomainType } from '../../../../services/kaeplaTypes/Application/KaeplaDomainType.js';
import { customerAssignmentState } from '../../../../services/recoil/nonpersistent/customerAssignmentState.js';
import { knownResellersState } from '../../../../services/recoil/nonpersistent/knownResellersState.js';
import { brandingState } from '../../../../services/recoil/persistent/brandingState.js';
import { kaeplaAssignmentState } from '../../../../services/recoil/persistent/kaeplaAssignmentState.js';

export const NoCustomers = () => {
  const navigate = useNavigate();
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  const customerAssignments = useRecoilValue(customerAssignmentState);
  const knownResellers = useRecoilValue(knownResellersState);
  const branding = useRecoilValue(brandingState);

  if (
    !kaeplaAssignment && // if we're not a kaepla admin
    customerAssignments.length === 0 && // we have more the 1 customerAssignment
    branding?.realmType === KaeplaDomainType.reseller && // we are on a reseller realm
    knownResellers.map((r) => r.id).includes(branding.providerId) // and we know this reseller (have a resellerAssignment for this reseller)
  ) {
    return (
      <Grid item xs={12}>
        <Paper data-testid="no-customers-info" sx={{ p: 6 }}>
          No customers yet. You can{' '}
          <Link
            href="#"
            onClick={() => {
              navigate(`/Customers`);
            }}
          >
            create one
          </Link>
          .
        </Paper>
      </Grid>
    );
  }

  // if we have just 1 customerAssignment, this customer is automatically selected
  // if (!selectedCustomer && customerAssignments.length > 1) {
  //   return (
  //     <Grid item xs={12}>
  //       <Paper data-testid="select-customer-info" sx={{ p: 6 }}>
  //         Please select a customer.
  //       </Paper>
  //     </Grid>
  //   );
  // }

  return null;
};
