import { Box, Skeleton } from '@mui/material';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { MutableRefObject, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';

import { donutChartConfig } from './donutChart.config.js';
import { KaeplaPieChartDatasets } from './getChartDataForPieChart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

interface SBOptions {
  chartReference: MutableRefObject<undefined>;
  chartData?: KaeplaPieChartDatasets;
  loading: boolean;
}
export const DonutChart = ({ chartReference, chartData, loading }: SBOptions) => {
  useEffect(() => {
    if (loading && chartReference?.current) {
      const chart = chartReference.current as ChartJS;
      chart.resetZoom();
    }
  }, [chartReference, loading]);

  return (
    <Box height={240}>
      {loading && (
        <Skeleton
          sx={{ margin: 'auto', opacity: 0.5 }}
          variant="circular"
          animation="wave"
          width={240}
          height={240}
        />
      )}
      {chartData && !loading && (
        <Doughnut
          ref={chartReference}
          options={donutChartConfig as Record<string, unknown>}
          data={{ ...chartData, datasets: chartData.datasets.filter((d) => !d.hidden) }}
        />
      )}
    </Box>
  );
};
