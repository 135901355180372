import { KaeplaDimension } from '../../../../../services/kaeplaTypes';

export const getAliasFor = (dimension: KaeplaDimension, dimensions?: KaeplaDimension[]) => {
  if (!dimensions) return dimension.columnName;
  let name = dimension.columnName;
  if (dimensions.some((d) => d.isAliasFor === dimension.columnName)) {
    const aliasDimension = dimensions.find((d) => d.isAliasFor === dimension.columnName);
    if (aliasDimension) {
      name = aliasDimension.columnName;
    }
  }
  return name;
};
