import CancelDeleteIcon from '@mui/icons-material/Cancel';
import DeleteAllIcon from '@mui/icons-material/DeleteForever';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { Box, Button, IconButton, Paper, Toolbar, Typography } from '@mui/material';

import { targetsColumnWidth } from './defaults.js';
import { KaeplaTargets } from '../../../services/kaeplaTypes/Application/KaeplaTargets';
import { KaeplaTargetsFigure } from '../../../services/kaeplaTypes/Application/KaeplaTargetsFigure';
import {
  matrixDataColor,
  targetsDataColor,
  targetsDataColorLowerLight,
  targetsDataColorUpperLight,
} from '../defaults';

interface Options {
  targets: Partial<KaeplaTargets>;
  batchActionsEnabled: boolean;
  setBatchActionsEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  selectedTargetFigures: KaeplaTargetsFigure[];
  editable: boolean;
  batchDeleteTargetFigures: () => void;
  setSelectedTargetFigures: React.Dispatch<React.SetStateAction<KaeplaTargetsFigure[]>>;
}

export const TargetFigureHeaderLine = ({
  // targets,
  editable,
  batchActionsEnabled,
  setBatchActionsEnabled,
  selectedTargetFigures,
  batchDeleteTargetFigures,
  setSelectedTargetFigures,
}: Options) => {
  return (
    <Box
      component={Paper}
      square
      elevation={0}
      borderBottom={1}
      borderColor="lightgray"
      sx={{ px: 2 }}
    >
      <Toolbar disableGutters variant="dense">
        <Typography variant="body1" sx={{ width: targetsColumnWidth }}>
          Year
        </Typography>
        <Typography variant="body1" sx={{ width: targetsColumnWidth, color: matrixDataColor }}>
          original value
        </Typography>
        <Typography variant="body1" sx={{ width: targetsColumnWidth, color: targetsDataColor }}>
          target
        </Typography>
        <Typography variant="body1" sx={{ width: targetsColumnWidth }}>
          <Box component="span" sx={{ color: targetsDataColorUpperLight }}>
            best
          </Box>
          <Box component="span" sx={{ mx: 1 }}>
            /
          </Box>
          <Box component="span" sx={{ color: targetsDataColorLowerLight }}>
            worst
          </Box>
        </Typography>
        <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
        {batchActionsEnabled && selectedTargetFigures.length > 0 && (
          <Button
            variant="contained"
            aria-label="delete selected"
            size="small"
            disabled={!editable}
            onClick={() => {
              batchDeleteTargetFigures();
            }}
            startIcon={<DeleteIcon fontSize="small" />}
          >
            delete selected
          </Button>
        )}
        <IconButton
          edge="end"
          aria-label="delete"
          color="primary"
          size="small"
          disabled={!editable}
          sx={{ mr: -1 }}
          onClick={() => {
            setSelectedTargetFigures([]);
            setBatchActionsEnabled(!batchActionsEnabled);
          }}
        >
          {batchActionsEnabled ? (
            <CancelDeleteIcon fontSize="small" />
          ) : (
            <DeleteAllIcon fontSize="small" />
          )}
        </IconButton>
      </Toolbar>
    </Box>
  );
};
