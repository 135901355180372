import { AiMetaLogFilters } from '../../../../../services/kaeplaTypes/importer/AiMetaLogFilters';

export const deleteFilter = (metaLogFilters: AiMetaLogFilters, filter: string) => {
  const newFilters = { ...metaLogFilters };
  if (filter === 'projectId') {
    delete newFilters.projectId;
  }
  if (filter === 'lastUid') {
    delete newFilters.lastUid;
  }
  if (filter === 'upvoteCount') {
    delete newFilters.upvoteCount;
  }
  if (filter === 'downvoteCount') {
    delete newFilters.downvoteCount;
  }
  if (filter === 'askedCount') {
    delete newFilters.askedCount;
  }
  if (filter === 'needsResolution') {
    delete newFilters.needsResolution;
  }
  return newFilters;
};
