import {
  Box,
  Chip,
  Divider,
  Grid,
  LinearProgress,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { useAuth } from '../../../AuthReactProvider.js';
import { createEvent } from '../../../services/firestore/createEvent.js';
import { getPerspectiveFavorites } from '../../../services/firestore/getPerspectiveFavorites';
import { getPerspectivesForProject } from '../../../services/firestore/getPerspectivesForProject';
import { getTargetsForProject } from '../../../services/firestore/getTargetsForProject.js';
import { KaeplaDataOperation } from '../../../services/kaeplaTypes/Application/KaeplaDataOperation.js';
import { KaeplaEventType } from '../../../services/kaeplaTypes/Application/KaeplaEventType.js';
import { KaeplaFunctionGroup } from '../../../services/kaeplaTypes/Application/KaeplaFunctionGroup.js';
import { KaeplaPerspective } from '../../../services/kaeplaTypes/Application/KaeplaPerspective';
import { KaeplaProjectAssignment } from '../../../services/kaeplaTypes/Application/KaeplaProjectAssignment.js';
import { KaeplaTargets } from '../../../services/kaeplaTypes/Application/KaeplaTargets.js';
import { perspectiveFavoritesState } from '../../../services/recoil/nonpersistent/perspectiveFavoritesState';
import { perspectivesState } from '../../../services/recoil/nonpersistent/perspectivesState';
import { projectState } from '../../../services/recoil/nonpersistent/projectState.js';
import { scopePathsState } from '../../../services/recoil/nonpersistent/scopePathsState.js';
import { simulationsState } from '../../../services/recoil/nonpersistent/simulationsState';
import { projectAssignmentsState } from '../../../services/recoil/nonpersistent/userAssignmentState.js';
import { currentScopePathState } from '../../../services/recoil/persistent/currentScopePathState.js';
import { Layout } from '../../Layout/Layout.js';
import { mainColor } from '../../Theme/colors.js';
import { DelayedLinearProgress } from '../../features/DelayedLinearProgress.js';
import { ProjectLoading } from '../../features/ProjectLoading.js';
import { HumanReadableTimestampType, convertTimestamp } from '../../helpers/convertTimestamp.js';
import { simplifyScopePath } from '../../helpers/simplifyScopePath.js';
import { ProjectInfoSmall } from '../Perspectives/features/ProjectSummary/features/ProjectInfoSmall.js';

export const Assignments = () => {
  const { kaeplaUser } = useAuth();
  const navigate = useNavigate();
  // get
  const project = useRecoilValue(projectState);
  const projectAssignments = useRecoilValue(projectAssignmentsState);
  // get & set
  const [currentScopePath, setCurrentScopePath] = useRecoilState(currentScopePathState);
  const [simulations, setSimulations] = useRecoilState(simulationsState);
  // set
  const setScopePaths = useSetRecoilState(scopePathsState);
  const setPerspectives = useSetRecoilState(perspectivesState);
  const setPerspectiveFavorites = useSetRecoilState(perspectiveFavoritesState);
  // reset
  const resetCurrentScopePath = useResetRecoilState(currentScopePathState);
  // local
  const [targets, setTargets] = useState<KaeplaTargets[]>();
  const [loading, setLoading] = useState(true);
  const [selecting, setSelecting] = useState(false);

  const isSelected = (assignment: KaeplaProjectAssignment) => {
    return JSON.stringify(currentScopePath) === assignment.scopePathStringified;
  };

  const simulationCount = (assignment: KaeplaProjectAssignment) => {
    if (!simulations) return 0;
    return simulations.filter((s) => s.scopePathStringified === assignment.scopePathStringified)
      .length;
  };

  const targetsCount = (assignment: KaeplaProjectAssignment) => {
    if (!targets) return 0;
    return targets.filter((s) => s.scopePathStringified === assignment.scopePathStringified).length;
  };

  // this is the "component mounts" effect
  useEffect(() => {
    if (!kaeplaUser?.uid) return;
    if (!project?.id) return;
    if (selecting) return;

    void createEvent({
      uid: kaeplaUser.uid,
      eventType: KaeplaEventType.ASSIGNMENT_ENTER_ASSIGNMENTS_OVERVIEW,
      functionGroup: KaeplaFunctionGroup.ASSIGNMENTS,
      operation: KaeplaDataOperation.READ,
      project,
    });

    const load = async () => {
      let perspectivesFromServer = await getPerspectivesForProject({
        project,
      });
      const defaultPerspective = { ...project.defaultPerspective } as KaeplaPerspective;
      if (defaultPerspective) {
        defaultPerspective.id = 'default';
        perspectivesFromServer = [defaultPerspective, ...perspectivesFromServer];
      }
      setPerspectives(perspectivesFromServer);
      const perspectiveFavorites = await getPerspectiveFavorites({ project, uid: kaeplaUser.uid });
      setPerspectiveFavorites(perspectiveFavorites);
      const targetsFromServer = await getTargetsForProject({
        project,
      });
      setTargets(targetsFromServer);
      setLoading(false);
    };
    void load();
  }, [
    kaeplaUser?.uid,
    project?.id,
    kaeplaUser,
    project,
    currentScopePath,
    setPerspectives,
    setSimulations,
    selecting,
    setPerspectiveFavorites,
  ]);

  useEffect(() => {
    if (!kaeplaUser?.uid) return;
    if (!project?.id) return;
    if (projectAssignments.filter((a) => a.projectId === project.id).length === 1) {
      const assignment = projectAssignments.find((a) => a.projectId === project.id);
      if (!assignment) {
        resetCurrentScopePath();
        return;
      }
      setCurrentScopePath(assignment.scopePath);
      setScopePaths((previousScopePaths) => {
        const newScopePaths = { ...previousScopePaths };
        newScopePaths[assignment.projectId] = assignment.scopePath;
        return newScopePaths;
      });

      void createEvent({
        uid: kaeplaUser?.uid,
        eventType: KaeplaEventType.ASSIGNMENT_SELECT_ASSIGNMENT,
        functionGroup: KaeplaFunctionGroup.ASSIGNMENTS,
        operation: KaeplaDataOperation.READ,
        project,
        scopePath: assignment.scopePath,
      });
      navigate(`/Perspective/${project.id}`);
    }
  }, [
    kaeplaUser?.uid,
    navigate,
    project,
    projectAssignments,
    resetCurrentScopePath,
    setCurrentScopePath,
    setScopePaths,
  ]);

  if (!project?.id) {
    return <ProjectLoading />;
  }

  if (!targets) {
    return (
      <Layout>
        <DelayedLinearProgress loading={loading} delay={1000} />
      </Layout>
    );
  }

  return (
    <Layout hasScopeNavigation showCustomerSelector>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {loading && <LinearProgress />}
          <Paper sx={{ p: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={3}>
                <ProjectInfoSmall title={project.name} />
              </Grid>
              <Grid item sm={2}>
                <Divider orientation="vertical" />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={7}
                sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
              >
                <Typography variant="h6">Assignments</Typography>
                <Typography variant="body1">
                  Project team members can be invited to a project on a specific sub-set (scope) of
                  the project's data. Find below a list of the scopes you've been invited to.
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {projectAssignments
            .filter((a) => a.projectId === project.id)
            .map((assignment) => (
              <Tooltip key={assignment.id} title={assignment.id}>
                <Box
                  data-testid="assignments-card"
                  component={Paper}
                  sx={{
                    p: 1,
                    mb: 1,
                    cursor: 'pointer',
                    borderLeft: isSelected(assignment) ? `3px solid ${mainColor}` : 'none',
                  }}
                  elevation={isSelected(assignment) ? 3 : 1}
                  onClick={() => {
                    if (loading) return;
                    setSelecting(true);
                    setCurrentScopePath(assignment.scopePath);
                    setScopePaths((previousScopePaths) => {
                      const newScopePaths = { ...previousScopePaths };
                      newScopePaths[assignment.projectId] = assignment.scopePath;
                      return newScopePaths;
                    });

                    void createEvent({
                      uid: kaeplaUser?.uid,
                      eventType: KaeplaEventType.ASSIGNMENT_SELECT_ASSIGNMENT,
                      functionGroup: KaeplaFunctionGroup.ASSIGNMENTS,
                      operation: KaeplaDataOperation.READ,
                      project,
                      scopePath: assignment.scopePath,
                    });
                    navigate(`/Perspective/${project.id}`);
                  }}
                >
                  {assignment.scopePath.length > 0 && (
                    <Typography data-testid="assignments-scopePath" variant="body1">
                      {project.name} ➔ {simplifyScopePath(assignment.scopePath, 1).join(' ➔ ')}
                    </Typography>
                  )}
                  {assignment.scopePath.length === 0 && (
                    <Typography data-testid="assignments-scopePath" variant="body1">
                      {project.name}, all scopes
                    </Typography>
                  )}
                  <Box sx={{ mt: 1 }}>
                    <Chip
                      data-testid="assignments-simulationsCount"
                      sx={{ mr: 1, mb: 1 }}
                      size="small"
                      label={`${simulationCount(assignment)} simulations`}
                    />
                    <Chip
                      data-testid="assignments-targetsCount"
                      sx={{ mr: 1, mb: 1 }}
                      size="small"
                      label={`${targetsCount(assignment)} targets`}
                    />
                  </Box>
                  <Typography data-testid="assignments-lastChecked" variant="caption">
                    last checked:{' '}
                    {assignment.lastVisitedAt
                      ? convertTimestamp(
                          assignment.lastVisitedAt,
                          HumanReadableTimestampType.timeago,
                        )
                      : 'never'}
                  </Typography>
                </Box>
              </Tooltip>
            ))}
        </Grid>
      </Grid>
    </Layout>
  );
};
