import { atom } from 'recoil';

import { KaeplaPieChartDatasets } from '../../../Frontend/Screens/Perspectives/features/PerspectiveBlocks/features/DonutChart/getChartDataForPieChart';
import { KaeplaChartDatasets } from '../../../Frontend/Screens/Perspectives/features/PerspectiveBlocks/features/StackedBarChart/getChartDataForStackedBarChart';

export const chartDataState = atom<
  Record<string, KaeplaChartDatasets | KaeplaPieChartDatasets | Record<string, unknown>>
>({
  key: 'chartDataState',
  default: undefined,
});
