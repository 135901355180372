import { Divider, Grid, Paper, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import TimeAgo from 'react-timeago';
import { useRecoilValue } from 'recoil';

import { projectState } from '../../../services/recoil/nonpersistent/projectState.js';
import { projectAwarePath } from '../../helpers/projectAwarePath.js';
import { agoColor } from '../ExperimentalAi/helpers/agoColor.js';
import { ProjectInfoSmall } from '../Perspectives/features/ProjectSummary/features/ProjectInfoSmall.js';

export const SyncDataHeader = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const project = useRecoilValue(projectState);

  if (!project?.id) return null;

  return (
    <Grid item sm={12}>
      <Paper sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} lg={3}>
            <ProjectInfoSmall title={project.name} />
          </Grid>
          <Grid item xs={0} sm={1}>
            <Divider orientation="vertical" />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            lg={7}
            sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
          >
            <Typography variant="h6">Import & Update Data</Typography>
            <Typography
              sx={{ fontSize: '90%', cursor: 'pointer' }}
              color="text.secondary"
              onClick={() => navigate(projectAwarePath('SyncData', project))}
            >
              Last delta update:{' '}
              {project.lastUpdatedAt ? (
                <TimeAgo
                  style={{ color: agoColor(theme, project.lastUpdatedAt) }}
                  date={project.lastUpdatedAt.toDate()}
                  max={Number.MAX_SAFE_INTEGER}
                />
              ) : (
                'never'
              )}
              <br />
              Last full update:{' '}
              {project.lastFullUpdateAt ? (
                <TimeAgo
                  style={{ color: agoColor(theme, project.lastFullUpdateAt) }}
                  date={project.lastFullUpdateAt.toDate()}
                  max={Number.MAX_SAFE_INTEGER}
                />
              ) : (
                'never'
              )}
            </Typography>

            <Typography variant="body1">
              Here you can see when your project was synced with your leading record keeping system
              and what changed since the latest updates. You can access the last 50 imports.
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
