import { Box } from '@mui/material';
import { green } from '@mui/material/colors';

export const UnsureHowToStart = () => {
  return (
    <Box border={1} borderLeft={3} borderColor={green[300]} borderRadius={1} p={1}>
      Unsure how to start?
    </Box>
  );
};
