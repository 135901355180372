import { DocumentData } from 'firebase/firestore';

import { addFirestoreDocumentListener } from './addFirestoreDocumentListener.js';

interface Options {
  projectId: string;
  simulationId: string;
  callback: (document: DocumentData | undefined) => void;
}

export const addSimulationListener = ({ projectId, simulationId, callback }: Options) => {
  const fireStorePath = `projects/${projectId}/simulations/${simulationId}`;
  return addFirestoreDocumentListener({ fireStorePath, callback });
};
