import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useRecoilValue } from 'recoil';

import { dataSourceUseAiRulesState } from '../../../../services/recoil/nonpersistent/dataSourceUseAiRules';

interface Options {
  setManageAiRules: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AiRules = ({ setManageAiRules }: Options) => {
  const useAiRules = useRecoilValue(dataSourceUseAiRulesState);
  return (
    <Box>
      <Stack direction="row" alignItems="center" spacing={2} sx={{ pb: 2 }}>
        {useAiRules && `You are using AI-Rules`}
        <Switch defaultChecked />
        <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
        <IconButton
          onClick={() => {
            setManageAiRules(false);
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Stack>
      <Card sx={{ maxWidth: 275 }}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {DateTime.now().toJSDate().toDateString()} @ {DateTime.now().toFormat('hh:mm')}
          </Typography>
          <Typography variant="h5" component="div">
            Rule #1
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            Ai generated suggestion
          </Typography>
          <Typography variant="body2">
            When multiple source rows match the target row and one or more source rows have a value
            which matches the original value, keep the original value.
          </Typography>
        </CardContent>
        <CardActions>
          <Stack direction="row" alignItems="center" spacing={2} sx={{ width: '100%' }}>
            <Button size="small">Learn More</Button>
            <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
            <Switch size="small" defaultChecked />
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Stack>
        </CardActions>
      </Card>
    </Box>
  );
};
