import { infoColor } from '../Theme/colors';

export const defaultFeatureTourOptions = {
  arrowColor: '#fff',
  backgroundColor: '#fff',
  beaconSize: 36,
  overlayColor: 'rgba(0, 0, 0, 0.5)',
  primaryColor: infoColor,
  spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
  textColor: '#333',
  maxWidth: 500,
  zIndex: 1500,
};
