import { getCleanPath } from './getCleanPath.js';
import { getCurrentLevel } from './getCurrentLevel.js';
import { KaeplaDimensionDetails } from '../../../services/kaeplaTypes/Application/KaeplaDimensionDetails.js';
import { MatrixDimensions } from '../../../services/kaeplaTypes/MatrixDimensions.js';

interface Options {
  scopePath: string[] | undefined;
  detail: KaeplaDimensionDetails;
  dimensions: MatrixDimensions | undefined;
}

export const isDimensionDelegable = ({ scopePath, detail, dimensions }: Options) => {
  // console.log('->', dimensions, scopePath, detail.columnName, detail.dimensionDetails.length);
  if (!dimensions || !scopePath) return false;

  // this is where we are now, we want it on top of all others
  const currentLevel = getCurrentLevel(scopePath);
  if (currentLevel === detail.columnName) {
    // console.log(detail.columnName, 'not delegable -> self');
    // self
    return true;
  }

  if (detail.dimensionDetails.length < 2) {
    return false;
  }
  // has been delegated along this path already
  if (getCleanPath(scopePath).includes(detail.columnName)) {
    // console.log(detail.dimension, "not delegable -> delegated on path");
    return false;
  }

  const dimensionMeta = dimensions?.dimensions.find(
    (dimension) => dimension.columnName === detail.columnName,
  );
  // floats and numerics probably do not make any sense for scopes
  if (
    dimensionMeta &&
    ['FLOAT64', 'NUMERIC', 'BIGNUMERIC', 'DATE'].includes(dimensionMeta?.dataType)
  ) {
    return false;
  }
  // integers probably do not make any sense for scopes if there are more than 20 rows
  if (
    detail.dimensionDetails.length > 20 &&
    dimensionMeta &&
    ['INT64'].includes(dimensionMeta?.dataType)
  ) {
    return false;
  }
  if (detail.dimensionDetails.length > 50) {
    return false;
  }
  return true;
};
