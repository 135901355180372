import { multiFactor, MultiFactorInfo, User } from 'firebase/auth';
import { Dispatch, SetStateAction } from 'react';

import { kaeplaAuth } from '../../../../firebaseInit.js';
import { mfaLog } from '../../../helpers/logger.js';

interface KOptions {
  user: User;
  setUser: Dispatch<SetStateAction<User | null>>;
  setEnrolledFactors: Dispatch<SetStateAction<MultiFactorInfo[]>>;
}
export const reloadUser = async ({ user, setUser, setEnrolledFactors }: KOptions) => {
  mfaLog.log('user.reload()');
  await user.reload();
  const newUser = kaeplaAuth.currentUser;
  setUser(newUser);

  mfaLog.log('get mfaEnrollment');
  const mfaEnrollment = multiFactor(user).enrolledFactors;
  if (mfaEnrollment.length > 0) {
    setEnrolledFactors(mfaEnrollment);
  } else {
    setEnrolledFactors(mfaEnrollment);
  }
  mfaLog.log('got mfaEnrollment', mfaEnrollment);
};
