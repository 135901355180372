import { generateRevisionNumber, generateVersionNumber } from './generateErpOrders';
import { ErpOrder } from '../types/ErpOrder';

export const generateOrdersMatch = (
  index: number,
  order: ErpOrder,
  insignificantOnly: boolean,
  noChangesAtAll: boolean,
): ErpOrder => {
  let productRevision = generateRevisionNumber();

  const version = Math.floor(Math.random() * 10) + 1; // Generate a random number between 1 and 10
  let productVersion = generateVersionNumber() + '.' + version;

  if (insignificantOnly) {
    productVersion = order.productVersion!;
  }
  if (noChangesAtAll) {
    productVersion = order.productVersion!;
    productRevision = order.productRevision!;
  }

  return {
    ...order,
    productRevision,
    productVersion,
    id: `match-${order.id}-${index}`,
  };
};
