import { getApp } from 'firebase/app';
import { collection, DocumentData, getFirestore, doc, setDoc, Timestamp } from 'firebase/firestore';

import { getKaeplaTargets } from '../api/getKaeplaTargets.js';
import { KaeplaDimension } from '../kaeplaTypes/Application/KaeplaDimension.js';
import { KaeplaProject } from '../kaeplaTypes/Application/KaeplaProject.js';
import { KaeplaSimulation } from '../kaeplaTypes/Application/KaeplaSimulation.js';
import { KaeplaTargets } from '../kaeplaTypes/Application/KaeplaTargets.js';
import { KaeplaTargetsFigure } from '../kaeplaTypes/Application/KaeplaTargetsFigure.js';
import { FirestoreTimestamp } from '../kaeplaTypes/FirestoreTimeStamp.js';

interface Options {
  project: KaeplaProject;
  simulation?: KaeplaSimulation;
  createdBy: string;
  scopePath: string[] | undefined;
  targetFigures: Partial<KaeplaTargetsFigure>[];
  targetsDimension?: KaeplaDimension;
}

export const createNewTargets = async ({
  project,
  simulation,
  scopePath,
  createdBy,
  targetFigures,
  targetsDimension,
}: Options) => {
  if (!project?.defaultPerspective?.valueDimension || !scopePath) return;
  const dimension = targetsDimension?.columnName ?? project.defaultPerspective.valueDimension;
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `projects/${project.id}/targets`;
  const firestoreCollectionReference = collection(firestoreDatabase, fireStorePath);
  const documentReference = doc(firestoreCollectionReference);

  const targetFiguresExtended: Partial<KaeplaTargetsFigure>[] = [];
  if (dimension && targetFigures) {
    targetFigures.forEach((t) => {
      const figure: Partial<KaeplaTargetsFigure> = {
        ...t,
        targetsId: documentReference.id,
        scopePathStringified: JSON.stringify(scopePath),
        dimension,
        timeSlice: 'YEAR',
        aggregation: 'SUM',
        currency: targetsDimension?.currency,
        decimalPositions: targetsDimension?.decimalPositions,
      };
      targetFiguresExtended.push(figure);
    });
  }

  const _targets: KaeplaTargets = {
    projectId: project.id,
    dimension,
    id: documentReference.id,
    targetsMatrix: `${project.dataMatrix}Targets`,
    createdBy,
    createdAt: Timestamp.now() as FirestoreTimestamp,
    scopePath,
    isBeingUpdated: true,
    scopePathStringified: JSON.stringify(scopePath),
  };
  if (simulation) {
    _targets.simulationId = simulation.id;
  }

  await setDoc(documentReference, _targets as DocumentData);

  const parameters = {
    projectId: project.id,
    targetsId: documentReference.id,
    targetFigures: targetFiguresExtended,
    createTargets: true,
  };

  void getKaeplaTargets({
    params: parameters,
  });

  return _targets;
};
