import DataSnapshotsIcon from '@mui/icons-material/History.js';
import { Box, Grid, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import TimeAgo from 'react-timeago';
import { useRecoilValue } from 'recoil';

import { ProjectRecordsCount } from './ProjectRecordsCount.js';
import { ProjectTeam } from './ProjectTeam.js';
import { projectState } from '../../../../../../services/recoil/nonpersistent/projectState.js';
import { selectedCustomerState } from '../../../../../../services/recoil/nonpersistent/selectedCustomer.js';
import { snapShotState } from '../../../../../../services/recoil/nonpersistent/snapshotState.js';
import { CustomerAvatar } from '../../../../../features/CustomerAvatar.js';
import { projectAwarePath } from '../../../../../helpers/projectAwarePath.js';
import { dataSnapshotColor } from '../../../../defaults.js';

interface Options {
  title?: string;
}

export const ProjectInfoSmall = ({ title }: Options) => {
  const navigate = useNavigate();
  const project = useRecoilValue(projectState);
  const snapshot = useRecoilValue(snapShotState);
  const selectedCustomer = useRecoilValue(selectedCustomerState);

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
          {selectedCustomer && <CustomerAvatar customer={selectedCustomer} size={30} />}
          <Typography
            sx={{
              fontSize: 20,
              color: grey[900],
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
            color="text.secondary"
            gutterBottom
          >
            {title ?? project.name}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <ProjectTeam project={project} />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ m: 1 }} />
      </Grid>
      <Grid item xs={6}>
        <ProjectRecordsCount />
        {snapshot?.id && (
          <Typography
            sx={{ fontSize: '90%', display: 'flex', gap: 0.5, alignItems: 'start' }}
            color="text.secondary"
          >
            <DataSnapshotsIcon sx={{ fontSize: 15, color: dataSnapshotColor }} />{' '}
            {DateTime.fromJSDate(snapshot.createdAt.toDate()).toFormat('yyyy-MM-dd hh:mm')}
          </Typography>
        )}
        {!snapshot?.id && (
          <Typography
            sx={{ fontSize: '90%', display: 'flex', gap: 0.5, alignItems: 'start' }}
            color="text.secondary"
          >
            latest
          </Typography>
        )}
      </Grid>
      <Grid item xs={6} onClick={() => navigate(projectAwarePath('SyncData', project))}>
        {project.lastUpdatedAt ? (
          <Box component="span" sx={{ whiteSpace: 'nowrap' }}>
            updated <TimeAgo date={project.lastUpdatedAt.toDate()} max={Number.MAX_SAFE_INTEGER} />
          </Box>
        ) : (
          'Last Update unknown'
        )}
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ m: 1 }} />
      </Grid>
    </Grid>
  );
};
