import LockedIcon from '@mui/icons-material/LockOutlined';
import { Box, IconButton, Paper, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { MultiFactorInfo, RecaptchaVerifier } from 'firebase/auth';
import { useEffect, useState } from 'react';

import { MfaActions } from './MfaActions.js';
import { ReCaptcha } from './ReCaptcha.js';
import { useAuth } from '../../../../../AuthReactProvider.js';
import { mfaLog } from '../../../../helpers/logger.js';
import { reloadUser } from '../reloadUser.js';

const steps = ['authenticate', 'Add phone number', 'Verify phone number'];

export const ManageMFA = () => {
  const { user, setUser } = useAuth();
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [enrolledFactors, setEnrolledFactors] = useState<MultiFactorInfo[]>([]);
  const [recaptchaVerifier, setRecaptchaVerifier] = useState<RecaptchaVerifier>();
  const [passwordRequired, setPasswordRequired] = useState<boolean>(false);
  const [verificationCodeRequired, setVerificationCodeRequired] = useState<boolean>(false);
  const [addFactor, setAddFactor] = useState<boolean>(false);
  const [isReAuthenticated, setIsReAuthenticated] = useState<boolean>(false);
  const [editable, setEditable] = useState<boolean>(false);
  const [verificationId, setVerificationId] = useState<string>('');
  const [activeStep, setActiveStep] = useState<number | undefined>();

  useEffect(() => {
    if (!user) return;
    mfaLog.log('(re-)initialize MFA');
    void reloadUser({ user, setUser, setEnrolledFactors });
  }, [setUser, user]);

  if (!user) return null;

  return (
    <Paper data-testid="mfa-card" sx={{ p: 2, maxWidth: 500, position: 'relative' }} elevation={0}>
      <ReCaptcha setRecaptchaVerifier={setRecaptchaVerifier} />
      <Typography data-testid="mfa-header" variant="h5" sx={{ mb: 4 }}>
        2-Factor Authentication
      </Typography>
      {enrolledFactors.length > 0 && !editable && (
        <IconButton
          size="small"
          data-testid="mfa-edit"
          sx={{ position: 'absolute', top: 8, right: 8 }}
          onClick={() => setEditable(true)}
        >
          <LockedIcon fontSize="small" color="error" />
        </IconButton>
      )}

      <Box data-testid="mfa-stepper" sx={{ width: '100%', mb: 3 }}>
        {activeStep !== undefined && (
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => {
              return (
                <Step key={label} completed={activeStep >= index}>
                  <StepLabel data-testid="mfa-step-label">
                    {index === activeStep ? label : ''}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        )}
      </Box>

      <MfaActions
        addFactor={addFactor}
        editable={editable}
        enrolledFactors={enrolledFactors}
        isReAuthenticated={isReAuthenticated}
        passwordRequired={passwordRequired}
        phoneNumber={phoneNumber}
        recaptchaVerifier={recaptchaVerifier}
        setAddFactor={setAddFactor}
        setEditable={setEditable}
        setIsReAuthenticated={setIsReAuthenticated}
        setPasswordRequired={setPasswordRequired}
        setPhoneNumber={setPhoneNumber}
        setVerificationCodeRequired={setVerificationCodeRequired}
        setVerificationId={setVerificationId}
        verificationCodeRequired={verificationCodeRequired}
        verificationId={verificationId}
        setEnrolledFactors={setEnrolledFactors}
        setActiveStep={setActiveStep}
      />
    </Paper>
  );
};
