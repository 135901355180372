import { Box, Stack } from '@mui/material';
import { purple } from '@mui/material/colors';
import CodeEditor from '@uiw/react-textarea-code-editor';
import { useEffect, useState } from 'react';

import { AiThreadItems } from './AiThreadItems';
import { getThreadIdForToolCall } from '../../../../../services/firestore/getThreadIdForToolCall';
import { ToolCall } from '../../../../../services/kaeplaTypes';
import { ContentExpander } from '../../../../features/ContentExpander';

interface ToolCallOptions {
  toolCall: ToolCall;
  showMarkdown: boolean;
}

const formatJson = (json?: string) => {
  if (!json) return '';
  try {
    return JSON.stringify(JSON.parse(json), null, 2);
  } catch {
    return json;
  }
};

const formatSqlFromJson = (json?: string) => {
  if (!json) return '';
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
    const out = JSON.parse(json);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
    return out.query.sql;
  } catch {
    return json;
  }
};

export const AiToolCall = ({ toolCall, showMarkdown }: ToolCallOptions) => {
  const [subThreadId, setSubThreadId] = useState<string>();

  useEffect(() => {
    const getSubThreadId = async () => {
      const _subThreadId = await getThreadIdForToolCall({ callId: toolCall.id });
      if (!_subThreadId) return;
      setSubThreadId(_subThreadId);
    };
    void getSubThreadId();
  }, [toolCall]);

  return (
    <ContentExpander label="Tool Call">
      <Stack
        direction="column"
        spacing={2}
        sx={{
          p: 1,
          m: 1,
          borderLeft: 3,
          borderColor: purple[300],
          whiteSpace: 'pre-wrap',
          fontFamily: 'monospace',
        }}
      >
        <Stack direction="row" alignItems="center" gap={2}>
          <Box>Tool Call</Box>
          <Box>{toolCall.input?.function.name}</Box>
          <Box>{toolCall.id}</Box>
        </Stack>
        <Stack direction="column" spacing={1}>
          <Box>INPUT</Box>
          <Box>{formatJson(toolCall.input?.function.arguments)}</Box>
        </Stack>
        {subThreadId && (
          <ContentExpander label="Tool Call Sub-Thread">
            <AiThreadItems threadId={subThreadId} showMarkdown={showMarkdown} />
          </ContentExpander>
        )}
        <Stack direction="column" spacing={1}>
          <Box>OUTPUT</Box>
          <Box>
            <CodeEditor
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              value={formatSqlFromJson(toolCall.output?.output)}
              data-color-mode="light"
              language="sql"
              padding={15}
              style={{
                fontSize: 14,
                fontFamily:
                  'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
              }}
            />
          </Box>
        </Stack>
      </Stack>
    </ContentExpander>
  );
};
