import { useRecoilValue } from 'recoil';

import { dataSourceState } from '../../../../services/recoil/nonpersistent/dataSourceState';
import { ErpOrdersManualReviewIndicator } from '../ERP-Orders/ErpOrdersManualReviewIndicator';
import { IhsVehiclesManualReviewIndicator } from '../IHS-Vehicles/IhsVehiclesManualReviewIndicator';

export const ManualReviewIndicator = () => {
  const selectedDataSource = useRecoilValue(dataSourceState);

  if (selectedDataSource.key === 'ihsVehicles') {
    return <IhsVehiclesManualReviewIndicator />;
  } else if (selectedDataSource.key === 'erpOrders') {
    return <ErpOrdersManualReviewIndicator />;
  }
  return null;
};
