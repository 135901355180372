import { Box, Link, Paper, Stack, Toolbar, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';

import { brandingState } from '../../../services/recoil/persistent/brandingState.js';
import { Layout } from '../../Layout/Layout.js';
import { broughtToYou } from '../../helpers/broughtToYou.js';
import { documentTitle } from '../../helpers/documentTitle.js';
import { toDoLog } from '../../helpers/logger.js';

export const Disclaimers = () => {
  const branding = useRecoilValue(brandingState);
  return (
    <Layout>
      <Paper data-testid="system-information" sx={{ p: 3, minWidth: 400, maxWidth: 600 }}>
        <Stack spacing={2}>
          <Toolbar disableGutters variant="dense">
            <Typography data-testid="header" variant="h5">
              System Information
            </Typography>
            <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
          </Toolbar>
          {documentTitle(branding)}
          {branding?.appLogoUrl && (
            <Box
              data-testid="app-logo"
              component="img"
              sx={{
                width: 150,
                minHeight: 20,
              }}
              alt={branding.appName}
              src={branding?.appLogoUrl}
            />
          )}
          <Box
            data-testid="provider-info"
            onClick={() => {
              toDoLog.log(branding);
            }}
          >
            {broughtToYou(branding)}{' '}
            {branding?.providerLinkUrl ? (
              <Link data-testid="provider-link" underline="hover" href={branding.providerLinkUrl}>
                {branding.appProvider}
              </Link>
            ) : (
              branding?.appProvider
            )}{' '}
            and runs on Kaepla, a big data analytics service build by{' '}
            <Link data-testid="atrigam-link" underline="hover" href="https://atrigam.com">
              Atrigam
            </Link>
            .
          </Box>
          <Box data-testid="copyright">
            {'© '} Atrigam {new Date().getFullYear()}
          </Box>
        </Stack>
      </Paper>
    </Layout>
  );
};
