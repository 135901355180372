import { atom } from 'recoil';

import { KaeplaTargets } from '../../kaeplaTypes/Application/KaeplaTargets.js';

const defaultTargetsState: Partial<KaeplaTargets> | undefined = undefined;

export const targetsState = atom<Partial<KaeplaTargets>>({
  key: 'Targets',
  default: defaultTargetsState,
});
