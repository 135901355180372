import { KaeplaSimulationParameterGranularity } from '../../../../../services/kaeplaTypes/Application/KaeplaSimulationParameterGranularity.js';

export const sliderGranularityMap: Record<string, Record<string, number>> = {
  [KaeplaSimulationParameterGranularity.max]: {
    max: 100,
    step: 1,
  },
  [KaeplaSimulationParameterGranularity.mid]: {
    max: 50,
    step: 0.5,
  },
  [KaeplaSimulationParameterGranularity.min]: {
    max: 10,
    step: 0.1,
  },
};
