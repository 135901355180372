import { IhsVehicleFull } from '../../types/IhsVehicleFull';
import { IhsVehiclesConfigurationSchemaField } from '../../types/IhsVehiclesConfigurationSchemaField';

export const vehicleSchema: IhsVehiclesConfigurationSchemaField[] = [
  {
    fieldName: 'make' as keyof IhsVehicleFull,
    label: 'Manufacturer',
    isKeyField: true,
    isVehicleLabelField: true,
    width: 100,
  },
  {
    fieldName: 'model' as keyof IhsVehicleFull,
    label: 'Nameplate',
    isKeyField: true,
    isVehicleLabelField: true,
    width: 100,
  },
  {
    fieldName: 'sop' as keyof IhsVehicleFull,
    label: 'SOP',
    isVehicleLabelField: true,
    width: 80,
  },
  {
    fieldName: 'eop' as keyof IhsVehicleFull,
    label: 'EOP',
    isVehicleLabelField: true,
    width: 80,
  },
  {
    fieldName: 'fuelType' as keyof IhsVehicleFull,
    label: 'Fuel Type',
    width: 120,
  },
  {
    fieldName: 'fuelSystem' as keyof IhsVehicleFull,
    label: 'Fuel System',
    width: 120,
  },
  {
    fieldName: 'region' as keyof IhsVehicleFull,
    label: 'Region',
    isInsignificantField: true,
    width: 200,
  },
  {
    fieldName: 'volume' as keyof IhsVehicleFull,
    label: 'Volume (all years)',
    isValueField: true,
    width: 200,
  },
];
const currentYear = new Date().getFullYear();
const currentQuarter = Math.ceil((new Date().getMonth() + 1) / 3);
const startYear = 2024;
const endYear = 2034;

for (let year = startYear; year <= endYear; year++) {
  for (let quarter = 1; quarter <= 4; quarter++) {
    if (year === currentYear && quarter <= currentQuarter) {
      continue;
    }

    const fieldName = `Q${quarter} ${year}` as keyof IhsVehicleFull;
    const label = `Q${quarter} ${year}`;
    const width = 1;

    vehicleSchema.push({
      fieldName,
      label,
      isValueField: true,
      isCollapsible: true,
      width,
    });
  }
}
