import { getApp } from 'firebase/app';
import { deleteDoc, doc, getFirestore } from 'firebase/firestore';

interface Options {
  resellerId: string;
}

export const deleteReseller = async ({ resellerId }: Options) => {
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `resellers/${resellerId}`;
  const firestoreDocumentReference = doc(firestoreDatabase, fireStorePath);
  await deleteDoc(firestoreDocumentReference);
};
