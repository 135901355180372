import AddIcon from '@mui/icons-material/AddOutlined';
import SqlOffIcon from '@mui/icons-material/CodeOffOutlined';
import SqlIcon from '@mui/icons-material/CodeOutlined';
// import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DuplicateIcon from '@mui/icons-material/FileCopyOutlined';
import FilterIcon from '@mui/icons-material/FilterAlt';
import FilterOffIcon from '@mui/icons-material/FilterAltOffOutlined';
import SaveIcon from '@mui/icons-material/SaveOutlined';
import ChooseOffIcon from '@mui/icons-material/SearchOffOutlined';
import ChooseIcon from '@mui/icons-material/SearchOutlined';
// import HideIcon from '@mui/icons-material/VisibilityOffOutlined';
import {
  Box,
  Button,
  ButtonGroup,
  Collapse,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Typography,
} from '@mui/material';
import {
  Utils as QbUtils,
  Config,
  ImmutableTree,
  JsonTree,
} from '@react-awesome-query-builder/mui';
import { diff } from 'deep-object-diff';
import { Timestamp } from 'firebase/firestore';
import { DateTime } from 'luxon';
import { isEmpty } from 'rambda';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { v4 as uuidv4 } from 'uuid';

import { FilterPresetSelector } from './FilterPresetSelector.js';
import { useAuth } from '../../../../AuthReactProvider.js';
// import { deleteFilter } from '../../../../services/firestore/deleteFilter.js';
import { getFilter } from '../../../../services/firestore/getFilter.js';
import { saveFilterAsPreset } from '../../../../services/firestore/saveFilterAsPreset.js';
import { updateFilter } from '../../../../services/firestore/updateFilter.js';
import { KaeplaFilter } from '../../../../services/kaeplaTypes/Application/KaeplaFilter.js';
import { applicationState } from '../../../../services/recoil/nonpersistent/applicationState.js';
import { knownProjectFiltersState } from '../../../../services/recoil/nonpersistent/knownProjectFiltersState.js';
import { projectState } from '../../../../services/recoil/nonpersistent/projectState.js';
import { currentScopePathState } from '../../../../services/recoil/persistent/currentScopePathState.js';
import { filterSettingsState } from '../../../../services/recoil/persistent/filterSettingState.js';
import { filterSqlState } from '../../../../services/recoil/persistent/filterSqlState.js';
import { filterTreeState } from '../../../../services/recoil/persistent/filterTreeState.js';
import { kaeplaAssignmentState } from '../../../../services/recoil/persistent/kaeplaAssignmentState.js';
import { ExpandMore } from '../../ExpandMore.js';
import { InlineEdit } from '../../InlineEdit.js';
import { filterConfigInit } from '../helpers/filterConfig.js';
// import { KIconButton } from '../../KIconButton.js';

interface Options {
  tree: ImmutableTree;
  config: Config;
  choosePreset: boolean;
  setChoosePreset: Dispatch<SetStateAction<boolean>>;
  autosave: boolean;
  setAutosave: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FilterToolbar = ({
  tree,
  choosePreset,
  setChoosePreset,
  autosave,
  setAutosave,
}: Options) => {
  const { kaeplaUser } = useAuth();
  // global
  const application = useRecoilValue(applicationState);
  const project = useRecoilValue(projectState);
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const knownProjectFilters = useRecoilValue(knownProjectFiltersState);
  const [filterSettings, setFilterSettings] = useRecoilState(filterSettingsState);
  const resetFilterTree = useResetRecoilState(filterTreeState);
  const resetFilterSql = useResetRecoilState(filterSqlState);
  const resetFilterSettings = useResetRecoilState(filterSettingsState);

  // local
  const [filterPreset, setFilterPreset] = useState<KaeplaFilter<Timestamp>>();
  const [canSaveFilter, setCanSaveFilter] = useState(false);

  const handleExpandClick = () => {
    setChoosePreset(false);
    setFilterSettings({ ...filterSettings, isExpanded: !filterSettings.isExpanded });
  };

  const handleToggleSqlClick = () => {
    setFilterSettings({ ...filterSettings, showSql: !filterSettings.showSql });
  };

  const handleToggleActiveClick = () => {
    setFilterSettings({ ...filterSettings, isActive: !filterSettings.isActive });
  };

  const saveFilterAsync = async () => {
    if (!kaeplaUser) return;
    if (!filterPreset) return;
    const jsonTree = QbUtils.getTree(tree);
    const filterSql = QbUtils.sqlFormat(tree, filterConfigInit);
    const updatedFilterPreset = {
      ...filterPreset,
      filterSql,
      filterTreeStringified: JSON.stringify(jsonTree),
    } as KaeplaFilter<Timestamp>;
    const _filterPreset = await updateFilter({
      project,
      filter: updatedFilterPreset,
    });
    setFilterPreset(_filterPreset);
    setFilterSettings({ ...filterSettings, filterPresetId: _filterPreset.id });
    const newKnownProjectFilters = [
      ...knownProjectFilters.filter((k) => k.id !== _filterPreset.id),
      _filterPreset,
    ];

    const _canSaveFilter = isFilterSaveable(_filterPreset, newKnownProjectFilters);
    setCanSaveFilter(_canSaveFilter);
  };

  const saveNewFilterAsync = async () => {
    if (!kaeplaUser) return;
    if (!filterPreset?.name) {
      const jsonTree = QbUtils.getTree(tree);
      const filterSql = QbUtils.sqlFormat(tree, filterConfigInit);
      const _filterPreset = await saveFilterAsPreset({
        createdBy: kaeplaUser.uid,
        filterTreeStringified: JSON.stringify(jsonTree),
        filterSql,
        scopePath: currentScopePath,
        project,
        filterName: `New filter ${DateTime.now().toLocaleString(DateTime.DATETIME_MED)}`,
        isCopy: false,
      });
      setFilterPreset(_filterPreset);
      setFilterSettings({ ...filterSettings, filterPresetId: _filterPreset.id });
      return;
    }
  };

  const saveFilter = () => {
    if (!filterPreset) {
      void saveNewFilterAsync();
      return;
    }
    void saveFilterAsync();
  };

  const duplicateFilterAsync = async () => {
    if (!kaeplaUser) return;
    const jsonTree = QbUtils.getTree(tree);
    const jsonTreeNewId = { ...jsonTree, id: uuidv4() };
    const filterSql = QbUtils.sqlFormat(tree, filterConfigInit);
    const _filterPreset = await saveFilterAsPreset({
      createdBy: kaeplaUser.uid,
      filterTreeStringified: JSON.stringify(jsonTreeNewId),
      filterSql,
      scopePath: currentScopePath,
      project,
      filterName: filterPreset?.name,
      isCopy: true,
    });
    setFilterPreset(_filterPreset);
    setFilterSettings({ ...filterSettings, filterPresetId: _filterPreset.id });
  };

  const duplicateFilter = () => {
    void duplicateFilterAsync();
  };

  const isFilterSaveable = useCallback(
    (
      _filterPreset: KaeplaFilter<Timestamp> | undefined,
      _knownProjectFilters: KaeplaFilter<Timestamp>[],
    ) => {
      if (kaeplaAssignment?.devTeamMember) {
        return true; // we are a dev team member
      }
      if (!filterSettings.isExpanded) {
        return false; // only if expanded
      }
      if (_filterPreset?.name && _filterPreset.createdBy !== kaeplaUser?.uid) {
        return false; // we don't own this
      }
      // did the tree change?
      let jsonTree = {};
      let knownTree = {};
      if (_filterPreset?.filterTreeStringified) {
        jsonTree = JSON.parse(_filterPreset.filterTreeStringified) as JsonTree;
        knownTree = QbUtils.getTree(tree);
      }
      if (_filterPreset?.filterTreeStringified && isEmpty(diff(jsonTree, knownTree))) {
        return true; // tree did not change
      }
      return true;
    },
    [filterSettings.isExpanded, kaeplaAssignment?.devTeamMember, kaeplaUser?.uid, tree],
  );

  // check if we can save the filter when the tree or the preset changes
  useEffect(() => {
    if (!kaeplaUser) return;
    const existingPreset = { ...filterPreset } || {};
    const jsonTree = QbUtils.getTree(tree);
    const filterSql = QbUtils.sqlFormat(tree, filterConfigInit);
    const checkFilterPreset = {
      ...existingPreset,
      filterTree: jsonTree,
      filterSql,
    } as KaeplaFilter<Timestamp>;
    const _canSaveFilter = isFilterSaveable(checkFilterPreset, knownProjectFilters);
    // console.log('check if we can save the filter when the filterTree changes->', _canSaveFilter);
    // const knownTree = knownProjectFilters.find((k) => k.id === filterPreset.id)?.filterTree || {};
    // console.log(jsonTree, knownTree);
    setCanSaveFilter(_canSaveFilter);
  }, [tree, filterPreset, kaeplaUser, isFilterSaveable, knownProjectFilters]);

  useEffect(() => {
    if (!kaeplaUser) return;

    const loadFilterPreset = async () => {
      const filterFromServer = await getFilter({
        projectId: project.id,
        filterId: filterSettings?.filterPresetId ?? 'none',
      });

      if (filterFromServer) {
        setFilterPreset(filterFromServer);
        const _canSaveFilter = isFilterSaveable(filterFromServer, knownProjectFilters);
        setCanSaveFilter(_canSaveFilter);
      } else {
        // eslint-disable-next-line unicorn/no-useless-undefined
        setFilterPreset(undefined);
      }
    };
    void loadFilterPreset();
  }, [
    filterSettings?.filterPresetId,
    isFilterSaveable,
    kaeplaUser,
    knownProjectFilters,
    project.id,
  ]);

  useEffect(() => {
    if (!kaeplaUser || !autosave) return;
    if (!filterPreset) return;

    const doAutosave = async () => {
      const jsonTree = QbUtils.getTree(tree);
      const updatedFilterPreset = {
        ...filterPreset,
        filterTreeStringified: JSON.stringify(jsonTree),
      } as KaeplaFilter<Timestamp>;
      const _filterPreset = await updateFilter({
        project,
        filter: updatedFilterPreset,
      });
      setFilterPreset(_filterPreset);
      setFilterSettings({ ...filterSettings, filterPresetId: _filterPreset.id });
      const newKnownProjectFilters = [
        ...knownProjectFilters.filter((k) => k.id !== _filterPreset.id),
        _filterPreset,
      ];

      const _canSaveFilter = isFilterSaveable(_filterPreset, newKnownProjectFilters);
      setCanSaveFilter(_canSaveFilter);
    };

    setAutosave(false);
    void doAutosave();
  }, [
    autosave,
    filterPreset,
    filterSettings,
    isFilterSaveable,
    kaeplaUser,
    knownProjectFilters,
    project,
    setAutosave,
    setFilterSettings,
    tree,
  ]);

  return (
    <>
      <Toolbar sx={{ minHeight: 16 }} disableGutters variant="dense">
        <Typography sx={{ mt: 1, mr: 1, fontSize: '110%' }}>
          {filterSettings.isActive ? (
            <FilterIcon color="info" />
          ) : (
            <FilterOffIcon color="disabled" />
          )}
        </Typography>
        {filterPreset && (
          <InlineEdit
            fullWidth
            value={filterPreset.name}
            callback={(newValue) => {
              if (filterPreset.createdBy !== kaeplaUser?.uid) return;
              const newFilterPreset = { ...filterPreset };
              newFilterPreset.name = newValue;
              newFilterPreset.description = `${newValue} ${DateTime.now().toLocaleString(
                DateTime.DATETIME_MED,
              )}`;
              void updateFilter({ project, filter: newFilterPreset });
              setFilterPreset(newFilterPreset);
              setFilterSettings({ ...filterSettings, updatedAt: DateTime.now() });
            }}
            readOnly={filterPreset.createdBy !== kaeplaUser?.uid}
          />
        )}
        {filterPreset && <Box sx={{ width: 20 }} />}
        {!filterPreset && <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />}

        <ToggleButtonGroup size="small" color="primary" sx={{ mr: 1 }}>
          <ToggleButton
            sx={{ py: 0.5 }}
            value="toggle filter"
            selected={filterSettings.isActive}
            onClick={handleToggleActiveClick}
            disabled={filterSettings.ruleCount === 0}
          >
            {filterSettings.isActive ? (
              <FilterIcon fontSize="small" />
            ) : (
              <FilterOffIcon fontSize="small" />
            )}
          </ToggleButton>
          {kaeplaAssignment?.devTeamMember && application.benchmarks && (
            <ToggleButton
              sx={{ py: 0.5 }}
              value="toggle SQL"
              selected={filterSettings.showSql}
              onClick={handleToggleSqlClick}
            >
              {filterSettings.showSql ? (
                <SqlIcon fontSize="small" />
              ) : (
                <SqlOffIcon fontSize="small" />
              )}
            </ToggleButton>
          )}
          <ToggleButton
            sx={{ py: 0.5 }}
            value="choose filter preset"
            selected={filterSettings.isExpanded && choosePreset}
            aria-label="choose preset"
            onClick={() => {
              setFilterSettings({ ...filterSettings, isExpanded: true });
              setChoosePreset(!choosePreset);
            }}
          >
            {choosePreset ? <ChooseOffIcon fontSize="small" /> : <ChooseIcon fontSize="small" />}
          </ToggleButton>
        </ToggleButtonGroup>
        <ButtonGroup sx={{ mr: 2 }} disableElevation variant="outlined" size="small">
          <Button
            endIcon={<SaveIcon sx={{ m: 0.25, ml: -1 }} />}
            onClick={saveFilter}
            disabled={!canSaveFilter}
          />
          <Button
            endIcon={<AddIcon sx={{ ml: -1 }} />}
            onClick={() => {
              // we "add" a new filter by resetting filters
              resetFilterTree();
              resetFilterSql();
              resetFilterSettings();
            }}
            disabled={!filterSettings.filterPresetId}
          />
          <Button
            endIcon={<DuplicateIcon sx={{ ml: -1 }} />}
            onClick={duplicateFilter}
            disabled={!filterSettings.filterPresetId}
          />
        </ButtonGroup>
        <ExpandMore
          expand={filterSettings.isExpanded}
          onClick={handleExpandClick}
          aria-expanded={filterSettings.isExpanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </Toolbar>
      <Collapse in={choosePreset && filterSettings.isExpanded} timeout="auto">
        <FilterPresetSelector setChoosePreset={setChoosePreset} />
      </Collapse>
      {!choosePreset && filterPreset && filterSettings.isExpanded && (
        <Toolbar sx={{ minHeight: 16, display: 'none' }} disableGutters variant="dense">
          <InlineEdit
            fullWidth
            value={filterPreset.description ?? 'no description'}
            callback={(newValue) => {
              if (filterPreset.createdBy !== kaeplaUser?.uid) return;
              const newFilterPreset = { ...filterPreset };
              newFilterPreset.description = newValue;
              void updateFilter({ project, filter: newFilterPreset });
              setFilterPreset(newFilterPreset);
            }}
            readOnly={filterPreset.createdBy !== kaeplaUser?.uid}
          />
        </Toolbar>
      )}
    </>
  );
};
