export const getGridSpanNumber = (
  breakpointAsString: string,
  _gridspan?: 'small' | 'medium' | 'large',
) => {
  let gridspan = 'large';
  if (_gridspan) {
    gridspan = _gridspan;
  }
  switch (gridspan) {
    case 'small': {
      switch (breakpointAsString) {
        case 'xl': {
          return 4;
        }
        case 'lg': {
          return 4;
        }
        case 'md': {
          return 6;
        }
        case 'sm': {
          return 12;
        }
        case 'xs': {
          return 12;
        }
        default: {
          return 12;
        }
      }
    }
    case 'medium': {
      switch (breakpointAsString) {
        case 'xl': {
          return 6;
        }
        case 'lg': {
          return 6;
        }
        case 'md': {
          return 12;
        }
        case 'sm': {
          return 12;
        }
        case 'xs': {
          return 12;
        }
        default: {
          return 12;
        }
      }
    }
    case 'large': {
      return 12;
    }
    default: {
      return 12;
    }
  }
};
