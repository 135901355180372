import SimulationIcon from '@mui/icons-material/AutoGraphOutlined';
import {
  Toolbar,
  Typography,
  Alert,
  Box,
  Grid,
  Card,
  LinearProgress,
  MenuItem,
  Stack,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { TargetFigureHeaderLine } from './TargetFigureHeaderLine.js';
import { TargetFigureLine } from './TargetFigureLine.js';
import { TargetsChart } from './TargetsChart.js';
import { useAuth } from '../../../AuthReactProvider.js';
import { useUserPreferences } from '../../../UserPreferencesProvider';
import { getKaeplaTargets } from '../../../services/api/getKaeplaTargets.js';
import { addTargetsListener } from '../../../services/firestore/addTargetsListener.js';
import { getProject } from '../../../services/firestore/getProject.js';
import { getTargets } from '../../../services/firestore/getTargets.js';
import { toggleSimulationFavorite } from '../../../services/firestore/toggleSimulationFavorite';
import { KaeplaSimulation } from '../../../services/kaeplaTypes/Application/KaeplaSimulation';
import { KaeplaTargets } from '../../../services/kaeplaTypes/Application/KaeplaTargets.js';
import { KaeplaTargetsFigure } from '../../../services/kaeplaTypes/Application/KaeplaTargetsFigure.js';
import { MatrixJob } from '../../../services/kaeplaTypes/MatrixJob.js';
import { projectState } from '../../../services/recoil/nonpersistent/projectState.js';
import { simulationState } from '../../../services/recoil/nonpersistent/simulationState';
import { simulationsState } from '../../../services/recoil/nonpersistent/simulationsState';
import { targetsState } from '../../../services/recoil/nonpersistent/targetsState.js';
import { currentScopePathState } from '../../../services/recoil/persistent/currentScopePathState';
import { Layout } from '../../Layout/Layout.js';
import { BQJobInfo } from '../../features/BQJobInfo.js';
import { CardContentMinPadding } from '../../features/CardContentMinPadding.js';
import { cleanColumnName } from '../../helpers/cleanColumnName';
import { HumanReadableTimestampType, convertTimestamp } from '../../helpers/convertTimestamp.js';
import { requestLog } from '../../helpers/logger.js';
import {
  matrixDataColorLight,
  matrixDataColor,
  targetsDataColorLight,
  targetsDataColor,
  simulationsColor,
} from '../defaults';

export const TargetsEditor = () => {
  const { id, projectId } = useParams();
  const { kaeplaUser } = useAuth();
  const navigate = useNavigate();
  const { setPreferences } = useUserPreferences();
  const [project, setProject] = useRecoilState(projectState);
  const [targets, setTargets] = useRecoilState(targetsState);
  const simulations = useRecoilValue(simulationsState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const setSimulation = useSetRecoilState(simulationState);
  const [loadingTargets, setLoadingTargets] = useState(true);
  const [loadingTargetFigures, setLoadingTargetFigures] = useState(true);
  const [updatedAt, setUpdatedAt] = useState<string>('n/a');
  const [job, setJob] = useState<MatrixJob>();
  const [targetFigures, setTargetFigures] = useState<KaeplaTargetsFigure[]>();
  const [baseSimulation, setBaseSimulation] = useState<KaeplaSimulation>();
  const [batchActionsEnabled, setBatchActionsEnabled] = useState(false);
  const [selectedTargetFigures, setSelectedTargetFigures] = useState<KaeplaTargetsFigure[]>([]);

  const goToSimulation = () => {
    if (!kaeplaUser?.uid) return;
    if (!baseSimulation) return;
    if (!currentScopePath) return;
    setSimulation(baseSimulation);
    setPreferences({ lastSimulationId: baseSimulation.id });

    void toggleSimulationFavorite({
      uid: kaeplaUser.uid,
      simulation: baseSimulation,
      scopePath: currentScopePath,
      isFavorite: true,
    });
    navigate(`/Simulation/${project.id}/${baseSimulation.id}`);
  };

  const updateTargets = (_targetFigures: KaeplaTargetsFigure[]) => {
    void getKaeplaTargets({
      params: {
        projectId: project.id,
        targetsId: targets.id,
        targetFigures: _targetFigures,
      },
    });
  };

  const deleteTargetFigure = (_targetFigure: KaeplaTargetsFigure) => {
    if (!targetFigures) return;
    const newTargetFigures = [...targetFigures].filter((f) => f.id !== _targetFigure.id);
    setTargetFigures(newTargetFigures);
    void updateTargets(newTargetFigures);
  };

  const batchDeleteTargetFigures = () => {
    if (!targetFigures) return;
    const newTargetFigures = [...targetFigures].filter(
      (f) => !selectedTargetFigures.map((s) => s.id).includes(f.id),
    );
    setTargetFigures(newTargetFigures);
    void updateTargets(newTargetFigures);
  };

  // this loads the targets object from firestore
  useEffect(() => {
    const load = async () => {
      if (!kaeplaUser?.uid) return;
      if (!projectId || !id) return;
      const projectFromServer = await getProject({
        id: projectId,
      });
      setProject(projectFromServer);
      const targetsFromServer = await getTargets({
        projectId: projectFromServer.id,
        targetsId: id,
      });
      if (!targetsFromServer) {
        setLoadingTargets(false);
        return;
      }
      setTargets(targetsFromServer);
      if (targetsFromServer?.simulationId) {
        setBaseSimulation(simulations.find((s) => s.id === targetsFromServer.simulationId));
      }
      setLoadingTargets(false);
    };
    void load();
  }, [id, kaeplaUser?.uid, projectId, setProject, setTargets, simulations]);

  // this loads the targets from bq
  useEffect(() => {
    if (!kaeplaUser?.uid) return;
    if (!project?.id || !targets?.id) return;
    if (loadingTargets) return;
    void getKaeplaTargets({
      setLoading: setLoadingTargets,
      callBack: (apiResponse) => {
        // const result = apiResponse;
        const _targetFigures = apiResponse.rows;
        if (_targetFigures) {
          setTargetFigures(_targetFigures.sort((a, b) => a.validAsOf.localeCompare(b.validAsOf)));
          setJob(apiResponse.job);
        }
        setLoadingTargetFigures(false);
      },
      errorCallBack: (error) => {
        requestLog.error(error);
        setLoadingTargetFigures(false);
      },
      params: {
        projectId: project.id,
        targetsId: targets.id,
      },
    });
  }, [
    kaeplaUser?.uid,
    loadingTargets,
    project?.id,
    setTargets,
    targets?.id,
    targets?.isBeingUpdated,
  ]);

  // this is the firestore targetsListener
  useEffect(() => {
    if (!kaeplaUser?.uid) return;
    if (!project?.id || !targets?.id) return;
    const unsubscribe = addTargetsListener({
      projectId: project.id,
      targetsId: targets.id,
      callback: (dataFromServer) => {
        const targetsFromServer = dataFromServer as KaeplaTargets;
        setTargets(targetsFromServer);
        if (targetsFromServer?.updatedAt) {
          const humanReadableTimeStamp = convertTimestamp(
            targetsFromServer.updatedAt,
            HumanReadableTimestampType.timeago,
          );
          setUpdatedAt(humanReadableTimeStamp);
        }
      },
    });
    return () => {
      requestLog.log(`unsubscribe targets Listener`);
      unsubscribe();
    };
  }, [kaeplaUser?.uid, project?.id, setTargets, targets?.id]);

  if (loadingTargets) {
    return (
      <Layout>
        <Alert severity="info">loading targets</Alert>
      </Layout>
    );
  }

  if (!targets?.id) {
    return (
      <Layout hasScopeNavigation showCustomerSelector>
        <Alert severity="error">Could not load targets</Alert>
      </Layout>
    );
  }

  return (
    <Layout hasScopeNavigation showCustomerSelector>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card sx={{ minHeight: 280, height: '100%' }}>
            {targets.isBeingUpdated ?? loadingTargetFigures ? (
              <LinearProgress />
            ) : (
              <Box sx={{ height: 4 }} />
            )}
            <CardContentMinPadding sx={{ pt: 1.1 }}>
              <Typography variant="h6">
                {targets.dimension && cleanColumnName(targets.dimension)}
              </Typography>
              <TargetsChart
                loading={targets.isBeingUpdated ?? loadingTargetFigures}
                targetFigures={targetFigures ?? []}
              />
              <Box fontSize={12} pt={1}>
                <Box
                  component="span"
                  display="inline-block"
                  border={1}
                  borderRadius={6}
                  width={12}
                  height={12}
                  bgcolor={matrixDataColorLight}
                  borderColor={matrixDataColor}
                  mr={1}
                />
                Original Data
                <Box
                  component="span"
                  display="inline-block"
                  border={1}
                  borderRadius={6}
                  width={12}
                  height={12}
                  bgcolor={targetsDataColorLight}
                  borderColor={targetsDataColor}
                  ml={2}
                  mr={1}
                />
                Targets
              </Box>
            </CardContentMinPadding>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Toolbar disableGutters variant="dense">
            <Typography variant="h5">{project.name} Targets</Typography>
            <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
            {baseSimulation && (
              <Stack
                component={MenuItem}
                direction="row"
                alignItems="center"
                spacing={2}
                onClick={() => {
                  void goToSimulation();
                }}
              >
                <Typography variant="body2">based on</Typography>
                <Typography component="span" variant="body1" sx={{ fontSize: 18 }}>
                  {baseSimulation.name}
                </Typography>
                <SimulationIcon sx={{ color: simulationsColor }} />
              </Stack>
            )}
          </Toolbar>
          {/* <Box>{targets.id}</Box> */}
          <Box>updated {updatedAt}</Box>
          {job && <BQJobInfo job={job} forceShow />}
        </Grid>
        {targetFigures && (
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <TargetFigureHeaderLine
                  targets={targets}
                  editable={!targets.isBeingUpdated}
                  batchActionsEnabled={batchActionsEnabled}
                  setBatchActionsEnabled={setBatchActionsEnabled}
                  selectedTargetFigures={selectedTargetFigures}
                  batchDeleteTargetFigures={batchDeleteTargetFigures}
                  setSelectedTargetFigures={setSelectedTargetFigures}
                />
              </Grid>
              {targetFigures.map((f) => (
                <Grid item xs={12} key={f.id}>
                  <TargetFigureLine
                    targetFigure={f}
                    deleteTargetFigure={deleteTargetFigure}
                    editable={!targets.isBeingUpdated}
                    batchActionsEnabled={batchActionsEnabled}
                    setSelectedTargetFigures={setSelectedTargetFigures}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Layout>
  );
};
