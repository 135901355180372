import AiRulesIcon from '@mui/icons-material/AutoAwesomeOutlined';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  List,
  ListItem,
  Stack,
  Box,
} from '@mui/material';

interface AiDialogOptions {
  open: boolean;
  onClose: (value: boolean) => void;
}

const aiDialogIntro = `We detected a pattern how you match records. 
We can generate a rule from this to suggest matches for you to review. AI-rules can boost your productivity.`;
const aiDialogOptionsInfo = [
  `accept or reject the suggestions.`,
  `activate and deactivate rules.`,
  `auto-accept suggestions from rules.`,
];

export const AiDialog = ({ open, onClose }: AiDialogOptions) => {
  const handleClose = () => {
    onClose(false);
  };

  const handleAccept = () => {
    onClose(true);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle variant="h4">
        <Stack direction="row" spacing={1} gap={1}>
          <AiRulesIcon />
          <Box>Ai Assistant</Box>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {aiDialogIntro}
          <br />
          <br />
          You will be able to
          <List>
            {aiDialogOptionsInfo.map((info, index) => (
              <ListItem key={index}>{info}</ListItem>
            ))}
          </List>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="inherit"
          onClick={() => {
            handleAccept();
          }}
        >
          Disagree
        </Button>
        <Button
          color="primary"
          onClick={() => {
            handleAccept();
          }}
        >
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};
