import { Box } from '@mui/material';
import { green } from '@mui/material/colors';

export const ContinueConversation = () => {
  return (
    <Box border={1} borderLeft={3} borderColor={green[300]} borderRadius={1} p={1}>
      You can continue this conversation, the last 10 questions and answers are well remembered.
    </Box>
  );
};
