import { DateTime } from 'luxon';

import { KaeplaImport } from '../../../../services/kaeplaTypes';

export const dataImportEndedAt = (dataImport: KaeplaImport) => {
  let ended = '';
  if (dataImport.updatedAt) {
    ended = DateTime.fromMillis(dataImport.updatedAt.toMillis())
      .setLocale('en')
      .toLocaleString({
        ...DateTime.DATETIME_MED_WITH_SECONDS,
      });
  } else {
    return '';
  }
  return `⟻ ${ended}`;
};
