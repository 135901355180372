import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ThumbDownVoteIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbDownIcon from '@mui/icons-material/ThumbDownAltOutlined';
import ThumbUpVoteIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbUpIcon from '@mui/icons-material/ThumbUpAltOutlined';
import {
  Box,
  Button,
  CardActions,
  Collapse,
  IconButton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { green, grey } from '@mui/material/colors';
import copy from 'copy-to-clipboard';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useDebouncedCallback } from 'use-debounce';

import { updateAiQuestionLog } from '../../../../services/firestore/updateAiQuestionLog';
import { AiQuestionLog } from '../../../../services/kaeplaTypes/importer/AiQuestionLog';
import { PromptAnswer } from '../../../../services/kaeplaTypes/prompt/PromptAnswer';
import { kaeplaAssignmentState } from '../../../../services/recoil/persistent/kaeplaAssignmentState';
import { ExpandMore } from '../../../features/ExpandMore';
import { AnswerPerAnswerType } from '../AnswerPerAnswerType';
import { agoColor } from '../helpers/agoColor';
import { isOld } from '../helpers/isOld';

interface Options {
  answer: PromptAnswer;
  log: AiQuestionLog;
}

export const Answer = ({ answer, log }: Options) => {
  const theme = useTheme();
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  const [downVote, setDownVote] = useState(log.useful === false);
  const [upVote, setUpVote] = useState(log.useful === true);
  const [showFeedBack, setShowFeedBack] = useState(false);
  const [showSql, setShowSql] = useState(false);
  const [feedbackSent, setFeedbackSent] = useState(false);
  const [userFeedback, setUserFeedback] = useState(log.feedback ?? '');
  const [showOutdated, setShowOutdated] = useState(false);
  const { query, answerType, checkQuery, description } = answer;

  const sendFeedback = useDebouncedCallback(async (feedBackText: string) => {
    const aiQuestionLogUpdate: Partial<AiQuestionLog> = {
      id: log.id,
      feedback: feedBackText,
      acceptedQuery: checkQuery,
    };
    await updateAiQuestionLog(aiQuestionLogUpdate);
    setFeedbackSent(false);
  }, 1500);

  const doDownVote = () => {
    const aiQuestionLogUpdate: Partial<AiQuestionLog> = {
      id: log.id,
      useful: false,
    };
    void updateAiQuestionLog(aiQuestionLogUpdate);
  };

  const doUpVote = () => {
    const aiQuestionLogUpdate: Partial<AiQuestionLog> = {
      id: log.id,
      acceptedQuery: checkQuery,
      useful: true,
    };
    void updateAiQuestionLog(aiQuestionLogUpdate);
  };

  return (
    <Box
      border={1}
      borderLeft={query ? 3 : 1}
      borderColor={query ? green[200] : grey[500]}
      borderRadius={1}
      p={1}
    >
      {description && (
        <Typography variant="body2" sx={{ py: 1, display: 'none' }}>
          {description}
        </Typography>
      )}
      <AnswerPerAnswerType answer={answer} />
      <CardActions disableSpacing>
        <IconButton
          onClick={() => {
            setUpVote(!upVote);
            void doUpVote();
            setDownVote(false);
            setShowFeedBack(false);
          }}
          onFocus={() => {
            setShowOutdated(true);
          }}
          onBlur={() => {
            setShowOutdated(false);
          }}
          onMouseEnter={() => {
            setShowOutdated(true);
          }}
          onMouseLeave={() => {
            setShowOutdated(false);
          }}
        >
          {upVote ? <ThumbUpVoteIcon fontSize="small" /> : <ThumbUpIcon fontSize="small" />}
        </IconButton>
        <IconButton
          onClick={() => {
            setShowFeedBack(!downVote);
            setDownVote(!downVote);
            void doDownVote();
            setUpVote(false);
          }}
          onMouseEnter={() => {
            setShowOutdated(true);
          }}
          onMouseLeave={() => {
            setShowOutdated(false);
          }}
        >
          {downVote ? <ThumbDownVoteIcon fontSize="small" /> : <ThumbDownIcon fontSize="small" />}
        </IconButton>
        {isOld(log.askedAt) && (
          <Typography
            variant="caption"
            sx={{ color: agoColor(theme, log.askedAt), opacity: showOutdated ? 1 : 0 }}
          >
            this answer might be outdated
          </Typography>
        )}
        <ExpandMore
          size="small"
          expand={showFeedBack}
          onClick={() => {
            setShowFeedBack(!showFeedBack);
          }}
          aria-expanded={showFeedBack}
          aria-label="show more"
        >
          <ExpandMoreIcon fontSize="small" />
        </ExpandMore>
      </CardActions>
      <Collapse in={showFeedBack} timeout="auto" unmountOnExit>
        <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
          {downVote && (
            <>
              <Typography>What did you expect?</Typography>
              <TextField
                fullWidth
                label="Feedback"
                placeholder="Tell us what you expected"
                id="fullWidth"
                value={userFeedback}
                onChange={(event) => {
                  setUserFeedback(event.target.value);
                }}
              />
              {feedbackSent && (
                <Typography sx={{ color: 'InfoText' }}>Thanks for your feedback!</Typography>
              )}
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  setFeedbackSent(true);
                  void sendFeedback(userFeedback);
                }}
              >
                {userFeedback === '' ? 'send' : 'update'}
              </Button>
            </>
          )}
          {showSql && kaeplaAssignment && (
            <Box bgcolor="lightGrey" p={2} sx={{ fontFamily: 'monospace', fontSize: 12 }}>
              {checkQuery}
            </Box>
          )}
          <Stack direction="row" spacing={1} sx={{ fontSize: 12 }}>
            <Box
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                copy(log.id);
              }}
            >
              Question-ID: {log.id}
            </Box>
            <Box>Result-Type: {answerType}</Box>
            {kaeplaAssignment && (
              <Box
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setShowSql(!showSql);
                }}
              >
                {showSql ? 'hide' : 'show'} SQL
              </Box>
            )}
          </Stack>
        </Stack>
      </Collapse>
    </Box>
  );
};
