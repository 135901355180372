import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { Box, IconButton, Typography } from '@mui/material';
import { multiFactor, MultiFactorInfo, PhoneMultiFactorInfo } from 'firebase/auth';
import { Dispatch, SetStateAction } from 'react';

import { useAuth } from '../../../../../AuthReactProvider.js';
import { mfaLog } from '../../../../helpers/logger.js';
import { reloadUser } from '../reloadUser.js';

interface Options {
  factor: PhoneMultiFactorInfo;
  editable: boolean;
  setEnrolledFactors: Dispatch<SetStateAction<MultiFactorInfo[]>>;
  setEditable: Dispatch<SetStateAction<boolean>>;
}
export const AuthenticationFactor = ({
  factor,
  editable,
  setEnrolledFactors,
  setEditable,
}: Options) => {
  const { user, setUser } = useAuth();

  const unEnrollFactorAndReloadUser = async () => {
    if (!user) return;
    mfaLog.log('unenroll phone number', factor.phoneNumber);
    await multiFactor(user).unenroll(factor);
    void reloadUser({ user, setUser, setEnrolledFactors });
  };

  return (
    <Box data-testid="mfa-factor">
      <Typography variant="body1" sx={{ fontSize: '1.2rem' }}>
        {factor.phoneNumber}
        <IconButton
          size="small"
          data-testid="mfa-delete-factor"
          sx={{ verticalAlign: 'bottom' }}
          onClick={() => {
            setEditable(false);
            void unEnrollFactorAndReloadUser();
          }}
          disabled={!editable}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Typography>
    </Box>
  );
};
