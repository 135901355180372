import { TableBody, TableCell, TableRow, lighten, useTheme } from '@mui/material';

import { INSIGNIFICANT_OPACITY } from '../../config';
import { IhsVehicleFull } from '../../types/IhsVehicleFull';
import { vehicleSchema } from '../data/vehicleSchema';

interface Options {
  ihsVehicle: IhsVehicleFull;
}

export const OneAndN2ZeroMatchDetailRenderer = ({ ihsVehicle }: Options) => {
  const theme = useTheme();
  const resultRowBgColor = lighten(theme.palette.success.light, 0.85);

  return (
    <TableBody>
      {ihsVehicle.matches.length > 1 &&
        ihsVehicle.matches.map((vehicle) => (
          <TableRow key={vehicle.id}>
            {vehicleSchema
              .filter((field) => !field.isCollapsible || ihsVehicle.isFieldsExpanded)
              .map((field) => (
                <TableCell
                  key={field.fieldName}
                  sx={{
                    whiteSpace: 'nowrap',
                    opacity: field.isInsignificantField ? INSIGNIFICANT_OPACITY : 1,
                    width: field.width ?? 'auto',
                  }}
                >
                  {vehicle[field.fieldName] as string}
                </TableCell>
              ))}
          </TableRow>
        ))}
      <TableRow key={ihsVehicle.id} sx={{ backgroundColor: resultRowBgColor }}>
        {vehicleSchema
          .filter((field) => {
            if (!ihsVehicle.isFieldsExpanded && field.isCollapsible) return false;
            return true;
          })
          .map((field) => (
            <TableCell
              key={field.fieldName}
              sx={{
                fontWeight: field.isValueField ?? field.isKeyField ? 'bold' : 100,
                whiteSpace: 'nowrap',
                opacity: field.isInsignificantField ? INSIGNIFICANT_OPACITY : 1,
              }}
            >
              {ihsVehicle[field.fieldName] as string}
            </TableCell>
          ))}
      </TableRow>
    </TableBody>
  );
};
