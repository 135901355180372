import { Box, Tab, Tabs } from '@mui/material';
import { useState } from 'react';

import { AiMeta } from './AiMeta/AiMeta.js';
import { Dummy } from './Dummy/Dummy.js';
import { GroupStack } from './Sandbox/GroupStack.js';
import { Layout } from '../../Layout/Layout.js';

interface TabPanelProperties {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(properties: TabPanelProperties) {
  const { children, value, index, ...other } = properties;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProperties(index: number) {
  return {
    id: `simple-tab-${index}`,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const Logs = () => {
  const [value, setValue] = useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Layout>
      <Box sx={{ m: -2 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="AI Meta" {...a11yProperties(0)} />
            <Tab label="Events" {...a11yProperties(1)} />
            <Tab label="Imports" {...a11yProperties(2)} />
            <Tab label="Dummy" {...a11yProperties(3)} />
            <Tab label="GroupStack" {...a11yProperties(4)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <AiMeta />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          Not implemented yet
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          Not implemented yet
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <Dummy />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <GroupStack />
        </CustomTabPanel>
      </Box>
    </Layout>
  );
};
