import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';

import { requestLog } from '../../Frontend/helpers/logger.js';

interface KOptions {
  params: Record<string, unknown>;
}
export const deltaImportOnCall = ({ params }: KOptions) => {
  const functions = getFunctions(getApp('kaepla'), 'europe-west3');
  const deltaImport = httpsCallable(functions, 'deltaImport', { timeout: 700_000 });

  requestLog.log(`deltaImport(${JSON.stringify(params)})`);
  void deltaImport(params);
};
