import { getApp } from 'firebase/app';
import { doc, DocumentData, getFirestore, Timestamp, updateDoc } from 'firebase/firestore';

import { KaeplaProject } from '../kaeplaTypes/Application/KaeplaProject.js';

interface Options {
  project: Partial<KaeplaProject>;
}

export const updateProject = async ({ project }: Options) => {
  if (!project.id) return;
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `projects/${project.id}`;
  const firestoreDocumentReference = doc(firestoreDatabase, fireStorePath);
  await updateDoc(firestoreDocumentReference, {
    ...project,
    updatedAt: Timestamp.now(),
  } as DocumentData);
};
