import { useRecoilValue } from 'recoil';

import { dataSourceState } from '../../../../services/recoil/nonpersistent/dataSourceState';
import { ErpOrdersMatchBuckets } from '../ERP-Orders/ErpOrdersMatchBuckets';
import { IhsVehiclesMatchBuckets } from '../IHS-Vehicles/IhsVehiclesMatchBuckets';

interface Options {
  explain?: boolean;
}

export const MatchBucketPanel = ({ explain }: Options) => {
  const selectedDataSource = useRecoilValue(dataSourceState);

  if (selectedDataSource.key === 'ihsVehicles') {
    return <IhsVehiclesMatchBuckets explain={explain} />;
  }
  if (selectedDataSource.key === 'erpOrders') {
    return <ErpOrdersMatchBuckets explain={explain} />;
  }
  return null;
};
