import { useRecoilValue } from 'recoil';

import { KaeplaPerspectiveBlock } from '../../../../../../../services/kaeplaTypes/Application/KaeplaPerspectiveBlock.js';
import { knownProjectFiltersState } from '../../../../../../../services/recoil/nonpersistent/knownProjectFiltersState.js';
import { cleanAggregation } from '../../../../../../helpers/cleanAggregation.js';
import { cleanColumnName } from '../../../../../../helpers/cleanColumnName.js';

interface ABOptions {
  block: KaeplaPerspectiveBlock;
}
export const StackedBarChartTitle = ({ block }: ABOptions) => {
  const valueDimension = cleanColumnName(block.valueDimension ?? 'n/a');
  const aggregation = cleanAggregation(block.aggregation);
  const aggregationDimension = cleanColumnName(block.aggregationDimension ?? 'n/a');
  const time = cleanColumnName(block.timeDimension ?? 'n/a');
  const filterId = block.filterId;
  const knownProjectFilters = useRecoilValue(knownProjectFiltersState);

  let title = (
    <>
      {valueDimension} <u>{aggregation}</u> per <b>{aggregationDimension}</b> over {time}
    </>
  );

  if (knownProjectFilters && filterId) {
    const filter = knownProjectFilters.find((k) => k.id === filterId);
    if (filter?.name) {
      title = (
        <>
          {valueDimension} <u>{aggregation}</u> per <b>{aggregationDimension}</b> over {time} for{' '}
          {filter.name}
        </>
      );
    }
  }

  return title;
};
