import { generateOrdersMatch } from './generateOrdersMatch';
import { getCustomerData } from '../ERP-Orders/data/customers';
import { ERP_ORDERS_MATCH_TYPE_COUNTS } from '../config';
import { ErpOrder } from '../types/ErpOrder';
import { MatchType } from '../types/MatchType';
interface SupplierProduct {
  name: string;
  version: string;
}

export const generateVersionNumber = () => {
  const version = Math.floor(Math.random() * 10) + 1; // Generate a random number between 1 and 10
  return `v${version}`;
};

export const generateRevisionNumber = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const version = Math.floor(Math.random() * 10) + 1;
  let randomString = version.toString();
  for (let index = 0; index < 3; index++) {
    randomString += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return `${randomString}${version}`;
};

const supplierProducts: SupplierProduct[] = [
  { name: 'Windsceen Rain Sensor', version: generateVersionNumber() },
  { name: 'Proximity Sensor', version: generateVersionNumber() },
  { name: 'Fastener', version: generateVersionNumber() },
  { name: 'Interior Component', version: generateVersionNumber() },
  // Add more products here
];

const generateProductionPlan = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let vin = '';
  for (let index = 0; index < 17; index++) {
    vin += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return vin;
};

export const generateErpOrders = () => {
  const orders: ErpOrder[] = [];
  const matchTypeCountsTotal = ERP_ORDERS_MATCH_TYPE_COUNTS.reduce(
    (accumulator, current) => accumulator + current.count,
    0,
  );

  const customersData = getCustomerData();
  for (let index = 0; index < matchTypeCountsTotal; index++) {
    const customerData = customersData[Math.floor(Math.random() * customersData.length)];
    const customerMaterialNumber =
      customerData.materialNumbers[Math.floor(Math.random() * customerData.materialNumbers.length)];
    const productionPlan = generateProductionPlan();
    const materialNumber = generateProductionPlan();
    const product = supplierProducts[Math.floor(Math.random() * supplierProducts.length)];
    const productVersion = product.version;
    const productHierarchy = `${product.name} ${product.version}`;
    const productRevision = generateRevisionNumber();
    const matchTypePercentage = Math.random();
    let cumulativePercentage = 0;
    let matchType: MatchType | undefined;

    for (const matchTypeCount of ERP_ORDERS_MATCH_TYPE_COUNTS) {
      const matchTypePercentageRange = matchTypeCount.count / matchTypeCountsTotal;
      cumulativePercentage += matchTypePercentageRange;

      if (matchTypePercentage <= cumulativePercentage) {
        matchType = matchTypeCount.matchType;
        break;
      }
    }

    const updates = matchType === MatchType.ONE2ONE ? Math.random() < 0.5 : undefined;
    const erpOrder: ErpOrder = {
      id: String(index + 1),
      customerNumber: customerData.customer.number,
      customerName: customerData.customer.name,
      customerMaterialNumber,
      materialNumber,
      productionPlan,
      productHierarchy,
      productVersion,
      productRevision,
      matchType,
      updates,
      quantity: 0,
      matches: [],
      isFieldsExpanded: false,
      quantityPercentChange: 0,
    };

    erpOrder.quantity = Math.floor(Math.random() * (1000 - 200 + 1)) + 200;
    erpOrder.quantityPercentChange = Math.floor(Math.random() * 100) - 50;

    // generate the matches

    // N2Zero -----> NEW VEHICLE
    switch (matchType) {
      case MatchType.ZERO2N: {
        erpOrder.matches = [];

        break;
      }
      case MatchType.ONE2ZERO: {
        const n2oneMatches: ErpOrder[] = [];
        n2oneMatches.push(generateOrdersMatch(1, erpOrder, true, true));
        erpOrder.matches = n2oneMatches;

        break;
      }
      case MatchType.N2ZERO: {
        const erpOrderCopy = { ...erpOrder };
        const n2oneMatches: ErpOrder[] = [];
        const numberItems = Math.floor(Math.random() * 4) + 2; // Generate a random number between 2 and 5

        for (let indexItems = 0; indexItems < numberItems; indexItems++) {
          const itemQuantity = Math.floor(Math.random() * 100) + 1; // Generate a random number between 1 and 100
          n2oneMatches.push(
            generateOrdersMatch(
              indexItems,
              { ...erpOrderCopy, quantity: itemQuantity },
              true,
              true,
            ),
          ); // productRevision changes
        }
        erpOrder.matches = n2oneMatches;

        erpOrder.quantity = n2oneMatches.reduce(
          (accumulator, current) => accumulator + current.quantity,
          0,
        );

        erpOrder.quantity = n2oneMatches.reduce(
          (accumulator, current) => accumulator + current.quantity,
          0,
        );

        break;
      }

      // manual Review
      case MatchType.N2ONE: {
        const erpOrderCopy = { ...erpOrder };
        const n2oneMatches: ErpOrder[] = [];
        const numberItems = Math.floor(Math.random() * 4) + 2; // Generate a random number between 2 and 5
        for (let itemIndex = 0; itemIndex < numberItems; itemIndex++) {
          const quantityPercentChange = Math.floor(Math.random() * 100) - 50;
          const item: ErpOrder = {
            ...erpOrderCopy,
            quantityPercentChange,
          };

          n2oneMatches.push(generateOrdersMatch(itemIndex, item, false, false));
        }

        erpOrder.matches = n2oneMatches;

        break;
      }
      // No default
    }

    // UPDATES

    // ONE2ONE without updates -----> EXISTING UNCHANGED VEHICLE
    if (matchType === MatchType.ONE2ONE && !updates) {
      const erpOrderCopy = { ...erpOrder };
      const one2oneUnchangedMatches: ErpOrder[] = [];
      const item = { ...erpOrderCopy, productRevision, quantity: 100 };
      one2oneUnchangedMatches.push(generateOrdersMatch(1, item, false, true));
      erpOrder.matches = one2oneUnchangedMatches;
    }

    // ONE2ONE with updates -----> EXISTING CHANGED VEHICLE
    else if (matchType === MatchType.ONE2ONE && updates) {
      const erpOrderCopy = { ...erpOrder };
      const one2oneUpdatedMatches: ErpOrder[] = [];
      const item = { ...erpOrderCopy, quantity: 200 }; // productRevision changes
      one2oneUpdatedMatches.push(generateOrdersMatch(1, item, true, false));
      erpOrder.matches = one2oneUpdatedMatches;
    }

    orders.push(erpOrder);
  }

  return orders;
};
