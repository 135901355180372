import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { dataSourceVehiclesState } from '../../../../services/recoil/nonpersistent/dataSourceVehiclesState';
import { MatchBuckets } from '../components/MatchBuckets';
import { IhsVehicleFull } from '../types/IhsVehicleFull';
import { MatchBucket } from '../types/MatchBucket';
import { MatchBucketType } from '../types/MatchBucketType';
import { MatchEffect } from '../types/MatchEffect';
import { MatchType } from '../types/MatchType';

export const newVehicles = (vehicles: IhsVehicleFull[]) => {
  return vehicles.filter((vehicle) => {
    if (vehicle.matchType === MatchType.ONE2ZERO || vehicle.matchType === MatchType.N2ZERO) {
      return true;
    }
    return false;
  });
};

export const unchangedVehicles = (vehicles: IhsVehicleFull[]) => {
  return vehicles.filter((vehicle) => {
    if (vehicle.matchType === MatchType.ONE2ONE && vehicle.updates === false) {
      return true;
    }
    return false;
  });
};

export const updatedVehicles = (vehicles: IhsVehicleFull[]) => {
  return vehicles.filter((vehicle) => {
    if (vehicle.matchType === MatchType.ONE2ONE && vehicle.updates === true) {
      return true;
    }
    return false;
  });
};

export const deletedVehicles = (vehicles: IhsVehicleFull[]) => {
  return vehicles.filter((vehicle) => {
    if (vehicle.matchType === MatchType.ZERO2N) {
      return true;
    }
    return false;
  });
};

export const manualReviewVehicles = (vehicles: IhsVehicleFull[]) => {
  return vehicles.filter((vehicle) => {
    if (vehicle.matchType === MatchType.N2ONE) {
      return true;
    }
    return false;
  });
};

interface Options {
  explain?: boolean;
}

export const IhsVehiclesMatchBuckets = ({ explain }: Options) => {
  const vehiclesFromState = useRecoilValue(dataSourceVehiclesState);
  const buckets: MatchBucket[] = useMemo(
    () => [
      {
        type: MatchBucketType.NEW,
        label: 'new',
        description: 'Vehicles that are new to the system',
        color: 'success' as MatchEffect['color'],
        count: newVehicles(vehiclesFromState).length,
        hideMatchIndicator: true,
      },
      {
        type: MatchBucketType.UNCHANGED,
        label: 'unchanged',
        description: 'Vehicles that have not been updated',
        color: 'success' as MatchEffect['color'],
        count: unchangedVehicles(vehiclesFromState).length,
      },
      {
        type: MatchBucketType.UPDATED,
        label: 'updated',
        description: 'Vehicles that contain information which can be automatically updated',
        color: 'info' as MatchEffect['color'],
        count: updatedVehicles(vehiclesFromState).length,
      },
      {
        type: MatchBucketType.DELETED,
        label: 'unmatched',
        description: 'Vehicles that have been deleted',
        color: 'error' as MatchEffect['color'],
        count: deletedVehicles(vehiclesFromState).length,
      },
      {
        type: MatchBucketType.MANUAL_REVIEW,
        label: 'manual matching',
        description: 'Vehicles that require manual review',
        color: 'warning' as MatchEffect['color'],
        count: manualReviewVehicles(vehiclesFromState).length,
      },
    ],
    [vehiclesFromState],
  );

  return <MatchBuckets buckets={buckets} explain={explain} />;
};
