import { getApp } from 'firebase/app';
import { doc, getFirestore, updateDoc } from 'firebase/firestore';

interface Options {
  projectId: string;
  dataSnapShotId: string;
  name: string;
  description?: string;
}

export const renameDataSnapshot = async ({
  projectId,
  dataSnapShotId,
  name,
  description,
}: Options) => {
  if (!projectId || !dataSnapShotId || !name) return;
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `projects/${projectId}/dataSnapshots/${dataSnapShotId}`;
  const firestoreDocumentReference = doc(firestoreDatabase, fireStorePath);
  const data: {
    name: string;
    description?: string;
  } = { name };
  if (description && description !== '') {
    data.description = description;
  }
  await updateDoc(firestoreDocumentReference, data);
};
