/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useRecoilValue } from 'recoil';

import { DataTimeline } from './features/DataTimeline/DataTimeline.js';
import { PivotView } from './features/PivotView/PivotView.js';
import { ProjectSummary } from './features/ProjectSummary/ProjectSummary.js';
import { applicationState } from '../../../services/recoil/nonpersistent/applicationState.js';
import { dataViewState } from '../../../services/recoil/nonpersistent/dataViewState.js';
import { KaeplaDataView } from '../../../typings/KaeplaDataView.js';
import { AiFab } from '../../AI/AiFab.js';
import { Layout } from '../../Layout/Layout.js';
import { Filters } from '../../features/Filters/Filters.js';

export const DefaultPerspective = () => {
  const application = useRecoilValue(applicationState);
  const dataView = useRecoilValue(dataViewState);

  if (dataView === KaeplaDataView.Grid) {
    return (
      <Box sx={{ display: 'flex' }}>
        <Box
          sx={{
            flexGrow: 1,
            height: '100vh',
          }}
        >
          <PivotView />
        </Box>
      </Box>
    );
  }

  return (
    <Layout hasScopeNavigation showCustomerSelector>
      <Grid container spacing={3}>
        <Grid item xs={12} md={5} lg={4}>
          <ProjectSummary />
        </Grid>
        <Grid item xs={12} md={7} lg={8}>
          <DataTimeline />
        </Grid>
        <Grid sx={{ display: application.showFilter ? 'block' : 'none' }} item xs={12}>
          <Filters />
        </Grid>
        <Grid item xs={12} sx={{ height: '100%' }}>
          <PivotView />
        </Grid>
        <AiFab />
      </Grid>
    </Layout>
  );
};
