/* eslint-disable @typescript-eslint/no-explicit-any */
import FilterIcon from '@mui/icons-material/FilterAltOutlined';
import TableIcon from '@mui/icons-material/GridOnOutlined';
import { Box, IconButton, Toolbar, Typography } from '@mui/material';
import { ColDef, ColumnState } from 'ag-grid-community';
import { Dispatch, MutableRefObject, SetStateAction } from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { ChartToolbarEditCopyDelete } from './ChartToolbarEditCopyDelete.js';
import { ChartToolbarFilter } from './ChartToolbarFilter.js';
import { ChartToolbarGridSpan } from './ChartToolbarGridSpan.js';
import { ChartToolbarZoom } from './ChartToolbarZoom.js';
import { KaeplaChartDatasets } from './StackedBarChart/getChartDataForStackedBarChart.js';
import { useAuth } from '../../../../../../AuthReactProvider.js';
import { KaeplaPerspectiveBlock } from '../../../../../../services/kaeplaTypes/Application/KaeplaPerspectiveBlock.js';
import { dataGridSettings } from '../../../../../../services/recoil/nonpersistent/dataGridSets.js';
import { dataGridState } from '../../../../../../services/recoil/nonpersistent/dataGridState.js';
import { dataViewState } from '../../../../../../services/recoil/nonpersistent/dataViewState.js';
import { gridColumnState } from '../../../../../../services/recoil/nonpersistent/gridColumnState.js';
import { matrixFilteredState } from '../../../../../../services/recoil/nonpersistent/matrixFilteredState.js';
import { perspectiveState } from '../../../../../../services/recoil/nonpersistent/perspectiveState.js';
import { projectState } from '../../../../../../services/recoil/nonpersistent/projectState.js';
import { filterSettingsState } from '../../../../../../services/recoil/persistent/filterSettingState.js';
import { KaeplaDataView } from '../../../../../../typings/KaeplaDataView.js';
import { getColumnDefinitions } from '../../PivotView/helpers/getColumnDefinitions.js';
import { KaeplaChartLabel } from '../helpers/createChartLabels.js';

interface Options {
  chartData?: KaeplaChartDatasets;
  chartLabels?: KaeplaChartLabel[];
  chartReference: MutableRefObject<undefined>;
  block: KaeplaPerspectiveBlock;
  setEditing: Dispatch<SetStateAction<boolean>>;
  editing: boolean;
  blockNumber: number;
  locked?: boolean;
  canZoomAndPan?: boolean;
  highlightFilterOverride?: boolean;
}

export const ChartHeaderToolbar = ({
  chartReference,
  block,
  setEditing,
  editing,
  blockNumber,
  locked,
  canZoomAndPan,
  highlightFilterOverride,
}: Options) => {
  const { kaeplaUser } = useAuth();
  const perspective = useRecoilValue(perspectiveState);
  const matrixFiltered = useRecoilValue(matrixFilteredState);
  const setDataView = useSetRecoilState(dataViewState);
  const setColumnState = useSetRecoilState(gridColumnState);
  const resetGridState = useResetRecoilState(dataGridState);
  const resetGridSetting = useResetRecoilState(dataGridSettings);
  const project = useRecoilValue(projectState);
  const filterSettings = useRecoilValue(filterSettingsState);
  const toolbarElements = [];

  if (highlightFilterOverride && block.filterId && filterSettings.isActive) {
    toolbarElements.push('chartFilterOverride');
  }

  if (!locked && !editing && perspective?.createdBy === kaeplaUser?.uid) {
    toolbarElements.push('chartToolbarFilter');
  }

  if (!locked && !editing && perspective?.createdBy === kaeplaUser?.uid) {
    toolbarElements.push('chartToolbarGridSpan');
  }

  if (!locked && !editing && perspective?.createdBy === kaeplaUser?.uid) {
    toolbarElements.push('chartToolbarEditCopyDelete');
  }

  if (canZoomAndPan) {
    toolbarElements.push('chartToolbarZoom');
  }

  if (toolbarElements.length === 0) {
    return null;
  }

  return (
    <Toolbar sx={{ minHeight: 16 }} disableGutters variant="dense">
      <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
      <IconButton
        size="small"
        data-testid="grid-table-button"
        color="primary"
        onClick={() => {
          if (project.defaultPerspective) {
            if (matrixFiltered?.dimensions?.dimensions) {
              const dimensions = matrixFiltered?.dimensions?.dimensions;
              const columnDefs = getColumnDefinitions(dimensions, perspective) as ColDef<
                any,
                any
              >[];
              const columnState: ColumnState[] = [];
              const valueDimension = block.valueDimension;
              const aggregation = block.aggregation;
              const aggregationDimension = block.aggregationDimension;
              columnDefs.forEach((d) => {
                if (d.field) {
                  const columnDefinition: ColumnState = {
                    colId: d.field,
                    hide: true,
                    rowGroup: false,
                    aggFunc: null,
                  };
                  if (d.field === valueDimension && aggregation) {
                    columnDefinition.aggFunc = aggregation?.toLowerCase();
                    columnDefinition.hide = false;
                  }
                  if (d.field === aggregationDimension) {
                    columnDefinition.rowGroup = true;
                  }
                  columnState.push(columnDefinition);
                }
              });
              resetGridState();
              resetGridSetting();
              setColumnState(columnState);
            }
            setDataView(KaeplaDataView.Grid);
          }
        }}
      >
        <TableIcon fontSize="small" />
      </IconButton>
      {toolbarElements.includes('chartFilterOverride') && locked && (
        <Box sx={{ mr: 1 }}>
          <FilterIcon sx={{ mb: -0.3 }} color="warning" fontSize="small" />
          <Typography variant="caption" sx={{ verticalAlign: 'text-bottom' }}>
            filter on page has no effect on this chart!
          </Typography>
        </Box>
      )}
      {toolbarElements.includes('chartToolbarFilter') && (
        <Box sx={{ mr: 1 }}>
          <ChartToolbarFilter block={block} blockNumber={blockNumber} />
        </Box>
      )}
      {toolbarElements.includes('chartToolbarGridSpan') && (
        <Box sx={{ mr: 1 }}>
          <ChartToolbarGridSpan block={block} blockNumber={blockNumber} />
        </Box>
      )}
      {toolbarElements.includes('chartToolbarEditCopyDelete') && (
        <Box sx={{ mr: 1 }}>
          <ChartToolbarEditCopyDelete
            block={block}
            setEditing={setEditing}
            blockNumber={blockNumber}
          />
        </Box>
      )}
      {toolbarElements.includes('chartToolbarZoom') && (
        <Box sx={{ mr: 1 }}>
          <ChartToolbarZoom chartReference={chartReference} />
        </Box>
      )}
    </Toolbar>
  );
};
