import { Box } from '@mui/material';

export const ItmCountInfo = (count: number, trunc = 100, unit = '') => {
  return (
    <Box
      component="span"
      sx={{
        fontFamily: 'monospace',
        color: 'grey',
        fontSize: 14,
      }}
    >
      {`[`}
      {count >= trunc ? `${count}+` : count}
      {`${unit}`}
      {`]`}
    </Box>
  );
};
