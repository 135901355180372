import { getApp } from 'firebase/app';
import { collection, DocumentData, getFirestore, doc, setDoc, Timestamp } from 'firebase/firestore';

import { KaeplaGridSetStored } from '../kaeplaTypes/Application/KaeplaGridSetStored.js';
import { KaeplaProject } from '../kaeplaTypes/Application/KaeplaProject.js';
import { FirestoreTimestamp } from '../kaeplaTypes/FirestoreTimeStamp.js';

interface Options {
  project: KaeplaProject;
  createdBy: string;
  name: string;
  gridSettingsStringified: string;
}

export const createGridSet = async ({
  project,
  createdBy,
  gridSettingsStringified,
  name,
}: Options) => {
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `projects/${project.id}/gridSets`;
  const firestoreCollectionReference = collection(firestoreDatabase, fireStorePath);
  const documentReference = doc(firestoreCollectionReference);
  const gridSet: KaeplaGridSetStored = {
    id: documentReference.id,
    projectId: project.id,
    name,
    createdBy,
    createdAt: Timestamp.now() as FirestoreTimestamp,
    gridSettingsStringified,
  };
  await setDoc(documentReference, gridSet as DocumentData);
  return gridSet;
};
